import Config from '../../json/config.json';
import themeStyles from '../../json/brand.config.json';
import axios from 'axios'
import lifestyle from './lifestyle';

function initialState(){
  // localStorage.clear()
  return {
    baseUrl: process.env.VUE_APP_URL,
    // baseUrl: Config[Config.target].base_url,
    config: Config[Config.target],
    styles: JSON.parse(localStorage.getItem('styles')) || themeStyles.brandColors,
    brandImages: localStorage.getItem('brand_logo') || '',
    practiceName: localStorage.getItem('practice_name') || '',
    appointments: [],
    currentPatient: {},
    discount: 0,
    insurancePlans: localStorage.getItem('insurance_plans') || []

  }
}

const state = initialState

const mutations = {
  set_styles(state, styles) {
    state.styles = styles
    localStorage.setItem('styles', JSON.stringify(styles))
  },
  set_brand_images(state, images){
    state.brandImages = images
    localStorage.setItem('brand_logo', images)
  },
  set_brand_name(state, name){
    state.practiceName = name
    localStorage.setItem('practice_name', name)
  },
  set_insurance(state, insurance){
    state.insurancePlans = insurance
    localStorage.setItem('insurance_plans', insurance)
  },
  set_pre_appointment(state, appointments){
    state.appointments = appointments
  },
  set_current_patient(state, patient){
    state.currentPatient = patient
  },
  set_discount(state, discount){
    state.discount = discount
  },
  clear_basecamp(state){
    // localStorage.clear()
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = {
  getBranding({ commit, state }) {
    return new Promise ((resolve, reject) => {
      axios.get(state.baseUrl + 'api/branding/show')
      .then(response => {
        if (response.data.error == 'Unauthorized'){
          reject({styles: false})
        }
        if (response.data){
          commit('set_styles', response.data.data.brandColors)
          commit('set_brand_images', response.data.data.images.logo)
          commit('set_brand_name', response.data.data.practiceName)
          commit('set_insurance',response.data.data.insurance)
          commit('set_discount', response.data.data.selfpayDiscount)
          // commit('set_frames_date', response.data.lastModifiedDate)
        }
        resolve({styles: true})
      })
      .catch(err => {
        console.log(err)
        reject({styles: false})
      })
    })
    
  },
  getPreAppointment({commit, state}) {
    return new Promise((resolve, reject) => {
      axios.get(state.baseUrl + 'api/groupNotifications?type=pre%20appointment%20info')
      .then(response => {
        if (response.data.error == 'Unauthorized'){
          reject({appointment: false})
        }
        if (response.data){
          console.log(response.data.data)
          commit('set_pre_appointment', response.data.data)
          resolve({appointment: true})
        }
      })
      .catch(err => {
        reject({appointment: false})
      })
    })
  },
  setCurrentPatient({commit, state}, patientId) {
    const patient = state.appointments.find(appoint => {
      return appoint.id == patientId
    })
    // const patient = state.appointments[patientIndex].data
    commit('set_current_patient', patient.data)
    for (let lifestyle in patient.data.lifestyle){
      commit('update_question', {key: lifestyle, status: !!+patient.data.lifestyle[lifestyle]}) //update question in lifestyle.js
    }
    for (let frameShape in patient.data.faceshape){
      commit('update_frame_shapes', {key: frameShape, status: !!+patient.data.faceshape[frameShape]})
    }

  }
  
}

const getters = {
  getStyles: state => state.styles,
  getPracticeName: state => state.practiceName,
  getLogo: state => state.brandImages,
  getAppointments: state => state.appointments,
  getCurrentPatient: state => state.currentPatient,
  getSelfpayDiscount: state => state.discount,
  getInsurancePlans: state => state.insurancePlans
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}