<template>
  <div>
    <div class="content-wrapper">
      <div class="header">We're getting ready for you! Stay tuned...</div>
      <div class="loadingio-spinner-rolling-gewnlysirl5">
      <div class="ldio-h0k0se42qt5">
      <div></div>
      </div></div>
      <div @click="reloadApp">You're connected to the internet, right? Just checking...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  methods: {
    reloadApp(){
      location.reload(true);
    }
  }
}
</script>

<style scoped>
  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 70%;
  }
  .header {
    font-weight: 600;
    font-size: 25px;
  }
  @keyframes ldio-h0k0se42qt5 {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-h0k0se42qt5 div {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 20px solid #cc9933;
    @apply border-primary3 ;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-h0k0se42qt5 div {
    animation: ldio-h0k0se42qt5 1s linear infinite;
    top: 100px;
    left: 100px
  }
  .loadingio-spinner-rolling-gewnlysirl5 {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
  }
  .ldio-h0k0se42qt5 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-h0k0se42qt5 div { box-sizing: content-box; }
  /* generated by https://loading.io/ */
</style>