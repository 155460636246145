var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "w-28 h-28 rounded-full mx-auto bg-primary3 text-primary3Text focus:outline-none flex flex-col items-center justify-center",
      on: {
        click: function($event) {
          return _vm.$emit("proceed")
        }
      }
    },
    [
      _c(
        "svg",
        {
          staticClass: "measureyes2 fill-current text-primary3Text w-20 ",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 229.68 94.51"
          }
        },
        [
          _c("g", { attrs: { id: "Layer_2", "data-name": "Layer 2" } }, [
            _c("g", { attrs: { id: "keyboard" } }, [
              _c("g", [
                _c("path", {
                  attrs: {
                    d:
                      "M229.06,13.13c-7,0-12.12-2.19-18-4.72-3-1.29-6.38-2.75-10.34-4h0c-12-3.93-23.38-5.93-39.95-2.88-11.52,2.12-19.09,7.15-23.62,10.16-1.9,1.26-3.4,2.26-4.14,2.31a21.44,21.44,0,0,1-5.3-1.12c-3.65-1-8.19-2.29-12.87-2.29s-9.24,1.27-12.9,2.29A22.18,22.18,0,0,1,96.61,14c-.74,0-2.24-1-4.15-2.31-4.53-3-12.12-8-23.64-10.16-16.54-3-27.91-1-39.9,2.88-4,1.3-7.37,2.77-10.36,4.06C12.68,11,7.6,13.13.62,13.13H0v13.3l.61,0c.06,0,6,.22,6.68,3.52.07.34.15.79.25,1.34C8.58,37,11.35,52.15,19.29,62c7.49,9.32,16.88,18,34.75,18.81,21.26.92,33-9.75,37-14.38C102.2,53.74,105,33.87,105.24,31.65l.05-.43-.38-.2a4.67,4.67,0,0,1-2.16-5.76c1-2.87,3.94-3.79,12.09-3.79s11,.88,12,3.79A4.7,4.7,0,0,1,124.66,31l-.38.2,0,.43c.28,2.22,3,22.08,14.17,34.81C142.39,70.9,153.33,80.9,173,80.9c.83,0,1.68,0,2.54-.06,17.88-.76,27.27-9.49,34.76-18.81,8-9.9,10.74-25.05,11.79-30.76.09-.53.17-1,.24-1.3.66-3.3,6.68-3.52,6.74-3.52l.61,0V13.13Zm-137.83,42a34,34,0,0,1-25.7,19.32c-10,1.5-17.47,2-28.93-4.2-10.93-5.94-18-17-19.91-31.08A29.21,29.21,0,0,1,30.86,10.3C36.69,6.94,46.49,5.07,56,5.07A56.29,56.29,0,0,1,70.66,6.84c12.1,3.35,20.71,11.58,23,18C96.14,32,96.38,44.15,91.23,55.15Zm121.64-16c-1.91,14.1-9,25.14-19.91,31.08C181.5,76.5,174.06,76,164,74.47a34,34,0,0,1-25.7-19.32c-5.14-11-4.9-23.17-2.38-30.34,2.24-6.39,10.85-14.62,23-18a56.21,56.21,0,0,1,14.68-1.77c9.49,0,19.29,1.87,25.11,5.23A29.2,29.2,0,0,1,212.87,39.19Z"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M188.2,93.89a12,12,0,0,1-2.46.53c-9.41,0-18.83,0-28.24,0q-13.73,0-27.46,0c-9.1,0-18.19,0-27.28,0H75.48l-27.76.05c-1.35,0-2.71,0-4.06-.05a3,3,0,0,1-3.18-3.22c0-4.74,0-9.48,0-14.22V59c0-1.67,0-3.35,0-5a3.16,3.16,0,0,1,3.45-3.33c4,0,8.07,0,12.1,0H78.72l20.89,0,24-.06c7.55,0,15.09,0,22.63,0,6.26,0,12.51,0,18.77,0q10.48,0,21,0a2.94,2.94,0,0,1,3.22,3.28c0,7.61,0,15.22,0,22.82,0,4.71,0,9.41.05,14.12a3,3,0,0,1-1.11,2.75Z",
                    fill: "#4c392c"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M46.5,56.74c0,4.38,0,8.54,0,12.69,0,6.06,0,12.12,0,18.18,0,.75.33.88.93.86,1.29,0,2.58,0,3.87,0h50.87c8,0,16.06,0,24.08,0,7,0,13.93,0,20.89,0l24.47,0c3.48,0,7,0,10.44,0,.81,0,1.14-.23,1.11-1.1-.06-1.67,0-3.35,0-5,0-8.22,0-16.45,0-24.67,0-.78-.23-1-1-1-2.45,0-4.9,0-7.35,0-1.23,0-1.23,0-1.22,1.21,0,3.54,0,16,0,19.57a3,3,0,0,1-3,3.16,3,3,0,0,1-3-3.1c-.05-2.71,0-5.41-.05-8.12,0-1.24,0-11.42,0-12.71-1.91,0-3.68,0-5.45,0-.75,0-.94.3-.93,1,0,1.78,0,12.49,0,14.26,0,2-1.23,3.24-3,3.2a3,3,0,0,1-3-3.3c0-1.71,0-12.36,0-14.07,0-.81-.27-1.08-1.07-1.07-2.45,0-4.91.07-7.35,0-.93,0-1.09.33-1.08,1.12.05,3.55.05,16,.08,19.58a3.2,3.2,0,0,1-1.81,3,2.79,2.79,0,0,1-2.94-.2c-1.24-.78-1.36-2-1.36-3.32,0-3.45,0-15.84,0-19.29,0-.65-.26-.86-.86-.84-.94,0-1.87,0-2.81,0l-5.21,0c0,1.73,0,12.27,0,13.88,0,1.19.11,2.41-.68,3.43a2.93,2.93,0,0,1-3.31,1,3.19,3.19,0,0,1-2.07-3c0-1.8,0-12.54,0-14.35,0-.47,0-.93-.65-.93l-6.25,0a11.71,11.71,0,0,0-.11,1.22c0,3.51,0,16,0,19.47a3.08,3.08,0,0,1-3,3.25,3,3,0,0,1-3-3.1c0-2.45,0-4.9,0-7.35,0-1.47,0-11.88,0-13.31a1.73,1.73,0,0,0-.4-.13l-7.15,0c-.59,0-.66.35-.65.83,0,1.84.05,12.62,0,14.45a3,3,0,0,1-3.76,3.08c-1.5-.37-2.3-1.58-2.29-3.48V56.71c-2.44,0-4.78,0-7.12.07-.19,0-.52.6-.53.92,0,3.19,0,15.32,0,18.51,0,1.18.12,2.41-.75,3.41a2.92,2.92,0,0,1-3.33.85,3.15,3.15,0,0,1-2-3.13c0-3.52,0-16,0-19.49,0-.8-.23-1.17-1.1-1.16-2.49.05-5,.07-7.45,0-.72,0-.9.26-.9.89,0,1.91,0,12.75-.05,14.65a2.92,2.92,0,0,1-3.19,2.91A3.26,3.26,0,0,1,68.24,72c.09-1.81,0-12.55,0-14.36,0-.48,0-.94-.68-.94l-4.93,0c-.55,0-.83.22-.82.81,0,1.61,0,12.16,0,13.77,0,2.16,0,4.32,0,6.47a2.84,2.84,0,0,1-2.53,2.85A2.76,2.76,0,0,1,56,78.55a8.3,8.3,0,0,1-.23-2.28c0-3.2,0-15.33,0-18.52,0-.83-.3-1.08-1.07-1-1.39,0-2.78,0-4.16.06Z"
                  }
                })
              ])
            ])
          ])
        ]
      ),
      _c("span", [_vm._v("calculate")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }