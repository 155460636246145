var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-10/12 bg-background text-backgroundText flex flex-col" },
    [
      _c(
        "header",
        {
          staticClass:
            "h-2/12 w-full flex justify-center items-center font-bold text-xl"
        },
        [_vm._v("Let's get started!")]
      ),
      _c("main", { staticClass: "w-full h-10/12 mb-10" }, [
        _c(
          "div",
          {
            staticClass:
              "w-full h-6/12 bg-primary2 flex justify-around items-center"
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "tiles w-3/12 h-10/12 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline",
                on: { click: _vm.openDemo }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-20 h-18 ml-2 flex justify-center items-center"
                  },
                  [_c("div", { staticClass: "w-20" }, [_c("LensIconSvg")], 1)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "h-10 flex justify-center items-center text-lg"
                  },
                  [_vm._v("Lens Features")]
                )
              ]
            ),
            _c(
              "button",
              {
                staticClass:
                  "tiles w-3/12 h-10/12 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline",
                on: { click: _vm.openPhotobooth }
              },
              [
                _c(
                  "div",
                  { staticClass: "w-20 h-18 flex justify-center items-center" },
                  [_c("div", { staticClass: "w-18" }, [_c("PhotoBoothSvg")], 1)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "h-10 flex justify-center items-center text-lg"
                  },
                  [_vm._v("Photobooth")]
                )
              ]
            ),
            _c(
              "button",
              {
                staticClass:
                  "relative tiles w-3/12 h-10/12 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline",
                on: { click: _vm.openMeasureyes }
              },
              [
                _c(
                  "div",
                  { staticClass: "w-24 h-16 flex justify-center items-center" },
                  [_c("div", { staticClass: "w-24" }, [_c("MeasureyesSvg")], 1)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "h-10 flex justify-center items-center text-lg"
                  },
                  [_vm._v("Measureyes")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-20 h-6 bg-primary3 text-primary3Text absolute top-0 left-0 flex justify-center items-center transform -rotate-25 mt-6 ml-2 font-medium"
                  },
                  [_vm._v("BETA")]
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "w-full h-6/12 bg-primary3 flex justify-center items-center"
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "tiles w-4/12 h-10/12 mr-6 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline",
                on: { click: _vm.openAppointments }
              },
              [
                _c(
                  "div",
                  { staticClass: "w-20 h-18 flex justify-center items-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "w-18" },
                      [_c("ImportCustomerSvg")],
                      1
                    )
                  ]
                ),
                _vm.currentPatient.user
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "h-10 flex justify-center items-center text-lg"
                      },
                      [_vm._v("Hi " + _vm._s(_vm.currentPatient.user.name))]
                    )
                  : _vm.appointments.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "h-10 flex justify-center items-center text-lg"
                      },
                      [_vm._v("Import Customer")]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "h-10 flex justify-center items-center text-lg"
                      },
                      [_vm._v("No Appointments")]
                    )
              ]
            ),
            _c(
              "button",
              {
                staticClass:
                  "tiles w-4/12 h-10/12 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline",
                class: { disabled: !_vm.databasesLoaded },
                on: { click: _vm.eyewearBuilder }
              },
              [
                _c(
                  "div",
                  { staticClass: "w-24 h-16 flex justify-center items-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "w-24" },
                      [_c("EyewearBuilderSvg")],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "h-10 flex justify-center items-center text-lg"
                  },
                  [_vm._v("Eyewear Builder")]
                ),
                !_vm.databasesLoaded
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "h-10 flex justify-center items-center text-lg"
                      },
                      [_vm._v("Databases Loading...")]
                    )
                  : _vm._e()
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }