import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import { event } from 'vue-analytics';
// import Config from '../../json/config.json'

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_ID//Insert Google Analytics ID here
  // id: Config[Config.target].ga_tracking_id //Insert Google Analytics ID here
})

const state = () => ({
})

const mutations = {
  send_event(state, payload){
    event(payload)
  }
}

const actions = {
  sendEvent({commit},payload){
    commit('send_event', payload)
  }


}

const getters = {
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
