<template>
<!-- <button @click="closeApp()" class="w-full flex items-center justify-center focus:outline-none portrait:w-2/12"> -->
  <button @click="closeApp()" class="h-2/12 w-full flex items-center justify-center focus:outline-none">
  <div class="text-lightGrayText w-5">
      <IconXSvg />
  </div>
    <!-- <svg class="icon_x fill-current stroke-current " :style="{color: color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8">
      <g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2">
        <path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z" />
        <path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/>
      </g>
    </svg> -->
  </button>
</template>
<script>
import IconXSvg from "../../assets/svg/icons/icon_x.svg";

export default {
  name: 'CloseButton',
  methods: {

  },
  computed:{

  },
  components: {
    IconXSvg
  },
  props: {
    color:String,
    closeApp: Function
  },
}
</script>