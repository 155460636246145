<template>
    <section id='demo-menu' class="blue-light h-full relative flex">
      <div class="">
          <CompareSlider
            leftImage="./img/demos/blueLight_none.jpg"
            rightImage="./img/demos/blueLight.jpg"
          />
          <label  class="absolute left-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl">Blue Light</label>
          <label class="absolute right-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl">Standard</label>
      </div>
    </section>
</template>

<script>
import CompareSlider from "./CompareSlider.vue";

export default {
  name: 'BlueLight',
  components: {
    CompareSlider
  },
  data() {
    return {

    };
  },
  methods: {

  },
  props: {

  },
  computed: {

  },
};
</script>

<style scoped>

</style>
