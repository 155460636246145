<template>
    <div class="Lens-measurements-popup">
        <section class="h-full w-full flex justify-center items-center bg-backOpacity absolute top-0 right-0 z-1000">
            <div class="h-10/12 w-11/12 flex flex-col relative bg-background text-backgroundText border-4 border-backgroundText mx-auto rounded-lg rounded-tl-none">
                <div class="w-full h-1/12 flex justify-between items-center">
                    <!-- <div class="w-7/12 text-2xl flex justify-end items-center">measureyes help</div> -->
                    <button @click="closeLensMeasurementsPopup()" class="w-full pr-6 flex items-center justify-end focus:outline-none">
                        <svg class="icon_x fill-current stroke-current text-backgroundText w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8">
                        <g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2">
                            <path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z" />
                            <path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/>
                        </g>
                        </svg>
                    </button>
                </div>
                <main class="h-11/12 w-full flex flex-col items-center justify-around">
                    <div class="w-full text-2xl flex justify-center items-center -mt-10">measureyes help</div>
                    <p class="h-1/12">You can find your A. B, & DBL Values on the interior arm of most frames.</p>
                    <div class="relative w-10/12 h-6/12">
                        <img src="../../assets/measure_Frame2-50.jpg" class="absolute left-0 top-0 h-full w-full object-cover" alt="">
                    </div>
                    <div class="h-3/12 flex flex-col justify-between pb-6">
                        <p>57 = Lens Width (measured on the interior frame)</p>
                        <p>19 = Bridge Width (narrowest distance between the lenses)</p>
                        <p>140 = Temple Length (complete length of temple arem)</p>
                    </div>
                </main>
            </div>
        </section>

    </div>
</template>

<script>
export default {
  name: 'LensMeasurementsPopup',
  data() {
    return{
        localLensPopup: this.showLensMeasurementsPopup
    }
  },
  props: {
    showLensMeasurementsPopup: Boolean,
    closeLensMeasurementsPopup: Function
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
