var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "lens-type bg-background text-backgroundText h-10/12" },
    [
      _c(
        "section",
        {
          staticClass:
            "h-2/12 w-full flex items-center justify-between portrait:h-1/12"
        },
        [
          _c("div", { staticClass: "flex items-center ml-4" }, [
            _c("div", { staticClass: "w-10 mr-6" }, [_c("BackButton")], 1),
            _vm.currentPatient.user
              ? _c("p", { staticClass: "text-xl" }, [
                  _vm._v(
                    _vm._s(_vm.currentPatient.user.name) +
                      "'s Optical Treatment Plan:"
                  )
                ])
              : _c("p", { staticClass: "text-xl" }, [
                  _vm._v("Optical Treatment Plan:")
                ])
          ])
        ]
      ),
      _c(
        "section",
        {
          staticClass:
            "h-10/12 w-full portrait:h-11/12 flex flex-col px-20 portrait:px-5"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "h-10/12 portrait:h-10/12 flex justify-center items-center"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-full h-10/12 portrait:h-full flex flex-col items-center portrait:flex-col portrait:items-center"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "h-10 w-10/12 flex items-center font-semibold portrait:justify-center"
                    },
                    [_vm._v("Type:")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full h-9/12 flex justify-center items-center portrait:justify-between portrait:h-11/12 portrait:w-full portrait:flex-col"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-48 w-5/12 flex flex-col justify-around items-center portrait:h-6/12\n                        border-toggleGray border-2 landscape:border-r-0 rounded-lg rounded-tl-none landscape:rounded-tr-none rounded-br-none portrait:rounded-bl-none",
                          class: {
                            activeLensType: _vm.typeSelected == "progressive"
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeType("progressive")
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: " w-full flex" }, [
                            _vm.typeSelected == "progressive"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-16 flex items-center justify-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "w-8 text-primary1Text" },
                                      [_c("CheckboxSelectedSvg")],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.typeSelected != "progressive"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-16 flex items-center justify-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "w-8" },
                                      [_c("CheckboxBlankSvg")],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._m(0)
                          ]),
                          _c(
                            "div",
                            { staticClass: "w-48" },
                            [_c("LifestyleProgressiveSvg")],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-48 w-5/12 flex flex-col justify-around items-center portrait:h-6/12\n                        border-toggleGray border-2  rounded-lg rounded-tl-none landscape:rounded-bl-none portrait:rounded-tr-none",
                          class: {
                            activeLensType: _vm.typeSelected == "single"
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeType("single")
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "w-full flex" }, [
                            _vm.typeSelected == "single"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-16 flex items-center justify-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "w-8 text-primary1Text" },
                                      [_c("CheckboxSelectedSvg")],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.typeSelected != "single"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-16 flex items-center justify-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "w-8" },
                                      [_c("CheckboxBlankSvg")],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._m(1)
                          ]),
                          _c(
                            "div",
                            { staticClass: "w-48" },
                            [_c("LifestyleSingleSvg")],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "h-2/12 w-full flex items-center justify-end portrait:justify-center portrait:items-end portrait:mb-4"
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "underline h-12 w-48 pb-1 flex justify-center items-center text-xl portrait:mb-0 portrait:mr-0 bg-primary3 text-primary3Text rounded-lg rounded-tl-none focus:outline-none",
                  on: { click: _vm.generatePlan }
                },
                [_vm._v(" Continue ")]
              )
            ]
          )
        ]
      ),
      _vm.showProgressPopup
        ? _c("ProgressPopup", { attrs: { pageName: _vm.pageName } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center" }, [
      _c(
        "label",
        { staticClass: "h-10 text-xl flex justify-center items-end ml-6" },
        [_vm._v("Progressive")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center" }, [
      _c(
        "label",
        { staticClass: "h-10 text-xl flex justify-center items-end ml-6" },
        [_vm._v("Single Vision")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }