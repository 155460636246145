var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-48 rounded-md rounded-tl-none focus:outline-none bg-primary3 flex flex-col items-center button"
    },
    [
      _c("span", { staticClass: "w-40 my-2 text-primary3Text" }, [
        _vm._v("patient's " + _vm._s(_vm.eye))
      ]),
      _c(
        "div",
        {
          staticClass:
            "h-10 w-40 rounded-md text-textDark rounded-tl-none bg-white flex justify-around items-center mb-1",
          class: {
            hasMeasurementClass: _vm.hasMeasurement("pupil"),
            isSelectedClass: _vm.isSelected("pupil")
          },
          on: {
            click: function($event) {
              return _vm.setPoint($event, "pupil")
            }
          }
        },
        [
          _c("span", { staticClass: "mr-10" }, [_vm._v("pupil")]),
          !_vm.hasMeasurement("pupil")
            ? [_c("span", { staticClass: "underline" }, [_vm._v("...")])]
            : [
                _c("div", [
                  _c(
                    "svg",
                    {
                      staticClass: "w-6 fill-current text-primary3",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 60.31 60.31"
                      }
                    },
                    [
                      _c("g", { attrs: { "data-name": "Layer 2" } }, [
                        _c("circle", {
                          attrs: { cx: "30.16", cy: "30.16", r: "30.16" }
                        }),
                        _c("path", {
                          staticClass: "stroke-current text-primary3Text",
                          attrs: {
                            fill: "none",
                            "stroke-miterlimit": "10",
                            "stroke-width": "6.32",
                            d: "M10.23 31.64L21.9 44.21l28.03-29.37"
                          }
                        })
                      ])
                    ]
                  )
                ])
              ]
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "h-10 w-40 shadow-shadowBrownOutter rounded-md text-textDark rounded-tl-none bg-white flex justify-between items-center px-4 mb-1",
          class: {
            hasMeasurementClass: _vm.hasMeasurement("top"),
            isSelectedClass: _vm.isSelected("top")
          },
          on: {
            click: function($event) {
              return _vm.setPoint($event, "top")
            }
          }
        },
        [
          _c("span", { staticClass: "mr-4" }, [_vm._v("top")]),
          !_vm.hasMeasurement("top")
            ? [_c("span", { staticClass: "underline" }, [_vm._v("...")])]
            : [
                _c("div", [
                  _c(
                    "svg",
                    {
                      staticClass: "w-6 fill-current text-primary3",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 60.31 60.31"
                      }
                    },
                    [
                      _c("g", { attrs: { "data-name": "Layer 2" } }, [
                        _c("circle", {
                          attrs: { cx: "30.16", cy: "30.16", r: "30.16" }
                        }),
                        _c("path", {
                          staticClass: "stroke-current text-primary3Text",
                          attrs: {
                            fill: "none",
                            "stroke-miterlimit": "10",
                            "stroke-width": "6.32",
                            d: "M10.23 31.64L21.9 44.21l28.03-29.37"
                          }
                        })
                      ])
                    ]
                  )
                ])
              ]
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "h-10 w-40 shadow-shadowBrownOutter rounded-md text-textDark rounded-tl-none bg-white flex justify-between items-center px-4 mb-1",
          class: {
            hasMeasurementClass: _vm.hasMeasurement("temporal"),
            isSelectedClass: _vm.isSelected("temporal")
          },
          on: {
            click: function($event) {
              return _vm.setPoint($event, "temporal")
            }
          }
        },
        [
          _c("span", [_vm._v("temporal")]),
          !_vm.hasMeasurement("temporal")
            ? [_c("span", { staticClass: "underline" }, [_vm._v("...")])]
            : [
                _c("div", [
                  _c(
                    "svg",
                    {
                      staticClass: "w-6 fill-current text-primary3",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 60.31 60.31"
                      }
                    },
                    [
                      _c("g", { attrs: { "data-name": "Layer 2" } }, [
                        _c("circle", {
                          attrs: { cx: "30.16", cy: "30.16", r: "30.16" }
                        }),
                        _c("path", {
                          staticClass: "stroke-current text-primary3Text",
                          attrs: {
                            fill: "none",
                            "stroke-miterlimit": "10",
                            "stroke-width": "6.32",
                            d: "M10.23 31.64L21.9 44.21l28.03-29.37"
                          }
                        })
                      ])
                    ]
                  )
                ])
              ]
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "h-10 w-40 shadow-shadowBrownOutter rounded-md text-textDark rounded-tl-none bg-white flex justify-between items-center px-4 mb-1",
          class: {
            hasMeasurementClass: _vm.hasMeasurement("bottom"),
            isSelectedClass: _vm.isSelected("bottom")
          },
          on: {
            click: function($event) {
              return _vm.setPoint($event, "bottom")
            }
          }
        },
        [
          _c("span", [_vm._v("bottom")]),
          !_vm.hasMeasurement("bottom")
            ? [_c("span", { staticClass: "underline" }, [_vm._v("...")])]
            : [
                _c("div", [
                  _c(
                    "svg",
                    {
                      staticClass: "w-6 fill-current text-primary3",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 60.31 60.31"
                      }
                    },
                    [
                      _c("g", { attrs: { "data-name": "Layer 2" } }, [
                        _c("circle", {
                          attrs: { cx: "30.16", cy: "30.16", r: "30.16" }
                        }),
                        _c("path", {
                          staticClass: "stroke-current text-primary3Text",
                          attrs: {
                            fill: "none",
                            "stroke-miterlimit": "10",
                            "stroke-width": "6.32",
                            d: "M10.23 31.64L21.9 44.21l28.03-29.37"
                          }
                        })
                      ])
                    ]
                  )
                ])
              ]
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "h-10 w-40 shadow-shadowBrownOutter rounded-md text-textDark rounded-tl-none bg-white flex justify-between items-center px-4 mb-2",
          class: {
            hasMeasurementClass: _vm.hasMeasurement("nasal"),
            isSelectedClass: _vm.isSelected("nasal")
          },
          on: {
            click: function($event) {
              return _vm.setPoint($event, "nasal")
            }
          }
        },
        [
          _c("span", [_vm._v("nasal")]),
          !_vm.hasMeasurement("nasal")
            ? [_c("span", { staticClass: "underline" }, [_vm._v("...")])]
            : [
                _c("div", [
                  _c(
                    "svg",
                    {
                      staticClass: "w-6 fill-current text-primary3",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 60.31 60.31"
                      }
                    },
                    [
                      _c("g", { attrs: { "data-name": "Layer 2" } }, [
                        _c("circle", {
                          attrs: { cx: "30.16", cy: "30.16", r: "30.16" }
                        }),
                        _c("path", {
                          staticClass: "stroke-current text-primary3Text",
                          attrs: {
                            fill: "none",
                            "stroke-miterlimit": "10",
                            "stroke-width": "6.32",
                            d: "M10.23 31.64L21.9 44.21l28.03-29.37"
                          }
                        })
                      ])
                    ]
                  )
                ])
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }