var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "help-page h-full w-full flex justify-center items-center bg-backOpacity absolute top-0 right-0 z-100"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "h-10/12 w-11/12 relative bg-background text-backgroundText border-4 border-backgroundText mx-auto rounded-lg rounded-tl-none"
        },
        [
          _c(
            "section",
            { staticClass: "h-16 w-full flex items-center justify-between" },
            [
              _c("div", { staticClass: "w-1/4 portrait:w-2/12" }),
              _vm._m(0),
              _c("div", { staticClass: "w-1/4" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-full h-2/12 flex items-center justify-end focus:outline-none pr-8",
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass:
                          "icon_x fill-current stroke-current text-backgroundText w-5",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 73.86 73.8"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "4.85",
                              "fill-rule": "evenodd",
                              "data-name": "Layer 2"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._m(1)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex flex-col justify-center items-center pt-4 z-100" },
      [
        _c("p", { staticClass: "text-2xl" }, [_vm._v("measureyes help")]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v("points should be positioned to the:")
        ]),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v("outermost point of lens & inner most edge of frame")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      {
        staticClass:
          "h-10/12 w-full flex justify-center items-center -mt-4 portrait:m-0"
      },
      [
        _c("div", { staticClass: "relative h-full w-11/12" }, [
          _c("img", {
            staticClass: "absolute left-0 top-0 h-full w-full object-contain",
            attrs: { src: require("../../assets/MeasureHelp.png"), alt: "" }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }