<template>
    <div class="transitions-popup h-10/12 w-full flex justify-center items-center  fixed bottom-0 left-0 z-1000">
    <!-- <div @click="closeTransitionsPopup">x</div> -->
        <div class="h-8/12 w-11/12 xs:h-full xs:w-full portrait:flex-wrap flex items-center portrait:flex-col border-2 border-primary2 bg-background text-backgroundText portrait:w-10/12 portrait:h-8/12">
            <!-- <div class="h-full w-6/12 flex flex-wrap portrait:w-full portrait:h-6/12"> -->
                <section class="h-full w-6/12 border-2 border-primary2 flex justify-center items-center portrait:h-6/12">
                    <div class="h-full w-11/12 md:w-10/12 flex flex-col items-center">
                        <div class="relative h-2/12 w-10/12 self-start ">
                            <img class="absolute left-0 bottom-0 h-full w-full object-contain" src="../../../assets/img/transitions/logo_XTRActive.png" alt="" srcset="">
                        </div>
                        <div class="text relative w-full text-left xs:text-xs my-3">
                            XTRActive is the only lens designed to darken while you're behind the wheel.
                        </div>
                        <button @click.stop="changeDemo(option.name)" :class="{activeBtn: currentOption == option.name}" v-for="option in XTRActiveColors" :key="option.id" class="transitionButton w-full h-8 sm:h-10 md:h-12 border-2 border-toggleGray rounded-lg rounded-tl-none flex justify-center items-center">
                            <div class="relative h-7/12 w-5/12 mr-4">
                                <img :src="option.img" alt="" class="absolute left-0 bottom-0 h-full w-full object-contain" srcset="">
                            </div>
                            <div class="relative h-7/12 w-7/12 flex items-center">gray</div>
                        </button>
                    </div>
                </section>
                <section class="h-full w-6/12 border-2 border-primary2 flex justify-center items-center portrait:h-6/12">
                    <div class="h-full w-11/12 md:w-10/12 flex flex-col items-center">
                        <div class="relative h-2/12 w-11/12 self-start">
                            <img class="absolute left-0 bottom-0 h-full w-full object-contain" src="../../../assets/img/transitions/transitions_Signature.png" alt="" srcset="">
                        </div>
                        <div class="h-2/12 text relative w-full text-left xs:text-xs my-3">
                            Our most popular style.
                        </div>
                        <button @click.stop="changeDemo(option.name)" :class="{activeBtn: currentOption == option.name}" v-for="option in transitionColors" :key="option.id" class="transitionButton w-full h-8 my-1 sm:h-10 md:h-12 border-2 border-toggleGray rounded-lg rounded-tl-none flex justify-center items-center">
                            <div class="relative h-7/12 w-5/12 mr-4">
                                <img :src="option.img" alt="" class="absolute left-0 bottom-0 h-full w-full object-contain" srcset="">
                            </div>
                            <div class="relative h-7/12 w-7/12 flex items-center">{{option.name}}</div>
                        </button>
                    </div>
                </section>
            <!-- </div> -->
            <!-- <div class="h-full w-6/12 flex portrait:w-full portrait:h-6/12"> -->
                <section class="h-full w-6/12 border-2 border-primary2 flex justify-center items-center portrait:h-6/12">
                    <div class="h-full w-11/12 md:w-10/12 flex flex-col items-center">
                        <div class="relative h-2/12 w-6/12 self-start ">
                            <img class="absolute left-0 bottom-0 h-full w-full object-contain pt-2" src="../../../assets/img/transitions/logo_StyleColors.png" alt="" srcset="">
                        </div>
                        <div class="h-2/12 text relative w-full text-left xs:text-xs my-3">
                            Show your style with the newest colors.
                        </div>
                        <button @click.stop="changeDemo(option.name)" :class="{activeBtn: currentOption == option.name}"  v-for="option in styleColors" :key="option.id" class="transitionButton w-full h-8 my-1 sm:h-10 md:h-12 border-2 border-toggleGray rounded-lg rounded-tl-none flex justify-center items-center">
                            <div class="relative h-7/12 w-5/12 mr-4">
                                <img :src="option.img" alt="" class="absolute left-0 bottom-0 h-full w-full object-contain" srcset="">
                            </div>
                            <div class="relative h-7/12 w-7/12 flex items-center">{{option.name}}</div>
                        </button>
                    </div>
                </section>
                <section class="h-full w-6/12 border-2 border-primary2 flex justify-center items-center portrait:h-6/12">
                    <div class="h-full w-11/12 md:w-10/12 flex flex-col items-center ">
                        <div class="relative h-2/12 w-10/12 self-start text-left text-base leading-5 xs:leading-4 font-semibold pt-2">
                            TRANSITIONS MIRRORS
                        </div>
                        <div class="h-2/12 text relative w-full text-left xs:text-xs my-3">
                            Clear indoors, activates in sunlight!
                        </div>
                        <button @click.stop="changeDemo(option.name)" :class="{activeBtn: currentOption == option.name}" v-for="option in mirrorColors" :key="option.id" class="transitionButton w-full h-8 my-1 sm:h-10 md:h-12 border-2 border-toggleGray rounded-lg rounded-tl-none flex justify-center items-center">
                            <div class="relative h-7/12 w-5/12 mr-4">
                                <img :src="option.img" alt="" class="absolute left-0 bottom-0 h-full w-full object-contain" srcset="">
                            </div>
                            <div class="relative h-7/12 w-7/12 flex items-center">{{option.name}}</div>
                        </button>
                    </div>
                </section>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>


export default {
  name: 'TransitionsPopup',
    data() {
        return{
            XTRActiveColors: [
                {name:'extraGray',img:'./img/transitions/icon_XTRA.png'},
            ],
            styleColors: [
                {name:'amber',img:'./img/transitions/icon_Amber.png'},
                {name:'amethyst',img:'./img/transitions/icon_Amethyst.png'},
                {name:'sapphire',img:'./img/transitions/icon_Sapphire.png'},
                {name:'emerald',img:'./img/transitions/icon_Emerald.png'},
            ],
            transitionColors: [
                {name:'gray', img:'./img/transitions/icon_Gray.png'},
                {name:'brown', img:'./img/transitions/icon_Brown.png'},
            ],
            mirrorColors: [
                {name:'color1', img:'./img/transitions/icon_Amber.png'},
                {name:'color2', img:'./img/transitions/icon_Amethyst.png'},
                {name:'color3',img:'./img/transitions/icon_Sapphire.png'},
            ],
        }
    },
    components: {

    },
    methods: {
        closeTransitionsPopup(){
            this.$store.dispatch('toggleTransitionsPopup')
        },
    },
    computed: {

    },
    props: {
        changeDemo: Function,
        currentOption: String,
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .activeBtn {
        @apply rounded-lg rounded-tl-none text-toggleGrayText no-underline
        bg-toggleGray shadow;
    }

    @media screen and ( max-height: 375px ) and (min-width: 500px) and (max-width: 897px){
        .transitions-popup {
            @apply text-xs
        }
        .text{
            @apply text-xs
        }
        .transitionButton {
            @apply h-8
        }

  }
</style>
