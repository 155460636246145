import { isEmpty } from "../../common/functions";
const cloneDeep = require('clone-deep');
import Vue from 'vue'

function clone(obj) { //clone js object
  if (obj == null || typeof (obj) != 'object')
    return obj;

  var temp = new obj.constructor();
  for (var key in obj)
    temp[key] = clone(obj[key]);

  return temp;
}

function initialState() {
  return {
    packages: {},
    packageMaster: {},
    packagesArray: [],
    currentLevelIndex: 0,
    currentPackageIndex: 0,
    defaultLevel: 'premium',
    currentInsurance: 'selfpay'
  }
}

const state = initialState

const mutations = {
  generate_packages(state, generatedPackages) {
    state.packages = cloneDeep(generatedPackages)
    state.packageMaster = cloneDeep(generatedPackages)
  },
  create_temp_package(state, tempPackage) {
    tempPackage.pack.edited = tempPackage.edited
    Vue.set(state.packagesArray, tempPackage.packageIndex, tempPackage.pack)
  },
  reset_edited(state){
    const temp = state.packagesArray[state.currentPackageIndex]
    temp.edited = false
    Vue.set(state.packagesArray, state.currentPackageIndex, temp)
  },
  revert_temp_package(state, info) {
    const masterPack = state.packageMaster[info.type].find(elem => {
      return info.index == elem.ind
    })
    // let changingPack = state.packages[info.type].find(elem => {
    //   info.index == elem.ind
    // })
    for (let packIndex in state.packages[info.type]) {
      if (state.packages[info.type][packIndex].ind == info.index) {
        state.packages[info.type][packIndex] = clone(masterPack)
        state.packages[info.type][packIndex].edited = info.edited
      }
    }
    // state.packages[info.type].forEach(elem => {
    //   if (elem.ind == info.index){
    //     elem = clone(masterPack)
    //     elem.edited = info.edited
    //   }
    // })
    state.packages = state.packages

  },
  set_index(state, ind) {
    state.currentLevelIndex = ind
  },
  set_current_insurance(state, insurance){
    state.currentInsurance = insurance
  },
  create_package_array(state, packArray){
    state.packagesArray = packArray
  },
  clear_packages(state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = {
  generatePackages({ commit, rootState }) {
    return new Promise((resolve, reject) => {
    const lenses = rootState.databases.lenses
    const packages = rootState.databases.packages
    if (isEmpty(packages)){
      reject({packages: 'Not Found'})
    }
    let generatedPackages = {}
    packages.forEach((pack, index) => {
      const base = lenses.find(obj => {
        return obj.code === pack.base
      })
      const materialArray = lenses.filter(obj => {
        return obj.code === pack.material
      })
      let material = {}
      if (materialArray.length > 1){
        material = materialArray.find(mat => {
          // if ((mat.mod.toLowerCase() == base.lensType.toLowerCase()) && !mat.lensType) {
          //   return
          // }
          // else if ((mat.mod.toLowerCase() == base.lensType.toLowerCase()) && base.mod == 'digital' && mat.lensType == 'aspheric'){
          //   return
          // }
          // else if ((mat.mod.toLowerCase() == base.lensType.toLowerCase()) && base.mod != 'digital' && mat.lensType == 'nonaspheric'){
          //   return
          // }
          return (mat.mod.toLowerCase() == base.lensType.toLowerCase()) && (base.mod == mat.lensType)
        })
        
      }
      else {
        material = materialArray[0]
      }
      // const addon = lenses.find(obj => {
      //   return obj.code === pack.addons
      // })
      let addons = []
      pack.addons.forEach(add => {
        addons.push(lenses.find(obj => {
          if (obj.mod){
            return obj.code === add && obj.mod === pack.type
          }
          else {
            return obj.code === add
          }
          
        }))
      })
      const insurance = pack.insurance
      
      const type = pack.type
      let level = pack.level
      let mod = pack.mod
      const option = pack.options
      const price = pack.price
      if (!Array.isArray(generatedPackages[type])) {
        generatedPackages[type] = []
      }
      generatedPackages[type].push({ 'base': base, 'material': material, 'addons': addons, 'enchancements': [], 'edited': false, 'price': price, 'transition': {}, 'polarized': {}, 'color': {}, 'mirror': {}, 'mod': mod, 'level': level, 'option': option, 'ind': index, 'insurance': insurance })

    })
    commit('generate_packages', generatedPackages)
    resolve({packages: 'found'})
    })
  },
  createTempPackage({ commit }, tempPackage) {
    commit('create_temp_package', tempPackage)
  },
  resetEdited({commit}){
    commit('reset_edited')
  },
  revertTempPackage({ commit, state }, info) {
    
    const masterPack = state.packageMaster[info.type].find(elem => {
      return info.index == elem.ind
    })
    commit('revert_temp_package', info)
    return new Promise((resolve, reject) => {
      resolve(masterPack)
    })
  },
  setIndex({ commit }, ind) {
    commit('set_index', ind)
  },
  createPackageArray({commit, state, rootState}){
    return new Promise((resolve, reject) => {
    if (!state.packages){
      reject({packages: false})
    }
    let currentArray = []
      let levelObject = {
        premium: [],
        standard: [],
        economy: []
      }
      const insurance = state.currentInsurance
      const currentType = rootState.cart.cart.cartItems[rootState.cart.currentIndex].lensType
      let packArray = state.packages[currentType]
      let mod = ''
       if (rootState.cart.currentIndex == "polarized" || rootState.cart.currentIndex == "computer"){
        mod = rootState.cart.currentIndex
      }
        packArray.forEach(pack => {
          if (pack.mod == mod && (pack.insurance.includes(insurance) || insurance == 'Other') ){
            if (levelObject[pack.level]){
              levelObject[pack.level].push(pack)
            }
            // currentArray.push(pack)
          }
        })
        currentArray = levelObject.premium.concat(levelObject.standard.concat(levelObject.economy))
        commit('create_package_array',currentArray)
        resolve({packages: currentArray.length})
      })
  },
  setInsurance({commit}, insurance){
    commit('set_current_insurance', insurance)
  }
}

const getters = {
  getPackages: state => cloneDeep(state.packages),
  getCurrentLevelIndex: state => state.currentLevelIndex,
  getCurrentInsurance: state => state.currentInsurance,
  getPackageArray: state => state.packagesArray,
  getCurrentPackage: state => state.packagesArray[state.currentPackageIndex],
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
