<template>
  <div class="w-48 rounded-md rounded-tl-none focus:outline-none bg-primary3 flex flex-col items-center button">
    <span class="w-40 my-2 text-primary3Text">patient's {{eye}}</span>
    <!-- v-bind:style="[isSelected('pupil') ? styleObj : '']"  -->
    <div :class="{'hasMeasurementClass' : hasMeasurement('pupil'), 'isSelectedClass' : isSelected('pupil')}" @click="setPoint($event, 'pupil')" class="h-10 w-40 rounded-md text-textDark rounded-tl-none bg-white flex justify-around items-center mb-1">
      <span class="mr-10">pupil</span>
      <template v-if="!hasMeasurement('pupil')">
        <span class="underline">...</span>
      </template>
      <template v-else>
        <div>
          <svg class="w-6 fill-current text-primary3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.31 60.31">
            <g data-name="Layer 2">
              <circle cx="30.16" cy="30.16" r="30.16" />
              <path class="stroke-current text-primary3Text" fill="none" stroke-miterlimit="10" stroke-width="6.32" d="M10.23 31.64L21.9 44.21l28.03-29.37"/>
            </g>
          </svg>
        </div>
      </template>
    </div>
    <!-- v-bind:style="[isSelected('top') ? styleObj : '']" -->
    <div :class="{'hasMeasurementClass' : hasMeasurement('top'), 'isSelectedClass' : isSelected('top')}" @click="setPoint($event, 'top')" class="h-10 w-40 shadow-shadowBrownOutter rounded-md text-textDark rounded-tl-none bg-white flex justify-between items-center px-4 mb-1">
      <span class="mr-4">top</span>
      <template v-if="!hasMeasurement('top')">
        <span class="underline">...</span>
      </template>
      <template v-else>
        <div>
          <svg class="w-6 fill-current text-primary3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.31 60.31">
            <g data-name="Layer 2">
              <circle cx="30.16" cy="30.16" r="30.16" />
              <path class="stroke-current text-primary3Text" fill="none" stroke-miterlimit="10" stroke-width="6.32" d="M10.23 31.64L21.9 44.21l28.03-29.37"/>
            </g>
          </svg>
        </div>
      </template>
    </div>
    <div :class="{'hasMeasurementClass' : hasMeasurement('temporal'), 'isSelectedClass' : isSelected('temporal')}" @click="setPoint($event, 'temporal')" class="h-10 w-40 shadow-shadowBrownOutter rounded-md text-textDark rounded-tl-none bg-white flex justify-between items-center px-4 mb-1">
      <span>temporal</span>
      <template v-if="!hasMeasurement('temporal')">
        <span class="underline">...</span>
      </template>
      <template v-else>
        <div>
          <svg class="w-6 fill-current text-primary3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.31 60.31">
            <g data-name="Layer 2">
              <circle cx="30.16" cy="30.16" r="30.16" />
              <path fill="none" class="stroke-current text-primary3Text" stroke-miterlimit="10" stroke-width="6.32" d="M10.23 31.64L21.9 44.21l28.03-29.37"/>
            </g>
          </svg>
        </div>
      </template>
    </div>
    <div :class="{'hasMeasurementClass' : hasMeasurement('bottom'), 'isSelectedClass' : isSelected('bottom')}" @click="setPoint($event, 'bottom')" class="h-10 w-40 shadow-shadowBrownOutter rounded-md text-textDark rounded-tl-none bg-white flex justify-between items-center px-4 mb-1">
      <span>bottom</span>
      <template v-if="!hasMeasurement('bottom')">
        <span class="underline">...</span>
      </template>
      <template v-else>
        <div>
          <svg class="w-6 fill-current text-primary3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.31 60.31">
            <g data-name="Layer 2">
              <circle cx="30.16" cy="30.16" r="30.16" />
              <path fill="none" class="stroke-current text-primary3Text" stroke-miterlimit="10" stroke-width="6.32" d="M10.23 31.64L21.9 44.21l28.03-29.37" />
            </g>
          </svg>
        </div>
      </template>
    </div>
    <div :class="{'hasMeasurementClass' : hasMeasurement('nasal'), 'isSelectedClass' : isSelected('nasal')}" @click="setPoint($event, 'nasal')" class="h-10 w-40 shadow-shadowBrownOutter rounded-md text-textDark rounded-tl-none bg-white flex justify-between items-center px-4 mb-2">
      <span>nasal</span>
      <template v-if="!hasMeasurement('nasal')">
      <span class="underline">...</span>
      </template>
      <template v-else>
        <div>
          <svg class="w-6 fill-current text-primary3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.31 60.31">
            <g data-name="Layer 2">
              <circle cx="30.16" cy="30.16" r="30.16" />
              <path fill="none" class="stroke-current text-primary3Text" stroke-miterlimit="10" stroke-width="6.32" d="M10.23 31.64L21.9 44.21l28.03-29.37"/>
            </g>
          </svg>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EyeCoordinates',
  props: ['eye', 'framePixels'],
  data() {
    return {
      styleObj: {},
    }
  },
  methods: {
    setPoint(event, measure) {
      this.styleObj = {
        'background-color': '#4c392c',
        'color': '#fff',

      };
      event.stopPropagation();
      this.$emit('setPoint', `${this.eye}:${measure}`);
    },
    hasMeasurement(val) {
      const key = `${this.eye}:${val}`;
      let res = false;
      for(let i = 0; i < this.framePixels.length; i++) {
        const pixelObj = this.framePixels[i];
        if (Object.prototype.hasOwnProperty.call(pixelObj, `${this.eye}:${val}`)) {
          //make sure the values are not -1 & state is commit
          res = (pixelObj[key][0] !== -1 && pixelObj[key][1] !== -1 && pixelObj[key][2] === 'COMMIT');
          break;
        }
      }
      return res;
    },
    isSelected(val) {
      let res = false;
      for (let i = 0; i < this.framePixels.length; i++) {
        const pixelObj = this.framePixels[i];
        const _key = Object.keys(pixelObj)[0];
        if (pixelObj[_key][2] === 'EDIT') {
          res = _key == `${this.eye}:${val}`;
          break;
        }
      }
      return res;
    }
  },
  computed: {
  }
}
</script>


<style scoped>
  .hasMeasurementClass {
    @apply bg-primary2 text-primary2Text;
  }
  .isSelectedClass {
    @apply bg-primary1 text-primary1Text;
  }

</style>
