<template>
  <button @click="$emit('click')" class="w-20 focus:outline-none">
    <div class="text-backOpacityText w-20">
      <ToggleIconSvg />
    </div>
    <!-- <svg class="fill-current text-backOpacityText" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.36 99.69">
      <g id="Layer_2" data-name="Layer 2">
        <g id="keyboard">
          <g>
            <path d="M34.54,70.9h86.07a8.3,8.3,0,0,0,8.27-8.28V19a8.29,8.29,0,0,0-8.27-8.27H112.8L107,3.67A11.49,11.49,0,0,0,99.18,0h-21a11.47,11.47,0,0,0-7.78,3.67l-5.84,7.09h-16V6.62A1.13,1.13,0,0,0,47.39,5.5H36.9a1.13,1.13,0,0,0-1.13,1.12v4.14H34.54A8.3,8.3,0,0,0,26.26,19V62.62A8.31,8.31,0,0,0,34.54,70.9Zm83-54.4a5.44,5.44,0,1,1-5.44,5.44A5.44,5.44,0,0,1,117.53,16.5ZM78,15.48A23.42,23.42,0,1,1,54.62,38.9,23.42,23.42,0,0,1,78,15.48Z"/>
            <path d="M73.71,78a1,1,0,0,0-1.65.68v5.59a182.31,182.31,0,0,1-28.79-2.13,119.77,119.77,0,0,1-16.72-3.79,59,59,0,0,1-15.3-6.87A20.3,20.3,0,0,1,5.55,66a8.71,8.71,0,0,1-1.47-6.84,13.66,13.66,0,0,1,4-6.55,34.47,34.47,0,0,1,6.72-5,65.89,65.89,0,0,1,8.74-4.24V41a68.32,68.32,0,0,0-10.09,4.17,37.68,37.68,0,0,0-7.61,5.08,17.12,17.12,0,0,0-5.4,8,12.75,12.75,0,0,0,1.5,10,24.68,24.68,0,0,0,6.55,7A63.7,63.7,0,0,0,24.72,83.7,126.31,126.31,0,0,0,42,88.64a188.82,188.82,0,0,0,30,3.87s0,0,0,.06v6.15a1,1,0,0,0,.59.9,1,1,0,0,0,.38.07,1,1,0,0,0,.68-.28l10-10a1,1,0,0,0,0-1.37Z"/>
            <path d="M82.37,46.17a1,1,0,0,0,.69.28.94.94,0,0,0,.37-.07,1,1,0,0,0,.6-.9V38.4c5,0,9.9.19,14.84.58V38.9a20.85,20.85,0,0,0-1.43-7.55Q90.75,30.54,84,30.17s0,0,0-.06v-4.7a1,1,0,0,0-1.66-.68l-4.5,4.5a1,1,0,0,0-.27.57,1,1,0,0,0-.57.27l-4.69,4.69a1,1,0,0,0-.28.69,1,1,0,0,0,.28.68Z" />
            <path d="M153.42,54.4a24.68,24.68,0,0,0-6.55-7,62.27,62.27,0,0,0-15.26-8.06v5.93a54.47,54.47,0,0,1,12.5,5.95,20.3,20.3,0,0,1,5.7,5.43,8.71,8.71,0,0,1,1.47,6.84,13.63,13.63,0,0,1-4,6.55,34.47,34.47,0,0,1-6.72,5,78.37,78.37,0,0,1-15.89,6.81,142.3,142.3,0,0,1-17,4.18l.13.85a145,145,0,0,0,17.36-3.27,80.72,80.72,0,0,0,16.74-6.13,37.68,37.68,0,0,0,7.61-5.08,17.12,17.12,0,0,0,5.4-8A12.75,12.75,0,0,0,153.42,54.4Z"/>
          </g>
        </g>
      </g>
    </svg> -->
  </button>
</template>
<script>
import ToggleIconSvg from "../../assets/svg/icons/camera_toggle.svg";

export default {
  name: 'CameraToggle',
  methods: {
  },
  components: {
    ToggleIconSvg
  }
}
</script>