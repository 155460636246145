var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "parentSection relative transitions xs:h-2/5 z-30 h-full w-full portrait:h-7/12"
    },
    [
      _vm.currentSetting == "outdoors"
        ? _c(
            "div",
            { staticClass: "imageWrapper h-full w-full relative " },
            [
              _c("div", {
                staticClass: "lrOutdoor relative h-full w-full pl-full "
              }),
              _c(
                "transition",
                {
                  attrs: {
                    "enter-active-class":
                      "transition-all duration-3000 ease-in-out",
                    "enter-class": "opacity-0",
                    "enter-to-class": "opacity-100",
                    "leave-class": "opacity-0",
                    "leave-to-class": "opacity-100"
                  }
                },
                [
                  _c("div", {
                    key: _vm.outdoorsSrc,
                    staticClass:
                      "bg-cover bg-center absolute left-0 top-0 h-full w-full",
                    style: { backgroundImage: "url(" + _vm.outdoorsSrc + ")" }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentSetting == "indoor"
        ? _c(
            "div",
            { staticClass: "imageWrapper h-full w-full relative" },
            [
              _c("div", {
                staticClass:
                  "bg-cover bg-center relative h-full w-full pl-full",
                style: { backgroundImage: "url(" + _vm.indoorSrc + ")" }
              }),
              _c(
                "transition",
                {
                  attrs: {
                    "enter-active-class": "duration-3000 ease-in-out",
                    "enter-class": "opacity-0",
                    "enter-to-class": "opacity-100",
                    "leave-class": "opacity-0",
                    "leave-to-class": "opacity-100"
                  }
                },
                [
                  _c("div", {
                    key: _vm.indoorSrc,
                    staticClass: "lrIndoor absolute left-0 top-0 h-full w-full"
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", [
        _vm._v(" --> "),
        _c(
          "div",
          { staticClass: "xs:hidden absolute top-0 right-0 mt-6 mr-4 z-50" },
          [
            _c(
              "button",
              {
                staticClass:
                  "btn-setting button focus:outline-none focus:bordernone overflow-hidden",
                class: [_vm.colorDropDown ? "animation" : ""],
                attrs: { id: "btn-lr-1" },
                on: {
                  click: function($event) {
                    return _vm.$store.dispatch("toggleTransitionsPopup")
                  }
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "btn-setting textShadow text-white font-bold xs:font-normal xs:text-xs",
                    class: { display: _vm.colorDropDown },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("colors")]
                ),
                _vm.showTransitionsPopup
                  ? _c("TransitionsPopup", {
                      staticClass: "btn-setting",
                      attrs: {
                        currentOption: _vm.currentColor,
                        changeDemo: _vm.changeColors
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "sm:hidden md:hidden lg:hidden xl:hidden xxl:hidden absolute top-0 right-0 mr-2 mt-2 z-50"
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "btn-setting buttonXS focus:outline-none focus:bordernone overflow-hidden",
                class: [_vm.colorDropDown ? "animationXS" : ""],
                attrs: { id: "btn-lr-1" },
                on: {
                  click: function($event) {
                    return _vm.$store.dispatch("toggleTransitionsPopup")
                  }
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "btn-setting textShadow text-white font-bold xs:font-normal xs:text-xs",
                    class: { display: _vm.colorDropDown },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("colors")]
                ),
                _vm.showTransitionsPopup
                  ? _c("TransitionsPopup", {
                      staticClass: "btn-setting",
                      attrs: {
                        currentOption: _vm.currentColor,
                        changeDemo: _vm.changeColors
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "xs:hidden absolute top-0 right-0 mt-32 mr-4 z-30" },
          [
            _c(
              "button",
              {
                staticClass:
                  "button focus:outline-none focus:bordernone overflow-hidden",
                class: [_vm.settingDropDownLarge ? "animationSetting" : ""],
                attrs: { id: "btn-setting" },
                on: {
                  click: function($event) {
                    _vm.settingDropDownLarge = !_vm.settingDropDownLarge
                  }
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "text-white font-semibold xs:font-normal xs:text-xs",
                    class: { display: _vm.settingDropDownLarge },
                    attrs: { id: "btn-setting" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("setting")]
                ),
                _vm.settingDropDownLarge
                  ? _c("demo-button", {
                      staticClass: "btn-setting",
                      attrs: {
                        dropDown: _vm.settingDropDownLarge,
                        currentOption: _vm.currentSetting,
                        changeDemo: _vm.changeSetting,
                        title: "setting",
                        changeArray: _vm.setting
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "sm:hidden md:hidden lg:hidden xl:hidden xxl:hidden absolute top-0 right-0 mt-16 mr-2 z-30"
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "buttonXS focus:outline-none focus:bordernone overflow-hidden",
                class: [_vm.settingDropDown ? "animationXS" : ""],
                attrs: { id: "btn-setting" },
                on: {
                  click: function($event) {
                    _vm.settingDropDown = !_vm.settingDropDown
                  }
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "text-white font-semibold xs:font-normal xs:text-xs",
                    class: { display: _vm.settingDropDown },
                    attrs: { id: "btn-setting" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("setting")]
                ),
                _vm.settingDropDown
                  ? _c("demo-button", {
                      staticClass: "btn-setting",
                      attrs: {
                        dropDown: _vm.settingDropDown,
                        currentOption: _vm.currentSetting,
                        changeDemo: _vm.changeSetting,
                        title: "setting",
                        changeArray: _vm.setting
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "absolute bottom-0 right-0 xs:mb-4 xs:mr-2 mb-40 mr-4 sm:mb-10",
            on: {
              click: function($event) {
                return _vm.updateTransition()
              }
            }
          },
          [
            _c("img", {
              staticClass: "xs:w-10 w-16 mb-10",
              attrs: {
                src: require("../../../assets/img/demo_icon_replay.png"),
                alt: ""
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }