var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "ml-8 focus:outline-none flex",
        on: {
          click: function($event) {
            return _vm.$emit("help")
          }
        }
      },
      [
        _c(
          "svg",
          {
            staticClass: "w-6 mr-2",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 49.22 52.67"
            }
          },
          [
            _c("g", { attrs: { "data-name": "Layer 2" } }, [
              _c("circle", {
                attrs: { cx: "24.61", cy: "24.61", r: "24.61", fill: "#cb9700" }
              }),
              _c(
                "text",
                {
                  attrs: {
                    transform: "translate(12.64 39.04)",
                    "font-size": "42",
                    fill: "#fff",
                    "font-family": "ArialMT, Arial"
                  }
                },
                [_vm._v("?")]
              )
            ])
          ]
        ),
        _c(
          "a",
          {
            staticClass: "text-backOpacityText underline",
            attrs: { href: "javascript:void(0)" }
          },
          [_vm._v("placement guide")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }