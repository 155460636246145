<template>
  <div class="lens-package bg-background text-backgroundText relative h-10/12">
    <Navigation />
    <CheckoutBox />
    <main class="h-10/12 flex-col flex relative border-t-4 border-primary1">
      <PlansButton />
      <div v-if="!showCustomize" class="h-full w-full flex portrait:flex-col">
        <section
          class="w-3/12 h-full ml-16 flex flex-col portrait:flex-row portrait:justify-between portrait:items-center portrait:w-full portrait:h-3/12"
        >
        <div v-if="currentPatient.user" class="w-11/12 h-16 flex items-end portrait:absolute top-0">
          <h1
            class="h-12 font-bold text-lg"
            v-if="currentMod == ''"
          >
            {{currentPatient.user.name}}'s Daily Pair
          </h1>
          <h1
            class="h-12 font-bold text-lg"
            v-if="currentMod == 'polarized'"
          >
            {{currentPatient.user.name}}'s Sun Pair
          </h1>
          <h1
            class="h-12 font-bold text-lg leading-6"
            v-if="currentMod == 'computer'"
          >
          {{currentPatient.user.name}}'s Computer Pair
          </h1>
        </div>
        <div v-else class="w-11/12 h-16 flex items-end portrait:absolute top-0">
          <h1
            class="h-12 font-bold text-lg"
            v-if="currentMod == ''"
          >
            Your Daily Pair
          </h1>
          <h1
            class="h-12 font-bold text-lg"
            v-if="currentMod == 'polarized'"
          >
            Your Sun Pair
          </h1>
          <h1
            class="h-12 font-bold text-lg leading-6"
            v-if="currentMod == 'computer'"
          >
            Your Computer Pair
          </h1>
        </div>

          <div
            v-if="currentFrame.name != 'Customer Supplied'"
            class="relative h-4/12 w-10/12 pl-full portrait:w-4/12 portrait:h-6/12 portrait:mt-4"
          >
            <img
              v-if="currentFrame.images && currentFrame.images[0].image"
              class="absolute left-0 top-0 h-full w-full object-contain"
              :src="currentFrame.images[0].image"
              alt=""
            />
            <img
              v-else
              class="absolute left-0 top-0 h-full w-full object-contain"
              src="../assets/img/frames/frame_ImagePlaceholder.png"
              alt=""
            />
          </div>
          <div v-if="currentFrame.name == 'Customer Supplied'">
            <div class="font-bold">Frames: No Charge</div>
            <div>{{ currentFrame.name }}</div>
          </div>
          <div
            v-if="currentFrame.name != 'Customer Supplied'"
            class="h-5/12 w-10/12 flex flex-col justify-between portrait:w-6/12"
          >
            <!-- <div class="">{{currentFrame.name}}</div> -->
            <div>
              <div class="">{{ currentFrame.name }}</div>
              <div class="">{{ currentFrame.color }}</div>
              <div>{{ currentFrame.code }}</div>
              <div :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold">
                <span>${{ currentFrame.price.toFixed(2) }}*</span>
              </div>
            </div>
            <p class="underline text-sm" @click="$router.push('/frames')">
              (edit)
            </p>
          </div>
        </section>
        <section
          class="w-8/12 h-full pr-4 portrait:flex-col portrait:w-full portrait:ml-24 portrait:h-9/12"
        >
          <!-- <p
            class="h-12 flex items-center portrait:relative portrait:mt-0 portrait:mb-1"
          >
            {{ packagePhrases[currentPackage.level] }}
            <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold ml-2">${{ packagePrice }}*</span>
          </p> -->
          <p
          v-if="currentPackage.level == 'premium'"
            class="h-12 flex items-center portrait:relative portrait:mt-0 portrait:mb-1"
          >
            <span v-if="currentPatient.user"><span class="mr-1">{{ packagePhrases[currentPackage.level] }}</span> <span>{{currentPatient.user.name}}: </span></span>
            <span v-else class="mr-1">{{ packagePhrases[currentPackage.level] }} you: </span>

            <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold ml-2">${{ packagePrice }}*</span>
          </p>

          <p
          v-if="currentPackage.level != 'premium'"
            class="h-12 flex items-center portrait:relative portrait:mt-0 portrait:mb-1"
          >
            {{ packagePhrases[currentPackage.level] }}
            <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold ml-2">${{ packagePrice }}*</span>
          </p>


          <!-- shadow-primary3Inner -->
          <div
            class="w-full h-10/12 border-4 border-primary1 bg-white text-textDark flex rounded-lg relative portrait:w-10/12"
          >
            <div
              v-if="currentPackageIndex > 0"
              class="w-8 h-full absolute left-0 -ml-5 flex justify-center items-center"
            >
              <div
                @click="changePackage('left')"
                class="w-16 h-16 flex justify-center items-center"
              >
                <div class="transform rotate-180 w-8 text-primary1">
                  <BundleIconSvg />
                </div>
              </div>
            </div>
            <div class="h-full w-1/2 pl-8 flex flex-col portrait:pl-8">
              <div class="h-1/2 flex flex-col pt-4">
                <div class="w-full h-20 flex items-center">
                  <div v-click-outside="closeMaterialDrop" class="relative z-50 w-56 bg-primary1 rounded-lg rounded-tl-none">
                    <button
                      @click="lensMaterialDropdown = !lensMaterialDropdown"
                      :class="[lensMaterialDropdown ? 'materialDropdown' : '']"
                      class="h-12 w-56 portrait:mb-0 portrait:mr-0 bg-primary1 text-primary1Text rounded-lg rounded-tl-none focus:outline-none"
                    >
                      <div
                        class="w-56 h-12 flex items-center justify-between pl-4 pr-2"
                      >
                        <span v-if="!currentPackage.material.brandName">{{ currentPackage.material.lensType }}</span>
                        <span v-else>{{ currentPackage.material.brandName }}</span>
                        <div
                          class="fill-current w-6 text-primary1Text"
                          :class="{ arrowRotation: lensMaterialDropdown }"
                        >
                          <ArrowDropdownSvg />
                        </div>
                      </div>
                    </button>
                    <div
                      v-if="lensMaterialDropdown"
                      class="w-56 absolute top-0 mt-12 bg-primary1 border-4 border-t-0 border-t-none border-primary1 rounded-lg rounded-tl-none rounded-tr-none"
                      @click="lensMaterialDropdown = !lensMaterialDropdown"

                    >
                      <div
                        v-for="material in currentMaterials"
                        :key="material.id"
                        @click="selectMaterial(material)"
                      >
                      <div v-if="currentPackage.material.code != material.code" class="h-12 w-full rounded-md border-t-2 border-primary1 rounded-tl-none bg-white flex items-center px-4 button text-textDark">
                        <span v-if="!material.brandName">{{material.lensType}}</span>
                        <span v-else>{{material.brandName}}</span>
                        </div>
                      </div>

                    </div>
                  </div>

                  <!-- <button
                    @click="lensMaterialDropdown = !lensMaterialDropdown"
                    :class="[lensMaterialDropdown ? 'materialDropdown' : '']"
                    class="fixed z-50 h-12 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-primary1 text-primary1Text rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                  >
                    <div
                      v-if="lensMaterialDropdown"
                      class="w-full z-50 bg-primary2"
                    >
                      <div
                        v-for="material in currentMaterials"
                        :key="material.id"
                        @click="selectMaterial(material)"
                        v-if="currentPackage.material.code != material.code"
                        class="h-12 w-full rounded-md border-b-2 border-primary2 rounded-tl-none bg-white flex items-center px-4 button text-primary3Text"
                      >
                        {{material.lensType}}
                      </div>

                    </div>
                    <div
                      class="w-full h-12 flex items-center justify-between pl-4 pr-2"
                    >
                      <span>{{ currentPackage.material.lensType }}</span>
                      <div

                        class="fill-current w-6 text-primary1Text"
                        :class="{ arrowRotation: lensMaterialDropdown }"
                      >
                        <ArrowDropdownSvg />
                      </div>
                    </div>

                    <div
                      v-if="lensMaterialDropdown"
                      class="w-full text-primary2Text z-50 bg-primary2 bg"
                    >

                    </div>
                  </button> -->
                </div>

                <div class="w-full h-20 flex items-center">
                  <div v-click-outside="closeTypeDrop" class="relative z-20 w-56 bg-primary1 rounded-lg rounded-tl-none">
                    <button
                      @click="lensTypeDropdown = !lensTypeDropdown"
                      :class="[lensTypeDropdown ? 'materialDropdown' : '']"
                      class="h-12 w-56 portrait:mb-0 portrait:mr-0 bg-primary1 text-primary1Text rounded-lg rounded-tl-none focus:outline-none"
                    >
                      <div
                        class="w-56 h-12 flex items-center justify-between pl-4 pr-2"
                      >
                        <span>{{ currentPackage.base.lensType }}</span>
                        <div

                          class="fill-current w-6 text-primary1Text"
                          :class="{ arrowRotation: lensTypeDropdown }"
                        >
                          <ArrowDropdownSvg />
                        </div>
                      </div>
                    </button>
                    <div
                      v-if="lensTypeDropdown"
                      class="w-56 absolute top-0 mt-12 bg-primary1 border-4 border-t-0 border-t-none border-primary1 rounded-lg rounded-tl-none rounded-tr-none"
                      @click="lensTypeDropdown = !lensTypeDropdown"

                    >
                      <div
                        v-for="(type, index) in lensTypes"
                        :key="index"
                        @click="changeLensType(lensTypes[index])"
                      >
                        <div v-if="currentPackage.base.lensType != lensTypes[index].name" class="h-12 w-full rounded-md border-t-2 border-primary1 rounded-tl-none bg-white flex items-center px-4 button text-textDark">
                          {{ lensTypes[index].name }}
                          </div>
                        </div>

                    </div>
                  </div>
                </div>

                <!-- <div class="w-full h-20 flex items-center relative">
                  <button
                    @click="lensTypeDropdown = !lensTypeDropdown"
                    v-click-outside="closeTypeDrop"
                    :class="[lensTypeDropdown ? 'animationPrimary2' : '']"
                    class="absolute top-0 h-12 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-primary2 text-primary2Text rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                  >
                    <div
                      class="w-full h-12 flex items-center justify-between pl-4 pr-2"
                    >
                      <span>{{ currentPackage.base.lensType }}</span>
                      <div class="fill-current text-primary2Text w-6"  :class="{arrowRotation:lensTypeDropdown}">
                        <ArrowDropdownSvg />
                      </div>
                    </div>

                    <div
                      v-if="lensTypeDropdown"
                      class="w-full text-primary2Text z-50 bg-primary2"
                    >
                      <div
                        v-for="(type, index) in lensTypes"
                        :key="index"
                        @click="changeLensType(lensTypes[index])"
                      >
                        <div
                          v-if="currentPackage.base.lensType != lensTypes[index].name"
                          class="h-12 w-full rounded-md border-t-2 border-primary2 rounded-tl-none text-primary1Text flex items-center px-4"
                        >
                          {{ lensTypes[index].name }}
                        </div>
                      </div>
                    </div>
                  </button>
                </div> -->
              </div>

              <div class="h-1/2 flex flex-col pt-4 lg:pt-6">

                <div v-click-outside="closeTransDrop" v-if="currentMod != 'polarized' && LRCompatiblity" class="w-full h-16 flex items-center relative">

                  <button @click="transitionDropdown = !transitionDropdown" :class="[transitionDropdown ? 'animationprimary3' : '']"
                    class="absolute bottom-0 h-16 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                  >
                    <!-- <button v-closable="{exclude: [],handler: 'closeTransDrop'}"  @click="transitionDropdown = !transitionDropdown" :class="[transitionDropdown ? 'animationprimary3' : '']"
                    class="absolute bottom-0 h-16 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                  > -->

                    <div
                      v-if="transitionDropdown"
                      class="w-56 ext-lightGrayText z-50"
                    >
                      <div
                        v-for="(transition, name) in currentLightResonsives"
                        :key="name"
                        @click="selectTransition(transition, 'human')"
                      >
                        <div
                          class="h-16 w-full rounded-md border-b-2 border-primary1 rounded-tl-none pl-2 bg-white text-lightGrayText flex items-center button"
                        >
                          <div class="w-40">
                            <img
                              v-if="transition.image"
                              :src="transition.image"
                              alt=""
                            />
                            <div v-else>{{ transition.brandDescText }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="currentPackage.transition.brandDescText"
                        @click="selectTransition(false, 'human')"
                      >
                        <div
                          class="h-16 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-lightGrayText flex items-center button"
                        >
                          <div class="w-48">No Light Responsive</div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="w-full h-16 flex items-center justify-between px-2"
                    >
                      <div
                        v-if="!currentPackage.transition.brandDescText"
                        class="w-40"
                      >
                        <div>No Light Responsive</div>
                      </div>
                      <div
                        v-if="currentPackage.transition.brandDescText"
                        class="w-40"
                      >
                        <img
                          v-if="currentPackage.transition.image"
                          :src="currentPackage.transition.image"
                          alt=""
                        />
                        <div v-else>
                          {{ currentPackage.transition.brandDescText }}
                        </div>
                      </div>
                      <div
                        class="fill-current text-lightGrayText"
                        :class="{ arrowRotation: transitionDropdown }"
                      >
                        <ArrowDropdownSvg />
                      </div>
                    </div>
                  </button>
                </div>
                <!-- Transition Colors Dropdown -->
                <div
                  v-click-outside="closeColorDrop"
                  v-if="currentPackage.transition.brandDescText"
                  class="w-56 h-16 flex items-center relative"
                >
                  <!-- <div v-closable="{exclude: [],handler: 'closeColorDrop'}"
                  v-if="selectedTransition.name != 'noTransitions'"
                  class="w-56 h-16 flex items-center relative"
                > -->
                  <button
                    @click="colorDropdown = !colorDropdown"
                    :class="[colorDropdown ? 'animationprimary3' : '']"
                    class="absolute bottom-0 h-12 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                  >
                    <div
                      v-if="colorDropdown"
                      class="w-56 text-lightGrayText z-50 bg-primary1"
                    >
                      <div
                        v-if="currentColors.length > 1"
                        class="ml-1 w-full h-14 text-white p-2 flex justify-between text-left z-50 bg-secondary"
                      >
                        <p class="">colors</p>
                          <TransitionLogoSvg />
                      </div>
                      <div
                        v-for="color in currentLRColors"
                        @click="changeColor(color, 'human')"
                        :key="color.id"
                      >
                        <div
                          v-if="
                            currentPackage.color.brandDescText !=
                            color.brandDescText
                          "
                          class="h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-lightGrayText flex items-center px-2 button"
                        >
                          <div class="w-12 mr-3">
                            <img
                              :src="color.image"
                              :alt="color.brandDescText"
                            />
                          </div>
                          <span>{{ color.brandDescText }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="w-full h-12 flex items-center">
                      <div class="flex w-48">
                        <div class="w-12 mx-3">
                          <img
                            :src="currentPackage.color.image"
                            :alt="currentPackage.color.brandDescText"
                          />
                        </div>
                        <span>{{ currentPackage.color.brandDescText }}</span>
                      </div>

                      <div
                        v-if="currentLRColors.length > 1"
                        :class="{ arrowRotation: colorDropdown }"
                        class="fill-current text-lightGrayText"
                      >
                        <ArrowDropdownSvg />
                      </div>
                    </div>
                  </button>
                </div>

                <!-- Polarized Dropdown -->
                <div v-click-outside="closePolDrop" v-if="currentMod == 'polarized'" class="w-56 h-16 flex items-center relative">
                <!-- <div v-closable="{exclude: [],handler: 'closePolDrop'}" v-if="currentMod == 'polarized'" class="w-56 h-16 flex items-center relative"> -->


                  <button
                    @click="polDropdown = !polDropdown"
                    :class="[polDropdown ? 'animationprimary3' : '']"
                    class="absolute bottom-0 h-12 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                  >
                    <div v-if="polDropdown" class="w-56 z-50 bg-primary1">
                      <div
                        class="w-full h-14 text-white p-2 flex justify-between text-left z-50 bg-secondary"
                      >
                        <p class="">colors</p>
                        <XperioUvSvg v-if="selectedAddon.code == 'XPUV'" />
                      </div>
                      <div
                        v-for="(color, name) in addonColors"
                        @click="selectPolarizedColor(color, 'human')"
                        :key="name"
                      >
                        <div
                          v-if="
                            currentPackage.color.brandDescText !=
                            color.brandDescText
                          "
                          class="h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-textDark flex items-center px-3 button"
                        >
                          <img
                            class="w-12"
                            v-if="color.image"
                            :src="color.image"
                            :alt="color.brandDescText"
                          />
                          <span class="ml-3">{{ color.brandDescText }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="w-full h-12 flex items-center">
                      <div class="h-full flex items-center w-48 pl-3">
                        <img
                          class="w-12"
                          v-if="currentPackage.color.image"
                          :src="currentPackage.color.image"
                          :alt="currentPackage.color.brandDescText"
                        />
                        <span class="ml-3">{{
                          currentPackage.color.brandDescText
                        }}</span>
                      </div>

                      <div
                        class="fill-current text-lightGrayText"
                        :class="{ arrowRotation: polDropdown }"
                      >
                        <ArrowDropdownSvg />
                      </div>
                    </div>
                  </button>
                </div>
                <!-- Mirror Dropdown -->
                <div
                  v-click-outside="closeMirrorDrop"
                  v-if="currentMod == 'polarized'"
                  class="w-56 h-16 flex items-center relative"
                >
                  <!-- <div v-closable="{exclude: [],handler: 'closeMirrorDrop'}"
                  v-if="currentMod == 'polarized'"
                  class="w-56 h-16 flex items-center relative"
                > -->
                  <button
                    @click="mirrorDropdown = !mirrorDropdown"
                    :class="[mirrorDropdown ? 'animationprimary3' : '']"
                    class="absolute bottom-0 h-12 w-56 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                  >
                    <div
                      v-if="mirrorDropdown"
                      class="w-56 text-primary1Text z-50 bg-primary1"
                    >
                      <div
                        v-for="(color, name) in mirrorColors"
                        @click="selectMirror(color, 'human')"
                        :key="name"
                      >
                        <div
                          v-if="currentPackage.mirror.name != color.name"
                          class="h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-textDark flex items-center px-3 button"
                        >
                          <!-- <div v-if="color.code == 'NOMIRR'">
                          <component class="w-12 mr-3" v-bind:is="color.img"/>
                        </div> -->
                          <div>
                            <img
                              class="w-12 mr-3"
                              :key="color.img"
                              :src="color.img"
                            />
                          </div>

                          <!-- <component class="w-12 mr-3" v-bind:is="color.img" /> -->
                          <span>{{ color.name }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="w-full h-12 flex items-center">
                      <div class="h-full w-48 flex items-center">
                        <!-- <component v-if="currentPackage.mirror.code == 'NOMIRR'" class="w-12 mx-3" v-bind:is="currentPackage.mirror.img"/> -->
                        <img
                          class="w-12 mx-3"
                          :key="currentPackage.mirror.img"
                          :src="currentPackage.mirror.img"
                        />
                        <!-- <component class="w-12 mx-3" v-bind:is="currentPackage.mirror.img" /> -->
                        <span>{{ currentPackage.mirror.name }}</span>
                      </div>

                      <div
                        :class="{ arrowRotation: mirrorDropdown }"
                        class="fill-current text-lightGrayText"
                      >
                        <ArrowDropdownSvg />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <div
              class="h-full w-1/2 relative flex flex-col items-center justify-around"
            >
              <div
                v-if="currentTreatments"
                class="absolute top-0 right-0 m-1"
                @click="openCustomize"
              >
                <!-- <div @click="showCustomize = !showCustomize" class="w-full flex justify-end"> -->
                <div  class="Edit_icon fill-current text-textDark w-6">
                  <EditIconSvg />
                </div>
              </div>
              <div class="h-3/6 flex flex-col items-center justify-center">
              <div v-if="!currentPackage.base.image" class="w-48 portrait:h-2/6">
                <div class="package-heading">{{currentPackage.base.brandDescText}}</div>
              </div>
              <div v-else class="w-48 portrait:h-2/6">
                <img :src="currentPackage.base.image" :alt="currentPackage.base.brandDescText">
              </div>

                <div v-click-outside="closeEyezenDrop" v-if="currentPackage.base.code.includes('EYZ')" class="w-56  h-16 flex items-center relative">

                  <!-- <div  v-closable="{exclude: [],handler: 'closeEyezenDrop'}" v-if="currentPackage.base.code.includes('EYZ')" class="w-56  h-16 flex items-center relative"> -->
                  <button
                    @click="eyezenDropdown = !eyezenDropdown"
                    :class="[eyezenDropdown ? 'animationprimary3' : '']"
                    class="absolute bottom-0 h-12 w-56 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                  >
                    <div
                      v-if="eyezenDropdown"
                      class="w-56 text-primary1Text z-50 bg-primary1"
                    >
                      <div
                        @click="changeEyezenOptions($event, index)"
                        v-for="(option, index) in eyezenArray"
                        :key="index"
                      >
                        <div
                          v-if="
                            selectedEyezen.brandDescText != option.brandDescText
                          "
                          class="h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-textDark flex items-center px-3 button"
                        >
                          <span class="">{{ option.brandDescText }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="w-full h-12 flex items-center">
                      <div
                        v-if="selectedEyezen"
                        class="w-48 flex justify-start pl-3"
                      >
                        {{ selectedEyezen.brandDescText }}
                      </div>
                      <div
                        :class="{ arrowRotation: eyezenDropdown }"
                        class="fill-current text-lightGrayText w-6"
                      >
                        <ArrowDropdownSvg />
                      </div>
                    </div>
                  </button>
                  <div
                    @click="eyezenDropdown = !eyezenDropdown"
                    v-if="eyezenDropdown"
                    class="w-full mt-14 absolute top-0 z-40 text-primary text-xs p-2 text-left bg-primary1 text-primary1Text"
                  >
                    *It is recommended that you consider progressive lenses for
                    patients aged 45+
                  </div>
                </div>
              </div>
              <div
                class="w-full h-1/3 flex flex-col justify-center items-center"
              >
                <div class="h-1/3">with anti-reflective</div>
                <div
                  v-if="optionsArray.length == 0"
                  class="w-40 h-3/6 portrait:h-2/6 mt-3 flex justify-center items-center"
                >
                  <img
                    v-if="currentAddonImage.img"
                    :src="currentAddonImage.img"
                    :key="currentAddonImage.img"
                  />
                  <div
                    class="package-heading leading-6"
                    v-else
                    v-html="currentAddonImage.div"
                  ></div>
                </div>
                <div
                  class="h-3/6 w-full flex justify-center items-center portrait:h-2/6"
                >
                  <div v-click-outside="closeOptionDrop"  class="flex justify-center items-center">
                    <button
                      v-if="optionsArray.length > 0"
                      @click="optionDropdown = !optionDropdown"
                      :class="[optionDropdown ? 'animationprimary3' : '']"
                      class="absolute h-12 w-56 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                    >
                      <div
                        v-if="optionDropdown"
                        class="w-56 text-primary1Text z-50 bg-primary1"
                      >
                        <div
                          @click="changeAddon(option)"
                          v-for="(option, index) in optionsArray"
                          :key="index"
                        >
                          <div
                            v-if="selectedAddon.code != option.code"
                            class="h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-textDark flex items-center button"
                          >
                            <img
                              class="w-48 mx-6"
                              v-if="option.image"
                              :src="option.image"
                              :alt="option.brandDescText"
                            />
                            <div class="w-48" v-else>
                              <div class="w-48">{{ option.brandDescText }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="w-full h-12 flex items-center">
                        <img
                          class="w-48 flex justify-start px-3"
                          v-if="currentAddonImage.img"
                          :src="currentAddonImage.img"
                          :key="currentAddonImage.img"
                        />
                        <div
                          v-else
                          class="w-48 text-lightGrayText"
                          v-html="currentAddonImage.div"
                        ></div>
                        <div :class="{ arrowRotation: optionDropdown }">
                          <div class="text-lightGrayText">
                            <ArrowDropdownSvg />
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="w-full h-16 flex justify-end items-end portrait:justify-center portrait:items-center mt-4 mb-2 portrait:mb-0"
              >
                <button
                  @click="goToCheckout"
                  class="underline h-12 w-48 text-xl mr-8 portrait:mb-0 portrait:mr-0 bg-primary3 rounded-lg rounded-tl-none text-primary3Text block focus:outline-none"
                >
                  Continue
                </button>
              </div>
            </div>
            <div
              v-if="currentPackageIndex < packageArray.length - 1"
              class="w-8 h-full absolute right-0 -mr-4 flex justify-center items-center"
            >
              <div
                @click="changePackage('right')"
                class="w-16 h-16 flex justify-center items-center"
              >
                <div class="w-8 text-primary1">
                  <BundleIconSvg />
                </div>
                </div>
            </div>
          </div>
        </section>
      </div>
      <Customize v-if="showCustomize" :goToCheckout="goToCheckout" />
    </main>
  </div>
</template>

<script>
import Navigation from "../components/subcomponents/Navigation.vue";
import CheckoutBox from "../components/subcomponents/CheckoutBox.vue";
import PlansButton from "../components/subcomponents/Buttons/PlansButton.vue";
import Customize from "../components/subcomponents/Customize.vue";
import Dropdown from "../components/subcomponents/Inputs/Dropdown.vue";
import DropdownWImage from "../components/subcomponents/Inputs/DropdownWImage.vue";
import Lenses from "../json/lenses.json";
import svgLogo from "../assets/img/icon_progressive_good.svg";
import SignatureLogo from "../assets/svg/transitions/transitions_Signature.svg";
import VantageLogo from "../assets/svg/transitions/transitions_Vantage.svg";
import XtraActiveLogo from "../assets/svg/transitions/transitions_XTRActive.svg";
import AmberLogo from "../assets/svg/transitions_colors/transitions_Amber.svg";
import AmethystLogo from "../assets/svg/transitions_colors/transitions_Amethyst.svg";
import BrownLogo from "../assets/svg/transitions_colors/transitions_Brown.svg";
import GrayLogo from "../assets/svg/transitions_colors/transitions_Gray.svg";
import SapphireLogo from "../assets/svg/transitions_colors/transitions_Sapphire.svg";
import EmeraldLogo from "../assets/svg/transitions_colors/transitions_Emerald.svg";
import GreenLogo from "../assets/svg/transitions_colors/transitions_Green.svg";
import TransitionLogoSvg from "../assets/svg/transitions/transitionLogo.svg";
import PlumLogo from "../assets/svg/polarizedColors/polarized_Plum.svg";
import GrapeLogo from "../assets/svg/polarizedColors/polarized_Grape.svg";
import EmeraldBlue from "../assets/svg/polarizedColors/polarized_ Blue.svg";
import ForestGray from "../assets/svg/polarizedColors/polarized_Gray.svg";
import SiennaBrown from "../assets/svg/polarizedColors/polarized_Brown.svg";
import GrayMist from "../assets/svg/polarizedColors/polarized_GrayMist.svg";
import XperioUvSvg from "../assets/svg/polarizedColors/XperioUV.svg";
import MirrorGold from "../assets/svg/mirrorColors/mirror_Gold.svg";
import MirrorBlue from "../assets/svg/mirrorColors/mirror_Blue.svg";
import MirrorSilver from "../assets/svg/mirrorColors/mirror_Silver.svg";
import ArrowDropdownSvg from "../assets/svg/icons/Arrow_5.svg";
import EditIconSvg from "../assets/svg/icons/Edit_icon.svg";
import BundleIconSvg from "../assets/svg/icons/icon_BundleArrow.svg";
// import NoMirror from "../assets/svg/mirrorColors/mirror_none.svg";
import { generateToast, isEmpty } from "../common/functions";
const cloneDeep = require("clone-deep");

export default {
  name: "LensesPackage",
  data() {
    return {
      // currentLevelIndex: 0,
      // currentPackage: {},
      generateToast: generateToast,
      cloneDeep: cloneDeep,
      isEmpty: isEmpty,
      showCustomize: false,
      lensTypeDropdown: false,
      optionDropdown: false,
      lensMaterialDropdown: false,
      colorDropdown: false,
      transitionDropdown: false,
      selectedMaterial: "Trivex",
      selectedColor: "Gray",
      selectedPolColor: "Gray Mist",
      currentColors: [],
      img: "",
      // svg2: 'signatureLogo',
      // currentMod: '',
      // lenses: Lenses,
      visoPro: {},
      defaultTransition: {},
      selectedColorImg:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.33 62.27"><defs><clipPath id="a"><path d="M.32 16.52c0 .84 7.59 44.41 7.59 44.41l39.83-2.53v-7.65a18.8 18.8 0 010-37.6L34.05 1.34S.32 15.67.32 16.52" fill="none"/></clipPath><clipPath id="b"><path fill="none" d="M28.95 13.15h37.59v37.6H28.95z"/></clipPath><clipPath id="c"><path data-name="SVGID" fill="none" d="M44.17 13.15h37.59v37.6H44.17z"/></clipPath></defs><g data-name="Layer 2"><path fill="none" d="M0 0h111.33v62.27H0z"/><path d="M78.2 13.26a18.75 18.75 0 00-7.5 1.56 18.81 18.81 0 00-7.7-1.67 18.61 18.61 0 00-7.61 1.62 18.66 18.66 0 00-15.91.33 18.59 18.59 0 00-6.93-1.34 18.8 18.8 0 108.29 35.65 18.73 18.73 0 0014.55-.29 18.75 18.75 0 0015.11.06 18.79 18.79 0 107.7-35.92z" fill="#fff"/><g clip-path="url(#a)"><path d="M32.51 51.73a19.18 19.18 0 1119.18-19.17 19.19 19.19 0 01-19.18 19.17zm0-37.59a18.42 18.42 0 1018.42 18.42 18.43 18.43 0 00-18.42-18.42z" fill="#dadbdb"/></g><g clip-path="url(#b)" opacity=".5"><path d="M66.54 32a18.8 18.8 0 11-18.8-18.8A18.8 18.8 0 0166.54 32" fill="#9a9c9e"/></g><g clip-path="url(#c)" opacity=".8"><path d="M81.77 32A18.8 18.8 0 1163 13.15 18.8 18.8 0 0181.77 32" fill="#6d6f70"/></g><path d="M97 32.05a18.8 18.8 0 11-18.8-18.79A18.8 18.8 0 0197 32.05" fill="#474747"/><path d="M78.2 51.61a19.56 19.56 0 1119.55-19.56A19.58 19.58 0 0178.2 51.61zm0-37.6a18 18 0 1018 18A18.06 18.06 0 0078.2 14z" fill="#a3a5a9"/></g></svg>',
      eyezenDropdown: false,
      polDropdown: false,
      mirrorDropdown: false,
      selectedType: "Progressive",
      selectedMaterial: "Trivex",
      // selectedEyezen: {},
      selectedAge: "(ages 35-44)",
      selectedColor: "Gray",
      selectedColorImg:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.33 62.27"><defs><clipPath id="a"><path d="M.32 16.52c0 .84 7.59 44.41 7.59 44.41l39.83-2.53v-7.65a18.8 18.8 0 010-37.6L34.05 1.34S.32 15.67.32 16.52" fill="none"/></clipPath><clipPath id="b"><path fill="none" d="M28.95 13.15h37.59v37.6H28.95z"/></clipPath><clipPath id="c"><path data-name="SVGID" fill="none" d="M44.17 13.15h37.59v37.6H44.17z"/></clipPath></defs><g data-name="Layer 2"><path fill="none" d="M0 0h111.33v62.27H0z"/><path d="M78.2 13.26a18.75 18.75 0 00-7.5 1.56 18.81 18.81 0 00-7.7-1.67 18.61 18.61 0 00-7.61 1.62 18.66 18.66 0 00-15.91.33 18.59 18.59 0 00-6.93-1.34 18.8 18.8 0 108.29 35.65 18.73 18.73 0 0014.55-.29 18.75 18.75 0 0015.11.06 18.79 18.79 0 107.7-35.92z" fill="#fff"/><g clip-path="url(#a)"><path d="M32.51 51.73a19.18 19.18 0 1119.18-19.17 19.19 19.19 0 01-19.18 19.17zm0-37.59a18.42 18.42 0 1018.42 18.42 18.43 18.43 0 00-18.42-18.42z" fill="#dadbdb"/></g><g clip-path="url(#b)" opacity=".5"><path d="M66.54 32a18.8 18.8 0 11-18.8-18.8A18.8 18.8 0 0166.54 32" fill="#9a9c9e"/></g><g clip-path="url(#c)" opacity=".8"><path d="M81.77 32A18.8 18.8 0 1163 13.15 18.8 18.8 0 0181.77 32" fill="#6d6f70"/></g><path d="M97 32.05a18.8 18.8 0 11-18.8-18.79A18.8 18.8 0 0197 32.05" fill="#474747"/><path d="M78.2 51.61a19.56 19.56 0 1119.55-19.56A19.58 19.58 0 0178.2 51.61zm0-37.6a18 18 0 1018 18A18.06 18.06 0 0078.2 14z" fill="#a3a5a9"/></g></svg>',
      lensTypes: [
        {
          name: 'Single Vision',
          code: 'single'
        },
        {
          name: 'Progressive',
          code: 'progressive'
        }
      ],
      eyezenArray: [],
      mirrorColors: {
        blue: {
          name: "Blue",
          code: "MIRR",
          img: "./img/mirrorColors/Xperio_mirrorBlue.png",
          // img: 'MirrorBlue'
        },
        gold: {
          name: "Gold",
          code: "MIRR",
          img: "./img/mirrorColors/Xperio_mirrorGold.png",
          // img: 'MirrorGold'
        },
        silver: {
          name: "Silver",
          code: "MIRR",
          img: "./img/mirrorColors/Xperio_mirrorSilver.png",
          // img: 'MirrorSilver'
        },
        noMirror: {
          name: "No Mirror",
          code: "NOMIRR",
          // img: 'NoMirror'
          img: "./img/mirrorColors/Xperio_graymist.png",
        },
      },
      packagePhrases: {
        premium: "Recommended for",
        standard: "Standard lens package:",
        economy: "Economy lens package:",
      },
    };
  },
  methods: {
    openCustomize(){
      this.showCustomize = !this.showCustomize
      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Open Customize Popup",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    closeTransDrop(){
      this.transitionDropdown = false;
    },
    closeColorDrop(){
      this.colorDropdown = false;
    },
    closePolDrop() {
      this.polDropdown = false;
    },
    closeMirrorDrop() {
      this.mirrorDropdown = false;
    },
    closeEyezenDrop() {
      this.eyezenDropdown = false;
    },
    closeMaterialDrop(){
      this.lensMaterialDropdown = false
    },
    closeTypeDrop(){
      this.lensTypeDropdown = false
    },
    closeOptionDrop() {
      this.optionDropdown = false
    },
    goToCheckout() {
      this.$store.dispatch("setPlanStatus", {
        key: this.currentIndex,
        status: true,
      });
      this.$store.dispatch("setCheckedOut", true);
      this.$router.push("/checkout");
      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Continue to Checkout",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    openColorDropdown() {
      if (this.currentColors.length > 1) {
        this.colorDropdown = !this.colorDropdown;
      }
    },
    changeColor(color, editMode) {
      let edited = false;
      if (editMode == "human") {
        edited = true;
      }
      let tempPackage = this.cloneDeep(this.currentPackage);
      tempPackage.color = color;
      this.$store.dispatch("createTempPackage", {
        type: this.currentType,
        level: this.currentLevels[this.currentLevelIndex],
        pack: tempPackage,
        packageIndex: this.currentPackageIndex,
        index: this.currentPackage.ind,
        edited: edited,
      });
      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Lens Color Selection",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    selectMaterial(material){
      let tempPackage = this.cloneDeep(this.currentPackage)
      tempPackage.material = material
      this.$store.dispatch("createTempPackage", {
        type: this.currentType,
        level: this.currentLevels[this.currentLevelIndex],
        pack: tempPackage,
        index: this.currentPackage.ind,
        packageIndex: this.currentPackageIndex,
        edited: true,
      })
      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Material Selection Changed",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    selectPolarizedColor(color, editMode) {
      let edited = false;
      if (editMode == "human") {
        edited = true;
      }
      this.selectedPolColor = color.brandDescText;
      let tempPackage = this.cloneDeep(this.currentPackage);
      tempPackage.color = color;
      this.$store.dispatch("createTempPackage", {
        type: this.currentType,
        level: this.currentLevels[this.currentLevelIndex],
        pack: tempPackage,
        index: this.currentPackage.ind,
        packageIndex: this.currentPackageIndex,
        edited: edited,
      });
      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Polarized Color Selection",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    changeAddon(addon) {
      let edited = true;
      let tempPackage = this.cloneDeep(this.currentPackage);
      let addArr = tempPackage.addons.filter((add) => {
        return add.itemType != "addon";
      });
      addArr.push(addon);
      tempPackage.option = this.selectedAddon.code;
      tempPackage.addons = addArr;
      this.$store.dispatch("createTempPackage", {
        type: this.currentType,
        level: this.currentLevels[this.currentLevelIndex],
        pack: tempPackage,
        index: this.currentPackage.ind,
        packageIndex: this.currentPackageIndex,
        edited: edited,
      });
      this.selectPolarizedColor(this.addonColors[0], "auto");
      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Addon selection",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    selectMirror(color, editMode) {
      let edited = false;
      if (editMode == "human") {
        edited = true;
      }
      let tempPackage = this.cloneDeep(this.currentPackage);
      tempPackage.mirror = this.lenses.find((elem) => {
        return elem.code == color.code;
      });
      if (!tempPackage.mirror) {
        tempPackage.mirror = {
          name: color.name,
          code: color.code,
          img: color.img,
        };
      } else {
        tempPackage.mirror.name = color.name;
        tempPackage.mirror.code = color.code;
        tempPackage.mirror.img = color.img;
      }
      this.$store.dispatch("createTempPackage", {
        type: this.currentType,
        level: this.currentLevels[this.currentLevelIndex],
        packageIndex: this.currentPackageIndex,
        index: this.currentPackage.ind,
        pack: tempPackage,
        edited: edited,
      });
      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Mirror Selection",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    selectTransition(transition, editMode) {
      let edited = false;
      if (editMode == "human") {
        edited = true;
      }
      if (!transition) {
        this.defaultTransition = {};
        this.$store
          .dispatch("revertTempPackage", {
            type: this.currentType,
            index: this.currentPackage.ind,
            edited: edited,
          })
          .then((res) => {
            this.currentPackage.transition = res.transition;
            this.currentPackage.addons = res.addons;
          });

        this.$store.dispatch("sendEvent", {
          hitType: "event",
          eventCategory: "button",
          eventAction: "click",
          eventLabel: "Lens Type Selection",
          dimension1: this.currentUser.id,
          dimension2: this.currentUser.group.id,
          dimension3: this.view,
        });
        return;
      }
      let tempPackage = this.cloneDeep(this.currentPackage);
      //
      const previousAddons = this.cloneDeep(tempPackage.addons);
      if (tempPackage.base.code.includes("TRU")) {
        for (let i = 0; i < tempPackage.addons.length; i++) {
          if (tempPackage.addons[i].type == "addon") {
            tempPackage.addons[i] = this.visoPro;
          }
        }
      }
      tempPackage.transition = transition;

      this.$store.dispatch("createTempPackage", {
        type: this.currentType,
        level: this.currentLevels[this.currentLevelIndex],
        previousAddons: previousAddons,
        packageIndex: this.currentPackageIndex,
        index: this.currentPackage.ind,
        pack: tempPackage,
        edited: edited,
      });
      if (this.currentLRColors.length > 0) {
        //if there are transition colors then set a color
        this.changeColor(this.currentLRColors[0], editMode);
      }

      this.defaultTransition = transition.brandDescText;

      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Lens Transitions Selection",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    changeLensType(type) {
      this.$store.dispatch('updateCurrentLensType', type.code)
      this.createPackageArray({})
    },
    changePackage(direction) {
      if (direction == "left" && this.currentPackageIndex > 0) {
        this.$store.dispatch("setPackageIndex", this.currentPackageIndex - 1);
      } else if (
        direction == "right" &&
        this.currentPackageIndex < this.packageArray.length - 1
      ) {
        this.$store.dispatch("setPackageIndex", this.currentPackageIndex + 1);
      }
      this.packageCheck();
      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Arrow Package " + direction,
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    packageCheck() {
      //TODO: Refactor for maintainablity
      if (
        this.currentPackage &&
        !this.currentPackage.edited &&
        this.currentPackage.mod != "polarized"
      ) {
        if (this.$store.getters.getTransitionStatus) {
          this.selectTransition(this.currentLightResonsives[0], "auto");
        } else {
          this.selectTransition(false, "auto");
        }
      } else if (this.currentMod == "polarized") {
        if (this.currentPackage.color.brandDescText) {
          this.selectPolarizedColor(this.currentPackage.color, "auto");
        } else {
          this.selectPolarizedColor(this.addonColors[0], "auto");
        }
        if (
          this.currentPackage.mirror.code &&
          this.currentPackage.mirror.code != "noMirror"
        ) {
          this.selectMirror(this.currentPackage.mirror, "auto");
        } else {
          this.selectMirror(this.mirrorColors.noMirror, "auto");
        }
      }
    },
    setDefaultTransitions() {
      this.visoPro = this.lenses.find((lens) => {
        return lens.Code == "VSPR";
      });
      if (!this.currentPackage.edited) {
        if (this.$store.getters.getTransitionStatus) {
          this.selectTransition(this.currentLightResonsives[0], "auto");
        } else {
          this.selectTransition(false, "auto");
        }
      }
    },
    changeEyezenOptions(e, index) {
      const _eyezen = this.eyezenArray[index];
      const tempPackage = this.cloneDeep(this.currentPackage);
      tempPackage.base = _eyezen;
      const edited = true;
      this.$store.dispatch("createTempPackage", {
        type: this.currentType,
        level: this.currentLevels[this.currentLevelIndex],
        packageIndex: this.currentPackageIndex,
        pack: tempPackage,
        edited: edited,
      });
      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Eyezen DropDown Selection",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
    setTransitionColors() {
      const code = this.packageArray[this.currentPackageIndex].transition
        .brandDescText;
      this.currentColors = [];
      this.transitionImages[code].colors.forEach((color) => {
        //find all colors assosiated with current transition selection
        const _foundColor = this.colors[color];
        this.currentColors.push(_foundColor);
      });
    },
    createPackageArray(modifiedLens){
      this.$store
      .dispatch("createPackageArray")
      .catch((err) => {
        this.$store.dispatch("logout").then((res) => {
          this.$store.dispatch("setPreload", true);
        });
      })
      .then((res) => {
        let packLength = res.packages;
        let currIndex = this.$store.getters.getCurrentPackageIndex;
        // if (!packLength){ //catch for no packages created
        //   this.generateToast('No Packages Found')
        //   this.$router.push('/visionplan')
        //   return
        // }
        if (currIndex >= packLength) {
          this.$store.dispatch("setPackageIndex", 0);
          currIndex = this.$store.getters.getCurrentPackageIndex;
        }
        if (!this.isEmpty(modifiedLens)) {
          this.$store.dispatch("createTempPackage", {
            type: this.currentType,
            level: this.currentLevels[this.currentLevelIndex],
            packageIndex: currIndex,
            pack: modifiedLens,
            edited: true,
          });
        }
        this.visoPro = this.lenses.find((lens) => {
          //Initialize Transitions Addon Default
          return lens.code == "VSPR";
        });
        this.eyezenArray = this.lenses.filter((lens) => {
          //Initialize Eyezen Options
          return lens.code.includes("EYZ");
        });
        this.packageCheck();
        console.log(this.customize)
        if (this.$store.getters.getCustomize){ //check if router pushed customize view to be shown
          this.showCustomize = this.$store.getters.getCustomize
          this.$store.dispatch('showCustomize', false)
        }
        this.$store.dispatch("setView", this.view);
      });
      for (let transition in this.transitionImages) {
      let foundTransition = this.lenses.find((lens) => {
        return lens.code == this.transitionImages[transition].code;
      });
      if (foundTransition === undefined) {
        let transCode = this.transitionImages[transition].code;
        if (this.currentType == "single") {
          transCode = transCode + "SV";
        } else {
          transCode = transCode + "MF";
        }
        foundTransition = this.lenses.find((elem) => {
          return elem.code == transCode;
        });
      }
      if (foundTransition && foundTransition.price > 0) {
        this.transitionImages[transition].active = true;
      }
    }
    }
  },
  props: {
    view: String,
    frame: Object,
    customize: Boolean
  },
  components: {
    Navigation,
    CheckoutBox,
    PlansButton,
    Customize,
    Dropdown,
    DropdownWImage,
    svgLogo,
    SignatureLogo,
    VantageLogo,
    XtraActiveLogo,
    AmberLogo,
    AmethystLogo,
    BrownLogo,
    GrayLogo,
    SapphireLogo,
    EmeraldLogo,
    GreenLogo,
    PlumLogo,
    GrapeLogo,
    EmeraldBlue,
    ForestGray,
    SiennaBrown,
    GrayMist,
    MirrorGold,
    MirrorBlue,
    MirrorSilver,
    ArrowDropdownSvg,
    EditIconSvg,
    BundleIconSvg,
    XperioUvSvg,
    TransitionLogoSvg,
    // NoMirror
  },
  created() {
    // setDefaultTransitions();
    let modifiedLens = {};
      if (!this.isEmpty(this.currentCartLens)) {
        modifiedLens = this.cloneDeep(this.currentCartLens);
      }
    this.createPackageArray(modifiedLens)
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    currentFrame() {
      return this.$store.getters.getCurrentFrame;
    },
    lenses() {
      return this.$store.getters.getLenses;
    },
    currentType() {
      return this.$store.getters.getCurrentLensType;
    },
    transitionStatus() {
      return this.$store.getters.getTransitionStatus;
    },
    currentPatient(){
      return this.$store.getters.getCurrentPatient
    },
    currentLightResonsives() {
      const currentBrand = this.currentPackage.base.brandName;
      const lightArray = this.lenses.filter((lens) => {
        if (!lens.mod) {
          return (
            lens.lensType == "Light Responsive" &&
            lens.brandName == currentBrand &&
            lens.price > 0
          );
        } else {
          const currType = this.currentType;
          return (
            lens.lensType == "Light Responsive" &&
            lens.brandName == currentBrand &&
            lens.price > 0 &&
            lens.mod == currType
          );
        }
      });
      return lightArray;
    },
    currentLRColors() {
      if (this.currentPackage.transition.brandDescText) {
        const currentBrand = this.currentPackage.transition.brandName;
        let stringColors = this.currentPackage.transition.colors
          .split(",")
          .map((v) => v.toLowerCase().trim());
        const colorArray = this.lenses.filter((lens) => {
          return (
            lens.lensType.toLowerCase() == "light responsive color" &&
            lens.brandName == currentBrand &&
            stringColors.includes(lens.brandDescText.toLowerCase())
          );
        });
        return colorArray;
      }
      return [];
    },
    currentMaterials() {
      const matOrder = ['M174', 'M167', 'M16', 'TRIV', 'POLY', 'CR39']
      const mats = this.lenses.filter(lens => {
        return lens.itemType == 'material'
      })
      let matsArray = []
      matOrder.forEach(mat => {
        const foundMat = mats.filter(elem => {
          return elem.code == mat
        })
        if (foundMat.length >1) {
          const _found = foundMat.find(material => {
            return (material.mod.toLowerCase() == this.currentPackage.base.lensType.toLowerCase()) && (this.currentPackage.base.mod == material.lensType)
          })
          if (_found){
            matsArray.push(_found)
          }

        }
        else if (foundMat.length === 1){
          matsArray.push(foundMat[0])
        }
      })
      return matsArray
    },
    selectedEyezen() {
      if (this.currentPackage.base.code.includes("EYZ")) {
        return this.eyezenArray.find((lens) => {
          return lens.code === this.currentPackage.base.code;
        });
      }
      return {};
    },
    generatedPackages() {
      return this.$store.getters.getPackages;
    },
    packageArray() {
      return this.$store.getters.getPackageArray;
    },
    currentLevelIndex() {
      return this.$store.getters.getCurrentLevelIndex;
    },
    currentPackageIndex() {
      return this.$store.getters.getCurrentPackageIndex;
    },
    packagePrice() {
      let currentPrice =
        parseInt(this.currentPackage.price) +
        this.currentPackage.base.price +
        this.currentPackage.material.price;
      this.currentPackage.addons.forEach((add) => {
        currentPrice += add.price;
      });
      if (this.currentPackage.transition.brandDescText) {
        currentPrice += this.currentPackage.transition.price;
      }
      if (this.currentPackage.mirror.price) {
        currentPrice += this.currentPackage.mirror.price;
      }
      return currentPrice;
    },
    currentLevels() {
      let levArr = Object.keys(this.generatedPackages[this.currentType]);
      return levArr;
    },
    currentPackage() {
      const pack = this.packageArray[this.currentPackageIndex];
      this.$store.dispatch("setLens", pack);
      return pack;
      // return this.packageArray[this.currentPackageIndex]
    },
    currentMod() {
      const mod = this.$store.getters.getCurrentIndex;
      if (mod == "polarized") {
        return mod;
      }
      else if (mod == 'computer'){
        return mod;
      }
      return "";
    },
    currentIndex() {
      return this.$store.getters.getCurrentIndex;
    },
    currentBaseImage() {
      let self = this;
      var selectedBase = this.baseImages.find((image) => {
        return self.currentPackage.base.code === image.code;
        // return self.currentPackage.base.code.includes(image.code)
      });
      if (selectedBase == undefined) {
        selectedBase = this.baseImages.find((image) => {
          return self.currentPackage.base.code.includes(image.code);
        });
      }
      return selectedBase.img;
    },
    currentAddonImage() {
      const selectedAddon = this.currentPackage.addons.find((add) => {
        return add.itemType === "addon";
      });
      let addonImg = {};
      if (selectedAddon.image) {
        addonImg = {
          img: selectedAddon.image,
        };
      } else {
        addonImg = {
          div: selectedAddon.brandDescText,
        };
      }

      return addonImg;
      // return this.addonImages[selectedAddon.code].img
    },
    selectedAddon() {
      //TODO: Replace all selectedAddons with this
      const selectedAddon = this.currentPackage.addons.find((add) => {
        return add.itemType === "addon";
      });
      return selectedAddon;
    },
    optionsArray() {
      let optionArr = [];
      if (this.currentPackage.option) {
        const foundOption = this.lenses.find((elem) => {
          return elem.code == this.currentPackage.option;
        });
        if (foundOption) {
          optionArr.push(foundOption);
        }
        const selectedAddon = this.currentPackage.addons.find((add) => {
          return add.itemType === "addon";
        });
        if (selectedAddon) {
          optionArr.push(selectedAddon);
        }
      }
      return optionArr;
    },
    currentTreatments() {
        const optionsArray = this.lenses.filter(option => {
            return option.itemType == 'extra' &&
            option.price > 0 &&
            !option.colors

        })
        return optionsArray
    },
    LRCompatiblity(){
      if (this.selectedAddon.incompatibility && this.selectedAddon.incompatibility.includes('light responsive')){
        return false
      }
      return true
    },
    addonColors() {
      let colorArray = [];
      if (this.selectedAddon.colors) {
        const currentBrand = this.selectedAddon.brandName;
        let stringColors = this.selectedAddon.colors
          .split(",")
          .map((v) => v.toLowerCase().trim());
        colorArray = this.lenses.filter((lens) => {
          return (
            lens.lensType.toLowerCase() == "polarized color" &&
            lens.brandName == currentBrand &&
            stringColors.includes(lens.brandDescText.toLowerCase())
          );
        });
      }
      return colorArray;
    },
    currentCartLens() {
      return this.$store.getters.getCurrentLens;
    },
    selfpayDiscount(){
        return this.$store.getters.getSelfpayDiscount
    },
    currentInsurancePlan(){
        return this.$store.getters.getCurrentInsurance
    },
  },
};
</script>

<style scoped>
.button {
  @apply scale-0 duration-300;
}
.animationprimary3 {
  @apply h-auto scale-100 border-4 border-primary1 z-50;
}
.animationPrimary2 {
  @apply h-auto scale-100 border-4 border-primary2 z-50;
}
.materialDropdown {
  @apply h-auto scale-100 border-4 border-primary1 border-b-0 z-50 bg-lightGray text-lightGrayText;
}
.arrowRotation {
  @apply transform rotate-90 text-lightGrayText;
}
div >>> .package-heading {
  @apply font-semibold text-xl text-primary3 text-center;
}
.display {
  @apply hidden;
}
</style>


