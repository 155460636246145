<template>
<!-- eslint-disable -->
  <section class="demoButtonMenu h-11/12 w-9/12 lg:w-7/12 absolute top-0 right-0 bg-toggleGray flex flex-col z-50">
    <div class="demoButtonMenu flex items-center justify-end mx-4 h-2/12">

    <!--Button for hide/show branded transitions button/demo-->
    <!-- <div @click="showTransitionsBtn = !showTransitionsBtn" class="demoButtonMenu w-4 h-4 mr-24">Hide</div> -->


      <!-- <div @click="closeDemo" class="flex">
        <button class="w-8 mr-6 block focus:outline-none">
          <svg class="icon_x fill-current stroke-current text-primary3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2"><path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"/><path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/></g></svg>
        </button>
        <P class="underline text-primary3">Exit Demos</P>
      </div> -->
      <button @click="$store.dispatch('toggleDemoButtons')" class="demoButtonMenu w-16 block focus:outline-none">
        <div class="demoButtonMenu w-full text-toggleGrayText">
          <LensIconSvg />
        </div>
        <!-- <svg class="demoButtonMenu icon_LensDemo fill-current stroke-current text-toggleGrayText " xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 161.32 124.35"><defs><symbol id="a" data-name="New Symbol 1" class="demoButtonMenu" viewBox="0 0 161.32 124.35"><path class="demoButtonMenu" d="M67.6 42.9h3.23c20.6 0 42.56 1.7 50.29 8 5.4 4.39 3.22 17.17 3.22 23.23 0 4.1-1.84 26.51-7.35 32.38s-32.9 11.73-56.26 11.73c-12.76 0-31.1-.74-37.44-5.63C13 104.61 9.51 78 9.51 71.06c0-4.78-2.91-16.59 5.74-22.29S49.6 42.9 67.6 42.9m-.6-6.26c-24.16 0-48.56.53-58.36 6.85s-8.9 18-8.37 24.26c.1 1.1.19 2.14.19 2.81 0 5.37 3 36.83 16.49 47 6.14 4.63 19.71 6.79 42.7 6.79 23.77 0 55.62-5.6 63.34-13.64 8-8.32 9.1-36.68 9.1-37 0-1.14.09-2.66.19-4.28.47-7.76 1.12-18.4-5.32-23.51-7.77-6.18-26.79-9.32-56.51-9.32H67z" fill="#34688c"/><path fill="#34688c" class="demoButtonMenu" stroke="#34688c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.261" d="M148.01 30.96l-3.3-13.11-3.3 13.11-12.68 2.88 12.68 2.87 3.3 13.11 3.3-13.11 12.68-2.87-12.68-2.88zM124.21 18.52l1.85-7.33 7.1-1.62-7.1-1.6-1.85-7.34-1.85 7.33-7.09 1.61 7.09 1.62 1.85 7.33z"/><path fill="#34688c" class="demoButtonMenu" stroke="#34688c" stroke-miterlimit="10" stroke-width="3.362" d="M110.14 80.98L85.31 105.8M95.14 52.53L71.61 76.06M54.98 54.43L29.55 79.86"/></symbol></defs><use class="demoButtonMenu" width="161.32" height="124.35" xlink:href="#a" data-name="Layer 2"/></svg> -->
      </button>
      <!-- <button @click="$store.dispatch('toggleDemoButtons')"  tabindex="-1" class="fixed inset-0 h-full w-full bg-black opacity-50 cursor-default"></button> -->
    </div>
    <div id="demoButtons" class="demoButtonMenu flex h-10/12 py-4">
      <div class="demoButtonMenu w-6/12 h-full flex flex-col justify-around items-center">
          <button class="demosBlock block focus:outline-none" @click.stop="changeDemo('lensMaterials')">Lens Material</button>
          <button class="demosBlock block focus:outline-none" @click.stop="changeDemo('linedBifocal')">Lined Bifocal</button>
          <button class="demosBlock block focus:outline-none" @click.stop="changeDemo('blueLight')">Blue Light</button>
          <button class="demosBlock block focus:outline-none" @click.stop="changeDemo('progressive')">Progressives</button>
      </div>
      <div class="demoButtonMenu w-6/12 h-full flex flex-col justify-around items-center">
          <button class="demosBlock block focus:outline-none" @click.stop="changeDemo('lightReactive')">Light Reactive</button>
          <button class="demosBlock block focus:outline-none" @click.stop="changeDemo('polarized')">Polarized</button>
          <button class="demosBlock block focus:outline-none" @click.stop="changeDemo('antiReflective')">Anti-Reflective</button>
          <button v-if="showTransitionsBtn" class="demosBlock block focus:outline-none" @click.stop="changeDemo('transitions')">Transitions</button>
           <!-- <button class="demosBlock block focus:outline-none">Tints</button> -->
          <!-- <button class="demosBlock block focus:outline-none">Blue Light Protection</button> -->
      </div>
    </div>
  </section>
</template>

<script>
import LensIconSvg from "../../assets/svg/demo_icons/icon_Demos.svg";

export default {
  name: 'DemoButtons',
  data() {
    return {
      showTransitionsBtn: true,
    };
  },
  methods: {
    // closeDemo() {
    //   this.$store.dispatch('toggleDemo');
    //   this.$store.dispatch('toggleDemoButtons')
    // }
  },
  components: {
    LensIconSvg
  },
  props: {
    changeDemo: Function,
    currentDemo: String,
    showBrandedTransitionsBtn: Boolean,
  },
  computed: {

  },
};
</script>

<style scoped>
.demosBlock{
  @apply underline w-11/12 h-4/12 m-2 flex justify-center items-center
          bg-primary1 text-primary1Text rounded-lg rounded-tl-none text-center;
}

.hideTransitionsBtn{
  @apply hidden
}

</style>
