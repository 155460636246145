import Vue from 'vue'

function initialState(){
  return {
    frameShapesQuestions: {
      rectangle: false,
      square: false,
      round: false,
      aviator: false,
      oval: false,
      cateye: false,
      wayfarer: false,
      geometric: false,
      browline: false,
    },
  }
}

const state = initialState

const mutations = {
  update_frame_shapes(state, question){
    Vue.set(state.frameShapesQuestions, question.key, question.status)
  },
  clear_frame_shapes(state){
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = {
  updateFrameShapes({commit}, question) {
    commit('update_frame_shapes', question)
  },
}

const getters = {
  getFrameShapes: state => state.frameShapesQuestions,
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
