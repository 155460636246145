<template>
  <section class="parentSection relative transitions xs:h-2/5 z-30 h-full w-full portrait:h-7/12">

      <div v-if="currentSetting == 'outdoors'" class="imageWrapper h-full w-full relative ">
        <div class="lrOutdoor relative h-full w-full pl-full ">
        </div>
        <transition
          enter-active-class="transition-all duration-3000 ease-in-out"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-0"
          leave-to-class="opacity-100"
        >
          <div :style="{ backgroundImage: 'url(' + outdoorsSrc + ')' }" :key="outdoorsSrc" class="bg-cover bg-center absolute left-0 top-0 h-full w-full"></div>
        </transition>
      </div>
      <div v-if="currentSetting == 'indoor'" class="imageWrapper h-full w-full relative">
        <div :style="{ backgroundImage: 'url(' + indoorSrc + ')' }" class="bg-cover bg-center relative h-full w-full pl-full">
        </div>
        <transition
          enter-active-class="duration-3000 ease-in-out"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-0"
          leave-to-class="opacity-100"
        >
        <div :key="indoorSrc" class="lrIndoor absolute left-0 top-0 h-full w-full"></div>
        </transition>
      </div>
      <div>
        <!-- initial sizing for colors button, switches between button/animation and buttonXS/animationXS (stylings are changed in <style> subtag at the bottom of the screen). -->
        <!-- <div class="xs:hidden absolute top-0 right-0 mt-6 mr-4 z-50">
          <button id="btn-color" @click="toggleColor" :class="[colorDropDownLarge ? 'animation' : '']"  class="button focus:outline-none focus:bordernone overflow-hidden">
            <label id="btn-color" v-on:click.prevent  :class="{display: colorDropDownLarge}" class="text-white font-semibold xs:font-normal xs:text-xs">colors</label>
            <demo-button class="btn-color" v-if="colorDropDownLarge" :changeDemo="changeColors" :currentOption="currentColor" :dropDown="colorDropDownLarge" :title="'colors'" :changeArray="colors" :transitionsDropdown="'transitions'" />
          </button>
        </div>  --> -->
        <!-- initial sizing for colors button, switches between button/animation and buttonXS/animationXS (stylings are changed in <style> subtag at the bottom of the screen). -->
        <div class="xs:hidden absolute top-0 right-0 mt-6 mr-4 z-50">
          <button id="btn-lr-1" @click="$store.dispatch('toggleTransitionsPopup')" :class="[colorDropDown ? 'animation' : '']"  class="btn-setting button focus:outline-none focus:bordernone overflow-hidden">
            <label v-on:click.prevent :class="{display: colorDropDown}" class="btn-setting textShadow text-white font-bold xs:font-normal xs:text-xs">colors</label>
            <TransitionsPopup class="btn-setting" :currentOption="currentColor" :changeDemo="changeColors" v-if="showTransitionsPopup" />
          </button>
        </div>
        <!-- alternate sizing for smaller smaller screens, switches between one of each after reading screen size with sm/md/lg tailwind (can adjust size in config file) -->
        <!-- <div class="sm:hidden md:hidden lg:hidden xl:hidden xxl:hidden absolute top-0 right-0 mr-2 mt-2 z-50">
          <button id="btn-color" @click="toggleColor" :class="[colorDropDown ? 'animationXS' : '']"  class="buttonXS focus:outline-none focus:bordernone overflow-hidden">
            <label id="btn-color" v-on:click.prevent  :class="{display: colorDropDown}" class="text-white font-semibold xs:font-normal xs:text-xs">colors</label>
            <demo-button v-if="colorDropDown" :changeDemo="changeColors" :currentOption="currentColor" :dropDown="colorDropDown" :title="'colors'" :changeArray="colors" :transitionsDropdown="'transitions'" />
          </button>
        </div>  -->
        <div class="sm:hidden md:hidden lg:hidden xl:hidden xxl:hidden absolute top-0 right-0 mr-2 mt-2 z-50">
          <button @click="$store.dispatch('toggleTransitionsPopup')" id="btn-lr-1" :class="[colorDropDown ? 'animationXS' : '']"  class="btn-setting buttonXS focus:outline-none focus:bordernone overflow-hidden">
            <label  v-on:click.prevent  :class="{display: colorDropDown}" class="btn-setting textShadow text-white font-bold xs:font-normal xs:text-xs">colors</label>
            <TransitionsPopup class="btn-setting" :currentOption="currentColor" :changeDemo="changeColors" v-if="showTransitionsPopup" />
          </button>
        </div>
        <!-- same logic as above to switch button stylings -->
        <div class="xs:hidden absolute top-0 right-0 mt-32 mr-4 z-30">
          <button id="btn-setting" @click="settingDropDownLarge = !settingDropDownLarge" :class="[settingDropDownLarge ? 'animationSetting' : '' ]"  class="button focus:outline-none focus:bordernone overflow-hidden">
            <label id="btn-setting" v-on:click.prevent  :class="{display: settingDropDownLarge}" class="text-white font-semibold xs:font-normal xs:text-xs">setting</label>
            <demo-button class="btn-setting" v-if="settingDropDownLarge" :dropDown="settingDropDownLarge" :currentOption="currentSetting" :changeDemo="changeSetting" :title="'setting'" :changeArray="setting" />
          </button>
        </div>
        <!-- sizing for smaller screens for second button (settings button) -->
        <div class="sm:hidden md:hidden lg:hidden xl:hidden xxl:hidden absolute top-0 right-0 mt-16 mr-2 z-30">
          <button id="btn-setting" @click="settingDropDown = !settingDropDown" :class="[settingDropDown ? 'animationXS' : '']"  class="buttonXS focus:outline-none focus:bordernone overflow-hidden">
            <label id="btn-setting" v-on:click.prevent  :class="{display: settingDropDown}" class="text-white font-semibold xs:font-normal xs:text-xs">setting</label>
            <demo-button class="btn-setting" v-if="settingDropDown" :dropDown="settingDropDown" :currentOption="currentSetting" :changeDemo="changeSetting" :title="'setting'" :changeArray="setting" />
          </button>
        </div>

        <div  @click="updateTransition()" class="absolute bottom-0 right-0 xs:mb-4 xs:mr-2 mb-40 mr-4 sm:mb-10">
          <img class="xs:w-10 w-16 mb-10" src="../../../assets/img/demo_icon_replay.png" alt="">
        </div>

      </div>
  </section>
</template>

<script>
import DemoButton from '../Buttons/DemoButton.vue';
import TransitionsPopup from '../Buttons/TransitionsPopup.vue';

export default {
  name: 'Transitions',
  data() {
    return {
      colorDropDown: false,
      settingDropDown: false,
      colorDropDownLarge: true,
      settingDropDownLarge: true,
      currentColor: 'gray',
      currentSetting: 'outdoors',
      outdoorsSrc: '',
      indoorSrc: '',
      transitionsDropdown: 'transitions',
      colors: [
        // {name:'gray', img:'TransitionsGraySvg'},
        // {name:'brown', img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.33 62.27"><defs><clipPath id="a" transform="translate(-.06)"><path d="M0 16.52c0 .84 7.59 44.41 7.59 44.41l39.83-2.53v-7.65a18.8 18.8 0 010-37.6L33.73 1.34S0 15.67 0 16.52" fill="none"/></clipPath><clipPath id="b" transform="translate(-.06)"><path fill="none" d="M28.62 13.15h37.6v37.6h-37.6z"/></clipPath><clipPath id="c" transform="translate(-.06)"><path data-name="SVGID" fill="none" d="M43.85 13.15h37.6v37.6h-37.6z"/></clipPath></defs><g data-name="Layer 2"><path fill="none" d="M0 0h111.33v62.27H0z"/><path d="M78.53 13.59a18.76 18.76 0 00-7.51 1.57 18.68 18.68 0 00-16-.39 18.66 18.66 0 00-15.91.33 18.6 18.6 0 00-6.94-1.34 18.8 18.8 0 108.3 35.65 18.73 18.73 0 0014.47-.29 18.72 18.72 0 0015.12.05 18.79 18.79 0 108.43-35.58z" fill="#fff"/><g clip-path="url(#a)"><path d="M32.13 51.73a19.18 19.18 0 1119.18-19.17 19.19 19.19 0 01-19.18 19.17zm0-37.59a18.42 18.42 0 1018.42 18.42 18.44 18.44 0 00-18.42-18.42z" fill="#dadbdb"/></g><g clip-path="url(#b)" opacity=".5"><path d="M66.16 32a18.8 18.8 0 11-18.8-18.8A18.8 18.8 0 0166.16 32" fill="#8c431f"/></g><g clip-path="url(#c)" opacity=".8"><path d="M81.39 32a18.8 18.8 0 11-18.8-18.8A18.8 18.8 0 0181.39 32" fill="#74401d"/></g><path d="M97.32 32.39a18.8 18.8 0 11-18.79-18.8 18.8 18.8 0 0118.79 18.8" fill="#59231a"/><path d="M78.53 51.94a19.56 19.56 0 1119.55-19.55 19.57 19.57 0 01-19.55 19.55zm0-37.59a18 18 0 1018 18 18.07 18.07 0 00-18-18z" fill="#a3a5a9"/></g></svg>'},
        // {name:'sapphire',img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.33 62.27"><defs><clipPath id="a"><path d="M.56 9L7 58.58l35.89-8.93a18.55 18.55 0 114.81-36.79l-7-9.17z" fill="none"/></clipPath><clipPath id="b"><path fill="none" d="M26.74 12.71h37.1v37.1h-37.1z"/></clipPath><clipPath id="c"><path data-name="SVGID" fill="none" d="M41.77 12.71h37.1v37.1h-37.1z"/></clipPath></defs><g data-name="Layer 2"><path fill="none" d="M0 0h111.33v62.27H0z"/><path d="M81.21 12.53a20 20 0 00-12.46 2.52 18.27 18.27 0 00-6-1.86 18.62 18.62 0 00-9.9 1.46 18.25 18.25 0 00-5.12-1.46 18.65 18.65 0 00-9.91 1.46 18.15 18.15 0 00-5.12-1.46 18.56 18.56 0 105.08 35.35A18.14 18.14 0 0042.91 50a18.55 18.55 0 009.91-1.45A18.14 18.14 0 0057.94 50 18.55 18.55 0 0068 48.46a19.06 19.06 0 008.22 3.09 19.8 19.8 0 002.54.16A19.52 19.52 0 0092.68 46c.47-.47.89-1 1.3-1.46l.2.14.63-.86a19.86 19.86 0 00-13.6-31.26" fill="#fff"/><g clip-path="url(#a)"><path d="M30.26 50.37a20.63 20.63 0 01-2.48-.15 19.14 19.14 0 1116-5.45 19 19 0 01-13.52 5.6zm0-37.1A18 18 0 0012.43 33.6a18 18 0 0035.67-4.69 18 18 0 00-17.83-15.64z" fill="#dadbdb"/></g><g clip-path="url(#b)" opacity=".5"><path d="M63.69 28.84a18.56 18.56 0 11-16-16 18.56 18.56 0 0116 16" fill="#1274b7"/></g><g clip-path="url(#c)" opacity=".8"><path d="M78.71 28.84a18.55 18.55 0 11-16-16 18.56 18.56 0 0116 16" fill="#1274b7"/></g><path d="M97.42 31.65A18.55 18.55 0 1178.87 13.1a18.56 18.56 0 0118.55 18.55" fill="#046c9c"/><path d="M78.87 51.31a19.66 19.66 0 1119.66-19.66 19.68 19.68 0 01-19.66 19.66zm0-37.1a17.44 17.44 0 1017.44 17.44 17.46 17.46 0 00-17.44-17.44z" fill="#a3a5a9"/></g></svg>'},
        // {name:'green',img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.33 62.27"><defs><clipPath id="a" transform="translate(-.06)"><path d="M0 16.52c0 .84 7.59 44.41 7.59 44.41l39.83-2.53v-7.65a18.8 18.8 0 010-37.6L33.73 1.34S0 15.67 0 16.52" fill="none"/></clipPath><clipPath id="b" transform="translate(-.06)"><path fill="none" d="M28.62 13.15h37.6v37.6h-37.6z"/></clipPath><clipPath id="c" transform="translate(-.06)"><path data-name="SVGID" fill="none" d="M43.85 13.15h37.6v37.6h-37.6z"/></clipPath></defs><g data-name="Layer 2"><path fill="none" d="M0 0h111.33v62.27H0z"/><path d="M78.53 13.59a18.76 18.76 0 00-7.51 1.57 18.68 18.68 0 00-16-.39 18.66 18.66 0 00-15.91.33 18.6 18.6 0 00-6.94-1.34 18.8 18.8 0 108.3 35.65 18.73 18.73 0 0014.47-.29 18.72 18.72 0 0015.12.05 18.79 18.79 0 108.43-35.58z" fill="#fff"/><g clip-path="url(#a)"><path d="M32.13 51.73a19.18 19.18 0 1119.18-19.17 19.19 19.19 0 01-19.18 19.17zm0-37.59a18.42 18.42 0 1018.42 18.42 18.44 18.44 0 00-18.42-18.42z" fill="#dadbdb"/></g><g clip-path="url(#b)" opacity=".5"><path d="M66.16 32a18.8 18.8 0 11-18.8-18.8A18.8 18.8 0 0166.16 32" fill="#516b30"/></g><g clip-path="url(#c)" opacity=".8"><path d="M81.39 32a18.8 18.8 0 11-18.8-18.8A18.8 18.8 0 0181.39 32" fill="#516b30"/></g><path d="M97.32 32.39a18.8 18.8 0 11-18.79-18.8 18.8 18.8 0 0118.79 18.8" fill="#516b30"/><path d="M78.53 51.94a19.56 19.56 0 1119.55-19.55 19.57 19.57 0 01-19.55 19.55zm0-37.59a18 18 0 1018 18 18.07 18.07 0 00-18-18z" fill="#a3a5a9"/></g></svg>'},
        // {name:'amber',img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.33 62.27"><defs><clipPath id="a"><path d="M.56 9L7 58.58l35.89-8.93a18.55 18.55 0 114.81-36.79l-7-9.17z" fill="none"/></clipPath><clipPath id="b"><path fill="none" d="M26.74 12.71h37.1v37.1h-37.1z"/></clipPath><clipPath id="c"><path data-name="SVGID" fill="none" d="M41.77 12.71h37.1v37.1h-37.1z"/></clipPath></defs><g data-name="Layer 2"><path fill="none" d="M0 0h111.33v62.27H0z"/><path d="M81.21 12.53a20 20 0 00-12.46 2.52 18.27 18.27 0 00-6-1.86 18.62 18.62 0 00-9.9 1.46 18.25 18.25 0 00-5.12-1.46 18.65 18.65 0 00-9.91 1.46 18.15 18.15 0 00-5.12-1.46 18.56 18.56 0 105.08 35.35A18.14 18.14 0 0042.91 50a18.55 18.55 0 009.91-1.45A18.14 18.14 0 0057.94 50 18.55 18.55 0 0068 48.46a19.06 19.06 0 008.22 3.09 19.8 19.8 0 002.54.16A19.52 19.52 0 0092.68 46c.47-.47.89-1 1.3-1.46l.2.14.63-.86a19.86 19.86 0 00-13.6-31.26" fill="#fff"/><g clip-path="url(#a)"><path d="M30.26 50.37a20.76 20.76 0 01-2.48-.15 19.14 19.14 0 1116-5.45 19 19 0 01-13.52 5.6zm0-37.1A18 18 0 1048.1 28.91a18 18 0 00-17.83-15.64z" fill="#dadbdb"/></g><g clip-path="url(#b)" opacity=".5"><path d="M63.69 28.84a18.56 18.56 0 11-16-16 18.56 18.56 0 0116 16" fill="#be9438"/></g><g clip-path="url(#c)" opacity=".8"><path d="M78.71 28.84a18.55 18.55 0 11-16-16 18.56 18.56 0 0116 16" fill="#bd7937"/></g><path d="M97.42 31.65A18.55 18.55 0 1178.87 13.1a18.56 18.56 0 0118.55 18.55" fill="#9c4c16"/><path d="M78.87 51.31a19.66 19.66 0 1119.66-19.66 19.68 19.68 0 01-19.66 19.66zm0-37.1a17.44 17.44 0 1017.44 17.44 17.46 17.46 0 00-17.44-17.44z" fill="#a3a5a9"/></g></svg>'},
        // {name:'emerald',img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.33 62.27"><defs><clipPath id="a"><path d="M.56 9L7 58.58l35.89-8.93a18.55 18.55 0 114.81-36.79l-7-9.17z" fill="none"/></clipPath><clipPath id="b"><path fill="none" d="M26.74 12.71h37.1v37.1h-37.1z"/></clipPath><clipPath id="c"><path data-name="SVGID" fill="none" d="M41.77 12.71h37.1v37.1h-37.1z"/></clipPath></defs><g data-name="Layer 2"><path fill="none" d="M0 0h111.33v62.27H0z"/><path d="M81.21 12.53a20 20 0 00-12.46 2.52 18.27 18.27 0 00-6-1.86 18.62 18.62 0 00-9.9 1.46 18.25 18.25 0 00-5.12-1.46 18.65 18.65 0 00-9.91 1.46 18.15 18.15 0 00-5.12-1.46 18.56 18.56 0 105.08 35.35A18.14 18.14 0 0042.91 50a18.55 18.55 0 009.91-1.45A18.14 18.14 0 0057.94 50 18.55 18.55 0 0068 48.46a19.06 19.06 0 008.22 3.09 19.8 19.8 0 002.54.16A19.52 19.52 0 0092.68 46c.47-.47.89-1 1.3-1.46l.2.14.63-.86a19.86 19.86 0 00-13.6-31.26" fill="#fff"/><g clip-path="url(#a)"><path d="M30.26 50.37a20.76 20.76 0 01-2.48-.15 19.14 19.14 0 1116-5.45 19 19 0 01-13.52 5.6zm0-37.1A18 18 0 1048.1 28.91a18 18 0 00-17.83-15.64z" fill="#dadbdb"/></g><g clip-path="url(#b)" opacity=".5"><path d="M63.69 28.84a18.56 18.56 0 11-16-16 18.56 18.56 0 0116 16" fill="#007361"/></g><g><g clip-path="url(#c)" opacity=".8"><path d="M78.71 28.84a18.55 18.55 0 11-16-16 18.56 18.56 0 0116 16" fill="#007361"/></g><path d="M97.42 31.65A18.55 18.55 0 1178.87 13.1a18.56 18.56 0 0118.55 18.55" fill="#035b4d"/><path d="M78.87 51.31a19.66 19.66 0 1119.66-19.66 19.68 19.68 0 01-19.66 19.66zm0-37.1a17.44 17.44 0 1017.44 17.44 17.46 17.46 0 00-17.44-17.44z" fill="#a3a5a9"/></g></g></svg>'},
        // {name:'amethyst',img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.33 62.27"><defs><clipPath id="a"><path d="M.56 9L7 58.58l35.89-8.93a18.55 18.55 0 114.81-36.79l-7-9.17z" fill="none"/></clipPath><clipPath id="b"><path fill="none" d="M26.74 12.71h37.1v37.1h-37.1z"/></clipPath><clipPath id="c"><path data-name="SVGID" fill="none" d="M41.77 12.71h37.1v37.1h-37.1z"/></clipPath></defs><g data-name="Layer 2"><path fill="none" d="M0 0h111.33v62.27H0z"/><path d="M81.21 12.53a20 20 0 00-12.46 2.52 18.27 18.27 0 00-6-1.86 18.62 18.62 0 00-9.9 1.46 18.25 18.25 0 00-5.12-1.46 18.65 18.65 0 00-9.91 1.46 18.15 18.15 0 00-5.12-1.46 18.56 18.56 0 105.08 35.35A18.14 18.14 0 0042.91 50a18.55 18.55 0 009.91-1.45A18.14 18.14 0 0057.94 50 18.55 18.55 0 0068 48.46a19.06 19.06 0 008.22 3.09 19.8 19.8 0 002.54.16A19.52 19.52 0 0092.68 46c.47-.47.89-1 1.3-1.46l.2.14.63-.86a19.86 19.86 0 00-13.6-31.26" fill="#fff"/><g clip-path="url(#a)"><path d="M30.26 50.37a20.76 20.76 0 01-2.48-.15 19.14 19.14 0 1116-5.45 19 19 0 01-13.52 5.6zm0-37.1A18 18 0 0012.43 33.6a18 18 0 0035.67-4.69 18 18 0 00-17.83-15.64z" fill="#dadbdb"/></g><g clip-path="url(#b)" opacity=".5"><path d="M63.69 28.84a18.56 18.56 0 11-16-16 18.56 18.56 0 0116 16" fill="#664498"/></g><g clip-path="url(#c)" opacity=".8"><path d="M78.71 28.84a18.55 18.55 0 11-16-16 18.56 18.56 0 0116 16" fill="#664498"/></g><path d="M97.42 31.14a18.55 18.55 0 11-18.55-18.55 18.55 18.55 0 0118.55 18.55" fill="#4b2a75"/><path d="M78.87 51.31a19.66 19.66 0 1119.66-19.66 19.68 19.68 0 01-19.66 19.66zm0-37.1a17.44 17.44 0 1017.44 17.44 17.46 17.46 0 00-17.44-17.44z" fill="#a3a5a9"/></g></svg>'}
      ],
      setting: [
          {name:'indoor', img:'ReactiveIndoorSvg'},
          {name: 'outdoors', img: 'ReactiveOutdoorSvg'}
        ],
    };
  },
  methods: {
    changeColors(color) {
      if(this.settingDropDown) {
        this.settingDropDown = false
      }
      switch (color) {
        case 'gray':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Gray.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Gray.jpg';
        break;
        case 'brown':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Brown.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Brown.jpg';
        break;
        case 'sapphire':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Sapphire.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Sapphire.jpg';
        break;
        case 'amethyst':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Amethyst.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Amethyst.jpg';
        break;
        case 'green':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Green.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Green.jpg';
        break;
        case 'amber':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Amber.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Amber.jpg';
        break;
        case 'emerald':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Emerald.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Emerald.jpg';
        break;
        case 'color1':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Amber.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Amber.jpg';
        break;
        case 'color2':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Amethyst.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Amethyst.jpg';
        break;
        case 'color3':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Sapphire.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Sapphire.jpg';
        break;
        case 'extraGray':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Gray.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Gray.jpg';
        break;
      }
       this.currentColor = color;
       this.colorDropDown = false;
       if(this.showTransitionsPopup == true){
        this.$store.dispatch('toggleTransitionsPopup')
      }
    },

    // toggleColor(){
    //    if(this.settingDropDown == true) {
    //     this.settingDropDown = false;
    //     this.colorDropDown = true;
    //     this.colorDropDownLarge = !this.colorDropDownLarge;
    //   }else if(this.colorDropDown == false){
    //      this.colorDropDownLarge = !this.colorDropDownLarge;
    //      this.colorDropDown = !this.colorDropDown;
    //   if(this.showTransitionsPopup == true){
    //      this.$store.dispatch('toggleTransitionsPopup')
    //     }
    //   }
    // },
    // toggleColor(){
    //    if(this.settingDropDown) {
    //     this.settingDropDown = false
    //     this.colorDropDown = !this.colorDropDown
    //   }else if(this.colorDropDown == false){
    //      this.colorDropDown = !this.colorDropDown;
    //   }
    // },

    changeSetting(option) {
      this.currentSetting = option;
      this.settingDropDown = false;
      this.settingDropDownLarge = !this.settingDropDownLarge;
    },
    // closeColorDropDown() {
    //   this.colorDropDown = false
    // },
    // closeSettingDropDown() {
    //   this.settingDropDown = false
    // },
    updateTransition(){
      var self = this
        if(this.outdoorsSrc != './img/demos/LightReactive_Outdoor_None.jpg'){
            this.outdoorsSrc = './img/demos/LightReactive_Outdoor_None.jpg'
            setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
        }
        if (this.indoorSrc == './img/demos/LightReactive_Indoor_Gray.jpg'){
            self.indoorSrc = './img/demos/LightReactive_Indoor_None.jpg'
            setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
        }
        if (this.indoorSrc == './img/demos/LightReactive_Indoor_Emerald.jpg'){
            self.indoorSrc = './img/demos/LightReactive_Indoor_None.jpg'
            setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
        }
        if (this.indoorSrc == './img/demos/LightReactive_Indoor_Amber.jpg'){
            self.indoorSrc = './img/demos/LightReactive_Indoor_None.jpg'
            setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
        }
        if (this.indoorSrc == './img/demos/LightReactive_Indoor_Green.jpg'){
            self.indoorSrc = './img/demos/LightReactive_Indoor_None.jpg'
            setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
        }
        if (this.indoorSrc == './img/demos/LightReactive_Indoor_Amethyst.jpg'){
            self.indoorSrc = './img/demos/LightReactive_Indoor_None.jpg'
            setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
        }
        if (this.indoorSrc == './img/demos/LightReactive_Indoor_Sapphire.jpg'){
            self.indoorSrc = './img/demos/LightReactive_Indoor_None.jpg'
            setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
        }
        if (this.indoorSrc == './img/demos/LightReactive_Indoor_Brown.jpg'){
            self.indoorSrc = './img/demos/LightReactive_Indoor_None.jpg'
            setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
        }

    },
  },
  mounted() {
    this.changeColors(this.currentColor);
  },
  components: {
    DemoButton,
    TransitionsPopup
  },
  computed: {
    showTransitionsPopup() {
      return this.$store.getters.getTransitionsPopupToggle;
    },
  },
  created: function() {
    let self = this;

    window.addEventListener('click', function(e){
      if ((e.target.id != 'btn-setting') && (e.target.id != 'btn-color')) {
        // console.log(e.target)
        self.settingDropDown = false;
        self.colorDropDown = false;
        self.settingDropDownLarge = false;
        self.colorDropDownLarge = false;
      }
    })
  },
};
</script>

<style scoped>
/* Media query for small screens (mobile, i.e iPhone 10 or Android equivalent) in landscape mode */
  @media screen and ( max-height: 375px ) and (min-width: 500px) and (max-width: 897px){

    .imageWrapper{
      /* @apply w-full h-11/12 */
    }


  }

  .button {
    @apply bg-primary2 w-20 h-20 rounded-full block scale-0 duration-300 overflow-auto;
  }
  .buttonXS {
    @apply bg-primary2 w-12 h-12 rounded-full block scale-0 duration-300 overflow-auto;
  }
  .animation {
    @apply scale-100 w-48 h-48 bg-primary2 rounded-lg rounded-tl-none transform-none overflow-y-auto;
  }
  .animationSetting {
    @apply scale-100 w-48 h-48 bg-primary2 rounded-lg rounded-tl-none transform-none overflow-y-auto;
  }
  .animationXS {
    @apply scale-100 w-48 h-auto bg-primary2 rounded-lg rounded-tl-none transform-none overflow-y-auto;
  }

  .duration-10000 {
    transition-duration: 10000ms;
  }
  .lrOutdoor{
    background-image: url(../../../assets/img/demos/LightReactive_Outdoor_None.jpg);
    background-size: cover;
    background-position: center;
  }
  .lrIndoor{
    background-image: url(../../../assets/img/demos/LightReactive_Indoor_None.jpg);
    background-size: cover;
    background-position: center;
  }
  .display{
    @apply hidden;
  }
</style>