var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "daily-plan dailyPlan h-full w-11/12 bg-primary1 text-primary1Text flex justify-between portrait:mb-6"
    },
    [
      _c("div", { staticClass: "flex w-3/12" }, [
        _c("div", { staticClass: "w-20 pl-3 " }, [
          _vm.currentView != "VisionPlan"
            ? _c(
                "div",
                {
                  staticClass:
                    "dailyPlan w-8 h-full flex justify-center items-center",
                  on: { click: _vm.openDailyPlan }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass:
                        "checkbox_blank fill-current text-primary1Text transform rotate-180 w-6",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 42.12 104.5"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M2 5.67l37.55 46.58L2 98.83V5.67M0 0v104.5l3.56-4.41L41.11 53.5l1-1.25-1-1.26L3.56 4.41 0 0z",
                          "data-name": "Layer 2"
                        }
                      })
                    ]
                  )
                ]
              )
            : _vm._e()
        ]),
        _c(
          "div",
          {
            staticClass: "h-full w-1/12 flex items-center text-xl portrait:ml-0"
          },
          [_vm._v("Daily Pair:")]
        )
      ]),
      _vm._m(0),
      _c(
        "button",
        {
          staticClass: "w-2/12 relative focus:outline-none portrait:w-2/12",
          on: {
            click: function($event) {
              return _vm.selectPlan("everyday")
            }
          }
        },
        [
          _c(
            "span",
            { staticClass: "-mr-10 text-primary1Text portrait:mr-0" },
            [_vm._v("Select")]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-10 h-full bg-transparent flex justify-center items-center absolute -mr-6 right-0 bottom-0"
            },
            [_c("ArrowForwardSvg")],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "h-full w-5/12 flex justify-center items-center portrait:w-4/12"
      },
      [
        _c("span", { staticClass: "w-10/12 portrait:ml-4" }, [
          _vm._v(
            "Improve your comfort and overall vision experience with a premium vision lens, complete with an anti-reflective coating."
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }