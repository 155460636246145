var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex w-full h-full flex-col items-center font-bold mt-1/3"
      },
      [
        _c("div", [_vm._v("Oops!")]),
        _c("div", [_vm._v("Something went wrong.")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }