<template>
  <!-- eslint-disable -->
 <main class="navigation h-2/12 flex flex-col justify-around">
    <div class="h-1/2 flex items-center pl-3 text-xl pt-2">
      <BackButton />
      <span @click="$router.push('/')" class="underline">home</span>
      <div v-if="['lenspackage','frames','visionplan','checkout'].indexOf(currentRouteName) > -1">
        <span class="mx-1">></span>
        <span @click="$router.push('/visionplan')" :class="{underline: currentRouteName != 'visionplan'}">plan</span>
      </div>
      <div v-if="['lenspackage','frames','checkout'].indexOf(currentRouteName) > -1">
        <span class="mx-1">></span>
        <span @click="$router.push('/frames')" :class="{underline: currentRouteName != 'frames'}">frames</span>
      </div>
      <div v-if="['lenspackage','checkout'].indexOf(currentRouteName) > -1">
        <span class="mx-1">></span>
        <span
          @click="$router.push('/lenspackage')"
          :class="{underline: currentRouteName != 'lenspackage'}">lenses</span>
      </div>

      <div v-if="currentRouteName == ('checkout')">
        <span class="mx-1">></span>
        <span @click="$router.push('/checkout')" :class="{underline: currentRouteName != 'checkout'}">checkout</span>
      </div>
    </div>
    <div class="w-8/12 h-12 flex justify-between portrait:items-center portrait:w-7/12">
      <p v-if="currentView == 'Frames'" class="flex pl-20 items-center">Let's start with frames.</p>
      <p v-if="currentView == 'LensPackage'" class="flex pl-20 items-center">Choose your lens package.</p>
      <p v-if="currentView == 'Checkout'" class="flex pl-20 items-center">Great choices!</p>
      <p v-if="currentView == 'VisionPlan' && currentPatient.user" class="flex pl-20 items-center">{{currentPatient.user.name}}'s Optical Treatment Plan:</p>
      <p v-else-if="currentView == 'VisionPlan'" class="flex pl-20 items-center">Your Optical Treatment Plan:</p>
      <!-- <button v-if="currentView == 'Checkout'"  @click="showEmailPopup = !showEmailPopup" class="underline h-12 w-40 -mt-2 text-xl flex justify-between items-center pl-4 pr-4 bg-primary3 text-primary3Text rounded-lg rounded-tl-none focus:outline-none portrait:mb-0 portrait:mr-0">
        <div class="w-8 text-primary3Text">
          <IconEmailSvg />
        </div>
        <span class="">Summary</span>
      </button> -->
    </div>
    <!-- <EmailPopup :toggleEmailPopup="toggleEmailPopup" v-if="showEmailPopup" /> -->
  </main>
</template>

<script>
import BackButton from "./Buttons/BackButton";
// import IconEmailSvg from "../../assets/svg/icons/icon_Email.svg";
// import EmailPopup from '../../components/subcomponents/EmailPopup.vue';

export default {
  name: "Navigation",
  data() {
    return {
      // showEmailPopup: false,
    };
  },
  components: {
    BackButton,
    BackButton,
    // EmailPopup,
    // IconEmailSvg
  },
  methods: {
    // toggleEmailPopup(){
    //   this.showEmailPopup = !this.showEmailPopup
    //   this.$store.dispatch('sendEvent', {
    //     hitType: 'event',
    //     eventCategory: 'button',
    //     eventAction: 'click',
    //     eventLabel: 'Email Popup',
    //     dimension1: this.currentUser.id,
    //     dimension2: this.currentUser.group.id,
    //     dimension3: this.view
    //   })
    // },
  },
  computed: {
    currentRouteName() {
      return this.$router.currentRoute.name;
    },
    currentView() {
      return this.$store.getters.getView
    },
    currentUser() {
      return this.$store.getters.getUser
    },
    currentPatient(){
      return this.$store.getters.getCurrentPatient
    }
  },

};
</script>

<style scoped>

</style>
