<template>
  <section class="polarized h-full relative flex">
    <div v-if="currentColor == 'gray'">
      <CompareSlider
        v-if="!tintSelected"
        leftImage="./img/demos/Polarized_Gray.jpg"
        rightImage="./img/demos/Polarized_None.jpg"
      />
      <CompareSlider
        v-if="tintSelected"
        leftImage="./img/demos/Polarized_Gray.jpg"
        rightImage="./img/demos/demo_TintGray.jpg"
      />
    </div>
    <div v-if="currentColor == 'brown'">
      <CompareSlider
        v-if="!tintSelected"
        leftImage="./img/demos/Polarized_Brown.jpg"
        rightImage="./img/demos/Polarized_None.jpg"
      />
       <CompareSlider
        v-if="tintSelected"
        leftImage="./img/demos/Polarized_Brown.jpg"
        rightImage="./img/demos/demo_TintBrown.jpg"
      />
    </div>
    <label class="absolute left-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl">Polarized</label>
    <div class="absolute bottom-0 right-0 mb-20 mr-4 ">
      <button @click="tintSelected = !tintSelected" class="w-16 h-16 rounded-full bg-blue-700 text-white flex justify-center items-center focus:outline-none focus:shadow-outline">
        <div v-show="tintSelected" class="text-center">Hide Tint</div>
        <div v-show="!tintSelected" class="w-14 text-center">See Tint</div>
      </button>
    </div>
    <!-- <label class="absolute right-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl">No Polarization</label> -->
    <!-- <div v-if="currentSetting == 'outdoors'" class="h-full w-full relative">
      <div class="relative h-full w-full pl-full">
        <img class="absolute left-0 top-0 h-full w-full object-cover" src="../../../assets/img/demos/Polarized_None.jpg" alt="">
      </div>
      <transition
        enter-active-class="transition-all duration-1000 ease-in-out"
        leave-active-class="transition-all duration-1000 ease-in-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-0"
        leave-to-class="opacity-100"
      >
        <img class="absolute left-0 top-0 h-full w-full object-cover" :src="outdoorsSrc" :key="outdoorsSrc" alt="">
      </transition>
    </div> -->

    <div>
      <div class="absolute top-0 right-0 mt-6 mr-4 z-10">
          <button @click="settingDropDown = !settingDropDown" :class="[settingDropDown ? 'animation' : '']"  class="btn-setting button focus:outline-none focus:bordernone overflow-hidden">
            <label v-on:click.prevent v-if="!settingDropDown" id="btn-color" class="btn-setting polColors">colors</label>
            <demo-button class="btn-setting" v-if="settingDropDown" :changeDemo="changeColors" :currentOption="currentColor" :dropDown="settingDropDown" :title="'colors'" :changeArray="colors"/>
          </button>
        </div>
    </div>
  </section>
</template>

<script>
import DemoButton from '../Buttons/DemoButton.vue';
import CompareSlider from "./CompareSlider.vue";

export default {
  name: 'Polarized',
  data() {
    return {
      settingDropDown: true,
      tintSelected: false,
      currentColor: 'gray',
      currentSetting: 'outdoors',
      colors: [
        {name:'gray', img:'PolarizedGraySvg'},
        {name:'brown',img:'PolarizedBrownSvg'}
        // {name:'gray', img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.61 71.64"><path d="M54.94 1.39h3c18.83 0 38.91 1.56 46 7.3 4.94 4 2.94 15.7 2.94 21.23 0 3.76-1.68 24.24-6.72 29.61S70 70.25 48.65 70.25c-11.66 0-28.43-.67-34.23-5.15C5 57.81 1.82 33.51 1.82 27.14 1.82 22.76-.84 12 7.07 6.76s31.41-5.37 47.87-5.37" fill="#585858" stroke="#fff" stroke-miterlimit="10" stroke-width="2.79" data-name="Layer 2"/></svg>'},
        // {name:'brown',img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.61 71.64"><path d="M54.94 1.39h3c18.83 0 38.91 1.56 46 7.3 4.94 4 2.94 15.7 2.94 21.23 0 3.76-1.68 24.24-6.72 29.61S70 70.25 48.65 70.25c-11.66 0-28.43-.67-34.23-5.15C5 57.81 1.82 33.51 1.82 27.14 1.82 22.76-.84 12 7.07 6.76s31.41-5.37 47.87-5.37" fill="#4c392c" stroke="#fff" stroke-miterlimit="10" stroke-width="2.79" data-name="Layer 2"/></svg>'}
      ],
    };
  },
  methods: {
    changeColors(color) {
      switch (color) {
        case 'gray':
          this.outdoorsSrc = './img/demos/Polarized_Gray.jpg';
          this.indoorSrc = '';
          this.tintSelected = false
        break;
        case 'brown':
          this.outdoorsSrc = './img/demos/Polarized_Brown.jpg';
          this.indoorSrc = '';
          this.tintSelected = false
        break;
      }
      this.currentColor = color;
      this.settingDropDown = !this.settingDropDown
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Demo Color ' + color,
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    },
    changeSetting(option) {
      this.currentSetting = option;
    },
    closeColorDrop(){
      this.colorDropDown = false;
    }
  },
  created: function() {
    let self = this;
  
    window.addEventListener('click', function(e){
      
      if (!e.target.classList.contains('btn-setting')) {
        // console.log(e.target)
        self.settingDropDown = false;

        if(self.CurrentColor != self.CurrentColor){
          self.settingDropDown = false;
        }

      }
    })
  },
  components: {
    DemoButton,
    CompareSlider
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser
    },
  },
};
</script>

<style scoped>
  .button {
    @apply bg-primary3 text-primary3Text w-20 h-20 rounded-full block scale-0 duration-300;
  }
  .animation {
    @apply scale-100 w-48 h-48 bg-primary3 text-primary3Text rounded-lg rounded-tl-none
    transform-none ease-in-out;
  }
</style>
