var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "blue-light h-full relative flex",
      attrs: { id: "demo-menu" }
    },
    [
      _c(
        "div",
        {},
        [
          _c("CompareSlider", {
            attrs: {
              leftImage: "./img/demos/blueLight_none.jpg",
              rightImage: "./img/demos/blueLight.jpg"
            }
          }),
          _c(
            "label",
            {
              staticClass:
                "absolute left-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl"
            },
            [_vm._v("Blue Light")]
          ),
          _c(
            "label",
            {
              staticClass:
                "absolute right-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl"
            },
            [_vm._v("Standard")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }