var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "block focus:outline-none",
      on: {
        click: function($event) {
          return _vm.$emit("recapture")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "w-8 text-lightGrayText" },
        [_c("PhotoBoothSvg")],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }