var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full h-16 flex items-center relative" }, [
    _c(
      "button",
      {
        staticClass:
          "absolute bottom-0 h-12 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray rounded-lg rounded-tl-none flex flex-col text-black focus:outline-none",
        class: [_vm.dropdownState ? "animationprimary3" : ""],
        on: {
          click: function($event) {
            return _vm.$emit("toggle-dropdown")
          }
        }
      },
      [
        _vm.dropdownState
          ? _c(
              "div",
              { staticClass: "w-56 text-textDark z-50 bg-primary3" },
              _vm._l(_vm.dropdownImages, function(color, name) {
                return _c(
                  "div",
                  {
                    key: name,
                    on: {
                      click: function($event) {
                        return _vm.$emit("select-item", color, "human")
                      }
                    }
                  },
                  [
                    _vm.selectedItem.color != color.color
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "h-12 w-full rounded-md border-b-2 border-primary3 rounded-tl-none bg-lightGray flex items-center px-2 button"
                          },
                          [
                            _c("div", {
                              staticClass: "w-12 mr-3",
                              domProps: { innerHTML: _vm._s(color.img) }
                            }),
                            _c("span", [_vm._v(_vm._s(color.color))])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _c("div", { staticClass: "w-full h-12 flex items-center" }, [
          _c("div", { staticClass: "flex w-48" }, [
            _c("div", {
              staticClass: "w-12 mx-3",
              domProps: { innerHTML: _vm._s(_vm.selectedItem.img) }
            }),
            _c("span", [_vm._v(_vm._s(_vm.selectedItem.color))])
          ]),
          _vm.dropdownImages.length > 1
            ? _c("div", { class: { arrowRotation: _vm.dropdownState } }, [
                _c(
                  "svg",
                  {
                    staticClass: "arrow5 fill-current text-primary3 w-6",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 40.19 40.19"
                    }
                  },
                  [
                    _c("g", { attrs: { "data-name": "Layer 2" } }, [
                      _c("circle", {
                        attrs: {
                          cx: "20.1",
                          cy: "20.1",
                          r: "20.1",
                          fill: "none"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d: "M5.11 13.87l14.98 19.66 14.99-19.66H5.11z"
                        }
                      })
                    ])
                  ]
                )
              ])
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }