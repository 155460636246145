<template>
<div>
  <button @click="$emit('help')"  class="ml-8 focus:outline-none flex">
    <svg class="w-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.22 52.67">
      <g data-name="Layer 2">
        <circle cx="24.61" cy="24.61" r="24.61" fill="#cb9700" />
        <text transform="translate(12.64 39.04)" font-size="42" fill="#fff" font-family="ArialMT, Arial">?</text>
      </g>
    </svg>
    <a href="javascript:void(0)" class="text-backOpacityText underline">placement guide</a>
  </button>
  </div>
</template>
<script>

export default {
  name: 'HelpButton',
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>