var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "lens-material bg-white w-full h-full" }, [
    _c("section", { staticClass: "h-10/12 relative flex justify-center" }, [
      _c("div", { staticClass: "absolute top-0 right-0 mt-6 mr-4 z-10" }, [
        _c(
          "button",
          {
            staticClass:
              "button focus:outline-none focus:bordernone overflow-hidden",
            class: [_vm.rxDropDown ? "animation" : ""],
            attrs: { id: "btn-RX" },
            on: {
              click: function($event) {
                _vm.rxDropDown = !_vm.rxDropDown
              }
            }
          },
          [
            _c(
              "label",
              { class: { display: _vm.rxDropDown }, attrs: { id: "btn-RX" } },
              [_vm._v("RX")]
            ),
            _vm.rxDropDown
              ? _c("demo-button", {
                  attrs: {
                    dropDown: _vm.rxDropDown,
                    currentOption: _vm.rxCurrentIndex,
                    changeDemo: _vm.changeRx,
                    title: "RX",
                    changeArray: _vm.rxNumbers,
                    lensRxDropdown: "lensMaterial"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "w-full h-full mt-10 flex flex-col relative " },
        [
          _c("img", {
            staticClass: "absolute top-0 w-9/12 right-0 mt-10 -mr-12",
            attrs: {
              src: require("../../../assets/img/lens_materials/demo_LensMaterialFrames.png"),
              alt: ""
            }
          }),
          _c(
            "div",
            {
              staticClass:
                "w-10/12 h-3/12 flex justify-center items-center z-10"
            },
            [
              _c("div", { staticClass: "mr-10 w-40 text-center font-bold" }, [
                _vm._v("High Index 1.67")
              ]),
              _c("div", [
                _vm.rxCurrentIndex == 8
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p8.00_hi.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == 6
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p6.00_hi.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == 4
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p4.00_hi.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == 2
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p2.00_hi.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -2
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n2.00_hi.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -4
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n4.00_hi.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -6
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n6.00_hi.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -8
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n8.00_hi.png")
                      }
                    })
                  : _vm._e()
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "w-10/12 h-3/12 flex justify-center items-center" },
            [
              _c("div", { staticClass: "mr-10 w-40 text-center font-bold" }, [
                _vm._v("Polycarbonate or Trivex")
              ]),
              _c("div", [
                _vm.rxCurrentIndex == 8
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p8.00_poly.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == 6
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p6.00_poly.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == 4
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p4.00_poly.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == 2
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p2.00_poly.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -2
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n2.00_poly.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -4
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n4.00_poly.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -6
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n6.00_poly.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -8
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n8.00_poly.png")
                      }
                    })
                  : _vm._e()
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "w-10/12 h-3/12 flex justify-center items-center" },
            [
              _c("div", { staticClass: "mr-10 w-40 text-center font-bold" }, [
                _vm._v("Basic Plastic")
              ]),
              _c("div", [
                _vm.rxCurrentIndex == 8
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p8.00_plastic.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == 6
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p6.00_plastic.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == 4
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p4.00_plastic.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == 2
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/p2.00_plastic.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -2
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n2.00_plastic.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -4
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n4.00_plastic.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -6
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n6.00_plastic.png")
                      }
                    })
                  : _vm._e(),
                _vm.rxCurrentIndex == -8
                  ? _c("img", {
                      staticClass: "lensImage",
                      attrs: {
                        src: require("../../../assets/img/lens_materials/n8.00_plastic.png")
                      }
                    })
                  : _vm._e()
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }