var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "absolute top-0 h-full w-full bg-background text-backgroundText flex justify-center items-center z-1000",
      attrs: { id: "container" }
    },
    [
      !_vm.hasFrameSize
        ? [
            _c("div", { staticClass: "w-full h-full flex portrait:flex-col" }, [
              _c(
                "div",
                {
                  staticClass:
                    "w-2/12 h-full bg-lightGray text-lightGrayText flex flex-col items-center justify-between portrait:h-2/12 portrait:w-full portrait:flex-row"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "h-2/12 w-full flex items-center justify-center focus:outline-none portrait:w-2/12 portrait:h-4/12",
                      on: { click: _vm.closeApp }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-lightGrayText w-10" },
                        [_c("BackArrowSvg")],
                        1
                      )
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "landscape:hidden h-2/12 w-full flex items-center justify-center focus:outline-none portrait:w-2/12 portrait:h-4/12",
                      on: { click: _vm.closeApp }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: " text-lightGrayText w-5 mr-4" },
                        [_c("IconXSvg")],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "section",
                {
                  staticClass:
                    "relative mx-auto h-full w-8/12 flex flex-col justify-around items-center portrait:h-8/12 portrait:w-full py-4"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-10/12 h-2/12 flex items-center justify-around rounded-lg rounded-tl-none bg-primary1 text-primary1Text"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-3/12 h-full flex flex-col justify-center items-center"
                        },
                        [
                          _vm._m(0),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-10 w-full border-toggleGray border-2 flex justify-center items-center rounded-lg rounded-tl-none px-4 mt-1 bg-primary1Text"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.lensWidth,
                                    expression: "lensWidth"
                                  }
                                ],
                                staticClass:
                                  "h-11/12 w-full outline-none text-center",
                                attrs: { type: "number" },
                                domProps: { value: _vm.lensWidth },
                                on: {
                                  keypress: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.proceed()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.lensWidth = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-3/12 h-full flex flex-col justify-center items-center"
                        },
                        [
                          _vm._m(1),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-10 w-full border-toggleGray flex justify-center items-center border-2 rounded-lg rounded-tl-none px-4 mt-1 bg-primary1Text"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.bridgeWidth,
                                    expression: "bridgeWidth"
                                  }
                                ],
                                staticClass:
                                  "h-11/12 w-full outline-none text-center",
                                attrs: { type: "number" },
                                domProps: { value: _vm.bridgeWidth },
                                on: {
                                  keypress: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.proceed()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.bridgeWidth = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "w-full  flex justify-center items-center" },
                    [
                      _c("ProceedButton", {
                        staticClass: "disable-dbl-tap-zoom mt-8",
                        on: {
                          proceed: function($event) {
                            return _vm.proceed()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full h-2/12 flex flex-col justify-center items-center underline"
                    },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              _vm.showLensMeasurementsPopup = !_vm.showLensMeasurementsPopup
                            }
                          }
                        },
                        [_vm._v("Where do I find this information?")]
                      )
                    ]
                  ),
                  _vm._m(2)
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "w-2/12 h-full bg-lightGray text-lightGrayText flex flex-col items-center justify-between portrait:h-2/12 portrait:w-full"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "portrait:hidden w-8/12 h-2/12 flex self-start items-center justify-end focus:outline-none",
                      on: { click: _vm.closeApp }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: " text-lightGrayText w-5 mr-4" },
                        [_c("IconXSvg")],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          ]
        : [
            _c("MeasureEyezCapture", {
              attrs: {
                lensWidth: _vm.lensWidth,
                bridgeWidth: _vm.bridgeWidth,
                templeLength: _vm.templeLength
              },
              on: {
                back: function($event) {
                  return _vm.navigateBack()
                }
              }
            })
          ],
      _vm.showLensMeasurementsPopup
        ? _c("LensMeasurementsPopup", {
            attrs: {
              closeLensMeasurementsPopup: _vm.closeLensMeasurementsPopup
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("span", { staticClass: "font-bold" }, [_vm._v("A.")]),
      _vm._v(" Lens Width")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("span", { staticClass: "font-bold" }, [_vm._v("B.")]),
      _vm._v(" Bridge Width")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full h-4/12 relative bg-white" }, [
      _c("img", {
        staticClass: "absolute left-0 top-0 h-full w-full object-contain px-2",
        attrs: { src: require("../../assets/measure_Frame1.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }