<template>
<div @click="goBack"  class="text-backgroundText w-8 mr-6">
  <BackArrowSvg />
</div>
  <!-- <svg @click="goBack" class="fill-current text-backgroundText w-8 mr-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.19 84.19"><g data-name="Layer 2"><path d="M42.09 84.19a42.1 42.1 0 1142.1-42.1 42.14 42.14 0 01-42.1 42.1z" fill="none"/><path d="M48.41 68.73a4 4 0 01-2.83-1.18L23 44.92a4 4 0 010-5.65l22.58-22.64a4 4 0 015.66 5.66l-19.81 19.8L51.24 61.9a4 4 0 01-2.83 6.83z"/></g></svg> -->
</template>

<script>
import BackArrowSvg from "../../../assets/svg/icons/back_arrow.svg";
export default {
  name: "BackButton",
  data() {
    return {
      };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Back Button',
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    }
  },
  components: {
    BackArrowSvg
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser
    },
  }
}
</script>

<style>

</style>