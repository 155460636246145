var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "h-full w-screen overflow-y-scroll",
      style: _vm.cssTheme,
      attrs: { id: "app" }
    },
    [
      !_vm.preloadDone
        ? _c("Spinner", {
            staticClass:
              " absolute h-full w-full bg-white z-1500 flex justify-center items-center"
          })
        : _vm._e(),
      _vm.showAboutApp
        ? _c("AboutApp", {
            attrs: { showAboutApp: _vm.showAboutApp },
            on: {
              "update:showAboutApp": function($event) {
                _vm.showAboutApp = $event
              },
              "update:show-about-app": function($event) {
                _vm.showAboutApp = $event
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "absolute hidden z-100 bottom-0 left-0 w-full h-10/12 justify-center items-center bg-white",
          class: { "please-rotate": _vm.showDemo }
        },
        [_vm._v("Please Rotate")]
      ),
      _c("TopNav"),
      _vm.showDemo
        ? _c("Demo", {
            attrs: { showLensMaterials: _vm.showDemo },
            on: {
              "update:showLensMaterials": function($event) {
                _vm.showDemo = $event
              },
              "update:show-lens-materials": function($event) {
                _vm.showDemo = $event
              }
            }
          })
        : _vm._e(),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class":
              "transition-all transform duration-300 reverse",
            "leave-active-class":
              "transition-all transform duration-200 ease-in-out",
            "enter-class": "-translate-x-full",
            "enter-to-class": "translate-x-0",
            "leave-class": "translate-x-0",
            "leave-to-class": "-translate-x-full"
          }
        },
        [
          _vm.showSideNav
            ? _c("SideNav", {
                attrs: { showSideNav: _vm.showSideNav },
                on: {
                  "update:showSideNav": function($event) {
                    _vm.showSideNav = $event
                  },
                  "update:show-side-nav": function($event) {
                    _vm.showSideNav = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.showMeasureEyez
        ? _c("MeasureeyezContainer", {
            attrs: {
              currentFrame: _vm.currentFrame,
              showMeasureEyez: _vm.showMeasureEyez
            },
            on: {
              "update:showMeasureEyez": function($event) {
                _vm.showMeasureEyez = $event
              },
              "update:show-measure-eyez": function($event) {
                _vm.showMeasureEyez = $event
              }
            }
          })
        : _vm._e(),
      _vm.showAppointment
        ? _c("AppointmentPopup", {
            attrs: { showAppointment: _vm.showAppointment },
            on: {
              "update:showAppointment": function($event) {
                _vm.showAppointment = $event
              },
              "update:show-appointment": function($event) {
                _vm.showAppointment = $event
              }
            }
          })
        : _vm._e(),
      _c("router-view", { key: _vm.reloadKey }),
      _c("BottomNav")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }