<template>
  <div id="frames" class="frames bg-background text-backgroundText relative h-10/12">
    <Navigation />
    <!-- <CheckoutBox /> -->
    <main class="h-10/12 w-full flex-col flex relative">
        <PlansButton />
        <section class="w-full h-2/12 border-t-4 border-primary1 portrait:h-1/12">
            <div class="h-full w-full flex items-center justify-between">
                <div v-if="currentPatient.user" >
                  <h1 v-if="currentMod == '' " class="h-12 flex ml-16 items-center font-bold text-lg float-left">{{currentPatient.user.name}}'s Daily Pair</h1>
                  <h1 v-if="currentMod == 'polarized'" class="h-12 flex ml-16 items-center font-bold text-lg float-left">{{currentPatient.user.name}}'s Sun Pair</h1>
                  <h1 v-if="currentMod == 'computer'" class="h-12 flex ml-16 items-center font-bold text-lg float-left">{{currentPatient.user.name}}'s Computer Pair</h1>
                </div>
                <div v-else>
                  <h1 v-if="currentMod == '' " class="h-12 flex ml-16 items-center font-bold text-lg float-left">Your Daily Pair</h1>
                  <h1 v-if="currentMod == 'polarized'" class="h-12 flex ml-16 items-center font-bold text-lg float-left">Your Sun Pair</h1>
                  <h1 v-if="currentMod == 'computer'" class="h-12 flex ml-16 items-center font-bold text-lg float-left">Your Computer Pair</h1>
                </div>
                <section class="w-full absolute top-0 right-0 mr-10 -mt-12 flex justify-end items-center portrait:w-7/12">
                  <div class="h-12 w-7/12 flex relative rounded-md rounded-tl-none portrait:w-full">
                    <input type="text" placeholder="search frames" v-model="searchQuery" class="frameSearch inputStyle focus:outline-none focus:shadow-outline">
                    <button @click="showSearchResult = !showSearchResult">
                      <div class="w-8 absolute top-0 right-0 mt-2 mr-2">
                        <IconSearchSvg />
                      </div>
                    </button>
                  </div>
                </section>
                <div>
                    <a v-if="!searchQuery" @click="skipFrames" class="underline h-10 pr-10">skip frames</a>
                </div>
            </div>
        </section>
        <section v-if="!searchQuery" class="w-full h-10/12 flex flex-col pl-20 portrait:h-11/12">
          <div class="w-full h-10/12 flex portrait:flex-col">
            <!-- <div class="h-full w-3/12 flex flex-col portrait:flex-row portrait:items-center portrait:w-full portrait:h-3/12">
              <p class="w-6/12 portrait:w-8/12">We'll estimate your face shape to offer frame recommendations!</p>
              <button @click="generateToast('Coming Soon')" class="w-40 h-40 underline text-xl flex flex-col justify-center items-center mt-8 portrait:mt-0 portrait:w-32 portrait:h-32 border-2 border-backgroundText rounded-2xl rounded-tl-md focus:outline-none focus:shadow-outline">
                <div class="w-20 mb-4 portrait:w-16">
                  <CosmeticSvg />
                </div>
                <span>Styleyes</span>
              </button>
            </div> -->
            <!-- <div class="h-full w-9/12 flex flex-col items-center portrait:w-full portrait:h-9/12"> -->
            <div class="h-full w-full flex flex-col items-center portrait:w-full portrait:mt-6">
                <!-- <p class="mb-4 flex self-start portrait:mb-8">Or, you can tell us your preferred frame styles</p> -->
                <p class="mb-4 ml-10 flex self-start portrait:mb-8 portrait:ml-6">Shapes that appeal to you the most:</p>
                <!-- <div class="h-9/12 w-11/12 grid grid-cols-3 justify-items-center"> -->
                <div class="h-9/12 w-11/12 flex flex-wrap justify-between">
                  <button @click="selectedOption({key: 'rectangle', status: !frameShapes.rectangle})" :class="{activeButtons: frameShapes.rectangle}" class="opticalButtons focus:outline-none">
                    <div v-if="!frameShapes.rectangle" class="w-8 ml-4">
                      <CheckboxBlankSvg />
                    </div>
                    <div v-if="frameShapes.rectangle" class="w-8 ml-4">
                      <div class="text-primary2Text">
                        <CheckboxSelectedSvg />
                      </div>
                    </div>
                    <div  class="text-backgroundText w-32">
                      <FrameRectangleSvg :class="{'text-primary2Text': frameShapes.rectangle}" />
                    </div>
                  </button>
                  <button @click="selectedOption({key: 'square', status: !frameShapes.square})" :class="{activeButtons: frameShapes.square}" class="opticalButtons focus:outline-none">
                    <div v-if="!frameShapes.square" class="w-8 ml-4">
                      <CheckboxBlankSvg />
                    </div>
                    <div v-if="frameShapes.square" class="w-8 ml-4">
                      <div class="text-primary2Text">
                        <CheckboxSelectedSvg />
                      </div>
                    </div>
                    <div  class="text-backgroundText w-32">
                      <FrameSquareSvg :class="{'text-primary2Text': frameShapes.square}" />
                    </div>
                  </button>
                  <button @click="selectedOption({key: 'round', status: !frameShapes.round})" :class="{activeButtons: frameShapes.round}" class="opticalButtons focus:outline-none ">
                    <div v-if="!frameShapes.round" class="w-8 ml-4">
                      <CheckboxBlankSvg />
                    </div>
                    <div v-if="frameShapes.round" class="w-8 ml-4">
                      <div class="text-primary2Text">
                        <CheckboxSelectedSvg />
                      </div>
                    </div>
                    <div  class="text-backgroundText w-32">
                      <FrameRoundSvg :class="{'text-primary2Text': frameShapes.round}" />
                    </div>
                  </button>
                  <button @click="selectedOption({key: 'aviator', status: !frameShapes.aviator})" :class="{activeButtons: frameShapes.aviator}" class="opticalButtons focus:outline-none">
                    <div v-if="!frameShapes.aviator" class="w-8 ml-4">
                      <CheckboxBlankSvg />
                    </div>
                    <div v-if="frameShapes.aviator" class="w-8 ml-4">
                      <div class="text-primary2Text">
                        <CheckboxSelectedSvg />
                      </div>
                    </div>
                    <div  class="text-backgroundText w-32">
                        <FrameAviatorSvg :class="{'text-primary2Text': frameShapes.aviator}" />
                    </div>
                  </button>
                  <button @click="selectedOption({key: 'oval', status: !frameShapes.oval})" :class="{activeButtons: frameShapes.oval}" class="opticalButtons focus:outline-none">
                    <div v-if="!frameShapes.oval" class="w-8 ml-4">
                      <CheckboxBlankSvg />
                    </div>
                    <div v-if="frameShapes.oval" class="w-8 ml-4">
                      <div class="text-primary2Text">
                        <CheckboxSelectedSvg />
                      </div>
                    </div>
                    <div  class="text-backgroundText w-32">
                      <FrameOvalSvg :class="{'text-primary2Text': frameShapes.oval}" />
                    </div>
                  </button>
                  <button @click="selectedOption({key: 'cateye', status: !frameShapes.cateye})" :class="{activeButtons: frameShapes.cateye}" class="opticalButtons focus:outline-none">
                      <div v-if="!frameShapes.cateye" class="w-8 ml-4">
                        <CheckboxBlankSvg />
                      </div>
                      <div v-if="frameShapes.cateye" class="w-8 ml-4">
                        <div class="text-primary2Text">
                          <CheckboxSelectedSvg />
                        </div>
                      </div>
                      <div  class="text-backgroundText w-32">
                        <FrameCateyeSvg :class="{'text-primary2Text': frameShapes.cateye}" />
                      </div>
                  </button>
                  <button @click="selectedOption({key: 'browline', status: !frameShapes.browline})" :class="{activeButtons: frameShapes.browline}" class="opticalButtons focus:outline-none">
                      <div v-if="!frameShapes.browline" class="w-8 ml-4">
                        <CheckboxBlankSvg />
                      </div>
                      <div v-if="frameShapes.browline" class="w-8 ml-4">
                        <div class="text-primary2Text">
                          <CheckboxSelectedSvg />
                        </div>
                      </div>
                      <div  class="text-backgroundText w-32">
                        <FrameBrowlineSvg :class="{'text-primary2Text': frameShapes.browline}" />
                      </div>
                  </button>
                  <button @click="selectedOption({key: 'geometric', status: !frameShapes.geometric})" :class="{activeButtons: frameShapes.geometric}" class="opticalButtons focus:outline-none">
                      <div v-if="!frameShapes.geometric" class="w-8 ml-4">
                        <CheckboxBlankSvg />
                      </div>
                      <div v-if="frameShapes.geometric" class="w-8 ml-4">
                        <div class="text-primary2Text">
                          <CheckboxSelectedSvg />
                        </div>
                      </div>
                      <div  class="text-backgroundText w-32">
                          <FrameGeometricSvg :class="{'text-primary2Text': frameShapes.geometric}" />
                      </div>
                  </button>
                  <button @click="selectedOption({key: 'wayfarer', status: !frameShapes.wayfarer})" :class="{activeButtons: frameShapes.wayfarer}" class="opticalButtons focus:outline-none">
                    <div v-if="!frameShapes.wayfarer" class="w-8 ml-4">
                      <CheckboxBlankSvg />
                    </div>
                    <div v-if="frameShapes.wayfarer" class="w-8 ml-4">
                      <div class="text-primary2Text">
                        <CheckboxSelectedSvg />
                      </div>
                    </div>
                    <div  class="text-backgroundText w-32">
                      <FrameWayfarerSvg :class="{'text-primary2Text': frameShapes.wayfarer}" />
                    </div>
                  </button>
              </div>
            </div>
          </div>
          <div class="opacity-0 w-full h-1/12 flex justify-end items-end mr-20 portrait:justify-center">
            <button class="underline h-12 w-48 text-xl mr-8 portrait:mb-0 portrait:mr-0 bg-primary3 rounded-lg rounded-tl-none text-primary3Text block focus:outline-none">
              Find Style
            </button>
          </div>
        </section>

        <FramesResults v-on:frames-details="displayFramesDetails" :frames="frameResults" v-if="searchQuery" />
        <!-- <FrameDetails v-if="frameAdded" v-on:remove-frame="removeFrame" :frame="currentFrame" /> -->
    </main>
  </div>
</template>

<script>
import Navigation from '../components/subcomponents/Navigation.vue';
import CheckoutBox from '../components/subcomponents/CheckoutBox.vue';
import FramesResults from '../components/frames/FramesResults.vue';
import FrameDetails from '../components/frames/FrameDetails';
import PlansButton from '../components/subcomponents/Buttons/PlansButton';
import FrameRectangleSvg from "../assets/svg/frames-shape/frameRectangle.svg";
import FrameSquareSvg from "../assets/svg/frames-shape/frameSquare.svg";
import FrameRoundSvg from "../assets/svg/frames-shape/frameRound.svg";
import FrameAviatorSvg from "../assets/svg/frames-shape/frameAviator.svg";
import FrameOvalSvg from "../assets/svg/frames-shape/frameOval.svg";
import FrameCateyeSvg from "../assets/svg/frames-shape/frameCateye.svg";
import FrameWayfarerSvg from "../assets/svg/frames-shape/frameWayfarer.svg";
import FrameGeometricSvg from "../assets/svg/frames-shape/frameGeometric.svg";
import FrameBrowlineSvg from "../assets/svg/frames-shape/frameBrowline.svg";
import CheckboxBlankSvg from "../assets/svg/icons/checkOutlineBlank.svg";
import CheckboxSelectedSvg from "../assets/svg/icons/checkOutlineSelected.svg";
import IconSearchSvg from "../assets/svg/icons/icon_Search.svg";
import CosmeticSvg from "../assets/svg/demo_icons/cosmetic.svg";
import {generateToast } from "../common/functions";
// import Frames from '../json/frames_db.json'

export default {
  name: 'NewFrames',
  data() {
    return {
      showSearchResult: false,
      showFramesDetails: false,
      // masterFrames: Frames,
      searchQuery: '',
      generateToast: generateToast,
      skipFrameItem: {
        name: 'Customer Supplied',
        size: '',
        color: '',
        price: 0,
        code: '',
        vendor: ''
      }
    };
  },
  methods: {
    selectedOption(option) {
      this.$store.dispatch('updateFrameShapes', option)
      if(option.status){
        this.$store.dispatch('sendEvent', {
          hitType: 'event',
          eventCategory: 'button',
          eventAction: 'Eyewear Shape Options Click',
          eventLabel: option.key.charAt(0).toUpperCase() + option.key. slice(1),
          dimension1: this.currentUser.id,
          dimension2: this.currentUser.group.id,
          dimension3: this.view
        })
      }
    },
    displayFramesDetails(frame){
      this.$store.dispatch('addFrameToCart', frame)
      this.$router.push('/lenspackage');
      // this.showFramesDetails = true;
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Added Frame ' + this.currentFrame.code,
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    },
    removeFrame(){
      this.$store.dispatch('removeFrameFromCart')
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Removed Frame '+ this.currentFrame.code,
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    },
    correctPrice(frame){
      // if (frame.Attributes == 'Ophthalmic Frame'){
      //   return frame.Cost*2.75
      // }
      // return frame.Cost*2
      return frame.price
    },
     skipFrames() {
       this.$store.dispatch('addFrameToCart', this.skipFrameItem)
      this.$router.push('/lenspackage');
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Skip Frame',
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
     }
  },
  components: {
    Navigation,
    CheckoutBox,
    FramesResults,
    PlansButton,
    FrameDetails,
    FrameRectangleSvg,
    FrameSquareSvg,
    FrameRoundSvg,
    FrameAviatorSvg,
    FrameOvalSvg,
    FrameCateyeSvg,
    FrameWayfarerSvg,
    FrameGeometricSvg,
    FrameBrowlineSvg,
    CheckboxBlankSvg,
    CheckboxSelectedSvg,
    IconSearchSvg,
    CosmeticSvg
  },
  computed: {
    frameResults() {
      if (this.searchQuery) {
        return this.masterFrames.filter(item => {
          return this.searchQuery.toLowerCase().split(" ").every(
            v =>
              item.code.toString().includes(v) ||
              item.name.toString().toLowerCase().includes(v) ||
              item.series.toLowerCase().includes(v)
          )
        }).slice(0, 30)
      }
      else {
        return this.masterFrames.slice(0, 30)
      }
    },
    frameShapes() {
      return this.$store.getters.getFrameShapes
    },
    showFrameDetails() {
      return this.$store.getters.getFrameDetailsToggle;
    },
    currentFrame() {
      return this.$store.getters.getCurrentFrame;
    },
    frameAdded() {
      if (Object.keys(this.currentFrame).length === 0 && this.currentFrame.constructor === Object) {
        return false
      }
      return true
    },
    currentUser() {
      return this.$store.getters.getUser
    },
    masterFrames() {
      return this.$store.getters.getFrames
    },
    currentMod() {
      const mod = this.$store.getters.getCurrentIndex
      if (mod == 'polarized'){
        return mod
      } else if (mod == 'computer'){
        return mod;
      }
      return "";
    },
    currentPatient(){
      return this.$store.getters.getCurrentPatient
    }
  },
  props: {
    view: String
  },
  created() {
    this.$store.dispatch('setView', this.view);
    if(this.currentFrame.code){
      this.searchQuery = this.currentFrame.code
    }

    if(/Android/.test(navigator.appVersion)) {
      window.addEventListener("resize", function() {
        if(document.activeElement.tagName=="INPUT" || document.activeElement.tagName=="TEXTAREA") {
          document.activeElement.scrollIntoView();
          document.getElementById('frames').style.height = "30rem";
        }else{
          document.getElementById('frames').style.removeProperty("height");
        }
      })
    }
  },
};
</script>

<style scoped>
  .inputStyle {
    @apply h-full w-full pl-4 border-primary1 border-2 border-b-0 rounded-md rounded-tl-none bg-background text-backgroundText;
  }
  .opticalButtons{
    @apply w-3/12 h-16 mr-5 shadow-shadowGray flex justify-start items-center bg-background border-toggleGray border-2 rounded-lg rounded-tl-none text-center;
  }
  .activeButtons {
    @apply bg-primary2 text-primary2Text border-2 rounded-lg rounded-tl-none text-center shadow-none;
  }
  @screen portrait {
    .opticalButtons{
      @apply w-3/12 mr-2;
    }
  }

</style>

