var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass:
        "h-10/12 bg-background text-backgroundText flex flex-col landscape:flex-row",
      attrs: { id: "auth" }
    },
    [
      _c(
        "section",
        {
          staticClass:
            "landscape:w-1/2 h-full flex flex-col justify-arounds portrait:items-center portrait:w-full"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-8/12 h-5/12 flex justify-center items-center ml-10 portrait:ml-0 portrait:h-4/12"
            },
            [
              _c("div", { staticClass: "h-10/12 w-11/12 relative" }, [
                !_vm.brandLogo
                  ? _c("img", {
                      staticClass:
                        "absolute left-0 top-0 h-full w-full object-contain",
                      attrs: {
                        src: require("../assets/img/contentlinqlogo.png"),
                        alt: "Advanced Eyecare Logo"
                      }
                    })
                  : _vm._e(),
                _vm.brandLogo
                  ? _c("img", {
                      staticClass:
                        "absolute left-0 top-0 h-full w-full object-contain",
                      attrs: { src: _vm.brandLogo, alt: "Practice Logo" }
                    })
                  : _vm._e()
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "h-7/12 w-full flex flex-col justify-between" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex w-full h-4/12 flex-col ml-10 portrait:items-center portrait:ml-0"
                },
                [
                  _vm._m(0),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.username,
                        expression: "username"
                      }
                    ],
                    staticClass:
                      "inputStyle focus:outline-none focus:shadow-outline w-8/12",
                    attrs: {
                      type: "email",
                      name: "email",
                      id: "email",
                      placeholder: "Enter your email"
                    },
                    domProps: { value: _vm.username },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.username = $event.target.value
                      }
                    }
                  })
                ]
              ),
              _c("div", { staticClass: "w-full h-8/12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex w-full flex-col ml-10 portrait:items-center portrait:ml-0"
                  },
                  [
                    _vm._m(1),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full flex portrait:justify-center portrait:-mr-14"
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password"
                            }
                          ],
                          staticClass:
                            "inputStyle focus:outline-none focus:shadow-outline w-8/12",
                          attrs: {
                            type: "password",
                            name: "password",
                            id: "password",
                            placeholder: "Enter your password"
                          },
                          domProps: { value: _vm.password },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.login()
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.password = $event.target.value
                            }
                          }
                        }),
                        !_vm.authInProgress
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "bg-primary3 h-10 w-12 rounded-md ml-3 text-primary3Text rounded-tl-none block focus:outline-none focus:shadow-outline",
                                on: { click: _vm.login }
                              },
                              [_vm._v("Go")]
                            )
                          : _vm._e(),
                        _vm.authInProgress
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "bg-primary3 h-10 w-12 rounded-md ml-3 rounded-tl-none flex justify-center relative overflow-hidden"
                              },
                              [
                                _c("img", {
                                  staticClass:
                                    "w-full h-full absolute top-0 right-0 object-cover",
                                  attrs: {
                                    src: require("../assets/img/loading.gif"),
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm.authError
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "mt-4 ml-10 portrait:w-8/12 portrait:ml-0"
                          },
                          [
                            _c("p", [
                              _vm._v("Email or password not recognized.")
                            ]),
                            _c("p", [_vm._v("Please try again.")])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "section",
        {
          staticClass:
            "landscape:w-1/2 portrait:h-1/2 flex justify-center items-center "
        },
        [
          _c("img", {
            staticClass:
              "object-cover landscape:object-left landscape:h-full landscape:border-none portrait:border-b-4 portrait:border-t-4 portrait:border-primary1  portrait:bottom-0 ",
            attrs: {
              src: "./img/optical_Female_sm.jpg",
              alt: "Woman face with Glasses"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-8/12 mb-2 rounded flex items-center justify-between" },
      [_c("label", { staticClass: "ml-2 font-semibold" }, [_vm._v("Email:")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-8/12 mb-2 rounded flex items-center justify-between" },
      [
        _c("label", { staticClass: "ml-2 font-semibold" }, [
          _vm._v("Password:")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }