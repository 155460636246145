<template>
    <section class="lined-bifocal h-full relative flex">
        <div class="">
          <CompareSlider
            leftImage="./img/demos/Bifocal_Progressive.jpg"
            rightImage="./img/demos/Bifocal.jpg"
          />
        </div>
        <label class="absolute left-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl">Progressive</label>
        <label class="absolute right-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl">Lined Bifocal</label>
    </section>
</template>

<script>
import CompareSlider from "./CompareSlider.vue";

export default {
  name: 'LinedBifocal',
  components: {
    CompareSlider
  },
  data() {
    return {
    };
  },
  props: {

  },
  computed: {

  },
};
</script>

<style scoped>

</style>
