import Config from '../../json/config.json'
import Vue from 'vue'
import VueRouter from 'vue-router'

function initialState(){
  return {
    selectedType: 'single',
    transitionsStatus: false,
    polarizedStatus: false,
    rxStrength: 1,
    config: Config[Config.target],
    baseUrl: process.env.VUE_APP_URL,
    lifestyleQuestions: {
      eyewear: false,
      contacts: false,
      digital: false,
      reading: false,
      sports: false,
      hiking: false,
      driving: false,
      gaming: false,
      safety: false,
      golfing: false,
    },
    lifestylePlan: {
      everyday: {
        description: "Improve your comfort and overall vision experience with a premium vision lens, complete with an anti-reflective coating.",
        status: false
      },
      polarized: {
        description: "Protect your eyes and reduce glare outdoors with our superior sun protection polarization lens.",
        status: false
      },
      
    },
    otherPlans: {
      computer: {
        description: "Rest your eyes from the strain of screens with blue light blocking glasses specific for your daily digital usage.",
        status: false
      },
    }
  }
}

const state = initialState

const mutations = {
  change_type(state, type){
    state.selectedType = type
  },
  toggle_transitions(state){
    state.transitionsStatus = !state.transitionsStatus
  },
  update_question(state, question){
    Vue.set(state.lifestyleQuestions, question.key, question.status)
    // state.lifestyleQuestions[question.key] = question.status
  },
  set_plan_status(state, planObject){
    state.lifestylePlan[planObject.key].status = planObject.status
  },
  change_rx_strength(state, strength){
    state.rxStrength = strength
  },
  add_plan(state, planKey){
    state.lifestylePlan[planKey] = state.otherPlans[planKey]
  },
  clear_lifestyle(state){
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = {
  changeType({commit}, type){
    commit('change_type', type)
  },
  toggleTransitions({commit, state}){
    commit('toggle_transitions')
    commit('update_cart_experience', {
      name: 'transitions',
      value: state.transitionsStatus
    }) //cart.js commit
  },
  setPlanStatus({commit}, planObject){
    commit('set_plan_status', planObject)
  },
  updateQuestion({commit}, question) {
    commit('update_question', question)
  },
  changeRXStrength({commit}, strength){
    commit('change_rx_strength', strength)
  },
  addPlan({commit}, planKey){
    commit('add_plan', planKey)
  }
}

const getters = {
  getCurrentType: state => state.selectedType,
  getTransitionStatus: state => state.transitionsStatus,
  getConfig: state => state.config,
  getBaseUrl: state => state.baseUrl,
  getLifestylePlan: state => state.lifestylePlan,
  getRXStrength: state=> state.rxStrength,
  getLifestyle: state => state.lifestyleQuestions,
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
