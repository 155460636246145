var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "demoButtonMenu h-11/12 w-9/12 lg:w-7/12 absolute top-0 right-0 bg-toggleGray flex flex-col z-50"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "demoButtonMenu flex items-center justify-end mx-4 h-2/12"
        },
        [
          _c(
            "button",
            {
              staticClass: "demoButtonMenu w-16 block focus:outline-none",
              on: {
                click: function($event) {
                  return _vm.$store.dispatch("toggleDemoButtons")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "demoButtonMenu w-full text-toggleGrayText" },
                [_c("LensIconSvg")],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "demoButtonMenu flex h-10/12 py-4",
          attrs: { id: "demoButtons" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "demoButtonMenu w-6/12 h-full flex flex-col justify-around items-center"
            },
            [
              _c(
                "button",
                {
                  staticClass: "demosBlock block focus:outline-none",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeDemo("lensMaterials")
                    }
                  }
                },
                [_vm._v("Lens Material")]
              ),
              _c(
                "button",
                {
                  staticClass: "demosBlock block focus:outline-none",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeDemo("linedBifocal")
                    }
                  }
                },
                [_vm._v("Lined Bifocal")]
              ),
              _c(
                "button",
                {
                  staticClass: "demosBlock block focus:outline-none",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeDemo("blueLight")
                    }
                  }
                },
                [_vm._v("Blue Light")]
              ),
              _c(
                "button",
                {
                  staticClass: "demosBlock block focus:outline-none",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeDemo("progressive")
                    }
                  }
                },
                [_vm._v("Progressives")]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "demoButtonMenu w-6/12 h-full flex flex-col justify-around items-center"
            },
            [
              _c(
                "button",
                {
                  staticClass: "demosBlock block focus:outline-none",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeDemo("lightReactive")
                    }
                  }
                },
                [_vm._v("Light Reactive")]
              ),
              _c(
                "button",
                {
                  staticClass: "demosBlock block focus:outline-none",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeDemo("polarized")
                    }
                  }
                },
                [_vm._v("Polarized")]
              ),
              _c(
                "button",
                {
                  staticClass: "demosBlock block focus:outline-none",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeDemo("antiReflective")
                    }
                  }
                },
                [_vm._v("Anti-Reflective")]
              ),
              _vm.showTransitionsBtn
                ? _c(
                    "button",
                    {
                      staticClass: "demosBlock block focus:outline-none",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.changeDemo("transitions")
                        }
                      }
                    },
                    [_vm._v("Transitions")]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }