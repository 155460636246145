<template>
  <section class="light-reactive h-full relative">
       <!-- <div v-if="currentSetting == 'outdoors'" class="relative bg-red-500" style="padding-left: 100%">
            <img class="absolute left-0 top-0 h-full w-full object-cover" src="../../../assets/img/demos/LightReactive_Outdoor_None.jpg" alt="">
            <transition
                enter-active-class="transition-all duration-1000 ease-in-out"
                leave-active-class="transition-all duration-1000 ease-in-out"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-class="opacity-0"
                leave-to-class="opacity-100"
            >
                <img class="absolute left-0 top-0 h-full w-full object-cover" :src="outdoorsSrc" :key="outdoorsSrc" alt="">
            </transition>
        </div> -->

    <div v-if="currentSetting == 'outdoors'" class="h-full w-full relative">
      <div class="relative h-full w-full pl-full">
        <img class="absolute left-0 top-0 h-full w-full object-cover" :src="`./img/demos/LightReactive_Outdoor_None.jpg`" alt="">
      </div>
      <transition
        enter-active-class="transition-all duration-3000 ease-in-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-0"
        leave-to-class="opacity-100"
      >
        <img class="absolute left-0 top-0 h-full w-full object-cover" :src="outdoorsSrc" :key="outdoorsSrc" alt="">
      </transition>
    </div>
    <div v-if="currentSetting == 'indoor'" class="h-full w-full relative">
      <div class="relative h-full w-full pl-full">
        <img class="absolute left-0 top-0 h-full w-full object-cover" :src="indoorSrc" alt="">
      </div>
      <transition
        enter-active-class="transition-all duration-3000 ease-in-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-0"
        leave-to-class="opacity-100"
      >
        <img class="absolute left-0 top-0 h-full w-full object-cover" :key="indoorSrc" :src="`./img/demos/LightReactive_Indoor_None.jpg`" alt="">
      </transition>
    </div>
    <div>
      <!--  v-closable="{exclude: [],handler: 'closeColorDropDown'}" -->
      <div class="absolute top-0 right-0 mt-6 mr-4 z-10">
        <button id="btn-color" @click="toggleColor" :class="[colorDropDown ? 'animation' : '']"  class="button focus:outline-none focus:border-none overflow-hidden">
          <label id="btn-color" :class="{display: colorDropDown}">colors</label>
          <demo-button v-if="colorDropDown" :changeDemo="changeColors" :currentOption="currentColor" :dropDown="colorDropDown" :title="'colors'" :changeArray="colors"/>
        </button>
      </div>

      <div class="absolute top-0 right-0 mt-32 mr-4">
        <button id="btn-setting" @click="changeSetting(currentSetting)" :class="[settingDropDown ? 'animation' : '' , colorDropDown ? 'mt-24': '']"   class="button focus:outline-none focus:border-none overflow-hidden">
          <label id="btn-setting" :class="{display: settingDropDown}">setting</label>
          <demo-button v-if="settingDropDown" :dropDown="settingDropDown" :currentOption="currentSetting" :changeDemo="changeSetting" :title="'setting'" :changeArray="setting" />
        </button>
      </div>
      <div  @click="updateTransition()" class="absolute bottom-0 right-0 mb-20 mr-4">
        <img class="w-16" src="../../../assets/img/demo_icon_replay.png" alt="">
      </div>
    </div>
  </section>
</template>

<script>
import DemoButton from '../Buttons/DemoButton.vue';

export default {
  name: 'LightReactive',
  data() {
    return {
      colorDropDown: false,
      settingDropDown: true,
      currentColor: 'gray',
      currentSetting: 'outdoors',
      outdoorsSrc: '',
      indoorSrc: '',
      publicPath: process.env.BASE_URL,
      renderComponent: true,
      colors: [
         {name:'gray', img:'ReactiveGraySvg'},
        {name:'brown',img:'ReactiveBrownSvg'}
        // {name:'gray', img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.61 71.64"><path d="M54.94 1.39h3c18.83 0 38.91 1.56 46 7.3 4.94 4 2.94 15.7 2.94 21.23 0 3.76-1.68 24.24-6.72 29.61S70 70.25 48.65 70.25c-11.66 0-28.43-.67-34.23-5.15C5 57.81 1.82 33.51 1.82 27.14 1.82 22.76-.84 12 7.07 6.76s31.41-5.37 47.87-5.37" fill="#585858" stroke="#fff" stroke-miterlimit="10" stroke-width="2.79" data-name="Layer 2"/></svg>'},
        // {name:'brown',img:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.61 71.64"><path d="M54.94 1.39h3c18.83 0 38.91 1.56 46 7.3 4.94 4 2.94 15.7 2.94 21.23 0 3.76-1.68 24.24-6.72 29.61S70 70.25 48.65 70.25c-11.66 0-28.43-.67-34.23-5.15C5 57.81 1.82 33.51 1.82 27.14 1.82 22.76-.84 12 7.07 6.76s31.41-5.37 47.87-5.37" fill="#4c392c" stroke="#fff" stroke-miterlimit="10" stroke-width="2.79" data-name="Layer 2"/></svg>'}
      ],
      setting: [
        {name:'indoor', img:'ReactiveIndoorSvg'},
        {name: 'outdoors', img: 'ReactiveOutdoorSvg'}
        // {name:'indoor', img:'<svg class="stroke-current fill-current text-primary3Text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.1 77.66"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42" data-name="Layer 2"><path d="M81.3 45.27c0-.26-1.32-1.23-2.83-2.5L49.24 18.3a2.54 2.54 0 00-3.41-.06c-6.87 5.4-32 25.27-32 26.83v1.81a9.11 9.11 0 000-1.81c-.05 0-.09 1.62-.09 3.59v24.7A3.6 3.6 0 0017.3 77h19.49a3.59 3.59 0 003.58-3.59V58A3.6 3.6 0 0144 54.4h7.18a3.6 3.6 0 013.55 3.6v15.36A3.59 3.59 0 0058.32 77H77.8a3.6 3.6 0 003.59-3.59V48.66c0-1.97-.09-3.13-.09-3.39z"/><path d="M93.86 41.11a2.08 2.08 0 00-.23-3l-11.27-9.34a5.23 5.23 0 01-1.66-3.53V4a2.15 2.15 0 00-2.15-2.15H68.31A2.16 2.16 0 0066.16 4v9.21c0 1.18-.74 1.53-1.65.78L49.23 1.28a2.76 2.76 0 00-3.36 0L1.47 38.12a2.08 2.08 0 00-.23 3l3.65 4.12a2.24 2.24 0 003.11.22L45.89 14a2.7 2.7 0 013.32 0l37.92 31.46a2.24 2.24 0 003.08-.23z"/></g></svg>'},
        // {name: 'outdoors', img: '<svg class="stroke-current fill-current text-primary3Text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105.26 84.72"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.261" data-name="Layer 2"><path d="M1 83.21a2.61 2.61 0 002.13.88H99a2.22 2.22 0 002.1-3.41c-.73-1.44-1.47-2.87-2.21-4.31Q92.6 64.1 86.33 51.83a3.3 3.3 0 00-.64-.92 2.21 2.21 0 00-3.38.37q-4.23 4.95-8.49 9.88c-1.28 1.48-2.55 3-3.86 4.48l-.88-1.42-23.4-38-4.38-7.09A2.28 2.28 0 0038.92 18a2.44 2.44 0 00-1.75 1.42q-7.08 12-14.18 24Q12.67 60.83 2.34 78.28c-.42.72-.87 1.43-1.34 2.14a2.62 2.62 0 000 2.79zM104 25c-1.49 3.63-3.87 5.59-7.55 6.18a22.69 22.69 0 01-3.69.13H68.89a9.46 9.46 0 01-6.14-2.08 9.18 9.18 0 01-3.58-7.72 9.2 9.2 0 013.88-7.4 9.09 9.09 0 015.74-1.83h.44a12.58 12.58 0 015.94-9.73 12.41 12.41 0 018-1.86 12.72 12.72 0 0111.36 11.56A9.54 9.54 0 01104 25z"/></g></svg>'}
      ],
    };
  },
  methods: {
    changeColors(color) {
      switch (color) {
        case 'gray':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Gray.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Gray.jpg';
        break;
        case 'brown':
          this.outdoorsSrc = './img/demos/LightReactive_Outdoor_Brown.jpg';
          this.indoorSrc = './img/demos/LightReactive_Indoor_Brown.jpg';
        break;
      }
      this.currentColor = color;
      this.colorDropDown = !this.colorDropDown;
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Demo Color ' + color,
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    },
    updateTransition(){
      var self = this
      if(this.outdoorsSrc != './img/demos/LightReactive_Outdoor_None.jpg'){
        this.outdoorsSrc = './img/demos/LightReactive_Outdoor_None.jpg'
        setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
      }
      if (this.indoorSrc == './img/demos/LightReactive_Indoor_Gray.jpg'){
        self.indoorSrc = './img/demos/LightReactive_Indoor_None.jpg'
        setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
      }
       if (this.indoorSrc == './img/demos/LightReactive_Indoor_Brown.jpg'){
        self.indoorSrc = './img/demos/LightReactive_Indoor_None.jpg'
        setTimeout(function(){ self.changeColors(self.currentColor) }, 10);
      }

    },

    toggleColor(){
       if(this.settingDropDown) {
        // this.settingDropDown = false
        this.colorDropDown = !this.colorDropDown
      }else if(this.colorDropDown == false){
         this.colorDropDown = !this.colorDropDown;
      }
    },

    changeSetting(option) {
      //  This conditional was making the dropdown menus act weird once we wanted them auto open on page load. Don't think it's needed but
      //  just in case something weird happens later I'm leaving it.

      // if(this.colorDropDown) {
      //   this.colorDropDown = false;
      //   this.settingDropDown = !this.settingDropDown;
      // }

      this.currentSetting = option;
      this.settingDropDown = !this.settingDropDown;
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Demo Setting ' + option,
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    },
    // closeColorDropDown() {
    //   this.colorDropDown = false
    // },
    // closeSettingDropDown() {
    //   this.settingDropDown = false
    // },
  },

  mounted() {
    this.changeColors(this.currentColor);
  },

  components: {
    DemoButton,
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser
    },
  },
  created: function() {
    let self = this;

    window.addEventListener('click', function(e){
      if ((e.target.id != 'btn-setting') && (e.target.id != 'btn-color')) {
        // console.log(e.target)
        self.settingDropDown = false;
        self.colorDropDown = false;
      }
    })
  },
};
</script>

<style scoped>
  .button {
    @apply bg-primary3 text-primary3Text w-20 h-20 rounded-full block scale-0 duration-300;
  }
  .animation {
    @apply scale-100 w-48 h-48 bg-primary3 text-primary3Text rounded-lg rounded-tl-none transform-none;
  }
  .display {
    @apply hidden;
  }
</style>
