var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "transitions-popup h-10/12 w-full flex justify-center items-center  fixed bottom-0 left-0 z-1000"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "h-8/12 w-11/12 xs:h-full xs:w-full portrait:flex-wrap flex items-center portrait:flex-col border-2 border-primary2 bg-background text-backgroundText portrait:w-10/12 portrait:h-8/12"
        },
        [
          _c(
            "section",
            {
              staticClass:
                "h-full w-6/12 border-2 border-primary2 flex justify-center items-center portrait:h-6/12"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "h-full w-11/12 md:w-10/12 flex flex-col items-center"
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text relative w-full text-left xs:text-xs my-3"
                    },
                    [
                      _vm._v(
                        " XTRActive is the only lens designed to darken while you're behind the wheel. "
                      )
                    ]
                  ),
                  _vm._l(_vm.XTRActiveColors, function(option) {
                    return _c(
                      "button",
                      {
                        key: option.id,
                        staticClass:
                          "transitionButton w-full h-8 sm:h-10 md:h-12 border-2 border-toggleGray rounded-lg rounded-tl-none flex justify-center items-center",
                        class: { activeBtn: _vm.currentOption == option.name },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeDemo(option.name)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative h-7/12 w-5/12 mr-4" },
                          [
                            _c("img", {
                              staticClass:
                                "absolute left-0 bottom-0 h-full w-full object-contain",
                              attrs: { src: option.img, alt: "", srcset: "" }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "relative h-7/12 w-7/12 flex items-center"
                          },
                          [_vm._v("gray")]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ]
          ),
          _c(
            "section",
            {
              staticClass:
                "h-full w-6/12 border-2 border-primary2 flex justify-center items-center portrait:h-6/12"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "h-full w-11/12 md:w-10/12 flex flex-col items-center"
                },
                [
                  _vm._m(1),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-2/12 text relative w-full text-left xs:text-xs my-3"
                    },
                    [_vm._v(" Our most popular style. ")]
                  ),
                  _vm._l(_vm.transitionColors, function(option) {
                    return _c(
                      "button",
                      {
                        key: option.id,
                        staticClass:
                          "transitionButton w-full h-8 my-1 sm:h-10 md:h-12 border-2 border-toggleGray rounded-lg rounded-tl-none flex justify-center items-center",
                        class: { activeBtn: _vm.currentOption == option.name },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeDemo(option.name)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative h-7/12 w-5/12 mr-4" },
                          [
                            _c("img", {
                              staticClass:
                                "absolute left-0 bottom-0 h-full w-full object-contain",
                              attrs: { src: option.img, alt: "", srcset: "" }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "relative h-7/12 w-7/12 flex items-center"
                          },
                          [_vm._v(_vm._s(option.name))]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ]
          ),
          _c(
            "section",
            {
              staticClass:
                "h-full w-6/12 border-2 border-primary2 flex justify-center items-center portrait:h-6/12"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "h-full w-11/12 md:w-10/12 flex flex-col items-center"
                },
                [
                  _vm._m(2),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-2/12 text relative w-full text-left xs:text-xs my-3"
                    },
                    [_vm._v(" Show your style with the newest colors. ")]
                  ),
                  _vm._l(_vm.styleColors, function(option) {
                    return _c(
                      "button",
                      {
                        key: option.id,
                        staticClass:
                          "transitionButton w-full h-8 my-1 sm:h-10 md:h-12 border-2 border-toggleGray rounded-lg rounded-tl-none flex justify-center items-center",
                        class: { activeBtn: _vm.currentOption == option.name },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeDemo(option.name)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative h-7/12 w-5/12 mr-4" },
                          [
                            _c("img", {
                              staticClass:
                                "absolute left-0 bottom-0 h-full w-full object-contain",
                              attrs: { src: option.img, alt: "", srcset: "" }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "relative h-7/12 w-7/12 flex items-center"
                          },
                          [_vm._v(_vm._s(option.name))]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ]
          ),
          _c(
            "section",
            {
              staticClass:
                "h-full w-6/12 border-2 border-primary2 flex justify-center items-center portrait:h-6/12"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "h-full w-11/12 md:w-10/12 flex flex-col items-center "
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative h-2/12 w-10/12 self-start text-left text-base leading-5 xs:leading-4 font-semibold pt-2"
                    },
                    [_vm._v(" TRANSITIONS MIRRORS ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-2/12 text relative w-full text-left xs:text-xs my-3"
                    },
                    [_vm._v(" Clear indoors, activates in sunlight! ")]
                  ),
                  _vm._l(_vm.mirrorColors, function(option) {
                    return _c(
                      "button",
                      {
                        key: option.id,
                        staticClass:
                          "transitionButton w-full h-8 my-1 sm:h-10 md:h-12 border-2 border-toggleGray rounded-lg rounded-tl-none flex justify-center items-center",
                        class: { activeBtn: _vm.currentOption == option.name },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeDemo(option.name)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative h-7/12 w-5/12 mr-4" },
                          [
                            _c("img", {
                              staticClass:
                                "absolute left-0 bottom-0 h-full w-full object-contain",
                              attrs: { src: option.img, alt: "", srcset: "" }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "relative h-7/12 w-7/12 flex items-center"
                          },
                          [_vm._v(_vm._s(option.name))]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "relative h-2/12 w-10/12 self-start " }, [
      _c("img", {
        staticClass: "absolute left-0 bottom-0 h-full w-full object-contain",
        attrs: {
          src: require("../../../assets/img/transitions/logo_XTRActive.png"),
          alt: "",
          srcset: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "relative h-2/12 w-11/12 self-start" }, [
      _c("img", {
        staticClass: "absolute left-0 bottom-0 h-full w-full object-contain",
        attrs: {
          src: require("../../../assets/img/transitions/transitions_Signature.png"),
          alt: "",
          srcset: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "relative h-2/12 w-6/12 self-start " }, [
      _c("img", {
        staticClass:
          "absolute left-0 bottom-0 h-full w-full object-contain pt-2",
        attrs: {
          src: require("../../../assets/img/transitions/logo_StyleColors.png"),
          alt: "",
          srcset: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }