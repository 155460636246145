var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "vision-plan bg-background text-backgroundText h-10/12" },
    [
      _c("Navigation"),
      _c(
        "section",
        {
          staticClass:
            "h-10/12 w-full flex flex-col relative portrait:flex-col portrait:h-11/12"
        },
        [
          _c(
            "div",
            {
              staticClass: "h-3/12",
              class: { "h-4/12 mt-10": !_vm.computerPlan }
            },
            [_c("DailyPlan")],
            1
          ),
          _c(
            "div",
            {
              staticClass: "h-3/12 mt-3",
              class: { "h-4/12 mt-10": !_vm.computerPlan }
            },
            [_c("SunPlan")],
            1
          ),
          _c(
            "div",
            {
              staticClass: "h-3/12  mt-3",
              class: { hidden: !_vm.computerPlan }
            },
            [_vm.computerPlan ? _c("WorkPlan") : _vm._e()],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }