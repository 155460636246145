var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "h-2/12 w-full flex items-center justify-center focus:outline-none",
      on: {
        click: function($event) {
          return _vm.closeApp()
        }
      }
    },
    [_c("div", { staticClass: "text-lightGrayText w-5" }, [_c("IconXSvg")], 1)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }