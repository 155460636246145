<template>
  <!-- eslint-disable -->
  <div class="demo h-10/12 bg-background text-backgroundText w-full absolute left-0 bg-white z-110 demo-landscape">
    <!-- <section v-if="currentDemo == 'lensMaterials'" class="h-2/12 flex items-center justify-between px-4">
      <button class="w-10 block focus:outline-none">
        <svg class="icon_ArrowBack fill-current text-primary1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.19 84.19"><g data-name="Layer 2"><path d="M42.09 84.19a42.1 42.1 0 1142.1-42.1 42.14 42.14 0 01-42.1 42.1z" fill="none"/><path d="M48.41 68.73a4 4 0 01-2.83-1.18L23 44.92a4 4 0 010-5.65l22.58-22.64a4 4 0 015.66 5.66l-19.81 19.8L51.24 61.9a4 4 0 01-2.83 6.83z"/></g></svg>
      </button>
      <button @click="$store.dispatch('toggleDemoButtons')" class="w-16 block focus:outline-none">
        <svg class="icon_LensDemo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 161.32 124.35"><defs><symbol id="a" data-name="New Symbol 1" viewBox="0 0 161.32 124.35"><path d="M67.6 42.9h3.23c20.6 0 42.56 1.7 50.29 8 5.4 4.39 3.22 17.17 3.22 23.23 0 4.1-1.84 26.51-7.35 32.38s-32.9 11.73-56.26 11.73c-12.76 0-31.1-.74-37.44-5.63C13 104.61 9.51 78 9.51 71.06c0-4.78-2.91-16.59 5.74-22.29S49.6 42.9 67.6 42.9m-.6-6.26c-24.16 0-48.56.53-58.36 6.85s-8.9 18-8.37 24.26c.1 1.1.19 2.14.19 2.81 0 5.37 3 36.83 16.49 47 6.14 4.63 19.71 6.79 42.7 6.79 23.77 0 55.62-5.6 63.34-13.64 8-8.32 9.1-36.68 9.1-37 0-1.14.09-2.66.19-4.28.47-7.76 1.12-18.4-5.32-23.51-7.77-6.18-26.79-9.32-56.51-9.32H67z" fill="#34688c"/><path fill="#34688c" stroke="#34688c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.261" d="M148.01 30.96l-3.3-13.11-3.3 13.11-12.68 2.88 12.68 2.87 3.3 13.11 3.3-13.11 12.68-2.87-12.68-2.88zM124.21 18.52l1.85-7.33 7.1-1.62-7.1-1.6-1.85-7.34-1.85 7.33-7.09 1.61 7.09 1.62 1.85 7.33z"/><path fill="#34688c" stroke="#34688c" stroke-miterlimit="10" stroke-width="3.362" d="M110.14 80.98L85.31 105.8M95.14 52.53L71.61 76.06M54.98 54.43L29.55 79.86"/></symbol></defs><use width="161.32" height="124.35" xlink:href="#a" data-name="Layer 2"/></svg>
      </button>
    </section> -->

    <section  class="bg-toggleGray text-toggleGrayText h-16 flex items-center justify-between px-2">
      <button class="w-10 block focus:outline-none">
        <!-- <svg class="icon_ArrowBack fill-current text-primary1 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.19 84.19"><g data-name="Layer 2"><path d="M42.09 84.19a42.1 42.1 0 1142.1-42.1 42.14 42.14 0 01-42.1 42.1z" fill="none"/><path d="M48.41 68.73a4 4 0 01-2.83-1.18L23 44.92a4 4 0 010-5.65l22.58-22.64a4 4 0 015.66 5.66l-19.81 19.8L51.24 61.9a4 4 0 01-2.83 6.83z"/></g></svg> -->
      </button>
      <div class="flex items-center justify-start mr-44">
      <DemoTipSvg class="w-10 h-10 mr-6" />
      <p v-if="currentDemo == 'lensMaterials'" >Use this guide to determine how your lenses fit in your frame.</p>
      <p v-if="currentDemo == 'lightReactive'" >Light reactive lenses respond to UV light and darken accordingly.</p>
      <p v-if="currentDemo == 'linedBifocal'" >Consider a progressive to avoid the harsh bifocal line.</p>
      <p v-if="currentDemo == 'polarized'" >Polarization provides superior sun protection while minimizing glare.</p>
      <p v-if="currentDemo == 'progressive'" >Premium progressive lenses provide optimal vision in all corridors.</p>
      <p v-if="currentDemo == 'antiReflective'">Anti-Reflective reduces glare and leaves your glasses easier to clean.</p>
      <p v-if="currentDemo == 'blueLight'">Blue Light filters help protect your eyes from overexposure to harmful blue light.</p>
      <p v-if="currentDemo == 'transitions'">Transitions® lenses respond to UV light and darken accordingly.</p>

      </div>
      <button class="w-48 h-12 bg-primary3 text-primary3Text focus:outline-none flex justify-center items-center rounded-lg rounded-tl-none text-center">
        <div @click.stop="$store.dispatch('toggleDemoButtons')" class="w-20 text-toggleGrayText flex flex-row">
          <span class="inline whitespace-no-wrap underline text-primary3Text">View All Demos</span>
        </div>
        <LensIconSvg @click.stop="$store.dispatch('toggleDemoButtons')" class="w-8 ml-10" />
        <!-- <svg class="icon_LensDemo fill-current stroke-current text-toggleGrayText" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 161.32 124.35"><defs><symbol id="a" data-name="New Symbol 1" viewBox="0 0 161.32 124.35"><path d="M67.6 42.9h3.23c20.6 0 42.56 1.7 50.29 8 5.4 4.39 3.22 17.17 3.22 23.23 0 4.1-1.84 26.51-7.35 32.38s-32.9 11.73-56.26 11.73c-12.76 0-31.1-.74-37.44-5.63C13 104.61 9.51 78 9.51 71.06c0-4.78-2.91-16.59 5.74-22.29S49.6 42.9 67.6 42.9m-.6-6.26c-24.16 0-48.56.53-58.36 6.85s-8.9 18-8.37 24.26c.1 1.1.19 2.14.19 2.81 0 5.37 3 36.83 16.49 47 6.14 4.63 19.71 6.79 42.7 6.79 23.77 0 55.62-5.6 63.34-13.64 8-8.32 9.1-36.68 9.1-37 0-1.14.09-2.66.19-4.28.47-7.76 1.12-18.4-5.32-23.51-7.77-6.18-26.79-9.32-56.51-9.32H67z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.261" d="M148.01 30.96l-3.3-13.11-3.3 13.11-12.68 2.88 12.68 2.87 3.3 13.11 3.3-13.11 12.68-2.87-12.68-2.88zM124.21 18.52l1.85-7.33 7.1-1.62-7.1-1.6-1.85-7.34-1.85 7.33-7.09 1.61 7.09 1.62 1.85 7.33z"/><path stroke-miterlimit="10" stroke-width="3.362" d="M110.14 80.98L85.31 105.8M95.14 52.53L71.61 76.06M54.98 54.43L29.55 79.86"/></symbol></defs><use width="161.32" height="124.35" xlink:href="#a" data-name="Layer 2"/></svg> -->
      </button>
      <!-- <button class="w-48 h-12 bg-primary3 text-primary3Text focus:outline-none flex justify-center items-center rounded-lg rounded-tl-none text-center">
        <div @click.stop="showBrandedTransitionsBtn  true" class="w-20 text-toggleGrayText flex flex-row">
          <span class="inline whitespace-no-wrap underline text-primary3Text">Show Branded Transitions Button</span>
        </div>       
      </button> -->
    </section>

    <LensMaterials :currentDemo="currentDemo" v-if="currentDemo == 'lensMaterials'" />
    <LightReactive v-if="currentDemo == 'lightReactive'" />
    <Polarized v-if="currentDemo == 'polarized'" />
    <Progressive v-if="currentDemo == 'progressive'" />
    <AntiReflective v-if="currentDemo == 'antiReflective'" />
    <LinedBifocal v-if="currentDemo == 'linedBifocal'" />
    <Transitions v-if="currentDemo == 'transitions'" />
    <BlueLight v-if="currentDemo == 'blueLight'"/>


    <transition
      enter-active-class="transition-all transform duration-700 ease-in-out"
      leave-active-class="transition-all transform duration-700 ease-in-out"
      enter-class="-translate-y-full"
      enter-to-class="translate-y-0"
      leave-class="translate-y-0"
      leave-to-class="-translate-y-full"
    >
      <DemoButtons :showDemoButtons.sync="showDemoButtons" :currentDemo="currentDemo" :changeDemo="changeDemo" v-if="showDemoButtons" />
    </transition>
  </div>
</template>

<script>
import BackButton from "./Buttons/BackButton";
import DemoButtons from './DemoButtons.vue';
import LensMaterials from './Demos/LensMaterials.vue';
import LightReactive from './Demos/LightReactive.vue';
import Polarized from './Demos/Polarized.vue';
import Progressive from './Demos/Progressive.vue';
import AntiReflective from './Demos/AntiReflective.vue';
import LinedBifocal from './Demos/LinedBifocal.vue';
import LensIconSvg from "../../assets/svg/demo_icons/icon_Demos.svg";
import DemoTipSvg from '../../assets/svg/demo_icons/demo_Tip.svg';
import Transitions from './Demos/Transitions.vue';
import BlueLight from './Demos/BlueLight.vue';

export default {
  name: 'demo',
  data() {
    return {
      rxDropDown: false,
      rxCurrentIndex: -2,
      currentDemo: 'progressive',
    };
  },
  methods: {
    changeDemo(type) {
      this.currentDemo = type;
      if(this.showDemoButtons){
        this.$store.dispatch('toggleDemoButtons');
      }
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Demo ' + type,
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    },
  },
  components: {
    DemoButtons,
    LensMaterials,
    LightReactive,
    Polarized,
    Progressive,
    AntiReflective,
    LinedBifocal,
    BackButton,
    LensIconSvg,
    DemoTipSvg,
    Transitions,
    BlueLight
  },
  computed: {
    showDemoButtons() {
      return this.$store.getters.getDemoButtonsToggle;
    },
    currentUser() {
      return this.$store.getters.getUser
    },
  },
  created (){
    const _view = this.$store.getters.getView
    const _demoName = this.$store.getters.getCurrentDemoName
    switch(_demoName){
      case 'transitions':
        this.changeDemo('lightReactive')
        break
      case 'polarized':
        this.changeDemo('polarized')
        break
      default:
        this.changeDemo('progressive')
    }
  },
};
</script>

<style scoped>

</style>
