<template>
  <!-- eslint-disable -->
  <header class="topNav h-1/12 w-full min-h-12 bg-primary1 text-primary1Text top-0 flex items-center justify-between px-4 portrait:w-full">
    <div v-if="currentView != 'Auth'" class="sideNav landscape:w-full flex items-center ">
      <button @click="openSidNav" class="sideNav block focus:outline-none">
        <div class="sideNav w-10" v-if="showSideNav">
          <HamburgerXSvg />
        </div>
        <div class="sideNav w-10" v-if="!showSideNav">
          <HamburgerSvg />
        </div>
      </button>
      <p class="leading-tight text-center pl-2 landscape:pl-6 landscape:text-xl">{{practiceName}}</p>
    </div>
    <!-- <a @click="resetButton" v-if="(currentView == 'LensType' || currentView == 'LifeStyle' || currentView == 'VisionPlan' || currentView == 'Checkout') && showDemo == false" class="text-white underline">Reset</a> -->
    <a @click="resetButton" v-if="currentView != 'Auth' && showDemo == false" class="underline">Reset</a>
    <div v-if="showDemo" @click="closeDemo" class="flex items-center">
      <span class="underline float-right inline whitespace-no-wrap mr-6 text-primary1Text">Exit Lens Demos</span>
        <button class="w-6 focus:outline-none ">
          <DemoExit class="w-8 text-primary1Text"/>
        </button>
      </div>

  </header>
</template>

<script>
import HamburgerSvg from "../../assets/svg/icons/icon_hamburger.svg";
import HamburgerXSvg from "../../assets/svg/icons/icon_hamburger_x.svg";
import IconXSvg from "../../assets/svg/icons/icon_x.svg";
import DemoExit from "../../assets/svg/demo_icons/demo_Exit.svg"

export default {
  name: 'TopNav',
  data() {
    return {

    };
  },
  methods: {
    openSidNav() {
      this.$store.dispatch('toggleSideNav');
      if(this.showSideNav) {
        this.$store.dispatch('sendEvent', {
          hitType: 'event',
          eventCategory: 'button',
          eventAction: 'click',
          eventLabel: 'Open Side Navigation',
          dimension1: this.currentUser.id,
          dimension2: this.currentUser.group.id,
          dimension3: this.view
        })
      }
    },
    resetButton() {
      this.$store.dispatch('clearState');
      this.$store.dispatch('getPreAppointment') //TODO: Potentially remove this appointment call as it's redundant
      this.$store.dispatch('generatePackages')
      .then(res => {
        this.$store.dispatch('setPreload', true)
      })
      .catch(err => {
        this.$store.dispatch('logout')
        .then(res => {
         this.$store.dispatch('setPreload', true)
        })
      })
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Reset',
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    },
    closeDemo() {
      this.$store.dispatch('toggleDemo');
      // this.$store.dispatch('toggleDemoButtons')
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Exit Demo',
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    }
  },
  components: {
    HamburgerSvg,
    HamburgerXSvg,
    IconXSvg,
    DemoExit
  },
  props: {
  },
  computed: {
    showSideNav() {
      return this.$store.getters.getSideNavToggle;
    },
    currentView() {
      return this.$store.getters.getView
    },
    currentUser() {
      return this.$store.getters.getUser
    },
    showDemo() {
      return this.$store.getters.getDemoToggle;
    },
    practiceName() {
      return this.$store.getters.getPracticeName
    }
  },

};
</script>

<style scoped>

</style>
