var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "polarized h-full relative flex" }, [
    _vm.currentColor == "gray"
      ? _c(
          "div",
          [
            !_vm.tintSelected
              ? _c("CompareSlider", {
                  attrs: {
                    leftImage: "./img/demos/Polarized_Gray.jpg",
                    rightImage: "./img/demos/Polarized_None.jpg"
                  }
                })
              : _vm._e(),
            _vm.tintSelected
              ? _c("CompareSlider", {
                  attrs: {
                    leftImage: "./img/demos/Polarized_Gray.jpg",
                    rightImage: "./img/demos/demo_TintGray.jpg"
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm.currentColor == "brown"
      ? _c(
          "div",
          [
            !_vm.tintSelected
              ? _c("CompareSlider", {
                  attrs: {
                    leftImage: "./img/demos/Polarized_Brown.jpg",
                    rightImage: "./img/demos/Polarized_None.jpg"
                  }
                })
              : _vm._e(),
            _vm.tintSelected
              ? _c("CompareSlider", {
                  attrs: {
                    leftImage: "./img/demos/Polarized_Brown.jpg",
                    rightImage: "./img/demos/demo_TintBrown.jpg"
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _c(
      "label",
      {
        staticClass:
          "absolute left-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl"
      },
      [_vm._v("Polarized")]
    ),
    _c("div", { staticClass: "absolute bottom-0 right-0 mb-20 mr-4 " }, [
      _c(
        "button",
        {
          staticClass:
            "w-16 h-16 rounded-full bg-blue-700 text-white flex justify-center items-center focus:outline-none focus:shadow-outline",
          on: {
            click: function($event) {
              _vm.tintSelected = !_vm.tintSelected
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tintSelected,
                  expression: "tintSelected"
                }
              ],
              staticClass: "text-center"
            },
            [_vm._v("Hide Tint")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.tintSelected,
                  expression: "!tintSelected"
                }
              ],
              staticClass: "w-14 text-center"
            },
            [_vm._v("See Tint")]
          )
        ]
      )
    ]),
    _c("div", [
      _c("div", { staticClass: "absolute top-0 right-0 mt-6 mr-4 z-10" }, [
        _c(
          "button",
          {
            staticClass:
              "btn-setting button focus:outline-none focus:bordernone overflow-hidden",
            class: [_vm.settingDropDown ? "animation" : ""],
            on: {
              click: function($event) {
                _vm.settingDropDown = !_vm.settingDropDown
              }
            }
          },
          [
            !_vm.settingDropDown
              ? _c(
                  "label",
                  {
                    staticClass: "btn-setting polColors",
                    attrs: { id: "btn-color" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v("colors")]
                )
              : _vm._e(),
            _vm.settingDropDown
              ? _c("demo-button", {
                  staticClass: "btn-setting",
                  attrs: {
                    changeDemo: _vm.changeColors,
                    currentOption: _vm.currentColor,
                    dropDown: _vm.settingDropDown,
                    title: "colors",
                    changeArray: _vm.colors
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }