<template>
  <footer class="bottomNav text-sm pl-4 landscape:pl-6">
    <div class="flex flex-col">
      <p>*Prices shown are before any insurance or discount.</p>
      <a @click="$store.dispatch('toggleAboutApp');" class="underline leading-tight">All Rights Reserved 2020</a>
    </div>
    <ContentlinqSvg />
  </footer>
</template>

<script>
import ContentlinqSvg from "../../assets/svg/icons/contentlinq.svg";
export default {
  name: 'BottomNav',

  components: {
    ContentlinqSvg,
  }
};
</script>

<style scoped>
.bottomNav {
  @apply w-full h-1/12 min-h-12 relative border-t-2 border-solid border-backgroundText
  bg-background text-backgroundText flex items-center justify-between z-110;
}
</style>
