<template>
  <!-- eslint-disable -->
    <main class="lens-material bg-white w-full h-full">
        <section class="h-10/12 relative flex justify-center">
          <div class="absolute top-0 right-0 mt-6 mr-4 z-10">
            <button id="btn-RX" @click="rxDropDown = !rxDropDown" :class="[rxDropDown ? 'animation' : '']"  class="button focus:outline-none focus:bordernone overflow-hidden">
              <label id="btn-RX" :class="{display: rxDropDown}">RX</label>
              <demo-button v-if="rxDropDown" :dropDown="rxDropDown" :currentOption="rxCurrentIndex" :changeDemo="changeRx" :title="'RX'" :changeArray="rxNumbers" :lensRxDropdown="'lensMaterial'" />
            </button>
          </div>
            <div class="w-full h-full mt-10 flex flex-col relative ">
              <img class="absolute top-0 w-9/12 right-0 mt-10 -mr-12" src="../../../assets/img/lens_materials/demo_LensMaterialFrames.png" alt="">
                <!-- <div class="flex w-10/12 h-2/12 justify-center items-center">
                    <div class="mr-8 w-40 text-center">High index 1.74</div>
                    <div>
                        <img class="lensImage" v-if="rxCurrentIndex == 8"
                        src="../../../assets/img/lens_materials/p8.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == 6"
                        src="../../../assets/img/lens_materials/p6.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == 4"
                        src="../../../assets/img/lens_materials/p4.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == 2"
                        src="../../../assets/img/lens_materials/p2.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == -2"
                        src="../../../assets/img/lens_materials/n2.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == -4"
                        src="../../../assets/img/lens_materials/n4.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == -6"
                        src="../../../assets/img/lens_materials/n6.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == -8"
                        src="../../../assets/img/lens_materials/n8.00_hi.png">
                    </div>
                </div> -->

                <div class="w-10/12 h-3/12 flex justify-center items-center z-10">
                    <div class="mr-10 w-40 text-center font-bold">High Index 1.67</div>
                    <div>
                        <img class="lensImage" v-if="rxCurrentIndex == 8"
                        src="../../../assets/img/lens_materials/p8.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == 6"
                        src="../../../assets/img/lens_materials/p6.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == 4"
                        src="../../../assets/img/lens_materials/p4.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == 2"
                        src="../../../assets/img/lens_materials/p2.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == -2"
                        src="../../../assets/img/lens_materials/n2.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == -4"
                        src="../../../assets/img/lens_materials/n4.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == -6"
                        src="../../../assets/img/lens_materials/n6.00_hi.png">
                        <img class="lensImage" v-if="rxCurrentIndex == -8"
                        src="../../../assets/img/lens_materials/n8.00_hi.png">
                    </div>
                </div>

                <div class="w-10/12 h-3/12 flex justify-center items-center">
                    <div class="mr-10 w-40 text-center font-bold">Polycarbonate or Trivex</div>
                    <div>
                      <img class="lensImage" v-if="rxCurrentIndex == 8"
                       src="../../../assets/img/lens_materials/p8.00_poly.png">
                      <img class="lensImage" v-if="rxCurrentIndex == 6"
                      src="../../../assets/img/lens_materials/p6.00_poly.png">
                      <img class="lensImage" v-if="rxCurrentIndex == 4"
                      src="../../../assets/img/lens_materials/p4.00_poly.png">
                      <img class="lensImage" v-if="rxCurrentIndex == 2"
                      src="../../../assets/img/lens_materials/p2.00_poly.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -2"
                      src="../../../assets/img/lens_materials/n2.00_poly.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -4"
                      src="../../../assets/img/lens_materials/n4.00_poly.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -6"
                      src="../../../assets/img/lens_materials/n6.00_poly.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -8"
                      src="../../../assets/img/lens_materials/n8.00_poly.png">
                    </div>
                </div>

                <div class="w-10/12 h-3/12 flex justify-center items-center">
                    <div class="mr-10 w-40 text-center font-bold">Basic Plastic</div>
                    <div>
                      <img class="lensImage" v-if="rxCurrentIndex == 8"
                      src="../../../assets/img/lens_materials/p8.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == 6"
                      src="../../../assets/img/lens_materials/p6.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == 4"
                      src="../../../assets/img/lens_materials/p4.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == 2"
                      src="../../../assets/img/lens_materials/p2.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -2"
                      src="../../../assets/img/lens_materials/n2.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -4"
                      src="../../../assets/img/lens_materials/n4.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -6"
                      src="../../../assets/img/lens_materials/n6.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -8"
                      src="../../../assets/img/lens_materials/n8.00_plastic.png">
                    </div>
                </div>

                 <!-- <div class="w-10/12 h-2/12 flex justify-center items-center">
                    <div class="mr-8 w-40 text-center">Glass</div>
                    <div>
                      <img class="lensImage" v-if="rxCurrentIndex == 8"
                      src="../../../assets/img/lens_materials/p8.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == 6"
                      src="../../../assets/img/lens_materials/p6.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == 4"
                      src="../../../assets/img/lens_materials/p4.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == 2"
                      src="../../../assets/img/lens_materials/p2.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -2"
                      src="../../../assets/img/lens_materials/n2.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -4"
                      src="../../../assets/img/lens_materials/n4.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -6"
                      src="../../../assets/img/lens_materials/n6.00_plastic.png">
                      <img class="lensImage" v-if="rxCurrentIndex == -8"
                      src="../../../assets/img/lens_materials/n8.00_plastic.png">
                    </div>
                </div> -->
            </div>
            <!-- <div class="w-3/12 flex flex-col justify-around items-center">
                <div class="w-full h-70 bg-lightGray flex flex-col justify-around px-8 rounded-bl-lg">
                    <p class="w-24 ml-2">Hold frames over lens silhouette</p>
                    <div class="relative h-32">
                        <label class="ml-2">RX Strength</label>
                        <div class="absolute bottom-10 mt-2">
                            <div class="h-10 w-11/12 border-lightGrayText border-solid border-2 rounded-lg rounded-tl-none flex">
                                <input class="h-full w-8/12 pl-2 rounded-bl-lg focus:outline-none" readonly="readonly" placeholder="+2.00" v-model="rxCurrentIndex" type="text">
                                <div @click="rxDropeDown = !rxDropeDown" class="w-4/12 bg-primary3 rounded-r-lg relative flex justify-center items-center">
                                    <svg class="fill-current text-primary w-10 absolute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.19 84.19"><g data-name="Layer 2"><path d="M42.09 84.19a42.1 42.1 0 1142.1-42.142.14 42.14 0 01-42.1 42.1z" fill="none"/><path d="M67.09 34.14L42.87 58.37 18.64 34.14h48.45z"/></g></svg>
                                </div>
                            </div>
                            <ul v-if="rxDropeDown" class="h-40 w-11/12 bg-white pl-2 border-lightGrayText border-solid border-2 border-t-0 overflow-auto">
                                <li @click="rxCurrentIndex = '+'+ 8 + '.00'; rxDropeDown = false" class="py-1">+8.00</li>
                                <li @click="rxCurrentIndex = '+'+ 6 + '.00'; rxDropeDown = false" class="py-1">+6.00</li>
                                <li @click="rxCurrentIndex = '+'+ 4 + '.00'; rxDropeDown = false" class="py-1">+4.00</li>
                                <li @click="rxCurrentIndex = '+'+ 2 + '.00'; rxDropeDown = false" class="py-1">+2.00</li>
                                <li @click="rxCurrentIndex = 0 + '.00'; rxDropeDown = false" class="py-1 pl-2">0.00</li>
                                <li @click="rxCurrentIndex = -2 + '.00'; rxDropeDown = false" class="py-1">-2.00</li>
                                <li @click="rxCurrentIndex = -4 + '.00'; rxDropeDown = false" class="py-1">-4.00</li>
                                <li @click="rxCurrentIndex = -6 + '.00'; rxDropeDown = false" class="py-1">-6.00</li>
                                <li @click="rxCurrentIndex = -8 + '.00'; rxDropeDown = false" class="py-1">-8.00</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
        </section>
    </main>
</template>

<script>
import DemoButton from '../Buttons/DemoButton.vue';
import Demo from '../Demo.vue';


export default {
  name: 'lensMaterials',
  data() {
    return {
      rxDropDown: true,
      lensRxDropdown: 'lensMaterial',
      rxCurrentIndex: '+2.00',
      rxNumbers: [
        // {name: '0.00'},
        {name: '+2.00'},
        {name: '-2.00'},
        {name: '+4.00'},
        {name: '-4.00'},
        {name: '+6.00'},
        {name: '-6.00'},
        {name: '+8.00'},
        {name: '-8.00'},
      ],
    };
  },
  props: {
    currentDemo: String
  },
  methods: {
    changeRx(option) {
      this.rxCurrentIndex = option;
      this.rxDropDown =!this.rxDropDown
      this.$store.dispatch('sendEvent', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Demo RX ' + option,
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view
      })
    },
    closeRxDropDown() {
      this.rxDropDown = false
    },

  },
  components: {
    DemoButton,
    Demo
  },
  mounted: function() {
    let self = this;

    if(this.currentDemo == 'lensMaterials'){
      window.addEventListener('click', function(e){
        if (e.target.id != 'btn-RX') {
          // console.log(e);
          self.rxDropDown = false;
        }
      })
    }
    this.rxDropDown = true;
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser
    },
  },
};
</script>

<style scoped>
  .lensImage {
    @apply w-76;
  }
  .button {
    @apply bg-primary3 text-primary3Text w-20 h-20 rounded-full block scale-0 duration-300;
  }
  .animation {
    @apply scale-100 w-48 h-80 bg-primary3 text-primary3Text rounded-lg rounded-tl-none transform-none;
  }
  .display {
    @apply hidden;
  }

</style>
