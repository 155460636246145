var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "frames-results h-10/12" }, [
    _c("div", { staticClass: "h-full" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "h-5/6 overflow-auto" },
        _vm._l(_vm.frames, function(frame) {
          return _c(
            "div",
            {
              key: frame.id,
              staticClass:
                "w-full landscape:pr-4 flex items-center pl-24 h-20 border-toggleGray border-b-2 portrait:pl-16 portrait:text-center",
              on: {
                click: function($event) {
                  return _vm.$emit("frames-details", frame)
                }
              }
            },
            [
              _c("div", { staticClass: "w-40 portrait:w-3/12" }, [
                _vm._v(_vm._s(frame.code))
              ]),
              _c("div", { staticClass: "w-40 portrait:w-2/12" }, [
                _vm._v(_vm._s(frame.name))
              ]),
              _c("div", { staticClass: "w-40" }, [_vm._v(_vm._s(frame.size))]),
              _c("div", { staticClass: "w-40" }, [_vm._v(_vm._s(frame.color))]),
              _c("div", { staticClass: "w-40" }, [
                _vm._v("$" + _vm._s(_vm.correctPrice(frame)))
              ]),
              _c("div", { staticClass: "relative w-2/12 h-full pl-full" }, [
                frame.images && frame.images[0].image
                  ? _c("img", {
                      staticClass:
                        "absolute left-0 top-0 h-full w-full object-contain portrait:object-contain",
                      attrs: { src: frame.images[0].image, alt: "" }
                    })
                  : _c("img", {
                      staticClass:
                        "absolute left-0 top-0 h-full w-full object-contain portrait:object-contain",
                      attrs: {
                        src: require("../../assets/img/frames/frame_ImagePlaceholder.png"),
                        alt: ""
                      }
                    })
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-full h-10 flex pl-24 mt-3 border-toggleGray border-b-2 landscape:pr-4 portrait:pl-16"
      },
      [
        _c("div", { staticClass: "w-40 portrait:w-3/12" }, [_vm._v("CODE")]),
        _c("div", { staticClass: "w-40 portrait:w-2/12" }, [_vm._v("NAME")]),
        _c("div", { staticClass: "w-40" }, [_vm._v("SIZE")]),
        _c("div", { staticClass: "w-40" }, [_vm._v("COLOR")]),
        _c("div", { staticClass: "w-40" }, [_vm._v("RETAIL")]),
        _c("div", { staticClass: "relative w-2/12 h-full pl-full" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }