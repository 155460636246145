import Vue from 'vue';
import VueTailwind from 'vue-tailwind';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/tailwind.css';
import Toasted from 'vue-toasted';
import './registerServiceWorker'

Vue.use(Toasted)
// import measureeyez from './plugins/measureyez/src/measureeyez'

Vue.use(VueTailwind);

// Vue.use(measureeyez);

Vue.config.productionTip = false;

// https://stackoverflow.com/questions/36170425/detect-click-outside-element
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});


// let handleOutsideClick

// Vue.directive('closable', {
//   bind (el, binding, vnode) {
//     handleOutsideClick = (e) => {
//       e.stopPropagation()
//       const { handler, exclude } = binding.value

//       let clickedOnExcludedEl = false
//       exclude.forEach(refName => {
//         if (!clickedOnExcludedEl) {
//           const excludedEl = vnode.context.$refs[refName]
//           clickedOnExcludedEl = excludedEl.contains(e.target)
//         }
//       })
//       if (!el.contains(e.target) && !clickedOnExcludedEl) {
//         vnode.context[handler]()
//       }
//     }
//     window.addEventListener('click', handleOutsideClick)
//     // window.addEventListener('click', handleOutsideClick)
//     // window.addEventListener('click', handleOutsideClick)
//     // window.addEventListener('click', handleOutsideClick)
//     // window.addEventListener('click', handleOutsideClick)
//     // window.addEventListener('click', handleOutsideClick)
//   },

//   // unbind () {
//   //   window.removeEventListener('click', handleOutsideClick)
//   //   window.removeEventListener('click', handleOutsideClick)
//   //   window.removeEventListener('click', handleOutsideClick)
//   //   window.removeEventListener('click', handleOutsideClick)
//   // }
// })


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
