<template>
    <section id="container" class="measurements-result h-full w-full bg-background text-backgroundText flex justify-center items-center absolute top-0 right-0 z-100 pb-20">
        <!-- <div class="h-10/12 w-11/12 relative border-4 border-primary1 mx-auto rounded-lg rounded-tl-none"> -->
        <div class="h-full w-11/12 relative mx-auto">
            <section class="h-24 w-full flex items-center justify-between">
                <button @click="$emit('back')" class="w-10 ml-8 block focus:outline-none">
                    <svg class="arrow_back fill-current text-textDark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.19 84.19"><g data-name="Layer 2"><path d="M42.09 84.19a42.1 42.1 0 1142.1-42.1 42.14 42.14 0 01-42.1 42.1z" fill="none"/><path d="M48.41 68.73a4 4 0 01-2.83-1.18L23 44.92a4 4 0 010-5.65l22.58-22.64a4 4 0 015.66 5.66l-19.81 19.8L51.24 61.9a4 4 0 01-2.83 6.83z"/></g></svg>
                </button>
                <p class="text-xl">Measureyes <span class="font-medium">BETA</span></p>
                <button class="w-5 mr-8 block focus:outline-none">
                    <svg @click="closeApp" class="icon_x fill-current stroke-current text-textDark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2"><path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"/><path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/></g></svg>
                </button>
            </section>
            <!-- <section class="h-8 w-10/12 bg-primary2 text-primary2Text rounded-md rounded-tl-none rounded-tr-none flex justify-between items-center mx-auto">
                <button @click="changeLensType('bifocal')" :class="{activeButton: lensType == 'bifocal'}" class="w-1/3 underline focus:outline-none">bifocal</button>
                <button @click="changeLensType('progressive')" :class="{activeButton: lensType == 'progressive'}" class="w-1/3 underline focus:outline-none">progressive</button>
                <button @click="changeLensType('single')" :class="{activeButton: lensType == 'single'}" class="w-1/3 underline focus:outline-none">single vision</button>
            </section> -->
             <section class="h-8 w-10/12 bg-primary2 text-primary2Text rounded-md rounded-tl-none rounded-tr-none flex justify-center items-center mx-auto">
                <button @click="changeLensType('single')" :class="{activeButton: lensType == 'single'}" class="w-1/3 underline focus:outline-none">single vision</button>
            </section>

            <section class="w-10/12 h-3/12 mx-auto mt-8 flex justify-between portrait:flex-col portrait:h-4/12 portrait:items-center">
                <div class="relative h-full w-4/12 pl-full rounded-lg overflow-hidden portrait:w-8/12">
                    <img class="absolute left-0 top-0 h-full w-full object-cover " :src="imageSource"  alt="">
                </div>
                <div class="w-7/12 h-full flex flex-col portrait:w-full portrait:mt-6">
                    <div class="w-full flex justify-between">
                        <div class="w-5/12 flex flex-col">
                            <label>issued:</label>
                            <div class="h-10 border-textDark border-2 rounded-md rounded-tl-none flex justify-center items-center mt-2">
                                <input class="h-11/12 w-11/12 outline-none bg-background text-backgroundText" type="date" value=""  v-model="startDate">
                            </div>
                        </div>

                        <div class="w-5/12 flex flex-col">
                            <label>expires:</label>
                            <div class="h-10 border-textDark border-2 rounded-md rounded-tl-none flex justify-center items-center mt-2">
                                <input class="h-11/12 w-11/12 outline-none bg-background text-backgroundText" type="date" value="" v-model="endDate">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="text-gray-500 text-sm italic leading-tight mt-4 portrait:flex portrait:items-center portrait:justify-center portrait:flex-col portrait:mt-10">
                        <p>For Bifocal need to add a point for "Lower Lash Line"</p>
                        <p>Trifocal has another measurment needed as well.</p>
                    </div> -->
                </div>
            </section>

            <section class="w-10/12 h-4/12 mx-auto flex flex-col portrait:h-4/12 portrait:mt-6">
                <div class="w-full h-full mx-auto flex portrait:mt-6 portrait:h-8/12">
                    <div class="h-full w-4/12">
                        <div class="w-full h-full flex items-center justify-center">
                            <button @click="$emit('back')" class="h-12 w-32 underline bg-primary3 text-primary3Text rounded-md rounded-tl-none focus:outline-none">edit points</button>
                        </div>
                    </div>
                    <div class="h-full w-1/12">
                        <div class="h-1/2 w-full flex justify-center items-center">OD:</div>
                        <div class="h-1/2 w-full flex justify-center items-center">OS:</div>
                        <div class="h-12 w-full flex justify-center items-center -ml-2">Notes:</div>
                    </div>
                    <div class="h-full w-2/12">
                        <div class="w-full h-1/2 flex flex-col">
                            <label class="-mt-4">PD</label>
                            <div class="h-10 w-full border-textDark border-2 rounded-md rounded-tl-none flex justify-center items-center mt-2">
                                <input class="h-11/12 w-11/12  outline-none text-center bg-background text-backgroundText" v-model="frame.PDR">
                            </div>
                        </div>

                        <div class="w-full h-1/2 flex justify-center items-center">
                            <div class="h-10 w-full border-textDark border-2 rounded-md rounded-tl-none flex justify-center items-center mt-2">
                                <input class="h-11/12 w-11/12 outline-none text-center bg-background text-backgroundText" v-model="frame.PDL">
                            </div>
                        </div>
                    </div>
                    <div class="h-full w-4">
                        <div class="h-1/2 w-full flex justify-center items-center"></div>
                        <div class="h-1/2 w-full flex justify-center items-center"></div>
                    </div>
                    <div class="h-full w-3/12">
                        <div class="w-full h-1/2 flex flex-col">
                            <label class="-mt-4">OC Height</label>
                            <div class="h-10 w-full border-textDark border-2 rounded-md rounded-tl-none flex justify-center items-center mt-2 relative">
                                <!-- <span class="h-full absolute left-0 top-0  ml-2 text-red-600 text-3xl flex justify-center items-center">-</span> -->
                                <input class="h-11/12 w-11/12 outline-none text-center bg-background text-backgroundText" v-model="frame.SHR">
                                <!-- <span class="h-full absolute right-0 top-0 mr-2 mb-2 text-red-600 text-3xl flex justify-center items-center">+</span> -->
                            </div>
                        </div>
                        <div class="w-full h-1/2 flex justify-center items-center">
                            <div class="h-10 w-full border-textDark border-2 rounded-md rounded-tl-none px-4 mt-2 flex justify-center items-center relative">
                                <!-- <span class="h-full absolute left-0 top-0  ml-2 text-red-600 text-3xl flex justify-center items-center">-</span> -->
                                <input class="h-11/12 w-11/12 outline-none text-center bg-background text-backgroundText" v-model="frame.SHL">
                                <!-- <span class="h-full absolute right-0 top-0 mr-2 mb-2 text-red-600 text-3xl flex justify-center items-center">+</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="h-full w-2/12 flex flex-col justify-center items-center">
                    <!-- @click="$emit('reset')" -->
                        <!-- <button>
                            <svg class="reset w-6 fill-current text-textDark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.6 65.37"><g data-name="Layer 2"><path d="M45.67 48c-1.56-.47-3.11-.94-4.68-1.34-2.37-.62-4.75-1.18-7.13-1.75a2.59 2.59 0 00-1 0 2.93 2.93 0 00-2.18 4 2.26 2.26 0 001.38 1.54l1.18.38 4.11 1.2a2 2 0 01.29.1A21.43 21.43 0 017.12 27.37 3.18 3.18 0 001 25.7a27.77 27.77 0 0038.84 32.36 2.87 2.87 0 010 .35c-.09.43-.19.86-.28 1.29-.17.81-.39 1.61-.48 2.42a2.67 2.67 0 001.61 2.89 2.74 2.74 0 003.86-1.44c.05-.12.1-.24.14-.36.42-1.45.88-2.89 1.27-4.36.66-2.5 1.29-5 1.91-7.53a2.51 2.51 0 00-.41-2.15A3.22 3.22 0 0045.67 48zM35.13 6.24a27.66 27.66 0 00-17.86 1.1 2 2 0 010-.31c.11-.42.24-.85.35-1.27.21-.8.47-1.58.61-2.39a2.67 2.67 0 00-1.45-3 2.75 2.75 0 00-3.94 1.22c-.05.12-.11.23-.15.35-.51 1.43-1 2.84-1.51 4.28-.8 2.47-1.56 4.94-2.32 7.42a2.56 2.56 0 00.28 2.17 3.22 3.22 0 001.72 1.32c1.52.56 3 1.11 4.59 1.61 2.34.74 4.68 1.43 7 2.13a2.54 2.54 0 00.95.09 2.93 2.93 0 002.4-3.83 2.3 2.3 0 00-1.3-1.62c-.38-.15-.76-.31-1.15-.45l-4-1.42a3.89 3.89 0 01-.38-.16A21.43 21.43 0 0148.48 38.7a3.18 3.18 0 006.13 1.68A27.83 27.83 0 0035.13 6.24z"/></g></svg>
                            <span class="text-textDark">reset</span>
                        </button> -->
                    </div>
                </div>
                <div class="h-10 w-full flex justify-end items-center mx-auto -ml-2">
                    <div class="h-10 w-7/12 border-textDark border-2 rounded-md rounded-tl-none mt-2 relative flex justify-center items-center">
                        <input class="h-11/12 w-11/12 outline-none flex justify-center items-center bg-background text-backgroundText" placeholder="enter notes here">
                    </div>
                </div>
            </section>

            <section class="w-10/12 h-3/12 mx-auto flex justify-between items-center portrait:h-2/12">
                <div class="w-4/12 flex flex-col justify-center items-center">
                    <!-- <div class="w-1/2">
                        <div>frame:</div>
                        <div>Flexon 600</div>
                        <div>679516656690</div>
                        <div>(033)GUNMETAL</div>
                    </div> -->
                    <div v-if="!(isEmpty(currentFrame)) && currentFrame.name != 'Customer Supplied'" class="portrait:h-full">
                        <div>frame:</div>
                        <div class="">{{currentFrame.name}}</div>
                        <div class="">{{currentFrame.color}}</div>
                        <div>{{currentFrame.code}}</div>
                    </div>
                </div>
                <!-- <div class="h-8/12 w-8/12 flex items-center justify-end -mr-10 portrait:mr-0 portrait:h-3/12">
                    <button class="underline h-12 w-56 pb-1 flex justify-center items-center text-xl portrait:mb-0 portrait:mr-0 bg-primary3 text-primary3Text rounded-lg rounded-tl-none text-white focus:outline-none">
                        Save and Continue
                    </button>
                </div> -->
                <!-- <div class="w-7/12 flex justify-between">
                    <div class="w-2/12 flex flex-col justify-center items-center">
                        <label>A</label>
                        <div class="h-10 w-full border-textDark border-2 rounded-md rounded-tl-none flex justify-center items-center mt-2">
                            <input class="h-11/12 w-11/12 focus:outline-none text-center bg-background text-backgroundText" v-model="frame.A">
                        </div>
                    </div>
                    <div class="w-2/12 flex flex-col justify-center items-center">
                        <label>B</label>
                        <div class="h-10 w-full border-textDark border-2 rounded-md rounded-tl-none flex justify-center items-center mt-2">
                            <input class="h-11/12 w-11/12 focus:outline-none text-center bg-background text-backgroundText" v-model="frame.B">
                        </div>
                    </div>
                    <div class="w-2/12 flex flex-col justify-center items-center">
                        <label>ED</label>
                        <div class="h-10 w-full border-textDark border-2 rounded-md rounded-tl-none flex justify-center items-center mt-2">
                            <input class="h-11/12 w-11/12 focus:outline-none text-center bg-background text-backgroundText" value="">
                        </div>
                    </div>
                    <div class="w-2/12 flex flex-col justify-center items-center">
                        <label>DBL</label>
                        <div class="h-10 w-full border-textDark border-2 rounded-md rounded-tl-none flex justify-center items-center mt-2">
                            <input class="h-11/12 w-11/12 focus:outline-none text-center bg-background text-backgroundText" v-model="frame.DBL">
                        </div>
                    </div>

                </div> -->
            </section>
        </div>
    </section>
</template>

<script>
import { isEmpty } from "../../common/functions";

export default {
  name: 'MeasurementsResult',
  data() {
    return {
        lensType: 'single',
        isEmpty: isEmpty,
        startDate: new Date().toISOString().slice(0,10),
        endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0,10),

    };
  },
  methods: {
    changeLensType(type){
      this.lensType = type
    },
     closeApp() {
      this.$store.dispatch('toggleMeasureEyez');
    },
    // resetButton() {
    //   this.framePixels = [];
    //   this.redrawCanvas(1, 0);
    //   this.frame.DBL = 0
    //   console.log("reset")
    // },
  },
  components: {

  },
  props: ['imageSource', 'frame', 'lensWidth', 'bridgeWidth'],

  computed: {
    currentFrame() {
        return this.$store.getters.getCurrentFrame;
    },
  },
  created(){
    if(/Android/.test(navigator.appVersion)) {
      window.addEventListener("resize", function() {
        if(document.activeElement.tagName=="INPUT" || document.activeElement.tagName=="TEXTAREA") {
          document.activeElement.scrollIntoView();
          document.getElementById('container').style.height = "40rem";
        }else{
          document.getElementById('container').style.removeProperty("height");
        }
      })
    }

  },
};
</script>

<style scoped>
    .activeButton {
       @apply w-48 h-10 block no-underline bg-primary3 text-primary3Text rounded-sm rounded-tl-none;
    }
</style>
