var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "progressive h-full relative flex" }, [
    _vm.currentSetting == "good"
      ? _c("div", [
          _c("img", {
            staticClass: "object-cover block w-full h-full",
            attrs: { src: "./img/demos/Progressive_Good.jpg", alt: "" }
          })
        ])
      : _vm._e(),
    _vm.currentSetting == "better"
      ? _c("div", [
          _c("img", {
            staticClass: "object-cover block w-full h-full",
            attrs: { src: "./img/demos/Progressive_Better.jpg", alt: "" }
          })
        ])
      : _vm._e(),
    _vm.currentSetting == "best"
      ? _c("div", [
          _c("img", {
            staticClass: "object-cover block w-full h-full",
            attrs: { src: "./img/demos/Progressive_Best.jpg", alt: "" }
          })
        ])
      : _vm._e(),
    _c("div", { staticClass: "absolute top-0 right-0 mt-6 mr-4 z-20" }, [
      _c(
        "button",
        {
          staticClass:
            "button focus:outline-none focus:bordernone overflow-hidden",
          class: [_vm.settingDropDown ? "animation" : ""],
          attrs: { id: "btn-setting" },
          on: {
            click: function($event) {
              _vm.settingDropDown = !_vm.settingDropDown
            }
          }
        },
        [
          _c(
            "label",
            {
              class: { display: _vm.settingDropDown },
              attrs: { id: "btn-setting" }
            },
            [_vm._v("quality")]
          ),
          _vm.settingDropDown
            ? _c("demo-button", {
                attrs: {
                  dropDown: _vm.settingDropDown,
                  currentOption: _vm.currentSetting,
                  changeDemo: _vm.changeSetting,
                  title: "quality",
                  changeArray: _vm.setting
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }