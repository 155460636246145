<template>
    <div class="work-plan h-full w-11/12 bg-primary3 text-primary3Text flex justify-between portrait:mb-6">
      <!-- <div class="flex">
          <div class="h-full w-1/12 pl-6 pt-2 text-xl">3</div>
            <div v-if="currentView != 'VisionPlan'" class="dailyPlan w-8 h-full flex justify-center items-center">
              <svg class="checkbox_blank fill-current text-primary2Text transform rotate-180 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.12 104.5"><path d="M2 5.67l37.55 46.58L2 98.83V5.67M0 0v104.5l3.56-4.41L41.11 53.5l1-1.25-1-1.26L3.56 4.41 0 0z" data-name="Layer 2"/></svg>
            </div>
          </div>
        <div class="h-full w-1/12 flex justify-center items-center text-xl ml-16 portrait:ml-0">Digital devices:</div> -->
        <div class="flex w-3/12">
          <div class="w-20 pl-3">
            <div v-if="currentView != 'VisionPlan'" @click="openWorkPlan"  class="dailyPlan w-8 h-full flex justify-center items-center">
              <svg class="checkbox_blank fill-current text-primary2Text transform rotate-180 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.12 104.5"><path d="M2 5.67l37.55 46.58L2 98.83V5.67M0 0v104.5l3.56-4.41L41.11 53.5l1-1.25-1-1.26L3.56 4.41 0 0z" data-name="Layer 2"/></svg>
            </div>
          </div>
          <div class="h-full w-1/12 flex items-center text-xl portrait:ml-0">Computer Pair:</div>
        </div>
        <div class="h-full w-5/12 flex justify-center items-center portrait:w-4/12">
            <span class="w-10/12 portrait:ml-4">Rest your eyes from the strain of screens with blue light blocking glasses specific for your daily digital usage.</span>
            <!-- <span class="w-10/12">Focus on your work with a designated office pair to enhance your vision and get you through the day to day.</span> -->

        </div>
        <!-- <div class="w-2/12 flex justify-center items-center">
            <div @click="computerSelected = !computerSelected" :class="{'border-primary1': computerSelected}" class="h-10 w-10 border-2 rounded-lg rounded-tl-none bg-primary1Text">
              <div v-if="!computerSelected"  class="flex justify-center items-center">
                <div class="w-8">
                  <CheckboxBlankSvg />
                </div>
              </div>
              <div v-if="computerSelected" class="flex justify-center items-center">
                <div class="text-primary1 w-8">
              <CheckboxSelectedSvg />
            </div>
              </div>
            </div>
            <label class="ml-6 pt-2">Blue Light</label>
        </div> -->
        <button @click="selectPlan('computer')" class="w-2/12 focus:outline-none relative portrait:w-2/12">
        <span class="-mr-10 text-primary3Text portrait:mr-0">Select</span>
        <div class="w-10 h-full bg-transparent flex justify-center items-center absolute -mr-6 right-0 bottom-0">
          <ArrowForwardSvg />
        </div>
    </button>
    </div>

</template>

<script>
import { generateToast } from "../../../common/functions";
import CheckboxBlankSvg from "../../../assets/svg/icons/checkOutlineBlank.svg";
import CheckboxSelectedSvg from "../../../assets/svg/icons/checkOutlineSelected.svg";
import ArrowForwardSvg from "../../../assets/svg/icons/arrow_forwardWork.svg";

export default {

  name: 'WorkPlan',
  data() {
    return {
      computerSelected: false,
      generateToast: generateToast
    };
  },
  props: {
    openWorkPlan: Function,
  },
  components: {
    CheckboxBlankSvg,
    CheckboxSelectedSvg,
    ArrowForwardSvg
  },
  methods: {
    selectPlan(index) {
      this.$store.dispatch("setCartIndex", index);
       if (!this.currentLens.base){
        this.$router.push('/frames')
        .catch(err => {});
      }
      else if (this.currentPair.checkedOut){
          this.$router.push('/checkout')
          .catch(err => {});
      }
      else if (this.currentView == 'LensPackage'){
          this.$store.dispatch('setReloadKey', Math.round(Math.random() * 1000))
      }
      else {
          this.$router.push('/lenspackage')
          .catch(err => {});
      }

      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Selected Work",
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });
    },
  },
  computed: {
     currentView() {
      return this.$store.getters.getView;
    },
    currentUser() {
      return this.$store.getters.getUser;
    },
    currentLens(){
      return this.$store.getters.getCurrentLens
    },
    currentPair(){
      return this.$store.getters.getCurrentPair
    },
  }
};
</script>

<style scoped>

</style>
