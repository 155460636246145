import router from '../../router/index'

const cartObject = {
  lensType: 'single',
  frame: {},
  lens: {},
  checkedOut: false,
  packageIndex: 0
}

function clone(obj) { //clone js object
  if (obj == null || typeof (obj) != 'object')
    return obj;

  var temp = new obj.constructor();
  for (var key in obj)
    temp[key] = clone(obj[key]);

  return temp;
}

function initialState() {
  return {
    cart: {
      cartItems:{
        everyday: clone(cartObject),
        polarized: clone(cartObject),
        computer: clone(cartObject),
      } ,
      totalPrice: 0.00,
    },
    currentIndex: 'everyday'
  }
}

const state = initialState

const mutations = {
  add_frame(state, frame){
    state.cart.cartItems[state.currentIndex].frame = frame
  },
  update_total_price(state){
    state.cart.totalPrice = 0
    for (const [key, item] of Object.entries(state.cart.cartItems)){
      if (item.frame.price){
        state.cart.totalPrice += item.frame.price
      }
      if (item.lens){
        for (const [key, lens] of Object.entries(item.lens)){
          if (lens.price){
            state.cart.totalPrice += lens.price
          }
          else if (Array.isArray(lens)){
            lens.forEach(add => {
              if (add.price){
                state.cart.totalPrice += add.price
              }
            })
          }
        }
        if (item.lens.price){
          state.cart.totalPrice += parseInt(item.lens.price)
        }
        
      }
      
    }
  },
  remove_frame(state){
    state.cart.cartItems[state.currentIndex].frame = {}
  },
  set_lens(state, pack){
    state.cart.cartItems[state.currentIndex].lens = pack
  },
  update_cart_experience(state, experience){
    state.cart.cartItems[state.currentIndex][experience.name] = experience.value
  },
  change_cart(state, index){
    state.currentIndex = index
  },
  update_item_experience(state, experience){
    state.cart.cartItems[experience.experience][experience.name] = experience.value
  },
  set_cart_index(state, index){
    state.currentIndex = index
  },
  set_checked_out(state, status){
    state.cart.cartItems[state.currentIndex].checkedOut = status
  },
  clear_lens(state){
    state.cart.cartItems[state.currentIndex].lens = {}
  },
  set_package_index(state, index){
    state.cart.cartItems[state.currentIndex].packageIndex = index
  },
  update_all_lens_types(state, type){
    for (let item in state.cart.cartItems){
      state.cart.cartItems[item].lensType = type
    }
  },
  update_current_lens_type(state, type){
    state.cart.cartItems[state.currentIndex].lensType = type
  },
  clear_cart(state){

    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
    // window.sessionStorage.clear();
    // location.reload();
  }
}

const actions = {
  addFrameToCart({commit}, frame){
    // if (frame.Attributes == 'Ophthalmic Frame'){
    //   frame.Cost= frame.Cost*2.75
    // }
    // else {
    //   frame.Cost = frame.Cost*2
    // }
    commit('add_frame', frame)
    commit('update_total_price')
  },
  removeFrameFromCart({commit}){
    commit('remove_frame')
    commit('update_total_price')
  },
  setLens({commit}, pack){
    commit('set_lens', pack)
    commit('update_total_price')
  },
  changeCartItem(index){
    commmit('change_cart', index)
  },
  updateItemExperience({commit}, experience){
    commit('update_item_experience', experience)
  },
  setCartIndex({commit}, index){
    commit('set_cart_index', index)
  },
  setCheckedOut({commit}, status){
    commit('set_checked_out', status)
  },
  clearLens({commit}){
    commit('clear_lens')
  },
  setPackageIndex({commit}, index){
    commit('set_package_index', index)
  },
  clearCart({commit}){
    router.push('/')
    commit('clear_cart')
  },
  updateAllLensTypes({commit}, type){
    commit('update_all_lens_types', type)
  },
  updateCurrentLensType({commit}, type){
    commit('update_current_lens_type', type)
  }
}

const getters = {
  getTotalPrice: state => state.cart.totalPrice,
  getCurrentFrame: state => state.cart.cartItems[state.currentIndex].frame,
  getCurrentLens: state => state.cart.cartItems[state.currentIndex].lens,
  getCurrentIndex: state => state.currentIndex,
  getEveryday: state => state.cart.cartItems.everyday,
  getPolarized: state => state.cart.cartItems.polarized,
  getCheckedOut: state => state.cart.checkedOut,
  getCurrentPair: state => state.cart.cartItems[state.currentIndex],
  getWholeCart: state => state.cart,
  getCurrentLensType: state => state.cart.cartItems[state.currentIndex].lensType,
  getCurrentPackageIndex: state => state.cart.cartItems[state.currentIndex].packageIndex
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
