var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "frames bg-background text-backgroundText h-10/12" },
    [
      _c("Navigation"),
      _c(
        "main",
        { staticClass: "h-10/12 w-full flex-col flex relative" },
        [
          _c("PlansButton"),
          _c(
            "div",
            {
              staticClass:
                "bg-white xl:relative w-full h-2/12 border-t-4 border-primary1"
            },
            [
              _vm.currentMod == ""
                ? _c(
                    "h1",
                    {
                      staticClass:
                        "h-12 flex ml-16 items-center font-semibold text-xl float-left"
                    },
                    [_vm._v("Your Daily Pair")]
                  )
                : _vm._e(),
              _vm.currentMod == "polarized"
                ? _c(
                    "h1",
                    {
                      staticClass:
                        "h-12 flex ml-16 items-center font-semibold text-xl float-left"
                    },
                    [_vm._v("Your Sun Pair")]
                  )
                : _vm._e(),
              _c(
                "section",
                {
                  staticClass:
                    "w-9/12 h-3/12 flex ml-24 items-center mt-4 pl-20 portrait:w-8/12 portrait:h-2/12"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-12 w-11/12 flex relative shadow-shadowGray rounded-md rounded-tl-none portrait:w-full"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchQuery,
                            expression: "searchQuery"
                          }
                        ],
                        staticClass:
                          "frameSearch inputStyle focus:outline-none focus:shadow-outline",
                        attrs: { type: "text", placeholder: "search frames" },
                        domProps: { value: _vm.searchQuery },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.searchQuery = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              _vm.showSearchResult = !_vm.showSearchResult
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-8 absolute top-0 right-0 mt-2 mr-2"
                            },
                            [_c("IconSearchSvg")],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          !_vm.searchQuery
            ? _c(
                "section",
                {
                  staticClass:
                    "h-7/12 flex flex-col mt-20 relative w-9/12 ml-auto portrait:ml-auto"
                },
                [
                  _c("p", [_vm._v("Choose your desired frame shape?")]),
                  _c("div", { staticClass: "flex mt-4" }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            _vm.selectedFrame1 = !_vm.selectedFrame1
                          }
                        }
                      },
                      [
                        !_vm.selectedFrame1
                          ? _c("div", [_c("Frame1aSvg")], 1)
                          : _vm._e(),
                        _vm.selectedFrame1
                          ? _c("div", [_c("Frame1bSvg")], 1)
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            _vm.selectedFrame2 = !_vm.selectedFrame2
                          }
                        }
                      },
                      [
                        !_vm.selectedFrame2
                          ? _c("div", [_c("Frame2aSvg")], 1)
                          : _vm._e(),
                        _vm.selectedFrame2
                          ? _c("div", [_c("Frame2bSvg")], 1)
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            _vm.selectedFrame3 = !_vm.selectedFrame3
                          }
                        }
                      },
                      [
                        !_vm.selectedFrame3
                          ? _c("div", [_c("Frame5aSvg")], 1)
                          : _vm._e(),
                        _vm.selectedFrame3
                          ? _c("div", [_c("Frame5bSvg")], 1)
                          : _vm._e()
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "flex mt-4" }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            _vm.selectedFrame4 = !_vm.selectedFrame4
                          }
                        }
                      },
                      [
                        !_vm.selectedFrame4
                          ? _c("div", [_c("Frame3aSvg")], 1)
                          : _vm._e(),
                        _vm.selectedFrame4
                          ? _c("div", [_c("Frame3bSvg")], 1)
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            _vm.selectedFrame5 = !_vm.selectedFrame5
                          }
                        }
                      },
                      [
                        !_vm.selectedFrame5
                          ? _c("div", [_c("Frame4aSvg")], 1)
                          : _vm._e(),
                        _vm.selectedFrame5
                          ? _c("div", [_c("Frame4bSvg")], 1)
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            _vm.selectedFrame6 = !_vm.selectedFrame6
                          }
                        }
                      },
                      [
                        !_vm.selectedFrame6
                          ? _c("div", [_c("Frame6aSvg")], 1)
                          : _vm._e(),
                        _vm.selectedFrame6
                          ? _c("div", [_c("Frame6bSvg")], 1)
                          : _vm._e()
                      ]
                    )
                  ]),
                  !_vm.searchQuery
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "underline h-10 absolute bottom-0 right-0 mr-4",
                          on: { click: _vm.skipFrames }
                        },
                        [_vm._v("skip frames")]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.searchQuery
            ? _c("FramesResults", {
                attrs: { frames: _vm.frameResults },
                on: { "frames-details": _vm.displayFramesDetails }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }