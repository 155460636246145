<template>
    <div class="office-fees h-full w-full flex justify-center items-center bg-backOpacity fixed top-0 z-1000">
        <div class="h-7/12 w-5/12 flex flex-col items-center border-4 border-backgroundText rounded-lg rounded-tl-none bg-background text-backgroundText portrait:w-10/12 portrait:h-8/12">
            <div class="w-full h-12 flex justify-end">
                <button @click="toggleOfficeFees" class="w-10 block focus:outline-none">
                    <svg class="icon_x fill-current stroke-current text-textDark w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2"><path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"/><path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/></g></svg>
                </button>
            </div>
            <main class="h-11/12 w-full flex flex-col items-center justify-around">
                <div class="w-full h-1/12 flex items-center justify-center">
                    <p class="text-xl font-bold">Office Fees Include</p>
                </div>
                <div class="w-full h-11/12 flex flex-col justify-around mt-4">
                    <div class="w-full h-4/12">
                        <div class="h-1/2 w-full flex items-center justify-center -ml-12">
                            <span class="w-10">
                                <svg class="icon_x fill-current stroke-current text-backgroundText w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.53 62.52">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="keyboard">
                                        <g>
                                            <g>
                                            <circle cx="18.35" cy="56.74" r="5.78"/>
                                            <path d="M49.21,51A5.78,5.78,0,1,0,55,56.74,5.77,5.77,0,0,0,49.21,51Z"/>
                                            <path d="M53.67,6.07H12.56C9.57.72,8.06.67,7,.67H0V6.2H6.11A29.64,29.64,0,0,1,8.19,9.64c.31.86.76,1.91,1.32,3.16,2,4.5,7,13.64,8.48,17.16-4.47,6.65-6.85,10.72-5.16,13.89.58,1.11,1.88,2.42,4.72,2.42h37V40.74L18,40.71a43.81,43.81,0,0,1,4.06-6.93,10.57,10.57,0,0,0,1.16.08H40.36c5,0,6.44.29,7.75-1.47S52.65,25.08,57.62,16,61.13,6.07,53.67,6.07Z"/>
                                            </g>
                                            <text transform="translate(24.13 28.95)" font-size="33.74" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700">x</text>
                                        </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <span>Exam <span class="font-bold mr-2">$20</span><span class="line-through">$168</span></span>
                        </div>

                        <div class="h-1/2 w-full flex items-center justify-center -ml-8">
                            <span class="w-10">
                                <svg class="icon_x fill-current stroke-current text-backgroundText w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.53 62.52">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="keyboard">
                                        <g>
                                            <g>
                                            <circle cx="18.35" cy="56.74" r="5.78"/>
                                            <path d="M49.21,51A5.78,5.78,0,1,0,55,56.74,5.77,5.77,0,0,0,49.21,51Z"/>
                                            <path d="M53.67,6.07H12.56C9.57.72,8.06.67,7,.67H0V6.2H6.11A29.64,29.64,0,0,1,8.19,9.64c.31.86.76,1.91,1.32,3.16,2,4.5,7,13.64,8.48,17.16-4.47,6.65-6.85,10.72-5.16,13.89.58,1.11,1.88,2.42,4.72,2.42h37V40.74L18,40.71a43.81,43.81,0,0,1,4.06-6.93,10.57,10.57,0,0,0,1.16.08H40.36c5,0,6.44.29,7.75-1.47S52.65,25.08,57.62,16,61.13,6.07,53.67,6.07Z"/>
                                            </g>
                                            <text transform="translate(24.13 28.95)" font-size="33.74" fill="#fff" font-family="Arial-BoldMT, Arial" font-weight="700">x</text>
                                        </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <span>Refraction <span class="font-bold mr-2">$20</span><span class="line-through">$168</span></span>
                        </div>
                    </div>
                    <div class="h-4/12 w-full flex flex-col justify-center items-center">
                        <p>This information is</p>
                        <p>included in your <span class="underline">Summary</span></p>
                    </div>
                    <div class="h-4/12 flex justify-center items-center">
                        <button class="underline h-10 w-24 text-xl pb-1 flex justify-center items-center bg-primary3 text-primary3Text rounded-lg rounded-tl-none focus:outline-none portrait:mb-0 portrait:mr-0">Ok</button>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
export default {
  name: 'OfficeFeesPopup',
  data() {
    return{

    }
  },
  props: {
    toggleOfficeFees: Function
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
