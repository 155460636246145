<template>
  <div class="insurance-popup h-full w-full bg-white flex justify-center items-center bg-backOpacity fixed top-0 z-1000 ">
    <div class="h-10/12 w-7/12 relative flex flex-col items-center border-4 border-backgroundText rounded-lg rounded-tl-none bg-background text-backgroundText portrait:w-10/12 portrait:h-8/12">
        <div class="w-full h-2/12 text-center flex flex-col items-center justify-center">
            <div class="absolute top-0 right-0 m-3">
                <button @click="toggleInsurancePopup" class="w-6 stroke-current focus:outline-none text-backgroundText">
                    <IconXSvg />
                </button>
            </div>
            <div v-if="currentPatient.user">
                <h1  class="text-xl font-bold">{{currentPatient.user.name}}'s</h1>
                <h1 class="text-xl font-bold"> Optician's Lab Summary</h1>
            </div>
            <h1 v-else class="text-xl font-bold"> Optician's Lab Summary</h1>
        </div>

        <main class="h-10/12 w-full flex flex-col items-center justify-between overflow-y-auto">
            <section v-if="wholeCart.cartItems.everyday.checkedOut" class="h-auto w-7/12 mt-6 border-t-2 border-backgroundText">
                <div  class="flex items-center justify-center">
                    <h1 class="h-12 flex items-center font-semibold text-lg mr-4">Daily Pair</h1>
                    <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold mr-2">${{calculatePairPrice(wholeCart.cartItems.everyday)}}</span>
                    <span v-if="discountPairPrice > 0">${{discountPairPrice}}</span>
                    <!-- <span v-if="selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'">${{calculatePairPrice(wholeCart.cartItems.everyday, true)}}</span> -->
                </div>
                <div class="flex items-center justify-center" v-if="wholeCart.cartItems.everyday.frame.name != 'Customer Supplied'">
                    <h2 class="flex items-center font-semibold">Frames:</h2>
                    <div class="flex ml-4">
                        <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold mr-2">${{wholeCart.cartItems.everyday.frame.price.toFixed(2)}}*</span>
                        <span v-if="discountPairPrice > 0">${{discountPairPrice}}</span>
                        <!-- <span v-if="selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'">${{calculateDiscount(wholeCart.cartItems.everyday.frame.price)}}</span> -->
                    </div>
                </div>
                <div class="flex items-center justify-center" v-if="wholeCart.cartItems.everyday.frame.name == 'Customer Supplied'">
                    <div class="flex items-center font-semibold mr-4">Frames: </div>
                    <div>{{wholeCart.cartItems.everyday.frame.name}}</div>
                </div>
                <div v-if="wholeCart.cartItems.everyday.frame.name != 'Customer Supplied'" class="relative h-18 w-full my-2">
                    <img v-if="wholeCart.cartItems.everyday.frame.images && wholeCart.cartItems.everyday.frame.images[0].image" class="absolute left-0 top-0 h-full w-full object-contain" :src="wholeCart.cartItems.everyday.frame.images[0].image" alt="">
                    <img v-else class="absolute left-0 top-0 h-full w-full object-contain" src="../../assets/img/frames/frame_ImagePlaceholder.png" alt="">
                </div>
                <div v-if="wholeCart.cartItems.everyday.frame.name != 'Customer Supplied'" class="flex flex-col items-center justify-center">
                    <div class="">{{wholeCart.cartItems.everyday.frame.name}}</div>
                    <div class="">{{wholeCart.cartItems.everyday.frame.color}}</div>
                    <div class=""><span class="mr-2">UPC:</span>{{wholeCart.cartItems.everyday.frame.code}}</div>
                    <div class=""><span class="mr-2">Size:</span>{{wholeCart.cartItems.everyday.frame.size}}</div>
                </div>
                <div class="w-full h-40 flex flex-col border-2 border-backgroundText rounded-lg rounded-tl-none p-3 mt-2 hidden">
                    <h1 class="font-semibold">Measureyes:</h1>
                    <div class="flex w-full">
                        <div class="w-1/2">
                            <div class="font-semibold">PD</div>
                            <div>OD:<span class="ml-1">29</span></div>
                            <div>OS:<span class="ml-1">29</span></div>
                        </div>
                        <div class="w-1/2">
                            <div class="font-semibold">OC Height</div>
                            <div>OD:<span class="ml-1">4.5</span></div>
                            <div>OS:<span class="ml-1">5</span></div>
                        </div>
                    </div>
                    <p>Notes:</p>
                </div>
                <div class="flex flex-col items-center justify-center mt-2">
                    <h1 class="font-semibold">Lenses:</h1>
                    <div class="flex w-80 items-center justify-center">
                        <span v-if="wholeCart.cartItems.everyday.lens.base.price != 0"  :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.everyday.lens.base.price}}</span>
                        <!-- question -->
                        <!-- <span v-if="currentPackage.base.level">{{currentPackage.base.level}}</span> -->
                        <!-- v-else -->
                        <span>{{wholeCart.cartItems.everyday.lens.base.lensType}}</span>
                    </div>
                     <span v-if="wholeCart.cartItems.everyday.lens.base.brandName" class="w-full flex items-center justify-center">
                        <!-- <span class="mr-2">${{wholeCart.cartItems.everyday.lens.base.price}}</span> -->
                        <span class="">{{wholeCart.cartItems.everyday.lens.base.brandDescText}}</span>
                    </span>
                    <div v-if="wholeCart.cartItems.everyday.lens.material.brandName || wholeCart.cartItems.everyday.lens.material.lensType" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.everyday.lens.material.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.everyday.lens.material.price}}</span>
                        <span v-if="!wholeCart.cartItems.everyday.lens.material.brandName" class="">{{wholeCart.cartItems.everyday.lens.material.lensType}}</span>
                        <span v-else class="">{{wholeCart.cartItems.everyday.lens.material.brandName + ' ' + wholeCart.cartItems.everyday.lens.material.lensType}}</span>
                    </div>
                    <div v-for="addon in wholeCart.cartItems.everyday.lens.addons" :key="addon.id" class="w-full flex items-center justify-center">
                        <span v-if="addon.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{addon.price}}</span>
                        <span class="">{{addon.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.everyday.lens.transition.brandDescText" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.everyday.lens.transition.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.everyday.lens.transition.price}}</span>
                        <span class="">{{wholeCart.cartItems.everyday.lens.transition.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.everyday.lens.transition.brandDescText && wholeCart.cartItems.everyday.lens.color.brandDescText" class="w-full flex items-center justify-center">
                        <!-- <span class="mr-2">{{currentPackage.color.price}}</span> -->
                        <span class="">{{wholeCart.cartItems.everyday.lens.color.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.everyday.lens.polarized.brandDescText" class="w-full flex items-center justify-center">
                        <!-- <span class="mr-2"></span> -->
                        <span class="">Polarized {{wholeCart.cartItems.everyday.lens.polarized.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.everyday.lens.mirror.price" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.everyday.lens.mirror.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.everyday.lens.mirror.price}}</span>
                        <span class="">Mirror Color {{wholeCart.cartItems.everyday.lens.mirror.name}}</span>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center mt-2 hidden">
                    <h1 class="font-semibold">Light responsive:</h1>
                    <div class="w-full flex items-center justify-center">
                        <span class="mr-2">$123</span>
                        <span class="">Transitions</span>
                    </div>
                    <div class="w-full flex items-center justify-center">
                        <span class="">XTRActive Gray</span>
                    </div>
                </div>
                <div v-if="wholeCart.cartItems.everyday.lens.enchancements.length > 0" class="flex flex-col items-center justify-center mt-2">
                    <h1 class="font-semibold">Treatments:</h1>
                    <div class="w-full flex flex-col items-center justify-center">
                        <div v-for="enchancement in wholeCart.cartItems.everyday.lens.enchancements" :key="enchancement.id">
                            <div class="flex">
                                <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{enchancement.price}}</span>
                                <span>{{enchancement.lensType}} <span v-if="enchancement.selectedColor">{{enchancement.selectedColor.brandDescText}}</span></span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="w-full flex items-center justify-center">
                        <span class="mr-2">$123</span>
                        <span class="">Mirror</span>
                    </div>
                    <div class="w-full flex items-center justify-center">
                        <span class="mr-2">$123</span>
                        <span class="">Roll Polish</span>
                    </div>
                    <div class="w-full flex items-center justify-center">
                        <span class="mr-2">$123</span>
                        <span class="">Drill Mount</span>
                    </div> -->
                </div>
            </section>

            <section v-if="wholeCart.cartItems.polarized.checkedOut" class="h-auto w-7/12 mt-6 border-t-2 border-backgroundText">
                <div  class="flex items-center justify-center">
                    <h1 class="h-12 flex items-center font-semibold text-lg mr-4">Sun Pair</h1>
                    <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold mr-2">${{calculatePairPrice(wholeCart.cartItems.polarized)}}</span>
                    <span v-if="discountPairPrice > 0">${{discountPairPrice}}</span>
                    <!-- <span v-if="selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'">${{calculatePairPrice(wholeCart.cartItems.polarized, true)}}</span> -->
                </div>
                <div class="flex items-center justify-center" v-if="wholeCart.cartItems.polarized.frame.name != 'Customer Supplied'">
                    <h2 class="flex items-center font-semibold">Frames:</h2>
                    <div class="flex ml-4">
                        <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold mr-2">${{wholeCart.cartItems.polarized.frame.price.toFixed(2)}}*</span>
                        <span v-if="discountPairPrice > 0">${{discountPairPrice}}</span>
                        <!-- <span v-if="selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'">${{calculateDiscount(wholeCart.cartItems.polarized.frame.price)}}</span> -->
                    </div>
                </div>
                <div class="flex items-center justify-center" v-if="wholeCart.cartItems.polarized.frame.name == 'Customer Supplied'">
                    <div class="flex items-center font-semibold mr-4">Frames: </div>
                    <div>{{wholeCart.cartItems.polarized.frame.name}}</div>
                </div>
                <div v-if="wholeCart.cartItems.polarized.frame.name != 'Customer Supplied'" class="relative h-18 w-full my-2">
                    <img v-if="wholeCart.cartItems.polarized.frame.images && wholeCart.cartItems.polarized.frame.images[0].image" class="absolute left-0 top-0 h-full w-full object-contain" :src="wholeCart.cartItems.polarized.frame.images[0].image" alt="">
                    <img v-else class="absolute left-0 top-0 h-full w-full object-contain" src="../../assets/img/frames/frame_ImagePlaceholder.png" alt="">
                </div>
                <div v-if="wholeCart.cartItems.polarized.frame.name != 'Customer Supplied'" class="flex flex-col items-center justify-center">
                    <div class="">{{wholeCart.cartItems.polarized.frame.name}}</div>
                    <div class="">{{wholeCart.cartItems.polarized.frame.color}}</div>
                    <div class=""><span class="mr-2">UPC:</span>{{wholeCart.cartItems.polarized.frame.code}}</div>
                    <div class=""><span class="mr-2">Size:</span>{{wholeCart.cartItems.polarized.frame.size}}</div>
                </div>
                <div class="w-full h-40 flex flex-col border-2 border-backgroundText rounded-lg rounded-tl-none p-3 mt-2 hidden">
                    <h1 class="font-semibold">Measureyes:</h1>
                    <div class="flex w-full">
                        <div class="w-1/2">
                            <div class="font-semibold">PD</div>
                            <div>OD:<span class="ml-1">29</span></div>
                            <div>OS:<span class="ml-1">29</span></div>
                        </div>
                        <div class="w-1/2">
                            <div class="font-semibold">OC Height</div>
                            <div>OD:<span class="ml-1">4.5</span></div>
                            <div>OS:<span class="ml-1">5</span></div>
                        </div>
                    </div>
                    <p>Notes:</p>
                </div>
                <div class="flex flex-col items-center justify-center mt-2">
                    <h1 class="font-semibold">Lenses:</h1>
                    <span class="flex w-80 items-center justify-center">
                        <span v-if="wholeCart.cartItems.polarized.lens.base.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.polarized.lens.base.price}}</span>
                        <!-- <span v-if="currentPackage.base.level">{{currentPackage.base.level}}</span> -->
                        <span>{{wholeCart.cartItems.polarized.lens.base.lensType}}</span>
                    </span>
                    <span v-if="wholeCart.cartItems.polarized.lens.base.brandName" class="w-full flex items-center justify-center">
                        <!-- <span class="mr-2">${{wholeCart.cartItems.polarized.lens.base.price}}</span> -->
                        <span class="">{{wholeCart.cartItems.polarized.lens.base.brandDescText}}</span>
                    </span>

                    <div v-if="wholeCart.cartItems.polarized.lens.material.brandName ||wholeCart.cartItems.polarized.lens.material.lensType" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.polarized.lens.material.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.polarized.lens.material.price}}</span>
                        <span v-if="!wholeCart.cartItems.polarized.lens.material.brandName" class="">{{wholeCart.cartItems.polarized.lens.material.lensType}}</span>
                        <span v-else class="">{{wholeCart.cartItems.polarized.lens.material.brandName + ' ' + wholeCart.cartItems.polarized.lens.material.lensType}}</span>
                    </div>
                    <div v-for="addon in wholeCart.cartItems.polarized.lens.addons" :key="addon.id" class="w-full flex items-center justify-center">
                        <span v-if="addon.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{addon.price}}</span>
                        <span class="">{{addon.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.polarized.lens.transition.brandDescText" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.polarized.lens.transition.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.polarized.lens.transition.price}}</span>
                        <span class="">{{wholeCart.cartItems.polarized.lens.transition.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.polarized.lens.color.brandDescText" class="w-full flex items-center justify-center">
                        <!-- <span class="mr-2">{{wholeCart.cartItems.polarized.lens.color.price}}</span> -->
                        <span class="">Polarized {{wholeCart.cartItems.polarized.lens.color.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.polarized.lens.polarized.brandDescText" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.polarized.lens.polarized.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.polarized.lens.polarized.price}}</span>
                        <span class="">Polarized {{wholeCart.cartItems.polarized.lens.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.polarized.lens.mirror.price" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.polarized.lens.mirror.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.polarized.lens.mirror.price}}</span>
                        <span class="">Mirror Color {{wholeCart.cartItems.polarized.lens.mirror.name}}</span>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center mt-2 hidden">
                    <h1 class="font-semibold">Light responsive:</h1>
                    <div class="w-full flex items-center justify-center">
                        <span class="mr-2">$123</span>
                        <span class="">Transitions</span>
                    </div>
                    <div class="w-full flex items-center justify-center">
                        <span class="">XTRActive Gray</span>
                    </div>
                </div>
                <div v-if="wholeCart.cartItems.polarized.lens.enchancements.length > 0" class="flex flex-col items-center justify-center mt-2">
                    <h1 class="font-semibold">Treatments:</h1>
                    <div class="w-full flex flex-col items-center justify-center">
                        <div v-for="enchancement in wholeCart.cartItems.polarized.lens.enchancements" :key="enchancement.id">
                            <div class="flex">
                            <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{enchancement.price}}</span>
                            <span>{{enchancement.lensType}} <span v-if="enchancement.selectedColor">{{enchancement.selectedColor.brandDescText}}</span></span>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section v-if="wholeCart.cartItems.computer.checkedOut" class="h-auto w-7/12 mt-6 border-t-2 border-backgroundText">
                <div  class="flex items-center justify-center">
                    <h1 class="h-12 flex items-center font-semibold text-lg mr-4">Computer Pair</h1>
                    <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}"  class="font-semibold mr-2">${{calculatePairPrice(wholeCart.cartItems.computer)}}</span>
                    <span v-if="discountPairPrice > 0">${{discountPairPrice}}</span>
                    <!-- <span v-if="selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'">${{calculatePairPrice(wholeCart.cartItems.computer, true)}}</span> -->
                </div>
                <div class="flex items-center justify-center" v-if="wholeCart.cartItems.computer.frame.name != 'Customer Supplied'">
                    <h2 class="flex items-center font-semibold">Frames:</h2>
                    <div class="flex ml-4">
                        <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold mr-2">${{wholeCart.cartItems.computer.frame.price.toFixed(2)}}*</span>
                        <span v-if="discountPairPrice > 0">${{discountPairPrice}}</span>
                        <!-- <span v-if="selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'">${{calculateDiscount(wholeCart.cartItems.computer.frame.price)}}</span> -->
                    </div>
                </div>
                <div class="flex items-center justify-center" v-if="wholeCart.cartItems.computer.frame.name == 'Customer Supplied'">
                    <div class="flex items-center font-semibold mr-4">Frames: </div>
                    <div>{{wholeCart.cartItems.computer.frame.name}}</div>
                </div>
                <div v-if="wholeCart.cartItems.computer.frame.name != 'Customer Supplied'" class="relative h-18 w-full my-2">
                    <img v-if="wholeCart.cartItems.computer.frame.images && wholeCart.cartItems.computer.frame.images[0].image" class="absolute left-0 top-0 h-full w-full object-contain" :src="wholeCart.cartItems.computer.frame.images[0].image" alt="">
                    <img v-else class="absolute left-0 top-0 h-full w-full object-contain" src="../../assets/img/frames/frame_ImagePlaceholder.png" alt="">
                </div>
                <div v-if="wholeCart.cartItems.computer.frame.name != 'Customer Supplied'" class="flex flex-col items-center justify-center">
                    <div class="">{{wholeCart.cartItems.computer.frame.name}}</div>
                    <div class="">{{wholeCart.cartItems.computer.frame.color}}</div>
                    <div class=""><span class="mr-2">UPC:</span>{{wholeCart.cartItems.computer.frame.code}}</div>
                    <div class=""><span class="mr-2">Size:</span>{{wholeCart.cartItems.computer.frame.size}}</div>
                </div>
                <div class="w-full h-40 flex flex-col border-2 border-backgroundText rounded-lg rounded-tl-none p-3 mt-2 hidden">
                    <h1 class="font-semibold">Measureyes:</h1>
                    <div class="flex w-full">
                        <div class="w-1/2">
                            <div class="font-semibold">PD</div>
                            <div>OD:<span class="ml-1">29</span></div>
                            <div>OS:<span class="ml-1">29</span></div>
                        </div>
                        <div class="w-1/2">
                            <div class="font-semibold">OC Height</div>
                            <div>OD:<span class="ml-1">4.5</span></div>
                            <div>OS:<span class="ml-1">5</span></div>
                        </div>
                    </div>
                    <p>Notes:</p>
                </div>
                <div class="flex flex-col items-center justify-center mt-2">
                    <h1 class="font-semibold">Lenses:</h1>
                    <div class="flex w-80 items-center justify-center">
                        <span v-if="wholeCart.cartItems.computer.lens.base.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.computer.lens.base.price}}</span>
                        <!-- question -->
                        <!-- <span v-if="currentPackage.base.level">{{currentPackage.base.level}}</span> -->
                        <!-- v-else -->
                        <span>{{wholeCart.cartItems.computer.lens.base.lensType}}</span>
                    </div>
                     <span v-if="wholeCart.cartItems.computer.lens.base.brandName" class="w-full flex items-center justify-center">
                        <!-- <span class="mr-2">${{wholeCart.cartItems.computer.lens.base.price}}</span> -->
                        <span class="">{{wholeCart.cartItems.computer.lens.base.brandDescText}}</span>
                    </span>
                    <div v-if="wholeCart.cartItems.computer.lens.material.brandName || wholeCart.cartItems.computer.lens.material.lensType" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.computer.lens.material.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.computer.lens.material.price}}</span>
                        <span v-if="!wholeCart.cartItems.computer.lens.material.brandName" class="">{{wholeCart.cartItems.computer.lens.material.lensType}}</span>
                        <span v-else  class="">{{wholeCart.cartItems.computer.lens.material.brandName + ' ' + wholeCart.cartItems.computer.lens.material.lensType}}</span>
                    </div>
                    <div v-for="addon in wholeCart.cartItems.computer.lens.addons" :key="addon.id" class="w-full flex items-center justify-center">
                        <span v-if="addon.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{addon.price}}</span>
                        <span class="">{{addon.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.computer.lens.transition.brandDescText" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.computer.lens.transition.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.computer.lens.transition.price}}</span>
                        <span class="">{{wholeCart.cartItems.computer.lens.transition.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.computer.lens.color.brandDescText" class="w-full flex items-center justify-center">
                        <!-- <span class="mr-2">{{currentPackage.color.price}}</span> -->
                        <span class="">Polarized {{wholeCart.cartItems.computer.lens.color.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.computer.lens.polarized.brandDescText" class="w-full flex items-center justify-center">
                        <!-- <span class="mr-2"></span> -->
                        <span class="">Polarized {{wholeCart.cartItems.computer.lens.polarized.brandDescText}}</span>
                    </div>
                    <div v-if="wholeCart.cartItems.computer.lens.mirror.price" class="w-full flex items-center justify-center">
                        <span v-if="wholeCart.cartItems.computer.lens.mirror.price != 0" :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="mr-2">${{wholeCart.cartItems.computer.lens.mirror.price}}</span>
                        <span class="">Mirror Color {{wholeCart.cartItems.computer.lens.mirror.name}}</span>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center mt-2 hidden">
                    <h1 class="font-semibold">Light responsive:</h1>
                    <div class="w-full flex items-center justify-center">
                        <span class="mr-2">$123</span>
                        <span class="">Transitions</span>
                    </div>
                    <div class="w-full flex items-center justify-center">
                        <span class="">XTRActive Gray</span>
                    </div>
                </div>
                <div v-if="wholeCart.cartItems.computer.lens.enchancements.length > 0" class="flex flex-col items-center justify-center mt-2">
                    <h1 class="font-semibold">Treatments:</h1>
                    <div class="w-full flex flex-col items-center justify-center">
                        <div v-for="enchancement in wholeCart.cartItems.computer.lens.enchancements" :key="enchancement.id">
                            <div class="flex">
                            <span class="mr-2">${{enchancement.price}}</span>
                            <span>{{enchancement.lensType}} <span v-if="enchancement.selectedColor">{{enchancement.selectedColor.brandDescText}}</span></span>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section class="h-auto w-6/12 mt-6 border-t-2 border-backgroundText border-2">
                <div class="flex flex-col items-center justify-center mt-2">
                    <h2 class="flex items-center font-semibold text-lg">Total in Checkout:</h2>
                    <div class="flex">
                        <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold text-lg mx-2">${{totalPrice}}*</span>
                        <span class="text-lg" v-if="discountTotal > 0">${{discountTotal}}</span>
                    </div>
                </div>
            </section>

            <section :class="{'hidden':(wholeCart.cartItems.everyday.checkedOut) && (wholeCart.cartItems.polarized.checkedOut) && (wholeCart.cartItems.computer.checkedOut)}" class="h-auto w-5/12 border-2 border-backgroundText rounded-xlg rounded-tl-none p-4 mt-2">
                <h2 class="flex items-center font-semibold text-lg">Optical Treatment Plan Reminder:</h2>
                <div v-if="!wholeCart.cartItems.everyday.checkedOut" class="flex flex-col justify-center pt-4">
                    <h2 class="flex items-center font-semibold text-lg pb-2">Daily Pair:</h2>
                    <p class="leading-5">Improve your comfort and overall vision experience with a premium vision lens, complete with an anti-reflective coating.</p>
                </div>
                <div v-if="!wholeCart.cartItems.polarized.checkedOut" class="flex flex-col justify-center pt-4">
                    <h2 class="flex items-center font-semibold text-lg pb-2">Sun Pair:</h2>
                    <p class="leading-5">Polarization offers superior sun protection for your eyes and reduces glare outdoors.</p>
                </div>
                <div v-if="!wholeCart.cartItems.computer.checkedOut" class="flex flex-col justify-center pt-4">
                    <h2 class="flex items-center font-semibold text-lg pb-2">Computer Pair:</h2>
                    <p class="leading-5">Rest your eyes from the strain of screens with blue light blocking glasses specific for your daily digital usage</p>
                </div>
            </section>
            <section class="flex justify-center items-center my-4">
                <p>* inherent property listed for insurance</p>
            </section>
        </main>

    </div>
  </div>
</template>

<script>
import { capitalize, correctPrice } from "../../common/functions";
import IconXSvg from "../../assets/svg/icons/icon_x.svg";

export default {
  name: 'InsurancePopup',
    data() {
        return {
            capt: capitalize,
            correctPrice: correctPrice,
        }
    },
    props: {
        toggleInsurancePopup: Function,
        currentInsurancePlan: String,
        selfpayDiscount: Number
    },
    methods: {
        calculatePairPrice(pair, discount){
            let price = 0
            if (pair.frame.price){
                price += pair.frame.price
            }
            if (pair.lens){
                for (const [key, lens] of Object.entries(pair.lens)){
                    if (lens.price){
                        price += lens.price
                    }
                    else if(Array.isArray(lens)){
                        lens.forEach(add => {
                            if (add.price){
                                price += add.price
                            }
                        })
                    }
                }
                if (pair.lens.price){
                    price += parseInt(pair.lens.price)
                }
            }
            if (discount){
                const discountPercent = this.$store.getters.getSelfpayDiscount
                const discountPrice = price * (1-(discountPercent*0.01))
                return discountPrice.toFixed(2)
            }
            return price.toFixed(2)
        },
        calculateDiscount(price){
            const discountPercent = this.$store.getters.getSelfpayDiscount
            const discountPrice = price * (1-(discountPercent*0.01))
            return discountPrice.toFixed(2)
        }
    },
    computed: {
        wholeCart() {
            return this.$store.getters.getWholeCart
        },
        totalPrice() {
            return this.$store.getters.getTotalPrice
        },
        discountTotal() {
            const discount = this.$store.getters.getSelfpayDiscount
            const insurance = this.$store.getters.getCurrentInsurance
            let discountPrice = 0
            if (insurance == 'selfpay' && discount > 0){
                discountPrice = this.totalPrice * (1- (discount*0.01))
            }
            return discountPrice.toFixed(2)

        },
        currentFrame() {
            return this.$store.getters.getCurrentFrame;
        },
        currentView() {
            return this.$store.getters.getView
        },
        currentPackage() {
            return this.$store.getters.getCurrentLens;
        },
        currentType() {
            return this.$store.getters.getCurrentLensType;
        },
        currentUser() {
            return this.$store.getters.getUser
        },
        currentIndex() {
            return this.$store.getters.getCurrentIndex
        },
        everydaySelected() {
            return this.$store.getters.getEveryday
        },
        polarizedSelected() {
            return this.$store.getters.getPolarized
        },
        currentPatient(){
            return this.$store.getters.getCurrentPatient
        },
        currentLensPrice(){
            let currentPrice = parseInt(this.currentPackage.price) + this.currentPackage.base.price + this.currentPackage.material.price
            const discount = this.$store.getters.getSelfpayDiscount
            const insurance = this.$store.getters.getCurrentInsurance
            this.currentPackage.addons.forEach(add => {
                currentPrice += add.price
            })
            if (this.currentPackage.transition.brandDescText){
                currentPrice += this.currentPackage.transition.price
            }
            if (this.currentPackage.mirror.price){
                currentPrice += this.currentPackage.mirror.price
            }

            return currentPrice.toFixed(2)

        },
        discountLensPrice() {
            const discount = this.$store.getters.getSelfpayDiscount
            const insurance = this.$store.getters.getCurrentInsurance
            let discountPrice = 0
            if (insurance == 'selfpay' && discount > 0){
                discountPrice = this.currentLensPrice * (1- (discount*0.01))
            }
            return discountPrice.toFixed(2)
        },
        currentFramePrice(){
           return this.currentFrame.price.toFixed(2)
        },
        discountFramePrice(){
            const discount = this.$store.getters.getSelfpayDiscount
            const insurance = this.$store.getters.getCurrentInsurance
            let discountPrice = 0
            if (insurance == 'selfpay' && discount > 0){
                discountPrice = this.currentFramePrice * (1- (discount*0.01))
            }
            return discountPrice.toFixed(2)
        },
        currentPairPrice(){
            const pairPrice = parseFloat(this.currentFramePrice) + parseFloat(this.currentLensPrice)
            return pairPrice.toFixed(2)
        },
        discountPairPrice() {
            const disPrice = parseFloat(this.discountLensPrice) + parseFloat(this.discountFramePrice)
            return disPrice.toFixed(2)
        }

    },
    components: {
        IconXSvg
    },


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>