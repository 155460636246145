<template>
  <!-- <div class="absolute top-0 h-full w-full bg-background text-backOpacityText flex justify-center items-center z-1000">
    <template v-if="!hasFrameSize">
      <div class="w-full h-full flex portrait:flex-col">
        <div class="w-2/12 h-full bg-lightGray text-lightGrayText flex flex-col items-center justify-between portrait:h-2/12 portrait:w-full portrait:flex-row">
          <button @click="closeApp"  class="h-2/12 w-full flex items-center justify-center focus:outline-none portrait:w-2/12 portrait:h-4/12">
            <svg class="arrow_back fill-current text-lightGrayText w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.19 84.19">
              <g data-name="Layer 2">
                <path d="M42.09 84.19a42.1 42.1 0 1142.1-42.1 42.14 42.14 0 01-42.1 42.1z" fill="none" />
                <path d="M48.41 68.73a4 4 0 01-2.83-1.18L23 44.92a4 4 0 010-5.65l22.58-22.64a4 4 0 015.66 5.66l-19.81 19.8L51.24 61.9a4 4 0 01-2.83 6.83z" />
              </g>
            </svg>
          </button>

          <button @click="closeApp" class="landscape:hidden h-2/12 w-full flex items-center justify-center focus:outline-none portrait:w-2/12 portrait:h-4/12">
            <svg class=" icon_x fill-current stroke-current text-lightGrayText w-5 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8">
              <g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2">
                <path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z" />
                <path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/>
              </g>
            </svg>
          </button>
        </div>
        <section class="relative mx-auto h-full w-8/12 flex flex-col justify-center items-center z-1000 portrait:h-8/12 portrait:w-full">
          <div class="overlay h-full w-full absolute top-0 right-0 border-b-260 text-backOpacityText border-t-100 border-backOpacity flex justify-center items-center">
            <section class="h-full w-full  mx-auto my-4  flex bg-backOpacity">
              <div class="w-full h-full relative bg-white border-2 border-backOpacity rounded-lg rounded-tl-none">
                <img class="absolute left-0 top-0 h-full w-full object-contain px-2" src="../../assets/measure_Frame1.png">
              </div>
            </section>
          </div>

          <div class="absolute top-0 right-0 h-full w-full flex flex-col justify-between">
            <div class="w-full flex justify-between items-center px-10">
              <div class="w-3/12 h-full flex flex-col justify-center items-center">
                <label>A. Lens Width</label>
                <div class="h-10 w-full border-toggleGray border-2 flex justify-center items-center rounded-lg rounded-tl-none px-4 mt-1 bg-background text-backgroundText">
                  <input class="h-11/12 w-full outline-none text-center" @keypress.enter="proceed()" type="number"  v-model="lensWidth">
                </div>
              </div>
              <div class="w-3/12 h-full  flex flex-col justify-center items-center">
                <label>B. Bridge Width</label>
                <div class="h-10 w-full border-toggleGray flex justify-center items-center border-2 rounded-lg rounded-tl-none px-4 mt-1 bg-background text-backgroundText">
                  <input class="h-11/12 w-full outline-none text-center" @keypress.enter="proceed()" type="number" v-model="bridgeWidth">
                </div>
              </div>
              <div class=" w-3/12 h-full flex flex-col justify-center items-center">
                <ProceedButton class="disable-dbl-tap-zoom mt-8" v-on:proceed="proceed()" />
              </div>
            </div>
          </div>

          <section class="h-3/12 w-full absolute bottom-0 mb-10 flex flex-col items-center">
          <div class="w-full h-full flex flex-col justify-center items-center">
              <div class="w-3/12 h-full flex flex-col justify-center items-center">
                <label>DBL. Temple Length</label>
                <div class="h-12 w-full border-toggleGray flex justify-center items-center border-2 rounded-lg rounded-tl-none px-4 mt-2 bg-white">
                  <input class="h-11/12 w-full outline-none text-center" @keypress.enter="proceed()" type="number" v-model="templeLength">
                </div>
              </div>
              <a @click="showLensMeasurementsPopup = !showLensMeasurementsPopup">Where do I find this information?</a>
            </div>
          </section>

        </section>
        <div class="w-2/12 h-full bg-lightGray text-lightGrayText flex flex-col items-center justify-between portrait:h-2/12 portrait:w-full">
          <button @click="closeApp" class="portrait:hidden w-8/12 h-2/12 flex self-start items-center justify-end focus:outline-none">
            <svg class=" icon_x fill-current stroke-current text-lightGrayText w-5 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8">
              <g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2">
                <path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z" />
                <path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <MeasureEyezCapture v-on:back="navigateBack()" v-bind:lensWidth="lensWidth" v-bind:bridgeWidth="bridgeWidth" v-bind:templeLength="templeLength"  />
    </template>
    <LensMeasurementsPopup :closeLensMeasurementsPopup="closeLensMeasurementsPopup" v-if="showLensMeasurementsPopup" />
  </div> -->
  <div id="container" class="absolute top-0 h-full w-full bg-background text-backgroundText flex justify-center items-center z-1000">
    <template v-if="!hasFrameSize">
      <div class="w-full h-full flex portrait:flex-col">
        <!-- bg-gradient-right-to-left  -->
        <div class="w-2/12 h-full bg-lightGray text-lightGrayText flex flex-col items-center justify-between portrait:h-2/12 portrait:w-full portrait:flex-row">
          <button @click="closeApp"  class="h-2/12 w-full flex items-center justify-center focus:outline-none portrait:w-2/12 portrait:h-4/12">
            <div class="text-lightGrayText w-10">
              <BackArrowSvg />
            </div>
            <!-- <svg class="arrow_back fill-current text-lightGrayText w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.19 84.19">
              <g data-name="Layer 2">
                <path d="M42.09 84.19a42.1 42.1 0 1142.1-42.1 42.14 42.14 0 01-42.1 42.1z" fill="none" />
                <path d="M48.41 68.73a4 4 0 01-2.83-1.18L23 44.92a4 4 0 010-5.65l22.58-22.64a4 4 0 015.66 5.66l-19.81 19.8L51.24 61.9a4 4 0 01-2.83 6.83z" />
              </g>
            </svg> -->
          </button>

          <button @click="closeApp" class="landscape:hidden h-2/12 w-full flex items-center justify-center focus:outline-none portrait:w-2/12 portrait:h-4/12">
            <div class=" text-lightGrayText w-5 mr-4">
              <IconXSvg />
            </div>
            <!-- <svg class=" icon_x fill-current stroke-current text-lightGrayText w-5 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8">
              <g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2">
                <path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z" />
                <path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/>
              </g>
            </svg> -->
          </button>
        </div>
        <section class="relative mx-auto h-full w-8/12 flex flex-col justify-around items-center portrait:h-8/12 portrait:w-full py-4">
          <div class="w-10/12 h-2/12 flex items-center justify-around rounded-lg rounded-tl-none bg-primary1 text-primary1Text">
              <div class="w-3/12 h-full flex flex-col justify-center items-center">
                <label><span class="font-bold">A.</span> Lens Width</label>
                <div class="h-10 w-full border-toggleGray border-2 flex justify-center items-center rounded-lg rounded-tl-none px-4 mt-1 bg-primary1Text">
                  <input class="h-11/12 w-full outline-none text-center" @keypress.enter="proceed()" type="number"  v-model="lensWidth">
                </div>
              </div>
              <div class="w-3/12 h-full flex flex-col justify-center items-center">
                <label><span class="font-bold">B.</span> Bridge Width</label>
                <div class="h-10 w-full border-toggleGray flex justify-center items-center border-2 rounded-lg rounded-tl-none px-4 mt-1 bg-primary1Text">
                  <input class="h-11/12 w-full outline-none text-center" @keypress.enter="proceed()" type="number" v-model="bridgeWidth">
                </div>
              </div>
              <!-- <div class="w-3/12 h-full flex flex-col justify-center items-center">
                <label><span class="font-bold">DBL.</span> Temple Length</label>
                <div class="h-10 w-full border-toggleGray flex justify-center items-center border-2 rounded-lg rounded-tl-none px-4 mt-2 bg-primary1Text">
                  <input class="h-11/12 w-full outline-none text-center" @keypress.enter="proceed()" type="number" v-model="templeLength">
                </div>
              </div> -->
          </div>
          <div class="w-full  flex justify-center items-center">
            <ProceedButton class="disable-dbl-tap-zoom mt-8" v-on:proceed="proceed()" />
          </div>
          <div class="w-full h-2/12 flex flex-col justify-center items-center underline">
            <a @click="showLensMeasurementsPopup = !showLensMeasurementsPopup">Where do I find this information?</a>
          </div>
          <div class="w-full h-4/12 relative bg-white">
            <img class="absolute left-0 top-0 h-full w-full object-contain px-2" src="../../assets/measure_Frame1.png">
          </div>
        </section>
        <div class="w-2/12 h-full bg-lightGray text-lightGrayText flex flex-col items-center justify-between portrait:h-2/12 portrait:w-full">
          <button @click="closeApp" class="portrait:hidden w-8/12 h-2/12 flex self-start items-center justify-end focus:outline-none">
            <div class=" text-lightGrayText w-5 mr-4">
              <IconXSvg />
            </div>
            <!-- <svg class=" icon_x fill-current stroke-current text-lightGrayText w-5 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8">
              <g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2">
                <path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z" />
                <path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/>
              </g>
            </svg> -->
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <MeasureEyezCapture v-on:back="navigateBack()" v-bind:lensWidth="lensWidth" v-bind:bridgeWidth="bridgeWidth" v-bind:templeLength="templeLength"  />
    </template>
    <LensMeasurementsPopup :closeLensMeasurementsPopup="closeLensMeasurementsPopup" v-if="showLensMeasurementsPopup" />
  </div>
</template>

<script>
import MeasureEyezCapture from '../MeasureEyezCapture';
import ProceedButton from './ProceedButton';
import brandStyling from "../../json/brand.config.json";
import LensMeasurementsPopup from './LensMeasurementsPopup.vue';
import IconXSvg from "../../assets/svg/icons/icon_x.svg";
import BackArrowSvg from "../../assets/svg/icons/back_arrow.svg";

export default {
  name: 'MeasureeyezContainer',
  data() {
    return {
      cssTheme: {},
      lensWidth: null,
      bridgeWidth: null,
      // templeLength: null,
      hasFrameSize: false,
      showLensMeasurementsPopup: false
    }
  },
  methods: {
    proceed() {
      if (this.lensWidth > 0 && this.bridgeWidth > 0)
        this.hasFrameSize = true;
    },
    closeApp() {
      this.$store.dispatch('toggleMeasureEyez');
    },
    closeLensMeasurementsPopup(){
      this.showLensMeasurementsPopup = false
    },
    navigateBack() {
      this.hasFrameSize = false;
    }
  },
  props:{
    currentFrame: Object
  },
  components: {
    MeasureEyezCapture,
    ProceedButton,
    LensMeasurementsPopup,
    IconXSvg,
    BackArrowSvg
  },
  computed: {

  },
  created(){
    if(/Android/.test(navigator.appVersion)) {
      window.addEventListener("resize", function() {
        if(document.activeElement.tagName=="INPUT" || document.activeElement.tagName=="TEXTAREA") {
          document.activeElement.scrollIntoView();
          document.getElementById('container').style.height = "40rem";
        }else{
          document.getElementById('container').style.removeProperty("height");
        }
      })
    }

  },

  mounted() {
    const currentFrameSize = this.currentFrame.size;
    let frameSizes = currentFrameSize && typeof currentFrameSize === 'string' ? currentFrameSize.split('-') : [null, null];

    this.lensWidth = frameSizes[0];
    this.bridgeWidth = frameSizes[1];

  }
}
</script>

<style>

    @responsive {
      .bg-gradient-left-to-right {
        background-image: linear-gradient(to right, #7d7d7d, #8f8f8f, #a1a1a1, #b4b3b2, #c6c6c5);
      }
    }
    @responsive {
      .bg-gradient-right-to-left {
        background-image: linear-gradient(to left, #7d7d7d, #8f8f8f, #a1a1a1, #b4b3b2, #c6c6c5);
      }
    }

</style>
