var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Lens-measurements-popup" }, [
    _c(
      "section",
      {
        staticClass:
          "h-full w-full flex justify-center items-center bg-backOpacity absolute top-0 right-0 z-1000"
      },
      [
        _c(
          "div",
          {
            staticClass:
              "h-10/12 w-11/12 flex flex-col relative bg-background text-backgroundText border-4 border-backgroundText mx-auto rounded-lg rounded-tl-none"
          },
          [
            _c(
              "div",
              {
                staticClass: "w-full h-1/12 flex justify-between items-center"
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-full pr-6 flex items-center justify-end focus:outline-none",
                    on: {
                      click: function($event) {
                        return _vm.closeLensMeasurementsPopup()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass:
                          "icon_x fill-current stroke-current text-backgroundText w-5",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 73.86 73.8"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "4.85",
                              "fill-rule": "evenodd",
                              "data-name": "Layer 2"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._m(0)
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "main",
      {
        staticClass: "h-11/12 w-full flex flex-col items-center justify-around"
      },
      [
        _c(
          "div",
          {
            staticClass:
              "w-full text-2xl flex justify-center items-center -mt-10"
          },
          [_vm._v("measureyes help")]
        ),
        _c("p", { staticClass: "h-1/12" }, [
          _vm._v(
            "You can find your A. B, & DBL Values on the interior arm of most frames."
          )
        ]),
        _c("div", { staticClass: "relative w-10/12 h-6/12" }, [
          _c("img", {
            staticClass: "absolute left-0 top-0 h-full w-full object-cover",
            attrs: {
              src: require("../../assets/measure_Frame2-50.jpg"),
              alt: ""
            }
          })
        ]),
        _c(
          "div",
          { staticClass: "h-3/12 flex flex-col justify-between pb-6" },
          [
            _c("p", [
              _vm._v("57 = Lens Width (measured on the interior frame)")
            ]),
            _c("p", [
              _vm._v(
                "19 = Bridge Width (narrowest distance between the lenses)"
              )
            ]),
            _c("p", [
              _vm._v("140 = Temple Length (complete length of temple arem)")
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }