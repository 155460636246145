var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "measureEyez absolute top-0 h-full w-full bg-toggleGray flex justify-center items-center z-1000 portrait:flex-col"
    },
    [
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentScreen !== "review",
                expression: "currentScreen !== 'review'"
              }
            ],
            staticClass:
              "portrait:hidden w-2/12 h-full bg-lightGray text-lightGrayText flex flex-col items-center justify-between"
          },
          [
            _c("BackButton", {
              on: {
                back: function($event) {
                  return _vm.navigateBack()
                }
              }
            }),
            _vm.imageCaptured && _vm.currentScreen === "capture"
              ? _c(
                  "section",
                  {
                    staticClass: "h-8/12 w-20 pt-20 flex items-center flex-col"
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass:
                          "w-8 mb-20 fill-current text-lightGrayText",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 78.58 95.6"
                        },
                        on: {
                          click: function($event) {
                            return _vm.rotate(1)
                          }
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M76.76 72.62a5 5 0 00-7 .69 33.71 33.71 0 11-12.2-52.09 7.07 7.07 0 01-.66.27l-6.34 2.24c-.61.21-1.21.47-1.81.7a3.58 3.58 0 00-2 2.54 4.59 4.59 0 003.77 6 4 4 0 001.5-.14c3.68-1.09 7.37-2.18 11-3.35 2.43-.77 4.83-1.65 7.22-2.52a5.11 5.11 0 002.7-2.08 4 4 0 00.45-3.41c-1.2-3.89-2.4-7.78-3.66-11.66-.73-2.24-1.57-4.47-2.37-6.72a4.25 4.25 0 00-.24-.54A4.3 4.3 0 0060.93.63a4.2 4.2 0 00-2.28 4.66c.22 1.27.63 2.51 1 3.76.17.67.37 1.33.55 2a2.31 2.31 0 010 .43 43.7 43.7 0 1017.25 68.17 5 5 0 00-.69-7.03z",
                            "data-name": "Layer 2"
                          }
                        })
                      ]
                    ),
                    _c(
                      "svg",
                      {
                        staticClass:
                          "w-8 mb-10 fill-current text-lightGrayText",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 78.59 95.6"
                        },
                        on: {
                          click: function($event) {
                            return _vm.rotate(-1)
                          }
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M68.68 24.21a43.77 43.77 0 00-50.32-12.73 3.42 3.42 0 01.06-.43c.18-.67.37-1.33.55-2 .33-1.25.74-2.49 1-3.76A4.2 4.2 0 0017.65.63a4.29 4.29 0 00-6.18 1.92c-.09.18-.18.36-.25.54-.79 2.25-1.63 4.48-2.36 6.74C7.6 13.71 6.39 17.6 5.2 21.49a4 4 0 00.45 3.41A5.09 5.09 0 008.34 27c2.4.87 4.8 1.75 7.23 2.52 3.66 1.17 7.35 2.26 11 3.35a4 4 0 001.5.14 4.61 4.61 0 003.77-6 3.58 3.58 0 00-2-2.54c-.61-.23-1.21-.49-1.82-.7l-6.34-2.24c-.21-.07-.43-.17-.66-.27A33.7 33.7 0 118.85 73.31a5 5 0 00-7.72 6.34 43.69 43.69 0 0067.55-55.44z",
                            "data-name": "Layer 2"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm.imageCaptured && _vm.currentScreen === "capture"
              ? _c(
                  "div",
                  {
                    staticClass: "h-3/12 w-full flex flex-col items-center",
                    on: {
                      click: function($event) {
                        return _vm.recapture()
                      }
                    }
                  },
                  [
                    _c("ReCaptureButton"),
                    _c(
                      "p",
                      {
                        staticClass:
                          "underline w-12 mt-2 text-lightGrayText text-center"
                      },
                      [_vm._v("retake photo")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentScreen !== "review",
                expression: "currentScreen !== 'review'"
              }
            ],
            staticClass:
              "landscape:hidden w-full h-2/12 bg-lightGray text-lightGrayText flex flex-col"
          },
          [
            _c(
              "div",
              {
                staticClass: "h-full w-full flex items-center justify-between"
              },
              [
                _c(
                  "div",
                  { staticClass: "w-1/4" },
                  [
                    _c("BackButton", {
                      on: {
                        back: function($event) {
                          return _vm.navigateBack()
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-1/4" },
                  [_c("CloseButton", { attrs: { closeApp: _vm.closeApp } })],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentScreen !== "review",
                expression: "currentScreen !== 'review'"
              }
            ],
            ref: "outerContainer",
            staticClass:
              "w-8/12 relative text-backOpacityText mx-auto portrait:w-full portrait:h-8/12",
            staticStyle: { height: "728px", width: "728px" }
          },
          [
            _c(
              "div",
              {
                ref: "innerContainer",
                staticClass: "overflow-hidden",
                style: [_vm.innerContainerStyle],
                on: {
                  click: function($event) {
                    return _vm.captureCoords($event)
                  }
                }
              },
              [
                _c(
                  "video",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.imageCaptured,
                        expression: "!imageCaptured"
                      }
                    ],
                    ref: "video",
                    staticClass: "object-cover h-full w-full",
                    style: _vm.videoStyle,
                    attrs: { id: "video" }
                  },
                  [_vm._v("Video stream not available.")]
                ),
                _c("canvas", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.imageCaptured,
                      expression: "!imageCaptured"
                    }
                  ],
                  ref: "overlaycanvas",
                  staticClass: "h-full w-full",
                  attrs: { id: "overlaycanvas" }
                }),
                _c("canvas", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imageCaptured,
                      expression: "imageCaptured"
                    }
                  ],
                  ref: "canvasElement",
                  staticClass: "object-cover h-full w-full",
                  attrs: { id: "maincanvas" }
                }),
                _vm._m(0),
                _c(
                  "div",
                  {
                    staticClass:
                      "absolute top-0 right-0 h-full w-full flex flex-col justify-between"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-col items-center justify-between"
                      },
                      [
                        _c(
                          "section",
                          {
                            staticClass:
                              "h-20 w-full flex items-center justify-between"
                          },
                          [
                            _vm.currentScreen === "measurement"
                              ? _c(
                                  "section",
                                  {
                                    staticClass:
                                      "h-10 w-10/12 mx-auto flex justify-between items-center"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "underline focus:outline-none text-backOpacityText",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeLastPoint()
                                          }
                                        }
                                      },
                                      [_vm._v("undo")]
                                    ),
                                    _c("HelpButton", {
                                      on: {
                                        help: function($event) {
                                          return _vm.openHelp()
                                        }
                                      }
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "underline focus:outline-none text-backOpacityText",
                                        on: {
                                          click: function($event) {
                                            return _vm.clearAllPoints()
                                          }
                                        }
                                      },
                                      [_vm._v("clear all")]
                                    )
                                  ],
                                  1
                                )
                              : _vm.imageCaptured &&
                                _vm.currentScreen === "capture"
                              ? _c(
                                  "section",
                                  {
                                    staticClass:
                                      " h-20 w-full flex justify-center items-center"
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "Center frame and align top of frame to top line"
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    !_vm.imageCaptured && _vm.currentScreen === "capture"
                      ? [
                          _c(
                            "section",
                            {
                              staticClass:
                                "absolute bottom-0 h-3/12 w-full flex flex-col items-center"
                            },
                            [
                              _vm._m(1),
                              _c("CaptureButton", {
                                staticClass:
                                  "absolute left-0 -ml-32 -mt-32 portrait:ml-0 portrait:-mt-24",
                                on: {
                                  click: function($event) {
                                    return _vm.captureImage()
                                  }
                                }
                              }),
                              _c("CameraToggle", {
                                on: {
                                  click: function($event) {
                                    return _vm.toggleCamera()
                                  }
                                }
                              }),
                              _c("CaptureButton", {
                                staticClass:
                                  "absolute right-0 -mr-32 -mt-32 portrait:mr-0 portrait:-mt-24",
                                on: {
                                  click: function($event) {
                                    return _vm.captureImage()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm.imageCaptured && _vm.currentScreen === "capture"
                      ? [
                          _c(
                            "section",
                            {
                              staticClass:
                                "portrait:hidden h-4/12 flex flex-col items-center mx-8"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "h-full flex flex-col" },
                                [
                                  _c("div", {
                                    staticClass:
                                      "h-1/12 text-white flex flex-col items-center justify-center self-end w-full mb-12 textShadow"
                                  }),
                                  _c("ReCalibrateButton", {
                                    attrs: {
                                      imageCaptured: _vm.imageCaptured,
                                      currentScreen: _vm.currentScreen
                                    },
                                    on: {
                                      proceed: function($event) {
                                        return _vm.navigateForward()
                                      },
                                      left: _vm.moveCanvasLeft,
                                      top: _vm.moveCanvasTop,
                                      right: _vm.moveCanvasRight,
                                      bottom: _vm.moveCanvasBottom
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "section",
                            {
                              staticClass:
                                "landscape:hidden h-4/12 w-full flex items-center"
                            },
                            [
                              _vm.imageCaptured &&
                              _vm.currentScreen === "capture"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-1/3 h-8/12 flex flex-col items-center justify-between"
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "w-8",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 78.58 95.6"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.rotate(1)
                                            }
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d:
                                                "M76.76 72.62a5 5 0 00-7 .69 33.71 33.71 0 11-12.2-52.09 7.07 7.07 0 01-.66.27l-6.34 2.24c-.61.21-1.21.47-1.81.7a3.58 3.58 0 00-2 2.54 4.59 4.59 0 003.77 6 4 4 0 001.5-.14c3.68-1.09 7.37-2.18 11-3.35 2.43-.77 4.83-1.65 7.22-2.52a5.11 5.11 0 002.7-2.08 4 4 0 00.45-3.41c-1.2-3.89-2.4-7.78-3.66-11.66-.73-2.24-1.57-4.47-2.37-6.72a4.25 4.25 0 00-.24-.54A4.3 4.3 0 0060.93.63a4.2 4.2 0 00-2.28 4.66c.22 1.27.63 2.51 1 3.76.17.67.37 1.33.55 2a2.31 2.31 0 010 .43 43.7 43.7 0 1017.25 68.17 5 5 0 00-.69-7.03z",
                                              fill: "#fff",
                                              "data-name": "Layer 2"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "w-8",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 78.59 95.6"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.rotate(-1)
                                            }
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d:
                                                "M68.68 24.21a43.77 43.77 0 00-50.32-12.73 3.42 3.42 0 01.06-.43c.18-.67.37-1.33.55-2 .33-1.25.74-2.49 1-3.76A4.2 4.2 0 0017.65.63a4.29 4.29 0 00-6.18 1.92c-.09.18-.18.36-.25.54-.79 2.25-1.63 4.48-2.36 6.74C7.6 13.71 6.39 17.6 5.2 21.49a4 4 0 00.45 3.41A5.09 5.09 0 008.34 27c2.4.87 4.8 1.75 7.23 2.52 3.66 1.17 7.35 2.26 11 3.35a4 4 0 001.5.14 4.61 4.61 0 003.77-6 3.58 3.58 0 00-2-2.54c-.61-.23-1.21-.49-1.82-.7l-6.34-2.24c-.21-.07-.43-.17-.66-.27A33.7 33.7 0 118.85 73.31a5 5 0 00-7.72 6.34 43.69 43.69 0 0067.55-55.44z",
                                              fill: "#fff",
                                              "data-name": "Layer 2"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "section",
                                { staticClass: "h-32 w-1/3 flex items-center" },
                                [
                                  _c("ReCalibrateButton", {
                                    attrs: {
                                      imageCaptured: _vm.imageCaptured,
                                      currentScreen: _vm.currentScreen
                                    },
                                    on: {
                                      proceed: function($event) {
                                        return _vm.navigateForward()
                                      },
                                      left: _vm.moveCanvasLeft,
                                      top: _vm.moveCanvasTop,
                                      right: _vm.moveCanvasRight,
                                      bottom: _vm.moveCanvasBottom
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.imageCaptured &&
                              _vm.currentScreen === "capture"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-1/3 h-8/12 flex flex-col items-center justify-between"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "w-10 fill-current text-lightGrayText",
                                          on: {
                                            click: function($event) {
                                              return _vm.zoom(1)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "zoomIn",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                viewBox: "0 0 102.07 102.19"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d:
                                                    "M0 39a29.91 29.91 0 01.56-6.6 38.78 38.78 0 0113.09-23 35.66 35.66 0 018.43-5.5 53.23 53.23 0 015.4-2.15A47.54 47.54 0 0132.37.61 32.13 32.13 0 0140.5.05a44.76 44.76 0 014.9.54 39.79 39.79 0 015.06 1.14A39.75 39.75 0 0156.07 4a38.49 38.49 0 0110.44 7.4 37.92 37.92 0 016.2 8 33.49 33.49 0 013.07 6.76 51.32 51.32 0 011.49 5.43 34.93 34.93 0 01.59 10.55 42.56 42.56 0 01-.72 4.92 47.81 47.81 0 01-1.68 5.66 34.74 34.74 0 01-2.16 4.82 50.21 50.21 0 01-2.87 4.49c-1 1.47-1 1 .15 2.2.67.7 1.39 1.36 2 2.07a.73.73 0 001 .18 6.86 6.86 0 014.26-.48 7.22 7.22 0 013.85 2.07l6 6L99.54 86a8.41 8.41 0 012 2.75 7.3 7.3 0 01.26 4.88 7.58 7.58 0 01-2 3.28 53.6 53.6 0 01-4.1 3.88 6.66 6.66 0 01-6.32 1.1 9.31 9.31 0 01-3.94-2.71L75.06 88.82c-2.22-2.22-4.45-4.44-6.66-6.67a8.74 8.74 0 01-1.92-2.63 7.13 7.13 0 01-.29-5.12 5.8 5.8 0 01.19-.59c.29-.68.3-.67-.21-1.17-.88-.88-1.76-1.75-2.63-2.64a.5.5 0 00-.77-.06c-1.55 1.06-3.07 2.19-4.71 3.11a40.15 40.15 0 01-5 2.36c-1.63.63-3.31 1.11-5 1.58a31.27 31.27 0 01-3.34.65 34.4 34.4 0 01-6.57.43 40.91 40.91 0 01-8.52-1.15 38.56 38.56 0 01-5.48-1.79A36.21 36.21 0 0118 72 39.66 39.66 0 017.09 61.52a38.26 38.26 0 01-3.64-6.37 41.49 41.49 0 01-2.09-5.81A43.6 43.6 0 010 41.66V39zm7.08 0A31.86 31.86 0 1039 7.19 31.86 31.86 0 007.11 39zm52.28-4.74H42.72V17.6a4 4 0 10-8 0v16.68H18a4 4 0 000 8h16.72V59a4 4 0 008 0V42.28h16.67a4 4 0 000-8z",
                                                  fill: "#fff",
                                                  "data-name": "Layer 2"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "w-10 fill-current text-lightGrayText",
                                          on: {
                                            click: function($event) {
                                              return _vm.zoom(-1)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "zoomOut",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                viewBox: "0 0 102.07 102.19"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d:
                                                    "M0 39a29.91 29.91 0 01.56-6.6 38.78 38.78 0 0113.09-23 35.66 35.66 0 018.43-5.5 53.23 53.23 0 015.4-2.15A47.54 47.54 0 0132.37.61 32.13 32.13 0 0140.5.05a44.76 44.76 0 014.9.54 39.79 39.79 0 015.06 1.14A39.75 39.75 0 0156.07 4a38.49 38.49 0 0110.44 7.4 37.92 37.92 0 016.2 8 33.49 33.49 0 013.07 6.76 51.32 51.32 0 011.49 5.43 34.93 34.93 0 01.59 10.55 42.56 42.56 0 01-.72 4.92 47.81 47.81 0 01-1.68 5.66 34.74 34.74 0 01-2.16 4.82 50.21 50.21 0 01-2.87 4.49c-1 1.47-1 1 .15 2.2.67.7 1.39 1.36 2 2.07a.73.73 0 001 .18 6.86 6.86 0 014.26-.48 7.22 7.22 0 013.85 2.07l6 6L99.54 86a8.41 8.41 0 012 2.75 7.3 7.3 0 01.26 4.88 7.58 7.58 0 01-2 3.28 53.6 53.6 0 01-4.1 3.88 6.66 6.66 0 01-6.32 1.1 9.31 9.31 0 01-3.94-2.71L75.06 88.82c-2.22-2.22-4.45-4.44-6.66-6.67a8.74 8.74 0 01-1.92-2.63 7.13 7.13 0 01-.29-5.12 5.8 5.8 0 01.19-.59c.29-.68.3-.67-.21-1.17-.88-.88-1.76-1.75-2.63-2.64a.5.5 0 00-.77-.06c-1.55 1.06-3.07 2.19-4.71 3.11a40.15 40.15 0 01-5 2.36c-1.63.63-3.31 1.11-5 1.58a31.27 31.27 0 01-3.34.65 34.4 34.4 0 01-6.57.43 40.91 40.91 0 01-8.52-1.15 38.56 38.56 0 01-5.48-1.79A36.21 36.21 0 0118 72 39.66 39.66 0 017.09 61.52a38.26 38.26 0 01-3.64-6.37 41.49 41.49 0 01-2.09-5.81A43.6 43.6 0 010 41.66V39zm7.08 0A31.86 31.86 0 1039 7.19 31.86 31.86 0 007.11 39zm52.28 3.26H18a4 4 0 010-8h41.39a4 4 0 010 8z",
                                                  fill: "#fff",
                                                  "data-name": "Layer 2"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      : _vm.currentScreen === "measurement"
                      ? [
                          _c(
                            "section",
                            {
                              staticClass:
                                "h-4/12 w-full flex justify-around items-center"
                            },
                            [
                              _c("EyeCoordinates", {
                                staticClass:
                                  "absolute left-0 -ml-32 -mt-16 portrait:ml-0 portrait:mt-0",
                                attrs: {
                                  eye: "right",
                                  framePixels: _vm.framePixels
                                },
                                on: { setPoint: _vm.setPoint }
                              }),
                              _c(
                                "div",
                                { staticClass: "h-full flex flex-col" },
                                [
                                  _vm._m(2),
                                  _c("ReCalibrateButton", {
                                    attrs: {
                                      imageCaptured: _vm.imageCaptured,
                                      currentScreen: _vm.currentScreen
                                    },
                                    on: {
                                      left: _vm.movePixelLeft,
                                      top: _vm.movePixelTop,
                                      right: _vm.movePixelRight,
                                      bottom: _vm.movePixelBottom,
                                      commit: _vm.commitCoords
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("EyeCoordinates", {
                                staticClass:
                                  "absolute right-0 -mr-32 -mt-16 portrait:mr-0 portrait:mt-0",
                                attrs: {
                                  eye: "left",
                                  framePixels: _vm.framePixels
                                },
                                on: { setPoint: _vm.setPoint }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm.imageCaptured && _vm.currentScreen === "calculate"
                      ? [
                          _vm.showProgressPopup
                            ? _c("ProgressPopup", {
                                attrs: { pageName: _vm.pageName }
                              })
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentScreen !== "review",
                expression: "currentScreen !== 'review'"
              }
            ],
            staticClass:
              "portrait:hidden w-2/12 h-full bg-lightGray text-lightGrayText flex flex-col items-center justify-between"
          },
          [
            _c("CloseButton", { attrs: { closeApp: _vm.closeApp } }),
            _vm.imageCaptured && _vm.currentScreen === "capture"
              ? _c(
                  "section",
                  {
                    staticClass: "h-8/12 w-20 flex items-center pt-20 flex-col"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "w-10 mb-20",
                        on: {
                          click: function($event) {
                            return _vm.zoom(1)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "zoomIn",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 102.07 102.19"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M0 39a29.91 29.91 0 01.56-6.6 38.78 38.78 0 0113.09-23 35.66 35.66 0 018.43-5.5 53.23 53.23 0 015.4-2.15A47.54 47.54 0 0132.37.61 32.13 32.13 0 0140.5.05a44.76 44.76 0 014.9.54 39.79 39.79 0 015.06 1.14A39.75 39.75 0 0156.07 4a38.49 38.49 0 0110.44 7.4 37.92 37.92 0 016.2 8 33.49 33.49 0 013.07 6.76 51.32 51.32 0 011.49 5.43 34.93 34.93 0 01.59 10.55 42.56 42.56 0 01-.72 4.92 47.81 47.81 0 01-1.68 5.66 34.74 34.74 0 01-2.16 4.82 50.21 50.21 0 01-2.87 4.49c-1 1.47-1 1 .15 2.2.67.7 1.39 1.36 2 2.07a.73.73 0 001 .18 6.86 6.86 0 014.26-.48 7.22 7.22 0 013.85 2.07l6 6L99.54 86a8.41 8.41 0 012 2.75 7.3 7.3 0 01.26 4.88 7.58 7.58 0 01-2 3.28 53.6 53.6 0 01-4.1 3.88 6.66 6.66 0 01-6.32 1.1 9.31 9.31 0 01-3.94-2.71L75.06 88.82c-2.22-2.22-4.45-4.44-6.66-6.67a8.74 8.74 0 01-1.92-2.63 7.13 7.13 0 01-.29-5.12 5.8 5.8 0 01.19-.59c.29-.68.3-.67-.21-1.17-.88-.88-1.76-1.75-2.63-2.64a.5.5 0 00-.77-.06c-1.55 1.06-3.07 2.19-4.71 3.11a40.15 40.15 0 01-5 2.36c-1.63.63-3.31 1.11-5 1.58a31.27 31.27 0 01-3.34.65 34.4 34.4 0 01-6.57.43 40.91 40.91 0 01-8.52-1.15 38.56 38.56 0 01-5.48-1.79A36.21 36.21 0 0118 72 39.66 39.66 0 017.09 61.52a38.26 38.26 0 01-3.64-6.37 41.49 41.49 0 01-2.09-5.81A43.6 43.6 0 010 41.66V39zm7.08 0A31.86 31.86 0 1039 7.19 31.86 31.86 0 007.11 39zm52.28-4.74H42.72V17.6a4 4 0 10-8 0v16.68H18a4 4 0 000 8h16.72V59a4 4 0 008 0V42.28h16.67a4 4 0 000-8z",
                                "data-name": "Layer 2"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "w-10 mb-10",
                        on: {
                          click: function($event) {
                            return _vm.zoom(-1)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "zoomOut",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 102.07 102.19"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M0 39a29.91 29.91 0 01.56-6.6 38.78 38.78 0 0113.09-23 35.66 35.66 0 018.43-5.5 53.23 53.23 0 015.4-2.15A47.54 47.54 0 0132.37.61 32.13 32.13 0 0140.5.05a44.76 44.76 0 014.9.54 39.79 39.79 0 015.06 1.14A39.75 39.75 0 0156.07 4a38.49 38.49 0 0110.44 7.4 37.92 37.92 0 016.2 8 33.49 33.49 0 013.07 6.76 51.32 51.32 0 011.49 5.43 34.93 34.93 0 01.59 10.55 42.56 42.56 0 01-.72 4.92 47.81 47.81 0 01-1.68 5.66 34.74 34.74 0 01-2.16 4.82 50.21 50.21 0 01-2.87 4.49c-1 1.47-1 1 .15 2.2.67.7 1.39 1.36 2 2.07a.73.73 0 001 .18 6.86 6.86 0 014.26-.48 7.22 7.22 0 013.85 2.07l6 6L99.54 86a8.41 8.41 0 012 2.75 7.3 7.3 0 01.26 4.88 7.58 7.58 0 01-2 3.28 53.6 53.6 0 01-4.1 3.88 6.66 6.66 0 01-6.32 1.1 9.31 9.31 0 01-3.94-2.71L75.06 88.82c-2.22-2.22-4.45-4.44-6.66-6.67a8.74 8.74 0 01-1.92-2.63 7.13 7.13 0 01-.29-5.12 5.8 5.8 0 01.19-.59c.29-.68.3-.67-.21-1.17-.88-.88-1.76-1.75-2.63-2.64a.5.5 0 00-.77-.06c-1.55 1.06-3.07 2.19-4.71 3.11a40.15 40.15 0 01-5 2.36c-1.63.63-3.31 1.11-5 1.58a31.27 31.27 0 01-3.34.65 34.4 34.4 0 01-6.57.43 40.91 40.91 0 01-8.52-1.15 38.56 38.56 0 01-5.48-1.79A36.21 36.21 0 0118 72 39.66 39.66 0 017.09 61.52a38.26 38.26 0 01-3.64-6.37 41.49 41.49 0 01-2.09-5.81A43.6 43.6 0 010 41.66V39zm7.08 0A31.86 31.86 0 1039 7.19 31.86 31.86 0 007.11 39zm52.28 3.26H18a4 4 0 010-8h41.39a4 4 0 010 8z",
                                "data-name": "Layer 2"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm.imageCaptured && _vm.currentScreen === "capture"
              ? _c("div", { staticClass: "h-3/12 pt-8" })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentScreen !== "review",
                expression: "currentScreen !== 'review'"
              }
            ],
            staticClass:
              "landscape:hidden w-full h-2/12 bg-lightGray text-lightGrayText flex flex-col items-center justify-between"
          },
          [
            _vm.imageCaptured && _vm.currentScreen === "capture"
              ? _c(
                  "div",
                  {
                    staticClass: "h-full flex items-center justify-center",
                    on: {
                      click: function($event) {
                        return _vm.recapture()
                      }
                    }
                  },
                  [
                    _c("ReCaptureButton"),
                    _c(
                      "p",
                      {
                        staticClass:
                          "underline text-lightGrayText  text-center ml-4 "
                      },
                      [_vm._v("retake photo")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        ),
        _vm.showHelpPage
          ? _c("HelpPage", {
              on: {
                close: function($event) {
                  return _vm.closeHelp()
                }
              }
            })
          : _vm._e()
      ],
      [
        _c("MeasurementsResult", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentScreen === "review",
              expression: "currentScreen === 'review'"
            }
          ],
          attrs: { imageSource: _vm.reviewImageData, frame: _vm.frame },
          on: {
            back: function($event) {
              return _vm.navigateBack()
            },
            recalc: function($event) {
              return _vm.measurePoints()
            }
          }
        })
      ],
      _c("canvas", {
        ref: "hiddenCanvas",
        staticStyle: { width: "720px", height: "720px", display: "none" },
        attrs: { id: "hiddenCanvas" }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "overlay h-full w-full absolute top-0 right-0 border-b-260 text-backOpacityText border-t-100 border-backOpacity flex justify-center items-center"
      },
      [_c("section", { staticClass: "h-full w-full mx-auto my-4 flex" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "h-1/12 flex flex-col items-center justify-center self-end w-full mb-12"
      },
      [
        _c("p", [_vm._v("Adjust frames on patient")]),
        _c("p", [_vm._v("before taking measurements.")]),
        _c("p", { staticClass: "mt-2" }, [
          _vm._v(" Position camera eye level with patient.")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "h-1/12 text-backOpacityText flex flex-col items-center justify-center self-end w-full mb-12"
      },
      [
        _c("p", [_vm._v("place crosshair with finger or mouse")]),
        _c("p", [_vm._v("adjust point as precisely as possible")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }