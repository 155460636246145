var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "email-popup h-full w-full flex justify-center items-center bg-backOpacity fixed top-0 z-1000 overflow-y-scroll"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "h-7/12 w-5/12 flex flex-col items-center border-4 border-backgroundText rounded-lg rounded-tl-none bg-background text-backgroundText portrait:w-10/12 portrait:h-8/12",
          class: { animation: !_vm.termesAgreement },
          attrs: { id: "email-popup" }
        },
        [
          _c("div", { staticClass: "w-full h-12 flex justify-end" }, [
            _c(
              "button",
              {
                staticClass: "w-10 block focus:outline-none",
                on: { click: _vm.toggleEmailPopup }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass:
                      "icon_x fill-current stroke-current text-textDark w-6",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 73.86 73.8"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "4.85",
                          "fill-rule": "evenodd",
                          "data-name": "Layer 2"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            )
          ]),
          _c(
            "main",
            {
              staticClass:
                "h-11/12 w-full flex flex-col items-center justify-around"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "h-4/12 w-full flex flex-col justify-center items-center"
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-9/12 flex flex-col justify-center items-center z-1000 mt-3"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-10 w-full border-toggleGray border-2 flex justify-center items-center rounded-lg rounded-tl-none px-4 bg-background text-backgroundText"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userEmail,
                                expression: "userEmail"
                              }
                            ],
                            staticClass:
                              "h-11/12 w-full outline-none text-center bg-background text-backgroundText",
                            domProps: { value: _vm.userEmail },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.userEmail = $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "w-9/12 h-2/12 overflow-y-auto scale-0 duration-300",
                  class: { "h-6/12": !_vm.termesAgreement }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "h-10 flex items-center ",
                      on: { click: _vm.toggleTermesAgreement }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-full w-10 border-2 border-toggleGray rounded-lg rounded-tl-none ",
                          class: { "bg-primary3": _vm.termesAgreement }
                        },
                        [
                          !_vm.termesAgreement
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-center items-center"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "checkbox_blank w-8",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 68.8 62.63"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M3.32 36.82c6.71-4.25 11.51-9 13.27.73s3.86 9.41 8.34 4.17S56.35 1.8 64.41 1.06c8.33-.76 1.16 5.13-20 30.24s-18.8 30.33-25.8 30.33S8.1 53.16 5.8 47.85s-7.96-7.57-2.48-11.03z",
                                          fill: "#fff",
                                          stroke: "#c8c8c8",
                                          "stroke-miterlimit": "10",
                                          "stroke-width": "2",
                                          "fill-rule": "evenodd",
                                          "data-name": "Layer 2"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.termesAgreement
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-center items-center"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "checkbox_selected fill-current text-primary3Text w-8",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 47.03 42.69"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M1.63 25.22c4.73-3 8.11-6.31 9.35.51s2.71 6.63 5.87 2.94S39 .56 44.65 0c5.86-.53.81 3.61-14.08 21.29s-13.24 21.4-18.17 21.4-7.4-6-9-9.71-5.63-5.32-1.77-7.76z",
                                          "fill-rule": "evenodd",
                                          "data-name": "Layer 2"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._m(1)
                    ]
                  ),
                  !_vm.termesAgreement
                    ? _c("div", { staticClass: "h-5/12 w-full mt-6" }, [
                        _c("p", [_vm._v("How we use your information:")]),
                        _c("p", [
                          _vm._v(
                            "The information you have provided is used to create a personalized shopping experience. We do not sell or share customer data with any third parties."
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "w-full h-2/12 flex items-center justify-center"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "underline h-12 w-48 flex justify-between items-center pl-6 pr-10 bg-primary3 text-primary3Text rounded-lg rounded-tl-none focus:outline-none portrait:mb-0 portrait:mr-0",
                      class: { disabled: !_vm.termesAgreement },
                      on: { click: _vm.sendPayload }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-8" },
                        [
                          _vm.emailStatus != "sending"
                            ? _c(
                                "svg",
                                {
                                  staticClass:
                                    "icon_Email_White stroke-current fill-current text-primary3Text",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 74.13 68.83"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "Layer_2",
                                        "data-name": "Layer 2"
                                      }
                                    },
                                    [
                                      _c("g", { attrs: { id: "keyboard" } }, [
                                        _c("path", {
                                          staticStyle: {
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "stroke-width": "1.41364837025837px"
                                          },
                                          attrs: {
                                            d:
                                              "M37.08,68.12H2.42c-.21,0-.43,0-.65,0A1.17,1.17,0,0,1,.72,67c0-.15,0-.29,0-.44Q.72,48.31.71,30a1.94,1.94,0,0,1,.87-1.62c2.86-1.87,5.69-3.77,8.54-5.65a.94.94,0,0,0,.43-.79c0-6.53,0-13.07,0-19.6,0-.21,0-.42,0-.63a1.19,1.19,0,0,1,1-1c.2,0,.4,0,.61,0H62a4,4,0,0,1,.6,0,1.16,1.16,0,0,1,1,1c0,.2,0,.41,0,.61,0,6.53,0,13.07,0,19.6a.89.89,0,0,0,.46.83c2.86,1.88,5.7,3.79,8.56,5.67A1.78,1.78,0,0,1,73.42,30q0,18.3,0,36.6a1.56,1.56,0,0,1-1.57,1.56Zm-24.14-65v9q0,10.77,0,21.54c0,.25,0,.44.28.59Q23,39.45,32.84,44.66a.43.43,0,0,0,.53,0c.93-.63,1.87-1.23,2.79-1.87a1.58,1.58,0,0,1,1.81,0c.89.62,1.81,1.19,2.69,1.81a.7.7,0,0,0,.74,0q9.7-5.16,19.43-10.3a.7.7,0,0,0,.37-.62q0-15.06,0-30.11V3.12ZM68.16,65.71a4.18,4.18,0,0,0-.4-.29L37.43,45.29a.55.55,0,0,0-.71,0Q21.54,55.38,6.35,65.43c-.11.07-.21.16-.36.28Zm-65-34v33L30.81,46.31Zm67.84,0L43.32,46.31,71,64.67Z"
                                          }
                                        }),
                                        _c(
                                          "text",
                                          {
                                            staticStyle: {
                                              "font-size":
                                                "28.28809928894043px",
                                              "font-family":
                                                "Arial-BoldMT, Arial",
                                              "font-weight": "700"
                                            },
                                            attrs: {
                                              transform:
                                                "translate(23.09 30.07)"
                                            }
                                          },
                                          [_vm._v("@")]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.emailStatus == "sending"
                            ? _c("Spinner", {
                                staticClass: "w-11 h-12",
                                staticStyle: { color: "var(--primary3-text)" }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      !_vm.emailStatus || _vm.emailStatus == "error"
                        ? _c("span", {}, [_vm._v("Confirm")])
                        : _vm._e(),
                      _vm.emailStatus == "sending"
                        ? _c("span", {}, [_vm._v("Sending")])
                        : _vm._e(),
                      _vm.emailStatus == "sent"
                        ? _c("span", {}, [_vm._v("Sent")])
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm.emailStatus == "error" && _vm.termesAgreement
                ? _c(
                    "div",
                    { staticClass: "w-full h-2/12 text-center text-red-700" },
                    [_vm._v(_vm._s(_vm.errorMessage))]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-9/12 pl-2 flex flex-col" }, [
      _c("p", { staticClass: "text-xl font-bold" }, [_vm._v("Email")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "h-10 underline ml-4 flex flex-col justify-center" },
      [
        _c("p", [_vm._v("Patient agrees to")]),
        _c("p", [_vm._v("Terms and conditions")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }