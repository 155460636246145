<template>
  <main id="auth" class="h-10/12 bg-background text-backgroundText flex flex-col landscape:flex-row">
    <section class="landscape:w-1/2 h-full flex flex-col justify-arounds portrait:items-center portrait:w-full">
      <div class="w-8/12 h-5/12 flex justify-center items-center ml-10 portrait:ml-0 portrait:h-4/12">
        <div class="h-10/12 w-11/12 relative">
          <img v-if="!brandLogo" class="absolute left-0 top-0 h-full w-full object-contain" src="../assets/img/contentlinqlogo.png" alt="Advanced Eyecare Logo">
          <img v-if="brandLogo" class="absolute left-0 top-0 h-full w-full object-contain" :src="brandLogo" alt="Practice Logo">
        </div>
      </div>
      <!-- portrait:justify-center portrait:items-center -->
      <div class="h-7/12 w-full flex flex-col justify-between">
        <div class="flex w-full h-4/12 flex-col ml-10 portrait:items-center portrait:ml-0">
          <div class="w-8/12 mb-2 rounded flex items-center justify-between">
            <label class="ml-2 font-semibold">Email:</label>
            <!-- <a class="underline text-sm">forgot username</a> -->
          </div>
          <input type="email" name="email" id="email" v-model="username" placeholder="Enter your email" class="inputStyle focus:outline-none focus:shadow-outline w-8/12">
        </div>
        <div class="w-full h-8/12">
          <div class="flex w-full flex-col ml-10 portrait:items-center portrait:ml-0">
            <div class="w-8/12 mb-2 rounded flex items-center justify-between">
              <label class="ml-2 font-semibold">Password:</label>
              <!-- <a class="underline text-sm">forgot password</a> -->
            </div>
            <div class="w-full flex portrait:justify-center portrait:-mr-14">
              <input type="password" name="password" id="password" v-model="password" @keyup.enter="login()" placeholder="Enter your password" class="inputStyle focus:outline-none focus:shadow-outline w-8/12">
              <button @click="login" v-if="!authInProgress" class="bg-primary3 h-10 w-12 rounded-md ml-3 text-primary3Text rounded-tl-none block focus:outline-none focus:shadow-outline">Go</button>
              <div v-if="authInProgress" class="bg-primary3 h-10 w-12 rounded-md ml-3 rounded-tl-none flex justify-center relative overflow-hidden"><img class="w-full h-full absolute top-0 right-0 object-cover" src="../assets/img/loading.gif" alt=""></div>
              <!-- Loading indicator from https://loading.io/ -->
            </div>
            <div v-if="authError" class="mt-4 ml-10 portrait:w-8/12 portrait:ml-0">
            <p>Email or password not recognized.</p>
            <p>Please try again.</p>
          </div>
          </div>

          <!-- <div v-if="authError" class="mt-4 ml-10 border-2">
            <p>Email or password not recognized.</p>
            <p>Please try again.</p>
          </div> -->
        </div>
      </div>
      <!-- <div class="w-10/12 flex justify-end items-center mt-10 portrait:justify-center portrait:w-full">
        <img class="w-40" src="../assets/img/logo/logo_VisionSource.png" alt="Vision Source Logo">
      </div> -->
    </section>
    <section class="landscape:w-1/2 portrait:h-1/2 flex justify-center items-center ">
      <img class="object-cover landscape:object-left landscape:h-full landscape:border-none portrait:border-b-4 portrait:border-t-4 portrait:border-primary1  portrait:bottom-0 "
      :src="`./img/optical_Female_sm.jpg`" alt="Woman face with Glasses">
    </section>
    </main>
</template>

<script>
import { initApp } from "../common/functions";
export default {
  name: 'Auth',
  data(){
    return{
      username: '',
      password: '',
      authError: false,
      authInProgress: false,
      initApp: initApp

    }
  },
  methods: {
    login(){
      this.authInProgress = true
      this.$store.dispatch('login', {"email": this.username, "password": this.password})
      .then(response => {
        this.authInProgress = false
        this.$router.push('/')
        this.initApp()
      })
      .catch(err => {
        this.authInProgress = false
        this.authError = true
      })
    }
  },
  props: {
    view: String
  },
  created() {
    this.$store.dispatch('setView', this.view);

    if(/Android/.test(navigator.appVersion)) {
      window.addEventListener("resize", function() {
        if(document.activeElement.tagName=="INPUT" || document.activeElement.tagName=="TEXTAREA") {
          document.activeElement.scrollIntoView();
          document.getElementById('auth').style.height = "40rem";
        }else{
          document.getElementById('auth').style.removeProperty("height");
        }
      })
    }
  },
  computed: {
    brandLogo() {
      return this.$store.getters.getLogo
    }
  }
};
</script>

<style scoped>
  .inputStyle {
    @apply pl-4 border-backgroundText border-solid border-2 h-10 rounded-md rounded-tl-none bg-background text-backgroundText;
  }
</style>
