var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "w-28 h-40 mx-auto bg-primary3 text-primary3Text focus:outline-none flex items-center justify-center",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "w-full h-full flex flex-col items-center justify-center"
        },
        [
          _c(
            "svg",
            {
              staticClass:
                "icon_PhotoBooth w-16 fill-current text-primary3Text",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 141.6 108.87"
              }
            },
            [
              _c("g", { attrs: { "data-name": "Layer 2" } }, [
                _c("path", {
                  attrs: {
                    d:
                      "M70.71 108.86H13.44c-9 0-13.43-4.44-13.43-13.58Q0 63 0 30.65c0-8.82 4.25-13.11 12.93-13.12 6.32 0 12.65.14 19-.1a8.47 8.47 0 005-2.12c3.81-3.48 7.38-7.21 10.91-11A12.87 12.87 0 0157.7 0Q71 .08 84.4 0a12.38 12.38 0 019.35 3.87c3.87 4 7.7 8 11.75 11.78a7.17 7.17 0 004.33 1.79c6.57.19 13.16 0 19.73.09 7.43 0 12 4.58 12 12.1q.08 33.48 0 67c0 7.53-4.8 12.24-12.42 12.26-19.47 0-38.95-.04-58.43-.03zm31.46-48.65a31.38 31.38 0 10-31.32 31.58 31.45 31.45 0 0031.32-31.58zm25.72-24.71c2.7 0 5.16-2.23 4.9-4.84s-1.79-4.6-4.72-4.66-4.55 1.67-4.9 4.44a4.74 4.74 0 004.72 5.06z"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M94.79 60.22a24 24 0 11-24.15-24.16 24 24 0 0124.15 24.16z"
                  }
                })
              ])
            ]
          ),
          _c("p", { staticClass: "w-1/2 mt-2" }, [_vm._v("capture photo")])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }