<template>
  <div class="flex w-full h-full flex-col items-center font-bold mt-1/3">
    <div>Oops!</div>
    <div>Something went wrong.</div>
  </div>
  
</template>

<script>
export default {
  name: '404'
}
</script>

<style>

</style>