var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "demo h-10/12 bg-background text-backgroundText w-full absolute left-0 bg-white z-110 demo-landscape"
    },
    [
      _c(
        "section",
        {
          staticClass:
            "bg-toggleGray text-toggleGrayText h-16 flex items-center justify-between px-2"
        },
        [
          _c("button", { staticClass: "w-10 block focus:outline-none" }),
          _c(
            "div",
            { staticClass: "flex items-center justify-start mr-44" },
            [
              _c("DemoTipSvg", { staticClass: "w-10 h-10 mr-6" }),
              _vm.currentDemo == "lensMaterials"
                ? _c("p", [
                    _vm._v(
                      "Use this guide to determine how your lenses fit in your frame."
                    )
                  ])
                : _vm._e(),
              _vm.currentDemo == "lightReactive"
                ? _c("p", [
                    _vm._v(
                      "Light reactive lenses respond to UV light and darken accordingly."
                    )
                  ])
                : _vm._e(),
              _vm.currentDemo == "linedBifocal"
                ? _c("p", [
                    _vm._v(
                      "Consider a progressive to avoid the harsh bifocal line."
                    )
                  ])
                : _vm._e(),
              _vm.currentDemo == "polarized"
                ? _c("p", [
                    _vm._v(
                      "Polarization provides superior sun protection while minimizing glare."
                    )
                  ])
                : _vm._e(),
              _vm.currentDemo == "progressive"
                ? _c("p", [
                    _vm._v(
                      "Premium progressive lenses provide optimal vision in all corridors."
                    )
                  ])
                : _vm._e(),
              _vm.currentDemo == "antiReflective"
                ? _c("p", [
                    _vm._v(
                      "Anti-Reflective reduces glare and leaves your glasses easier to clean."
                    )
                  ])
                : _vm._e(),
              _vm.currentDemo == "blueLight"
                ? _c("p", [
                    _vm._v(
                      "Blue Light filters help protect your eyes from overexposure to harmful blue light."
                    )
                  ])
                : _vm._e(),
              _vm.currentDemo == "transitions"
                ? _c("p", [
                    _vm._v(
                      "Transitions® lenses respond to UV light and darken accordingly."
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass:
                "w-48 h-12 bg-primary3 text-primary3Text focus:outline-none flex justify-center items-center rounded-lg rounded-tl-none text-center"
            },
            [
              _c(
                "div",
                {
                  staticClass: "w-20 text-toggleGrayText flex flex-row",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$store.dispatch("toggleDemoButtons")
                    }
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "inline whitespace-no-wrap underline text-primary3Text"
                    },
                    [_vm._v("View All Demos")]
                  )
                ]
              ),
              _c("LensIconSvg", {
                staticClass: "w-8 ml-10",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$store.dispatch("toggleDemoButtons")
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _vm.currentDemo == "lensMaterials"
        ? _c("LensMaterials", { attrs: { currentDemo: _vm.currentDemo } })
        : _vm._e(),
      _vm.currentDemo == "lightReactive" ? _c("LightReactive") : _vm._e(),
      _vm.currentDemo == "polarized" ? _c("Polarized") : _vm._e(),
      _vm.currentDemo == "progressive" ? _c("Progressive") : _vm._e(),
      _vm.currentDemo == "antiReflective" ? _c("AntiReflective") : _vm._e(),
      _vm.currentDemo == "linedBifocal" ? _c("LinedBifocal") : _vm._e(),
      _vm.currentDemo == "transitions" ? _c("Transitions") : _vm._e(),
      _vm.currentDemo == "blueLight" ? _c("BlueLight") : _vm._e(),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class":
              "transition-all transform duration-700 ease-in-out",
            "leave-active-class":
              "transition-all transform duration-700 ease-in-out",
            "enter-class": "-translate-y-full",
            "enter-to-class": "translate-y-0",
            "leave-class": "translate-y-0",
            "leave-to-class": "-translate-y-full"
          }
        },
        [
          _vm.showDemoButtons
            ? _c("DemoButtons", {
                attrs: {
                  showDemoButtons: _vm.showDemoButtons,
                  currentDemo: _vm.currentDemo,
                  changeDemo: _vm.changeDemo
                },
                on: {
                  "update:showDemoButtons": function($event) {
                    _vm.showDemoButtons = $event
                  },
                  "update:show-demo-buttons": function($event) {
                    _vm.showDemoButtons = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }