<template>
  <section class="help-page h-full w-full flex justify-center items-center bg-backOpacity absolute top-0 right-0 z-100">
    <div class="h-10/12 w-11/12 relative bg-background text-backgroundText border-4 border-backgroundText mx-auto rounded-lg rounded-tl-none">
      <section class="h-16 w-full flex items-center justify-between">
        <div class="w-1/4 portrait:w-2/12">
          <!-- <button @click="$emit('close')" class="h-2/12 w-full flex items-center justify-center focus:outline-none">
            <svg class="arrow_back fill-current text-backgroundText w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.19 84.19">
              <g data-name="Layer 2">
                <path d="M42.09 84.19a42.1 42.1 0 1142.1-42.1 42.14 42.14 0 01-42.1 42.1z" fill="none" />
                <path d="M48.41 68.73a4 4 0 01-2.83-1.18L23 44.92a4 4 0 010-5.65l22.58-22.64a4 4 0 015.66 5.66l-19.81 19.8L51.24 61.9a4 4 0 01-2.83 6.83z" />
              </g>
            </svg>
          </button> -->
        </div>
        <div class="flex flex-col justify-center items-center pt-4 z-100">
          <p class="text-2xl">measureyes help</p>
          <p class="text-sm">points should be positioned to the:</p>
          <p class="text-sm">outermost point of lens & inner most edge of frame</p>
        </div>
        <!-- <div class="w-1/4"><CloseButton class="w-1/2" :color="color" v-on:close="$emit('close')" /></div> -->
        <div class="w-1/4">
          <button  @click="$emit('close')" class="w-full h-2/12 flex items-center justify-end focus:outline-none pr-8">
            <svg class="icon_x fill-current stroke-current text-backgroundText w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8">
              <g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2">
                <path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z" />
                <path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/>
              </g>
            </svg>
          </button>
        </div>
      </section>
      <!-- <div class="h-8 w-full flex justify-between -mt-4">
        <p class="w-3/12 flex justify-center items-center pl-2">patient's right</p>
        <p class="w-3/12 flex justify-center items-center pl-2">patient's left</p>
      </div> -->
      <section class="h-10/12 w-full flex justify-center items-center -mt-4 portrait:m-0">
        <div class="relative h-full w-11/12">
          <img class="absolute left-0 top-0 h-full w-full object-contain" src="../../assets/MeasureHelp.png" alt="">
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import CloseButton from '../clq-measurements/CloseButton';

export default {
  name: 'HelpPage',
  data() {
    return {

    };
  },
  methods: {

  },
  props: {

  },
  components: {
    CloseButton,
  },
};
</script>

<style scoped>

</style>
