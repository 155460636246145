var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "anti-reflective h-full relative flex" },
    [
      _vm.currentSetting == "daytime"
        ? _c(
            "div",
            [
              _c("CompareSlider", {
                attrs: {
                  leftImage: "./img/demos/AR_Day.jpg",
                  rightImage: "./img/demos/AR_Day_None.jpg"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.currentSetting == "computer"
        ? _c(
            "div",
            [
              _c("CompareSlider", {
                attrs: {
                  leftImage: "./img/demos/AR_Computer.jpg",
                  rightImage: "./img/demos/AR_Computer_None.jpg"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.currentSetting == "nighttime"
        ? _c(
            "div",
            [
              _c("CompareSlider", {
                attrs: {
                  leftImage: "./img/demos/AR_Night.jpg",
                  rightImage: "./img/demos/AR_Night_None.jpg"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.currentSetting == "cosmetic"
        ? _c(
            "div",
            [
              _c("CompareSlider", {
                attrs: {
                  leftImage: "./img/demos/AR_Cosmetic.jpg",
                  rightImage: "./img/demos/AR_Cosmetic_None.jpg"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", [
        _c("div", { staticClass: "absolute top-0 right-0 mt-6 mr-4 z-10" }, [
          _c(
            "button",
            {
              staticClass:
                "btn-setting button focus:outline-none focus:bordernone overflow-hidden",
              class: [_vm.settingDropDown ? "animation" : ""],
              on: {
                click: function($event) {
                  _vm.settingDropDown = !_vm.settingDropDown
                }
              }
            },
            [
              _c(
                "label",
                {
                  class: { display: _vm.settingDropDown },
                  attrs: { id: "btn-setting" }
                },
                [_vm._v("setting")]
              ),
              _vm.settingDropDown
                ? _c("demo-button", {
                    attrs: {
                      dropDown: _vm.settingDropDown,
                      currentOption: _vm.currentSetting,
                      changeDemo: _vm.changeSetting,
                      title: "setting",
                      changeArray: _vm.setting
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c(
        "label",
        {
          staticClass:
            "absolute left-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl"
        },
        [_vm._v("AR Coating")]
      ),
      _c(
        "label",
        {
          staticClass:
            "absolute right-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl"
        },
        [_vm._v("No Coating")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }