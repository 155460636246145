<template>
    <div>
        <main v-if="currentView == 'Checkout'" class="h-3/12 w-70 checkout-box bg-background text-backgroundText absolute top-0 right-0 mt-4 border-4 border-primary1 border-r-0 flex flex-col justify-around rounded-bl-lg z-50 portrait:h-1/5 portrait:mt-16 portrait:w-64s">
            <div class="flex flex-col justify-around h-full">
                <div class="w-10/12 h-2/5 flex items-center self-start pl-8">
                    <div class="w-8 text-primary1">
                        <ShoppingCartSvg />
                    </div>
                    <div class="w-9/12 flex">
                        <span :class="{'line-through': selfpayDiscount != 0 || currentInsurancePlan != 'selfpay'}" class="font-semibold text-lg mx-2">${{totalPrice}}*</span>
                        <span class="text-lg" v-if="discountTotal > 0">${{discountTotal}}</span>
                    </div>

                </div>
                <div class="w-full h-3/5 border-t-4 border-primary1 pl-8">
                    <div class="w-full h-full flex justify-between items-center">
                        <button  @click="toggleEmailPopup" class="underline h-12 w-40 text-xl flex justify-between items-center pl-4 pr-4 bg-primary3 text-primary3Text rounded-lg rounded-tl-none focus:outline-none portrait:mb-0 portrait:mr-0">
                            <div class="w-8 text-primary3Text">
                            <IconEmailSvg />
                            </div>
                            <span class="">Summary</span>
                        </button>
                        <button @click="toggleInsurancePopup" class="w-10  focus:outline-none">
                            <div class="text-backgroundText w-6">
                                <CheckoutDetailSvg />
                            </div>
                        </button>
                    </div>
                </div>
                <!-- <div class="w-full h-3/5 border-t-4 border-primary1 flex items-center pl-8 relative ">
                    <div @click="showOfficeFees = !showOfficeFees" class="absolute top-0 right-0 pt-2 pr-4 hidden">
                        <div class="text-textDark w-5">
                           <EditIconSvg />
                        </div>
                    </div>
                    <div class="w-full h-full flex justify-between items-center border-2">
                        <button  @click="showEmailPopup = !showEmailPopup" class="underline h-12 w-40 -mt-2 text-xl flex justify-between items-center pl-4 pr-4 bg-primary3 text-primary3Text rounded-lg rounded-tl-none focus:outline-none portrait:mb-0 portrait:mr-0">
                            <div class="w-8 text-primary3Text">
                            <IconEmailSvg />
                            </div>
                            <span class="">Summary</span>
                        </button>
                        <button @click="toggleInsurancePopup" class="w-10  focus:outline-none">
                            <div class="text-backgroundText w-6">
                                <CheckoutDetailSvg />
                            </div>
                        </button>
                    </div>
                    <div class="w-16 h-1/2">
                        <div class="text-backgroundText w-8">
                            <CalculatorSvg />
                        </div>
                    </div>
                    <div>before savings<br>or discounts</div>
                    <div class="w-full flexflex-col hidden">
                        <div>includes</div>
                        <div>Exam <span class="font-semibold">$168</span></div>
                        <div>Refraction <span class="font-semibold">$45</span></div>
                    </div>
                    <div class="w-full flexflex-col hidden">
                        <div class="line-through text-xl">$168.80</div>
                        <div>*includes <span class="underline">office fees</span></div>
                    </div>
                </div> -->

            </div>
        </main>
        <!-- shadow-primary3Inner -->
        <main v-if="currentView != 'Checkout'" class="checkout-box h-2/12 w-48 absolute top-0 right-0 mt-2 border-4 border-primary1 border-r-0 flex flex-col justify-around rounded-bl-lg bg-white z-40">
            <div @click="$router.push('checkout')" class="w-full flex justify-center items-center">
                <div class="w-10 text-primary1">
                    <ShoppingCartSvg />
                </div>
                <span class="underline text-xl mb-1 ml-3">Checkout</span>
            </div>
        </main>
        <OfficeFeesPopup :toggleOfficeFees="toggleOfficeFees" v-if="showOfficeFees" />
        <EmailPopup :toggleEmailPopup="toggleEmailPopup" v-if="showEmailPopup" />
        <InsurancePopup :currentInsurancePlan="currentInsurancePlan" :selfpayDiscount="selfpayDiscount" :toggleInsurancePopup="toggleInsurancePopup"  v-if="showInsurancePopup" />
    </div>
</template>

<script>
import OfficeFeesPopup from '../../components/subcomponents/OfficeFeesPopup.vue';
import ShoppingCartSvg from "../../assets/svg/icons/shopping_cart.svg";
import CalculatorSvg from "../../assets/svg/icons/calculator_icon.svg";
import EditIconSvg from "../../assets/svg/icons/Edit_icon.svg";
import IconEmailSvg from "../../assets/svg/icons/icon_Email.svg";
import CheckoutDetailSvg from "../../assets/svg/icons/icon_CheckoutDetails.svg";
import EmailPopup from '../../components/subcomponents/EmailPopup.vue';
import InsurancePopup from '../../components/subcomponents/InsurancePopup.vue';

export default {
  name: 'CheckoutBox',
    data() {
        return {
            showOfficeFees: false,
            showEmailPopup: false,
            showInsurancePopup: false,
        };
    },
    methods: {
        toggleOfficeFees(){
            this.showOfficeFees = !this.showOfficeFees
            this.$store.dispatch('sendEvent', {
                hitType: 'event',
                eventCategory: 'button',
                eventAction: 'click',
                eventLabel: 'Office Fees Popup',
                dimension1: this.currentUser.id,
                dimension2: this.currentUser.group.id,
                dimension3: this.view
            })
        },
        toggleEmailPopup(){
            this.showEmailPopup = !this.showEmailPopup
            this.$store.dispatch('sendEvent', {
                hitType: 'event',
                eventCategory: 'button',
                eventAction: 'click',
                eventLabel: 'Toggle Email Popup',
                dimension1: this.currentUser.id,
                dimension2: this.currentUser.group.id,
                dimension3: this.view
            })
        },
        toggleInsurancePopup(){
            this.showInsurancePopup = !this.showInsurancePopup
            this.$store.dispatch('sendEvent', {
                hitType: 'event',
                eventCategory: 'button',
                eventAction: 'click',
                eventLabel: 'Insurance Popup',
                dimension1: this.currentUser.id,
                dimension2: this.currentUser.group.id,
                dimension3: this.view
            })
        },
  },
    components: {
        OfficeFeesPopup,
        ShoppingCartSvg,
        CalculatorSvg,
        EditIconSvg,
        EmailPopup,
        IconEmailSvg,
        CheckoutDetailSvg,
        InsurancePopup
    },
    props: {
        currentInsurancePlan: String,
        selfpayDiscount: Number
    },
    // created: function() {
    //     this.$store.dispatch('setView', this.view);
    // },
    computed: {
        totalPrice() {
            return this.$store.getters.getTotalPrice
        },
        discountTotal() {
            const discount = this.$store.getters.getSelfpayDiscount
            const insurance = this.$store.getters.getCurrentInsurance
            let discountPrice = 0
            if (insurance == 'selfpay' && discount > 0){
                discountPrice = this.totalPrice * (1- (discount*0.01))
            }
            return discountPrice.toFixed(2)

        },
        currentView() {
            return this.$store.getters.getView
        },
        currentUser() {
            return this.$store.getters.getUser
        },
    }
};
</script>

<style scoped>
    .demosBlock{
        @apply underline w-10/12 h-1/3 m-2 flex justify-center items-center
        bg-primary2 rounded-lg rounded-tl-none text-center text-white;
    }

</style>
