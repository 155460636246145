var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "h-12 w-48 rounded-md bg-primary3 text-primary3Text rounded-tl-none focus:outline-none disable-dbl-tap-zoom",
      on: {
        click: function($event) {
          return _vm.$emit("proceed")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "h-full w-full flex justify-center items-center" },
        [
          _c("span", { staticClass: "underline pl-4" }, [_vm._v("proceed")]),
          _c(
            "div",
            { staticClass: "text-primary3Text w-8 ml-2 mt-1" },
            [_c("ProceedIconSvg")],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }