var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "frames-details absolute bottom-0 right-0 w-11/12 h-10/12 flex flex-col pr-4"
    },
    [
      _c(
        "div",
        {
          staticClass: "h-11/12 w-full flex portrait:flex-col portrait:h-full"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "h-full w-2/12 flex flex-col portrait:h-2/12 portrait:w-full portrait:flex-row portrait:justify-around portrait:items-center portrait:border-toggleGray portrait:border-2 portrait:rounded-lg portrait:rounded-tl-none"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "font-bold mt-8 leading-7 portrait:h-1/2 portrait:mt-0"
                },
                [_vm._v(_vm._s(_vm.frame.name))]
              ),
              _c("div", { staticClass: "portrait:h-full" }, [
                _c("div", { staticClass: "leading-7" }, [
                  _vm._v(_vm._s(_vm.frame.color))
                ]),
                _c("div", { staticClass: "leading-7" }, [
                  _vm._v(_vm._s(_vm.frame.code))
                ])
              ]),
              _c("div", { staticClass: "portrait:h-full" }, [
                _c("div", { staticClass: "leading-7" }, [
                  _vm._v("$" + _vm._s(_vm.correctPrice(_vm.frame)))
                ]),
                _c("div", { staticClass: "leading-7" }, [
                  _vm._v(_vm._s(_vm.frame.size))
                ])
              ]),
              _c(
                "button",
                {
                  staticClass:
                    "flex items-center mt-6 portrait:h-full portrait:mt-0 focus:outline-none"
                },
                [
                  _c("div", { staticClass: "w-8 mr-4" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "fill-current text-primary3",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 60.53 62.52"
                        }
                      },
                      [
                        _c("g", { attrs: { "data-name": "Layer 2" } }, [
                          _c("g", [
                            _c("circle", {
                              attrs: { cx: "18.35", cy: "56.74", r: "5.78" }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M49.21 51A5.78 5.78 0 1055 56.74 5.77 5.77 0 0049.21 51zM53.67 6.07H12.56C9.57.72 8.06.67 7 .67H0V6.2h6.11a29.64 29.64 0 012.08 3.44c.31.86.76 1.91 1.32 3.16 2 4.5 7 13.64 8.48 17.16-4.47 6.65-6.85 10.72-5.16 13.89.58 1.11 1.88 2.42 4.72 2.42h37v-5.53L18 40.71a43.81 43.81 0 014.06-6.93 10.57 10.57 0 001.16.08h17.14c5 0 6.44.29 7.75-1.47s4.54-7.31 9.51-16.39 3.51-9.93-3.95-9.93z"
                              }
                            })
                          ]),
                          _c(
                            "text",
                            {
                              attrs: {
                                transform: "translate(24.13 28.95)",
                                "font-size": "33.74",
                                fill: "#fff",
                                "font-family": "Arial-BoldMT, Arial",
                                "font-weight": "700"
                              }
                            },
                            [_vm._v("x")]
                          )
                        ])
                      ]
                    )
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "underline",
                      on: {
                        click: function($event) {
                          return _vm.$emit("remove-frame")
                        }
                      }
                    },
                    [_vm._v("remove")]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "w-7/12 flex flex-col items-center portrait:w-full portrait:h-5/12 portrait:justify-between portrait:mt-3"
            },
            [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass:
                    "w-7/12 h-2/12 flex justify-between items-center portrait:w-5/12"
                },
                [
                  _c("div", { staticClass: "w-10" }, [
                    _c(
                      "svg",
                      {
                        staticClass:
                          "fill-current text-primary3 transform rotate-180",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 111.79 45.94"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M72 45.94l19.9-11.48 19.89-11.49L91.9 11.48 72 0v15.13H0v15.69h72v15.12z",
                            "data-name": "Layer 2"
                          }
                        })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "underline" }, [
                    _vm._v("change view")
                  ]),
                  _c("div", { staticClass: "w-10" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "fill-current text-primary3",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 111.79 45.94"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M72 45.94l19.9-11.48 19.89-11.49L91.9 11.48 72 0v15.13H0v15.69h72v15.12z",
                            "data-name": "Layer 2"
                          }
                        })
                      ]
                    )
                  ])
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "h-3/12 w-3/12 justify-around flex flex-col ml-1 portrait:w-full portrait:h-3/12 portrait:ml-0 portrait:items-center"
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "underline text-xl h-12 w-48 bg-primary2 rounded-lg rounded-tl-none text-white block focus:outline-none",
                  on: { click: _vm.goToLenspackages }
                },
                [_vm._v(" Continue ")]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "relative h-8/12 w-10/12 pl-full portrait:w-9/12 portrait:h-9/12"
      },
      [
        _c("img", {
          staticClass: "absolute left-0 top-0 h-full w-full object-contain",
          attrs: {
            src: require("../../assets/img/frames/frame_ImagePlaceholder.png"),
            alt: ""
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }