<template>
  <div class="about-app h-full w-full flex justify-center items-center bg-backOpacity absolute bottom-0 right-0 z-1000">
    <div class="h-8/12 w-5/12 flex flex-col  items-center border-4 border-backgroundText rounded-lg rounded-tl-none bg-background text-backgroundText portrait:w-10/12 portrait:h-6/12">
      <div class="w-full h-12 flex justify-end">
        <button @click="$store.dispatch('toggleAboutApp')" class="w-10 block focus:outline-none">
          <svg class="icon_x fill-current stroke-current text-textDark w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2"><path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"/><path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/></g></svg>
        </button>
      </div>
      <main class="h-10/12 w-full flex flex-col items-center justify-around">
        <p class="text-xl">About App</p>
        <div class="h-4/12 flex flex-col items-center justify-around">
          <div>Frames DB: <span>{{framesDate}}</span></div>
          <div>Lenses DB: <span>{{lensesDate}}</span></div>
          <div>Packages DB: <span>{{packagesDate}}</span></div>
          <div>App Version: <span>{{version}}</span></div>
        </div>
        <button class="w-5/12 h-10  bg-primary1 text-primary1Text flex justify-center items-center bg-p rounded-lg rounded-tl-none focus:outline-none focus:shadow-outline">
          <div class="w-10">
            <svg class="icon_Refresh fill-current text-primary1Text w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.7 44.7"><g id="Layer_2" data-name="Layer 2"><g id="keyboard"><path d="M42.48,20.13a2.21,2.21,0,0,0-2.22,2.22A17.89,17.89,0,0,1,10.64,35.88l2.93.23a2.22,2.22,0,0,0,.34-4.43L5.66,31a2.23,2.23,0,0,0-2.39,2l-.64,8.26a2.22,2.22,0,0,0,2,2.38h.18a2.22,2.22,0,0,0,2.2-2l.23-2.87A22.31,22.31,0,0,0,44.7,22.35,2.22,2.22,0,0,0,42.48,20.13Z"/><path d="M4.44,22.35A17.89,17.89,0,0,1,34.77,9.47l-3.14-.24a2.22,2.22,0,1,0-.35,4.42l8.26.64h.17a2.23,2.23,0,0,0,2.21-2.05L42.56,4a2.22,2.22,0,1,0-4.42-.34l-.21,2.71A22.32,22.32,0,0,0,0,22.35a2.22,2.22,0,0,0,4.44,0Z"/></g></g></svg>
          </div>
          <span @click="reloadApp" class="underline">Refresh App</span>
        </button>
        <div class="w-full flex flex-col justify-center items-center">
          <p>contact us at:</p>
          <p>support@contentlinq.com</p>
          <!-- <button class="w-5/12 h-10 text-white flex justify-center items-center bg-p rounded-lg rounded-tl-none bg-primary3 mt-2">
            <div class="w-16">
              <svg class="icon_Email fill-current text-white w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.13 68.83"><g fill="#fff" data-name="Layer 2"><path d="M37.08 68.12H1.77A1.17 1.17 0 01.72 67v-.44Q.72 48.31.71 30a1.94 1.94 0 01.87-1.62c2.86-1.87 5.69-3.77 8.54-5.65a.94.94 0 00.43-.79V2.34v-.63a1.19 1.19 0 011-1H62a4 4 0 01.6 0 1.16 1.16 0 011 1v20.21a.89.89 0 00.46.83c2.86 1.88 5.7 3.79 8.56 5.67a1.78 1.78 0 01.8 1.58v36.6a1.56 1.56 0 01-1.57 1.56zm-24.14-65v30.54c0 .25 0 .44.28.59q9.78 5.2 19.62 10.41a.43.43 0 00.53 0c.93-.63 1.87-1.23 2.79-1.87a1.58 1.58 0 011.81 0c.89.62 1.81 1.19 2.69 1.81a.7.7 0 00.74 0q9.7-5.16 19.43-10.3a.7.7 0 00.37-.62V3.12zm55.22 62.59a4.18 4.18 0 00-.4-.29L37.43 45.29a.55.55 0 00-.71 0Q21.54 55.38 6.35 65.43c-.11.07-.21.16-.36.28zm-65-34v33l27.65-18.4zm67.84 0l-27.68 14.6L71 64.67z" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.414"/><text transform="translate(23.09 30.07)" font-size="28.288" font-family="Arial-BoldMT,Arial" font-weight="700">@</text></g></svg>
            </div>
            <span @click="generateToast('Coming Soon')" class="underline">Support</span>
          </button> -->
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import { generateToast } from "../../common/functions";
export default {
  name: 'AboutApp',
  data() {
    return {
      generateToast: generateToast
    };
  },
  methods: {
    reloadApp(){
      this.$store.dispatch('setPreload', false)
      this.$store.dispatch('logout')
      .then(res =>{
        // this.$store.dispatch('toggleSideNav');
        // this.$router.push('/auth');
        location.reload(true);
      })
    }
  },
  components: {

  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION
    },
    lensesDate() {
      try {
        return this.$store.getters.getLensesDate.split('.000')[0]
      } catch (error) {
        return ''
      }

    },
    framesDate() {
      try {
        return this.$store.getters.getFramesDate.split('.000')[0]
      } catch (error) {
        return ''
      }

    },
    packagesDate() {
      try {
        return this.$store.getters.getPackagesDate.split('.000')[0]
      } catch (error) {
        return ''
      }

    }
  },
};
</script>

<style scoped>

</style>
