<template>
<!-- <button @click="$emit('back')" class="w-full flex items-center justify-center focus:outline-none"> -->
  <button @click="$emit('back')" class="h-2/12 w-full flex items-center justify-center focus:outline-none">
  <div class="text-lightGrayText w-10">
    <BackArrowSvg />
  </div>
    <!-- <svg class="arrow_back fill-current text-lightGrayText w-10" :style="{color: color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.19 84.19">
      <g data-name="Layer 2">
        <path d="M42.09 84.19a42.1 42.1 0 1142.1-42.1 42.14 42.14 0 01-42.1 42.1z" fill="none" />
        <path d="M48.41 68.73a4 4 0 01-2.83-1.18L23 44.92a4 4 0 010-5.65l22.58-22.64a4 4 0 015.66 5.66l-19.81 19.8L51.24 61.9a4 4 0 01-2.83 6.83z" />
      </g>
    </svg> -->
  </button>
</template>
<script>
import BackArrowSvg from "../../assets/svg/icons/back_arrow.svg";

export default {
  name: 'BackButton',
  props: {
    color:String,
  },
  components: {
    BackArrowSvg
  }
}
</script>