var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "progress-bar h-full w-full flex justify-center items-center fixed  top-0 left-0 bg-background text-backgroundText z-110"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "w-7/12 h-7/12  flex flex-col justify-center items-center"
        },
        [
          _vm.pageName == "lensType" && _vm.currentPatient.user
            ? _c("p", { staticClass: "mb-10 font-thin text-3xl" }, [
                _vm._v(
                  "Creating " +
                    _vm._s(_vm.currentPatient.user.name) +
                    "'s Optical Treatment Plan"
                )
              ])
            : _vm.pageName == "lensType"
            ? _c("p", { staticClass: "mb-10 font-thin text-3xl" }, [
                _vm._v("Creating Your Optical Treatment Plan")
              ])
            : _vm._e(),
          _vm.pageName == "measureyes"
            ? _c(
                "div",
                { staticClass: "mb-10 font-thin text-3xl text-center" },
                [
                  _c("p", [_vm._v("Calculating measurements")]),
                  _c("p", [_vm._v("for a perfect fit")])
                ]
              )
            : _vm._e(),
          _c("img", {
            attrs: {
              src: require("../../assets/img/square_loading.gif"),
              alt: ""
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }