import Vue from 'vue';
import VueRouter from 'vue-router';
import LandingPage from '../views/LandingPage.vue';
import Auth from '../views/Auth.vue';
import Frames from '../views/Frames.vue';
import LifeStyle from '../views/LifeStyle.vue';
import LensType from '../views/LensType.vue';
import VisionPlan from '../views/VisionPlan.vue';
import LensPackage from '../views/LensPackage.vue';
import Checkout from '../views/Checkout.vue';
import NewFrames from '../views/NewFrames.vue';
import ErrorPage from '../views/404.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    props: {view: "Auth"}
  },
  // {
  //   path: '/newframes',
  //   name: 'NewFrames',
  //   component: NewFrames,
  //   props: {view: "NewFrames"}
  // },
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    props: {view: "LandingPage"}
  },
  {
    path: '/frames',
    name: 'frames',
    component: NewFrames,
    props: {view: "Frames"}
  },
  {
    path: '/lifestyle',
    name: 'lifestyle',
    component: LifeStyle,
    props: {view: "LifeStyle"}
  },
  {
    path: '/lenstype',
    name: 'lenstype',
    component: LensType,
    props: {view: "LensType"}
  },
  {
    path: '/visionplan',
    name: 'visionplan',
    component: VisionPlan,
    props: {view: "VisionPlan"}
  },
  {
    path: '/lenspackage',
    name: 'lenspackage',
    component: LensPackage,
    props: {view: "LensPackage"}
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
    props: {view: "Checkout"}
  },
  {
    path: '*',
    name: 'Not Found',
    component: ErrorPage,
    props: {view: 'ErrorPage'}
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
