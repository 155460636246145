<template>
    <div class="h-10/12 bg-background text-backgroundText flex flex-col">
        <header class="h-2/12 w-full flex justify-center items-center font-bold text-xl">Let's get started!</header>
        <main class="w-full h-10/12 mb-10">
            <div class="w-full h-6/12 bg-primary2 flex justify-around items-center">
                <button @click="openDemo" class="tiles w-3/12 h-10/12 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline">
                    <div class="w-20 h-18 ml-2 flex justify-center items-center">
                        <div class="w-20">
                            <LensIconSvg />
                        </div>
                    </div>
                    <div class="h-10 flex justify-center items-center text-lg">Lens Features</div>
                </button>
                <button @click="openPhotobooth" class="tiles w-3/12 h-10/12 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline">
                    <div class="w-20 h-18 flex justify-center items-center">
                        <div class="w-18">
                            <PhotoBoothSvg />
                        </div>
                    </div>
                    <div class="h-10 flex justify-center items-center text-lg">Photobooth</div>
                </button>
                <button @click="openMeasureyes" class="relative tiles w-3/12 h-10/12 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline">
                    <div class="w-24 h-16 flex justify-center items-center">
                        <div class="w-24">
                            <MeasureyesSvg />
                        </div>
                    </div>
                    <div class="h-10 flex justify-center items-center text-lg">Measureyes</div>
                    <div class="w-20 h-6 bg-primary3 text-primary3Text absolute top-0 left-0 flex justify-center items-center transform -rotate-25 mt-6 ml-2 font-medium">BETA</div>
                </button>
            </div>

            <div class="w-full h-6/12 bg-primary3 flex justify-center items-center">
                <button @click="openAppointments" class="tiles w-4/12 h-10/12 mr-6 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline">
                    <div class="w-20 h-18 flex justify-center items-center">
                        <div class="w-18">
                            <ImportCustomerSvg />
                        </div>
                    </div>
                    <div v-if="currentPatient.user" class="h-10 flex justify-center items-center text-lg">Hi {{currentPatient.user.name}}</div>
                    <div v-else-if="appointments.length > 0" class="h-10 flex justify-center items-center text-lg">Import Customer</div>
                    <div v-else class="h-10 flex justify-center items-center text-lg">No Appointments</div>
                </button>
                <button @click="eyewearBuilder" :class="{disabled: !databasesLoaded}" class="tiles w-4/12 h-10/12 flex flex-col items-center justify-center focus:outline-none focus:shadow-outline">
                    <div class="w-24 h-16 flex justify-center items-center">
                        <div class="w-24">
                            <EyewearBuilderSvg />
                        </div>
                    </div>
                    <div class="h-10 flex justify-center items-center text-lg">Eyewear Builder</div>
                    <div v-if="!databasesLoaded" class="h-10 flex justify-center items-center text-lg">Databases Loading...</div>
                </button>
            </div>
        </main>
    </div>
</template>

<script>
import LensIconSvg from "../assets/svg/demo_icons/icon_Demos.svg";
import PhotoBoothSvg from "../assets/svg/icons/icon_PhotoBooth.svg";
import MeasureyesSvg from "../assets/svg/icons/measureyes_icon.svg";
import ImportCustomerSvg from "../assets/svg/icons/icon_ImportCustomer.svg";
import EyewearBuilderSvg from "../assets/svg/icons/icon_EyewearBuilder.svg";

export default {
  name: 'LandingPage',
  data(){
    return{

    }
  },
  methods: {

    openPhotobooth() {
      this.clvm = document.getElementById('clvm');
      this.clvm.style.display = "block";



      if(this.currentPatient.user != null){
        var patientEmail = this.currentPatient.user.email;
        this.clvm.setAttribute("data-id", patientEmail);
      }

      if(this.showDemo == true){
        this.$store.dispatch('toggleDemo')
      }

      if(this.showDemo == true){
        this.clvm.setAttribute("style", "display: none");
      }
      scope.virtualMirror = new CLVirtualMirror();
      this.$store.dispatch('sendEvent', {
          hitType: 'event',
          eventCategory: 'button',
          eventAction: 'click',
          eventLabel: 'Open Photobooth',
          dimension1: this.currentUser.id,
          dimension2: this.currentUser.group.id,
          dimension3: this.view
        })
    },
    openMeasureyes() {
        this.$store.dispatch('toggleMeasureEyez');
        this.$store.dispatch('sendEvent', {
          hitType: 'event',
          eventCategory: 'button',
          eventAction: 'click',
          eventLabel: 'Open Measureyes',
          dimension1: this.currentUser.id,
          dimension2: this.currentUser.group.id,
          dimension3: this.view
        })
    },
    openDemo() {
        this.$store.dispatch('toggleDemo');
        this.$store.dispatch('sendEvent', {
          hitType: 'event',
          eventCategory: 'button',
          eventAction: 'click',
          eventLabel: 'Open Demo',
          dimension1: this.currentUser.id,
          dimension2: this.currentUser.group.id,
          dimension3: this.view
        })
    },
    eyewearBuilder() {
        this.$router.push('/lifestyle');
        this.$store.dispatch('sendEvent', {
            hitType: 'event',
            eventCategory: 'button',
            eventAction: 'click',
            eventLabel: 'Continue to Lifestyle page',
            dimension1: this.currentUser.id,
            dimension2: this.currentUser.group.id,
            dimension3: this.view
        })
    },
    openAppointments() {
        this.$store.dispatch('getPreAppointment')
        this.$store.dispatch('toggleAppointment');
        this.$store.dispatch('sendEvent', {
          hitType: 'event',
          eventCategory: 'button',
          eventAction: 'click',
          eventLabel: 'Open Appointment Popup',
          dimension1: this.currentUser.id,
          dimension2: this.currentUser.group.id,
          dimension3: this.view
        })
    },

  },
  created() {
    this.$store.dispatch('setView', this.view);
  },
  props: {

  },

  components: {
    LensIconSvg,
    PhotoBoothSvg,
    MeasureyesSvg,
    ImportCustomerSvg,
    EyewearBuilderSvg,
    // AppointmentPopup
  },
  computed: {
    showDemo() {
      return this.$store.getters.getDemoToggle;
    },
    currentUser() {
      return this.$store.getters.getUser
    },
    showMeasureEyez(){
      return this.$store.getters.getMeasureEyez;
    },
    currentPatient(){
      return this.$store.getters.getCurrentPatient
    },
    appointments() {
        return this.$store.getters.getAppointments
    },
    databasesLoaded(){
      // const lensesDate = this.$store.getters.getLensesDate
      // const packagesDate = this.$store.getters.getPackagesDate
      // const framesDate = this.$store.getters.getFramesDate

      // if (lensesDate && packagesDate && framesDate){
      //   return true
      // }
      // return false
      const isDownloading = this.$store.getters.getIsDownloading
      if (!isDownloading.frames && !isDownloading.lenses && !isDownloading.packages){
        return true
      }
      return false
    },
    showAppointment() {
      return this.$store.getters.getAppointmentToggle;
    },
  }
};
</script>

<style scoped>
    .tiles {
        @apply rounded-2xl rounded-tl-md bg-background text-backgroundText;
    }
    .disabled {
      @apply opacity-75 pointer-events-none;
    }
</style>
