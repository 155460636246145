var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass:
        "topNav h-1/12 w-full min-h-12 bg-primary1 text-primary1Text top-0 flex items-center justify-between px-4 portrait:w-full"
    },
    [
      _vm.currentView != "Auth"
        ? _c(
            "div",
            { staticClass: "sideNav landscape:w-full flex items-center " },
            [
              _c(
                "button",
                {
                  staticClass: "sideNav block focus:outline-none",
                  on: { click: _vm.openSidNav }
                },
                [
                  _vm.showSideNav
                    ? _c(
                        "div",
                        { staticClass: "sideNav w-10" },
                        [_c("HamburgerXSvg")],
                        1
                      )
                    : _vm._e(),
                  !_vm.showSideNav
                    ? _c(
                        "div",
                        { staticClass: "sideNav w-10" },
                        [_c("HamburgerSvg")],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "p",
                {
                  staticClass:
                    "leading-tight text-center pl-2 landscape:pl-6 landscape:text-xl"
                },
                [_vm._v(_vm._s(_vm.practiceName))]
              )
            ]
          )
        : _vm._e(),
      _vm.currentView != "Auth" && _vm.showDemo == false
        ? _c(
            "a",
            { staticClass: "underline", on: { click: _vm.resetButton } },
            [_vm._v("Reset")]
          )
        : _vm._e(),
      _vm.showDemo
        ? _c(
            "div",
            { staticClass: "flex items-center", on: { click: _vm.closeDemo } },
            [
              _c(
                "span",
                {
                  staticClass:
                    "underline float-right inline whitespace-no-wrap mr-6 text-primary1Text"
                },
                [_vm._v("Exit Lens Demos")]
              ),
              _c(
                "button",
                { staticClass: "w-6 focus:outline-none " },
                [_c("DemoExit", { staticClass: "w-8 text-primary1Text" })],
                1
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }