import axios from 'axios'
const https = require('https');
import router from '../../router/index'
// import * as d3 from 'd3-fetch'
const transformRequest = (jsonData = {}) =>
  Object.entries(jsonData)
    .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join('&');

function initialState() {
  // localStorage.clear()
  return {
    isLoggedIn: false, 
    token: localStorage.getItem('token') || '',
    currentUser: JSON.parse(localStorage.getItem('user')) || {}
  }
}

function createCORSRequest(method, url) {
  var xhr = new XMLHttpRequest();
  if ("withCredentials" in xhr) {
      // XHR for Chrome/Firefox/Opera/Safari.
      xhr.open(method, url, true);
  } else if (typeof XDomainRequest != "undefined") {
      // XDomainRequest for IE.
      xhr = new XDomainRequest();
      xhr.open(method, url);
  } else {
      // CORS not supported.
      xhr = null;
  }
  return xhr;
};

const state = initialState

const mutations = {
  login(state, cred){
    state.isLoggedIn = true
    state.currentUser = cred.data
    state.token = cred.headers.authorization
    localStorage.setItem('token', state.token)
    localStorage.setItem('user', JSON.stringify(state.currentUser))
  },
  logout(state, rootState){
    state.isLoggedIn = false
    state.currentUser = {}
    state.token = ''
    localStorage.setItem('token', state.token)
    localStorage.setItem('user', JSON.stringify(state.currentUser))
    router.push('/auth');
    // location.reload(true)
    // localStorage.setItem('isLoggedIn', state.isLoggedIn)
  },
  clear_auth(state) {
    // localStorage.clear()
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = {
  
  login({commit, rootState}, loginCred){
    return new Promise((resolve, reject) => {
      axios.post(rootState.lifestyle.baseUrl + 'api/auth/login', loginCred, {
        transformRequest: loginCred => transformRequest(loginCred),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(response => {
        commit('login', response)
        commit('send_event', {
          hitType: 'event',
          eventCategory: 'button',
          eventAction: 'click',
          eventLabel: 'Login Success',
          dimension1: response.data.id,
          dimension2: response.data.group.id
        })
        axios.defaults.headers.common = {'Authorization': 'Bearer ' + response.headers.authorization}
        resolve({auth: 'success'})
      })
      .catch(err =>{
        console.log(err)
        commit('send_event', {
          hitType: 'event',
          eventCategory: 'button',
          eventAction: 'click',
          eventLabel: 'Login Failure',
        })
        reject({auth: 'failure'})
      })
    })
  },
  logout({commit}){
    return new Promise((resolve) => {
      
      commit('send_event', {
        hitType: 'event',
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'Logout',
      })
      commit('clear_auth')
      commit('clear_toggles')
      commit('clear_cart')
      commit('clear_packages')
      commit('clear_lifestyle')
      commit('clear_frame_shapes')
      commit('set_current_patient', {})
      // commit('clear_databases')
      window.sessionStorage.clear();
      // location.reload();
      commit('logout')
      resolve({logout: 'success'})
    })
  },
  setAuthToken({commit, state}){
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + state.token}
  },
  clearAuth({commit}){
    commit('clear_auth')
  }


}

const getters = {
  getLoginStatus: state => state.isLoggedIn,
  getUser: state => state.currentUser,
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
