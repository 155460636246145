<template>
    <!-- <div class="email-popup"> -->
    <div class="apponitment-popup h-full w-full flex justify-center items-center bg-backOpacity absolute top-0 z-1000">
        <div class="h-9/12 w-8/12 flex flex-col items-center border-4 border-backgroundText rounded-lg rounded-tl-none bg-background text-backgroundText portrait:w-10/12 portrait:h-8/12">
            <div class="w-full h-2/12 flex justify-center relative">
                <div class="w-8 absolute top-0 right-0 mt-3">
                    <button @click="$store.dispatch('toggleAppointment')" class="w-10 block focus:outline-none">
                        <svg class="icon_x fill-current stroke-current text-textDark w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.86 73.8"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.85" fill-rule="evenodd" data-name="Layer 2"><path d="M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"/><path d="M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"/></g></svg>
                    </button>
                </div>
                <div class="h-full text-xl font-semibold flex flex-col justify-center items-center">
                    <p>Import Customer</p>
                </div>
            </div>
            <main class="h-10/12 w-full flex flex-col items-center justify-around">
                <!-- <div class="w-full ">
                    <input v-model="userName" placeholder="Search by Name" class="inputStyle focus:outline-none focus:shadow-outline">
                </div> -->
                <div class="h-3/12 w-9/12 flex flex-col">
                    <p class="mb-3">Search by email or patient name</p>
                    <div class="h-12 w-full flex relative rounded-md rounded-tl-none">
                        <input type="text" v-model="searchQuery" placeholder="Email or patient name" class="inputStyle focus:outline-none focus:shadow-outline">
                        <button>
                            <div class="w-8 absolute top-0 right-0 mt-4 mr-3">
                                <IconSearchSvg />
                            </div>
                        </button>
                    </div>
                </div>
                <div class="h-9/12 w-11/12">
                    <!-- <div class="h-full"> -->
                    <div class="w-full h-10 flex mt-6 border-toggleGray border-b-2 portrait:mt-0">
                        <div class="w-4/12">NAME</div>
                        <div class="w-5/12">EMAIL</div>
                        <div class="w-3/12">UPDATED</div>
                    </div>
                    <div class="h-5/6 overflow-auto">
                        <div v-for="appointment in appointmentResults" @click="setPatient(appointment.id)" :key="appointment.id" class="w-full flex items-center h-14 border-toggleGray border-b-2 ">
                            <div class="w-4/12">{{appointment.data.user.name}}</div>
                            <div class="w-5/12">{{appointment.data.user.email}}</div>
                            <div class="w-3/12">{{formatDate(appointment.data.user.time)}}</div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import IconSearchSvg from "../../assets/svg/icons/icon_Search.svg";


export default {
  name: 'ApponitmentPopup',
    data() {
        return{

            searchResult: false,
            searchQuery: ''
        }
    },
    components: {
        IconSearchSvg,
    },
    methods: {
        setPatient(index){
            this.$store.dispatch('setCurrentPatient', index)
            this.$store.dispatch('toggleAppointment')
            this.$router.push('/lifestyle');
        },
        formatDate(date){
            let appDate = new Date(date)
            return appDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })
        }
    },
    computed: {
        appointments() {
            return this.$store.getters.getAppointments
        },
        appointmentResults(){
            if (this.searchQuery){
                return this.appointments.filter(item => {
                    return this.searchQuery.toLowerCase().split(" ").every(
                        v =>
                            item.data.user.email.toString().toLowerCase().includes(v) ||
                            item.data.user.name.toString().toLowerCase().includes(v)
                    )
                })
            }
            else {
                return []
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .inputStyle {
    @apply w-full h-14 pl-4 border-primary1 border-2 rounded-md rounded-tl-none bg-background text-backgroundText;
  }
</style>
