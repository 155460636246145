import Vue from 'vue';
import Vuex from 'vuex';
import toggles from './modules/toggles'
import auth from './modules/auth'
import cart from './modules/cart'
import packages from './modules/packages'
import lifestyle from './modules/lifestyle'
import analytics from './modules/analytics'
import databases from './modules/databases'
import basecamp from './modules/basecamp'
import createPersistedState from "vuex-persistedstate";
import router from '../router/index'
import frameshapes from './modules/frameshapes';

Vue.use(Vuex);

export default new Vuex.Store({
  // plugins: [createPersistedState({
  //   storage: window.sessionStorage,
  // })],
  actions: {
    clearState({commit}){
      router.push('/')
      commit('clear_toggles')
      commit('clear_cart')
      commit('clear_packages')
      commit('clear_lifestyle')
      commit('clear_frame_shapes')
      commit('set_current_patient', {})
      // window.sessionStorage.clear();
      // location.reload();
    },
    reload(){
      location.reload();
    }
  },
  modules: {
    toggles,
    auth,
    cart,
    packages,
    lifestyle,
    analytics,
    databases,
    basecamp,
    frameshapes,
  },
});
