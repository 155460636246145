var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-wrapper" }, [
      _c("div", { staticClass: "header" }, [
        _vm._v("We're getting ready for you! Stay tuned...")
      ]),
      _vm._m(0),
      _c("div", { on: { click: _vm.reloadApp } }, [
        _vm._v("You're connected to the internet, right? Just checking...")
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loadingio-spinner-rolling-gewnlysirl5" }, [
      _c("div", { staticClass: "ldio-h0k0se42qt5" }, [_c("div")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }