var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "checkout bg-background text-backgroundText h-10/12 relative overflow-y-scroll",
      attrs: { id: "checkout" }
    },
    [
      _c("Navigation"),
      _c("CheckoutBox", {
        attrs: {
          currentInsurancePlan: _vm.currentInsurancePlan,
          selfpayDiscount: _vm.selfpayDiscount
        }
      }),
      _c(
        "main",
        {
          staticClass:
            "h-10/12 flex-col flex relative border-t-4 border-primary1"
        },
        [
          _c("PlansButton"),
          _c(
            "div",
            {
              staticClass:
                "h-full w-full flex landscape:mb-2 portrait:flex-col portrait:h-full"
            },
            [
              _c(
                "section",
                {
                  staticClass:
                    "w-4/12 pl-16 pb-3 flex flex-col bg-lightGray rounded-lg portrait:w-full portrait:h-5/12 overflow-y-auto",
                  class: { "h-40": _vm.checkAllPlans }
                },
                [
                  _c("div", { staticClass: "h-3/12 portrait:h-4/12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full pr-4 flex items-baseline portrait:w-5/12"
                      },
                      [
                        _vm.currentPatient.user
                          ? _c("div", [
                              _vm.currentIndex == "everyday"
                                ? _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "h-12 w-36 flex items-center font-bold text-lg mr-8 leading-6"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentPatient.user.name) +
                                          "'s Daily Pair"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentIndex == "polarized"
                                ? _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "h-12 w-36 flex items-center font-bold text-lg mr-8 leading-6"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentPatient.user.name) +
                                          "'s Sun Pair"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentIndex == "computer"
                                ? _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "h-12 w-36 flex items-center font-bold text-lg mr-4 leading-6"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentPatient.user.name) +
                                          "'s Computer Pair"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _c("div", [
                              _vm.currentIndex == "everyday"
                                ? _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "h-12 w-36 flex items-center font-bold text-lg mr-8 leading-6"
                                    },
                                    [_vm._v("Your Daily Pair")]
                                  )
                                : _vm._e(),
                              _vm.currentIndex == "polarized"
                                ? _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "h-12 w-36 flex items-center font-bold text-lg mr-8 leading-6"
                                    },
                                    [_vm._v("Your Sun Pair")]
                                  )
                                : _vm._e(),
                              _vm.currentIndex == "computer"
                                ? _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "h-12 w-36 flex items-center font-bold text-lg mr-4 leading-6"
                                    },
                                    [_vm._v("Your Computer Pair")]
                                  )
                                : _vm._e()
                            ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-8 h-12 flex justify-center items-center"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-6 text-backgroundText" },
                              [_c("CheckboxSelectedSvg")],
                              1
                            )
                          ]
                        )
                      ]
                    ),
                    _c("div", [
                      _c("span", { staticClass: "text-lg font-semibold" }, [
                        _vm._v("Price: ")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "font-semibold mr-2",
                          class: {
                            "line-through":
                              _vm.selfpayDiscount != 0 ||
                              _vm.currentInsurancePlan != "selfpay"
                          }
                        },
                        [_vm._v("$" + _vm._s(_vm.currentPairPrice) + "*")]
                      ),
                      _vm.discountPairPrice > 0
                        ? _c("span", [
                            _vm._v("$" + _vm._s(_vm.discountPairPrice))
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "portrait:w-full pr-8" }, [
                    _c("div", [
                      !_vm.checkAllPlans
                        ? _c(
                            "h1",
                            { staticClass: "text-xl mb-2 portrait:mt-3" },
                            [_vm._v("Don't forget:")]
                          )
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "portrait:flex items-center" }, [
                      _vm.lifestylePlan.everyday &&
                      !_vm.lifestylePlan.everyday.status
                        ? _c("div", [
                            _c(
                              "h2",
                              { staticClass: "mt-4 mb-2 portrait:mt-0" },
                              [_vm._v("Daily Pair:")]
                            ),
                            _c("p", { staticClass: "leading-5" }, [
                              _vm._v(
                                "Improve your comfort and overall vision experience with a premium vision lens, complete with an anti-reflective coating."
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.lifestylePlan.polarized &&
                      !_vm.lifestylePlan.polarized.status
                        ? _c("div", [
                            _c(
                              "h2",
                              { staticClass: "mt-4 mb-2 portrait:mt-0" },
                              [_vm._v("Sun Pair:")]
                            ),
                            _c("p", { staticClass: "leading-5" }, [
                              _vm._v(
                                "Polarization offers superior sun protection for your eyes and reduces glare outdoors."
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.lifestylePlan.computer &&
                      !_vm.lifestylePlan.computer.status
                        ? _c("div", [
                            _c(
                              "h2",
                              { staticClass: "mt-4 mb-2 portrait:mt-0" },
                              [_vm._v("Computer Pair:")]
                            ),
                            _c("p", { staticClass: "leading-5" }, [
                              _vm._v(
                                "Rest your eyes from the strain of screens with blue light blocking glasses specific for your daily digital usage."
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              ),
              _c(
                "section",
                {
                  staticClass:
                    "w-8/12 h-10/12 flex self-end portrait:pl-12 portrait:h-7/12 portrait:w-full portrait:mt-2"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-full w-6/12 flex flex-col items-center portrait:h-ful"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-9/12 h-2/12 flex relative items-center"
                        },
                        [
                          _vm.currentFrame.name != "Customer Supplied"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "h-full w-full absolute top-0 left-0 flex items-baseline"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "h-4",
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push("frames")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "Edit_icon fill-current text-textDark w-5 mr-2"
                                        },
                                        [_c("EditIconSvg")],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "h-4 flex items-center font-semibold text-lg"
                                    },
                                    [_vm._v("Frames:")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "h-4 flex flex-col ml-2" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "font-semibold",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(_vm.currentFramePrice) +
                                              "*"
                                          )
                                        ]
                                      ),
                                      _vm.discountFramePrice > 0
                                        ? _c("span", [
                                            _vm._v(
                                              "$" +
                                                _vm._s(_vm.discountFramePrice)
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.currentFrame.name == "Customer Supplied"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "h-full w-full flex flex-col absolute top-0 left-0"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        " flex items-center font-semibold text-lg"
                                    },
                                    [_vm._v("Frames: No Charge")]
                                  ),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.currentFrame.name))
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm.currentFrame.name != "Customer Supplied"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "relative h-2/12 w-8/12 pl-full -ml-16 mb-3 portrait:mt-2 portrait:full portrait:h-3/12 portrait:-ml-10"
                            },
                            [
                              _vm.currentFrame.images &&
                              _vm.currentFrame.images[0].image
                                ? _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src: _vm.currentFrame.images[0].image,
                                      alt: ""
                                    }
                                  })
                                : _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src: require("../assets/img/frames/frame_ImagePlaceholder.png"),
                                      alt: ""
                                    }
                                  })
                            ]
                          )
                        : _vm._e(),
                      _vm.currentFrame.name != "Customer Supplied"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-9/12 h-4/12 overflow-auto portrait:h-6/12"
                            },
                            [
                              _vm.currentFrame.name != "Customer Supplied"
                                ? _c("div", {}, [
                                    _vm._v(_vm._s(_vm.currentFrame.name))
                                  ])
                                : _vm._e(),
                              _vm.currentFrame.name != "Customer Supplied"
                                ? _c(
                                    "div",
                                    { staticClass: "portrait:h-full" },
                                    [
                                      _c("div", {}, [
                                        _vm._v(_vm._s(_vm.currentFrame.color))
                                      ]),
                                      _c("div", {}, [
                                        _vm._v(_vm._s(_vm.currentFrame.code))
                                      ]),
                                      _c("div", { staticClass: "l" }, [
                                        _vm._v(_vm._s(_vm.currentFrame.size))
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "w-9/12 flex items-end" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "relative w-40 h-28 rounded-2xl rounded-tl-md flex flex-col items-center justify-center portrait:h-24 portrait:w-32 portrait:mt-0 focus:outline-none",
                            class: {
                              measureEyezBtnEveryday:
                                _vm.currentIndex == "everyday",
                              measureEyezBtnSun:
                                _vm.currentIndex == "polarized",
                              measureEyezBtnWork: _vm.currentIndex == "computer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openMeasureEyez()
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-20 mt-4 portrait:w-16" },
                              [_c("MeasureyesSvg")],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "underline text-lg mt-1 portrait:mt-2"
                              },
                              [_vm._v("Measureyes")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-18 h-6 bg-primary3 text-primary3Text border-2 border-primary3Text absolute top-0 left-0 flex justify-center items-center transform -rotate-25 mt-2 -ml-2 font-medium portrait:mt-1"
                              },
                              [_vm._v("BETA")]
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-full w-6/12 flex flex-col justify-center  pl-10 portrait:pl-0 portrait:h-full"
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "w-9/12 h-14 flex items-center" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "h-full w-full flex items-baseline"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "h-4",
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push("lenspackage")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "Edit_icon fill-current text-textDark w-5 mr-2"
                                      },
                                      [_c("EditIconSvg")],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "h-4 flex items-center font-semibold text-lg"
                                  },
                                  [_vm._v("Lenses:")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "h-4 flex flex-col ml-2" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "font-semibold",
                                        class: {
                                          "line-through":
                                            _vm.selfpayDiscount != 0 ||
                                            _vm.currentInsurancePlan !=
                                              "selfpay"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "$" +
                                            _vm._s(_vm.currentLensPrice) +
                                            "*"
                                        )
                                      ]
                                    ),
                                    _vm.discountLensPrice > 0
                                      ? _c("span", [
                                          _vm._v(
                                            "$" + _vm._s(_vm.discountLensPrice)
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "w-full flex flex-col" },
                          [
                            _c("div", { staticClass: "flex w-full" }, [
                              _vm.currentPackage.base.level
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.currentPackage.base.level)
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentPackage.base.brandDescText
                                      ) +
                                        " " +
                                        _vm._s(_vm.currentPackage.base.lensType)
                                    )
                                  ])
                            ]),
                            _c("div", { staticClass: "w-full flex" }),
                            _vm._l(_vm.currentPackage.addons, function(addon) {
                              return _c(
                                "div",
                                { key: addon.id, staticClass: "w-full flex" },
                                [
                                  _c("span", {}, [
                                    _vm._v(_vm._s(addon.brandDescText))
                                  ])
                                ]
                              )
                            }),
                            _vm.currentPackage.transition.brandDescText
                              ? _c("div", { staticClass: "w-full flex" }, [
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentPackage.transition
                                          .brandDescText
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.currentPackage.transition.brandDescText &&
                            _vm.currentPackage.color.brandDescText
                              ? _c("div", { staticClass: "w-full flex" }, [
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentPackage.color.brandDescText
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.currentPackage.polarized.brandDescText
                              ? _c("div", { staticClass: "w-full flex" }, [
                                  _c("span", {}, [
                                    _vm._v(
                                      "Polarized " +
                                        _vm._s(
                                          _vm.currentPackage.polarized
                                            .brandDescText
                                        )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.currentPackage.mirror.price
                              ? _c("div", { staticClass: "w-full flex" }, [
                                  _c("span", {}, [
                                    _vm._v(
                                      "Mirror Color " +
                                        _vm._s(_vm.currentPackage.mirror.name)
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      ]),
                      _vm.currentPackage.enchancements.length > 0
                        ? _c("div", { staticClass: "mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "w-9/12 h-14 flex items-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-full w-full flex items-baseline"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "h-4",
                                        on: { click: _vm.showCustomize }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "Edit_icon fill-current text-textDark w-5 mr-2"
                                          },
                                          [_c("EditIconSvg")],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "h-4 flex items-center font-semibold text-lg"
                                      },
                                      [_vm._v("Treatments:")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "h-4 flex flex-col ml-2" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-semibold",
                                            class: {
                                              "line-through":
                                                _vm.selfpayDiscount != 0 ||
                                                _vm.currentInsurancePlan !=
                                                  "selfpay"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "$" +
                                                _vm._s(
                                                  _vm.currentTreatmentPrice
                                                ) +
                                                "*"
                                            )
                                          ]
                                        ),
                                        _vm.discountTreatmentPrice > 0
                                          ? _c("span", [
                                              _vm._v(
                                                "$" +
                                                  _vm._s(
                                                    _vm.discountTreatmentPrice
                                                  )
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "w-full flex flex-col" },
                              _vm._l(_vm.currentPackage.enchancements, function(
                                enchancement
                              ) {
                                return _c("div", { key: enchancement.id }, [
                                  _vm._v(_vm._s(enchancement.lensType) + " "),
                                  enchancement.selectedColor
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            enchancement.selectedColor
                                              .brandDescText
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }