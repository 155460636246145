var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "customize bg-background text-backgroundText absolute bottom-0 right-0 w-11/12 h-full flex flex-col pr-4 portrait:pl-10 portrait:h-full"
    },
    [
      _vm.currentPatient.user
        ? _c("div", [
            _vm.currentMod == ""
              ? _c(
                  "h1",
                  { staticClass: "h-12 flex items-center font-bold text-xl" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.currentPatient.user.name) +
                        "'s Daily Pair "
                    )
                  ]
                )
              : _vm._e(),
            _vm.currentMod == "polarized"
              ? _c(
                  "h1",
                  { staticClass: "h-12 flex items-center font-bold text-xl" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.currentPatient.user.name) +
                        "'s Sun Pair "
                    )
                  ]
                )
              : _vm._e(),
            _vm.currentMod == "computer"
              ? _c(
                  "h1",
                  { staticClass: "h-12 flex items-center font-bold text-xl" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.currentPatient.user.name) +
                        "'s Computer Pair "
                    )
                  ]
                )
              : _vm._e()
          ])
        : _c("div", [
            _vm.currentMod == ""
              ? _c(
                  "h1",
                  { staticClass: "h-12 flex items-center font-bold text-xl" },
                  [_vm._v(" Your Daily Pair ")]
                )
              : _vm._e(),
            _vm.currentMod == "polarized"
              ? _c(
                  "h1",
                  { staticClass: "h-12 flex items-center font-bold text-xl" },
                  [_vm._v(" Your Sun Pair ")]
                )
              : _vm._e(),
            _vm.currentMod == "computer"
              ? _c(
                  "h1",
                  { staticClass: "h-12 flex items-center font-bold text-xl" },
                  [_vm._v(" Your Computer Pair ")]
                )
              : _vm._e()
          ]),
      _c("div", { staticClass: "h-10/12 w-full flex" }, [
        _c(
          "div",
          {
            staticClass:
              "h-10/12 w-full flex portrait:flex-col portrait:h-full overflow-y-auto"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "w-7/12 h-11/12 portrait:w-full portrait:h-1/2 portrait:overflow-y-auto flex flex-wrap"
              },
              _vm._l(_vm.currentOptions, function(option) {
                return _c(
                  "div",
                  { key: option.id, staticClass: "w-1/2 h-14 flex items-end" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "h-10 w-10 border-2 border-toggleGray rounded-lg rounded-tl-none",
                        class: {
                          "bg-primary3":
                            _vm.currentPackage.enchancements.findIndex(function(
                              elem
                            ) {
                              return elem.code == option.code
                            }) >= 0
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggleOption(option)
                          }
                        }
                      },
                      [
                        _vm.currentPackage.enchancements.findIndex(function(
                          elem
                        ) {
                          return elem.code == option.code
                        }) < 0
                          ? _c(
                              "div",
                              {
                                staticClass: "flex justify-center items-center"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-8" },
                                  [_c("CheckboxBlankSvg")],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.currentPackage.enchancements.findIndex(function(
                          elem
                        ) {
                          return elem.code == option.code
                        }) >= 0
                          ? _c(
                              "div",
                              {
                                staticClass: "flex justify-center items-center"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-primary3Text w-8" },
                                  [_c("CheckboxSelectedSvg")],
                                  1
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _c("label", { staticClass: "underline ml-4" }, [
                      _vm._v(_vm._s(option.lensType) + " "),
                      _c("span", [_vm._v("+$" + _vm._s(option.price))])
                    ])
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "w-5/12 h-11/12 portrait:w-full portrait:h-1/2" },
              _vm._l(_vm.currentColorOptions, function(option) {
                return _c(
                  "div",
                  {
                    key: option.id,
                    staticClass: "w-full flex h-14 items-center"
                  },
                  [
                    _c("div", { staticClass: "w-1/2 h-full flex items-end" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-10 w-10 border-2 border-toggleGray rounded-lg rounded-tl-none",
                          class: {
                            "bg-primary3":
                              _vm.currentPackage.enchancements.findIndex(
                                function(elem) {
                                  return elem.code == option.code
                                }
                              ) >= 0
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleOption(option)
                            }
                          }
                        },
                        [
                          _vm.currentPackage.enchancements.findIndex(function(
                            elem
                          ) {
                            return elem.code == option.code
                          }) < 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-center items-center"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "w-8" },
                                    [_c("CheckboxBlankSvg")],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.currentPackage.enchancements.findIndex(function(
                            elem
                          ) {
                            return elem.code == option.code
                          }) >= 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-center items-center"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary3Text w-8" },
                                    [_c("CheckboxSelectedSvg")],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c("label", { staticClass: "underline ml-4" }, [
                        _vm._v(_vm._s(option.lensType) + " "),
                        _c("span", [_vm._v("+$" + _vm._s(option.price))])
                      ])
                    ]),
                    option.selectedColor
                      ? _c("div", { staticClass: "w-1/2 h-full mt-6" }, [
                          _c(
                            "div",
                            { staticClass: "w-40 flex items-center relative" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "relative z-50 w-40 bg-primary1 rounded-lg rounded-tl-none"
                                },
                                [
                                  _vm.currentPackage.enchancements.findIndex(
                                    function(elem) {
                                      return elem.code == option.code
                                    }
                                  ) >= 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "h-12 w-40 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none",
                                          class: [
                                            option.dropdown
                                              ? "animationprimary3"
                                              : ""
                                          ],
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleDropdown(option)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-40 h-12 flex items-center justify-between pl-4 pr-2"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    option.selectedColor
                                                      .brandDescText
                                                  )
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-lightGrayText w-6",
                                                  class: {
                                                    arrowRotation:
                                                      option.dropdown
                                                  }
                                                },
                                                [_c("ArrowDropdownSvg")],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  option.dropdown
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-40 absolute top-0 mt-12 bg-primary1 border-4 border-t-0 border-t-none border-primary1 rounded-lg rounded-tl-none rounded-tr-none"
                                        },
                                        _vm._l(option.colors, function(color) {
                                          return _c(
                                            "div",
                                            {
                                              key: color.id,
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleDropdown(
                                                    option
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              color != option.selectedColor
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "h-12 w-full rounded-md border-t-2 border-primary1 rounded-tl-none bg-white flex items-center px-4 button text-textDark",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectColor(
                                                            option,
                                                            color
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      color.image
                                                        ? _c("div", [
                                                            _c("img", {
                                                              staticClass:
                                                                "w-12 mr-3",
                                                              attrs: {
                                                                alt:
                                                                  color.brandDescText,
                                                                src: color.image
                                                              }
                                                            })
                                                          ])
                                                        : _vm._e(),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            color.brandDescText
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass:
            "h-1/12 w-11/12 flex items-center justify-end portrait:justify-center portrait:items-end portrait:pb-4"
        },
        [
          _c(
            "button",
            {
              staticClass:
                "underline text-xl h-12 w-48 mb-20 portrait:mb-0 portrait:mr-0 bg-primary3 text-primary3Text rounded-lg rounded-tl-none block focus:outline-none",
              on: { click: _vm.goToCheckout }
            },
            [_vm._v(" Continue ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }