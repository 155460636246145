<template>
  <div id="app" class="h-full w-screen overflow-y-scroll" :style="cssTheme">
    <Spinner class=" absolute h-full w-full bg-white z-1500 flex justify-center items-center" v-if="!preloadDone" />
    <AboutApp :showAboutApp.sync="showAboutApp" v-if="showAboutApp"  />
    <!-- <div id="nav"> -->
      <div :class="{'please-rotate': showDemo}" class="absolute hidden z-100 bottom-0 left-0 w-full h-10/12 justify-center items-center bg-white"  >Please Rotate</div>
      <TopNav />
      <!-- <BottomNav /> -->
      <Demo :showLensMaterials.sync="showDemo" v-if="showDemo" />
      <transition
        enter-active-class="transition-all transform duration-300 reverse"
        leave-active-class="transition-all transform duration-200 ease-in-out"
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <SideNav :showSideNav.sync="showSideNav" v-if="showSideNav" />
      </transition>
    <MeasureeyezContainer :currentFrame="currentFrame" :showMeasureEyez.sync="showMeasureEyez" v-if="showMeasureEyez" />
    <AppointmentPopup :showAppointment.sync="showAppointment" v-if="showAppointment" />
    <router-view :key="reloadKey"/>
    <BottomNav />
  </div>

</template>
<script>
import MeasureeyezContainer from './components/clq-measurements/MeasureyezContainer';
import TopNav from './components/nav/TopNav.vue';
import BottomNav from './components/nav/BottomNav.vue';
import Demo from './components/subcomponents/Demo.vue';
import SideNav from './components/nav/SideNav.vue';
import AboutApp from './components/subcomponents/AboutApp.vue';
import Spinner from './components/subcomponents/Loaders/Spinner.vue';
import { isEmpty, initApp, generateToast } from "./common/functions";
import MeasureEyezCapture from './components/MeasureEyezCapture';
import ProceedButton from './components/clq-measurements/ProceedButton';
import brandStyling from "./json/brand.config.json";
import AppointmentPopup from "./components/subcomponents/AppointmentPopup.vue";

export default {
  name: 'app',
  data() {
    return {
      isEmpty: isEmpty,
      initApp: initApp,
      // preloadDone: false,
      generateToast: generateToast,
      images: []
    }
  },
  components: {
    TopNav,
    BottomNav,
    Demo,
    SideNav,
    AboutApp,
    MeasureeyezContainer,
    Spinner,
    AppointmentPopup
  },
  methods: {
     preload() {
      return new Promise((resolve, reject) => {
      let loadCounter = 0;
      for (var i = 0; i < arguments.length; i++) {
        this.images[i] = new Image();
        this.images[i].src = arguments[i];
        this.images[i].onload = function(){
          loadCounter++
          if (loadCounter == arguments.length) {
            resolve({preload: true})
          }
        }
      }

     })
    },
  },
  created() {
    this.$store.dispatch('setAuthToken')
    if (this.isEmpty(this.currentUser)){
      this.$store.dispatch('logout')
    }
    else {
      this.initApp()
    }
    let self = this;

    window.addEventListener('click', function(e){
      if (!e.target.classList.contains('sideNav')) {
        // console.log(e.target)
        self.$store.dispatch('closeSideNav');

      }
    })

    window.addEventListener('click', function(e){
        if(!e.target.classList.contains('demoButtonMenu') && !e.target.classList.contains('icon_LensDemo')) {
          // console.log(e.target)
          self.$store.dispatch('closeDemoButtons');
      }
    })


  },
  mounted() {
    this.preload(
      './img/demos/LightReactive_Outdoor_Gray.jpg',
      './img/demos/LightReactive_Indoor_Gray.jpg',
      './img/demos/LightReactive_Outdoor_Brown.jpg',
      './img/demos/LightReactive_Indoor_Brown.jpg',
      './img/demos/LightReactive_Indoor_None.jpg',
      './img/demos/LightReactive_Outdoor_None.jpg',
      './img/optical_Female_sm.jpg',
      './img/demos/AR_Day_None.jpg',
      './img/demos/AR_Day.jpg',
      './img/demos/AR_Computer.jpg',
      './img/demos/AR_Computer_None.jpg',
      './img/demos/AR_Night.jpg',
      './img/demos/AR_Night_None.jpg',
      './img/demos/AR_Cosmetic.jpg',
      './img/demos/AR_Cosmetic_None.jpg',
      './img/demos/Bifocal_Progressive.jpg',
      './img/demos/Bifocal.jpg',
      './img/demos/Polarized_Gray.jpg',
      './img/demos/Polarized_None.jpg',
      './img/demos/Polarized_Brown.jpg',
      './img/demos/Polarized_None.jpg',
      './img/demos/demo_TintBrown.jpg',
      './img/demos/demo_TintGray.jpg',
      './img/demos/Progressive_Best.jpg',
      './img/demos/Progressive_Better.jpg',
      './img/demos/Progressive_Good.jpg',
    ).then(res => {
      this.$store.dispatch('setPreload', true)
    })
  },
  computed: {
    cssTheme() {
      const theme = this.$store.getters.getStyles;
      return {
        '--color-primary1': theme.primary1,
        '--primary1-text': theme.primary1Text,
        '--color-primary2': theme.primary2,
        '--primary2-text': theme.primary2Text,
        '--color-primary3': theme.primary3,
        '--primary3-text': theme.primary3Text,
        '--text-gray': theme.textGray,
        '--accent-1': theme.accent1,
        '--accent-1-text': theme.accent1Text,
        '--accent-2': theme.accent2,
        '--accent-2-text': theme.accent2Text,
        '--back-opacity': theme.backOpacity,
        '--back-opacity-text': theme.backOpacityText,
        '--background': theme.background,
        '--background-text': theme.backgroundText,
        '--gradient': theme.gradient,
        '--gradient-text': theme.gradientText,
        '--gradient2': theme.gradient2,
        '--gradient-2-text': theme.gradientText2,
        '--toggle-gray': theme.toggleGray,
        '--toggle-gray-text': theme.toggleGrayText,
        '--light-gray': theme.lightGray,
        '--light-gray-text': theme.lightGrayText,
        '--text-dark': theme.textDark,
        '--text-light': theme.textLight,
    }

    if (!this.isLoggedIn){
      this.$router.push('/auth')
    }
  },
  preloadDone() {
    return this.$store.getters.getPreloadStatus
  },
  reloadKey() {
    return this.$store.getters.getReloadKey
  },

    showDemo() {
      return this.$store.getters.getDemoToggle;
    },
    showSideNav() {
      return this.$store.getters.getSideNavToggle;
    },
    isLoggedIn() {
      return this.$store.getters.getLoginStatus;
    },
    currentUser() {
      return this.$store.getters.getUser;
    },
    showAboutApp() {
      return this.$store.getters.getAboutAppToggle;
    },
    showMeasureEyez(){
      return this.$store.getters.getMeasureEyez;
    },
    currentFrame() {
      return this.$store.getters.getCurrentFrame;
    },
    showAppointment() {
      return this.$store.getters.getAppointmentToggle;
    },
  },


}
</script>
<style>
.app{
  @apply h-full w-screen relative;
}

@media screen and (orientation: portrait) {
  .demo-landscape {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh !important;
    height: 100vw !important;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
  .please-rotate {
    display: flex !important;
  }


}
</style>
