var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-28 h-28 relative rounded-full mx-auto bg-primary3 flex items-center justify-center"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "w-24 h-16 absolute top-0 -mt-14 focus:outline-none disable-dbl-tap-zoom"
        },
        [
          _c("img", {
            staticClass: "measurement_Arrow_top transform -rotate-90",
            attrs: {
              src: require("../../assets/measurement_ControlArrow1.png"),
              alt: ""
            },
            on: {
              click: function($event) {
                return _vm.$emit("top")
              }
            }
          })
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "w-24 h-16 flex justify-center items-center absolute left-0 -ml-20 focus:outline-none disable-dbl-tap-zoom"
        },
        [
          _c("div", { staticClass: "-mr-11" }, [
            _c("img", {
              staticClass: "measurement_Arrow_left transform rotate-180 w-24",
              attrs: {
                src: require("../../assets/measurement_ControlArrow1.png"),
                alt: ""
              },
              on: {
                click: function($event) {
                  return _vm.$emit("left")
                }
              }
            })
          ])
        ]
      ),
      _vm.currentScreen === "measurement"
        ? _c(
            "div",
            {
              staticClass:
                "w-16 h-16 flex justify-center items-center focus:outline-none disable-dbl-tap-zoom",
              on: {
                click: function($event) {
                  return _vm.$emit("commit")
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass:
                    "measurementCheck w-12 fill-current text-primary1 shadow-shadowBrownOutter rounded-full",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 97.83 97.83"
                  }
                },
                [
                  _c("g", { attrs: { "data-name": "Layer 2" } }, [
                    _c("circle", {
                      attrs: { cx: "48.92", cy: "48.92", r: "48.92" }
                    }),
                    _c("path", {
                      attrs: {
                        fill: "none",
                        stroke: "#fff",
                        "stroke-miterlimit": "10",
                        "stroke-width": "10.07",
                        d: "M17.21 50.72l18.6 20.02 44.62-46.77"
                      }
                    })
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm.imageCaptured && _vm.currentScreen === "capture"
        ? _c(
            "div",
            {
              staticClass:
                "w-16 h-16 flex justify-center items-center focus:outline-none disable-dbl-tap-zoom",
              on: {
                click: function($event) {
                  return _vm.$emit("proceed")
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass:
                    "measurementCheck w-12 fill-current text-primary1 shadow-shadowBrownOutter rounded-full",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 97.83 97.83"
                  }
                },
                [
                  _c("g", { attrs: { "data-name": "Layer 2" } }, [
                    _c("circle", {
                      attrs: { cx: "48.92", cy: "48.92", r: "48.92" }
                    }),
                    _c("path", {
                      attrs: {
                        fill: "none",
                        stroke: "#fff",
                        "stroke-miterlimit": "10",
                        "stroke-width": "10.07",
                        d: "M17.21 50.72l18.6 20.02 44.62-46.77"
                      }
                    })
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "w-24 h-16 flex justify-center items-center absolute right-0 -mr-20 focus:outline-none disable-dbl-tap-zoom"
        },
        [
          _c("div", { staticClass: "-ml-11" }, [
            _c("img", {
              staticClass: "measurement_Arrow_right w-24",
              attrs: {
                src: require("../../assets/measurement_ControlArrow1.png"),
                alt: ""
              },
              on: {
                click: function($event) {
                  return _vm.$emit("right")
                }
              }
            })
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "w-24 h-12 flex justify-center items-center absolute bottom-0 -mb-8 focus:outline-none disable-dbl-tap-zoom"
        },
        [
          _c("img", {
            staticClass: "measurement_Arrow_bottom transform rotate-90",
            attrs: {
              src: require("../../assets/measurement_ControlArrow1.png"),
              alt: ""
            },
            on: {
              click: function($event) {
                return _vm.$emit("bottom")
              }
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }