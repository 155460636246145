<template>
  <button @click="$emit('recapture')" class="block focus:outline-none">
    <div class="w-8 text-lightGrayText">
      <PhotoBoothSvg />
    </div>
    <!-- <svg class="icon_PhotoBooth w-8 fill-current text-lightGrayText" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.06 69.26">
      <g id="Layer_2" data-name="Layer 2">
        <g id="keyboard">
          <path d="M45,69.25q-18.21,0-36.43,0C1.89,69.24-.79,65.48.2,57.68c1.52-12,1.11-24.08-.09-36.1-.7-7,2-10.42,8.12-10.43,4,0,7.94.09,11.9-.06a6.11,6.11,0,0,0,3.32-1.35c2.42-2.22,4.69-4.59,6.94-7A8.76,8.76,0,0,1,36.83,0Q45.3,0,53.77,0a8.15,8.15,0,0,1,5.87,2.46C62.11,5,64.55,7.55,67.13,10a4.84,4.84,0,0,0,2.74,1.12c4.18.13,8.37,0,12.55.06,5.65,0,8.69,4.15,7.3,10.71a83.85,83.85,0,0,0,0,36.43c1.54,6.59-1.68,10.93-7.52,10.95C69.76,69.27,57.37,69.25,45,69.25ZM61.31,38.3A16.28,16.28,0,1,0,45.06,54.69,16.31,16.31,0,0,0,61.31,38.3Zm20-15.72a3,3,0,0,0,3.11-3.07,3.06,3.06,0,0,0-6.11-.12A3,3,0,0,0,81.36,22.58Z" />
        </g>
      </g>
    </svg> -->
  </button>
</template>
<script>
import PhotoBoothSvg from "../../assets/svg/icons/icon_PhotoBooth.svg";

export default {
  name: 'ReCaptureButton',

  components: {
    PhotoBoothSvg
  }
}
</script>