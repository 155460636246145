<template>
 <div :class="{addWidth: activeButton}" class="dailyPlan plans-button h-full absolute left-0 top-0 z-100 ">
    <div  class="w-full h-full flex flex-col z-20">
        <div class="h-3/12" :class="{'h-4/12 mt-10': !computerPlan}">
            <transition
                enter-active-class="transition-all transform duration-700 ease-in-out"
                leave-active-class="transition-all transform duration-700 ease-in-out"
                enter-class="opcity-100 hidden"
                enter-to-class="opacity-0 hidden"
                leave-class="opacity-0 hidden"
                leave-to-class="opacity-100 hidden"
            >
                <div v-if="!dailyActive" @click.stop="openDailyPlan" class="dailyPlan h-full w-10 bg-primary1 text-primary1Text rounded-md rounded-l-none relative">
                    <div class="w-full h-full flex">
                        <!-- <div class="dailyPlan h-full w-1/12 pl-3 pt-2 text-xl">1</div> -->
                        <div v-if="(everydaySelected.checkedOut) && (currentIndex != 'everyday')" class="w-full h-full flex justify-center items-center absolute left-0 bottom-0">
                            <div class="w-8 text-primary1">
                                <CheckMarkSvg />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="dailyPlan h-full w-1/12 pl-3 pt-2 text-xl">1</div> -->
                    <div v-if="(currentIndex != 'everyday') && (!everydaySelected.checkedOut) " class="dailyPlan w-8 h-full flex justify-center items-center absolute -ml-4 left-0 bottom-0">
                        <i class="dailyPlan h-6 w-6 border-primary1Text border-2 border-t-0 border-l-0 inline-block transform -rotate-45"></i>
                    </div>
                    <div v-if="(currentIndex == 'everyday')" class="dailyPlan w-10 h-full bg-transparent flex justify-center items-center absolute -mr-5 right-0 bottom-0">
                        <svg class="dailyPlan Arrow_3 stroke-current fill-current text-primary1 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.33 93.16"><g data-name="Layer 2"><path class="dailyPlan" d="M18.78 0l37.55 46.58-37.55 46.58V0z"/><path class="dailyPlan fill-current text-primary1Text" d="M0 0l37.55 46.58L0 93.16V0z"/></g></svg>
                    </div>
                </div>
            </transition>
            <transition
                enter-active-class="transition-all transform duration-700 reverse"
                leave-active-class="transition-all transform duration-700 ease-in-out"
                enter-class="-translate-x-full"
                enter-to-class="translate-x-0"
                leave-class="translate-x-0"
                leave-to-class="-translate-x-full"
            >
                <DailyPlan v-if="dailyActive" :dailyActive="dailyActive" :openDailyPlan="openDailyPlan"  />
            </transition>
        </div>

        <div :class="{'h-4/12 mt-10': !computerPlan}" class="h-3/12 mt-3">
            <transition
                enter-active-class="transition-all transform duration-700 ease-in-out"
                leave-active-class="transition-all transform duration-700 ease-in-out"
                enter-class="opcity-100 hidden"
                enter-to-class="opacity-0 hidden"
                leave-class="opacity-0 hidden"
                leave-to-class="opacity-100 hidden"
            >
                <div v-if="!sunActive" @click.stop="openSunPlan" class="sunPlan h-full w-10 bg-primary2 text-primary2Text rounded-md rounded-l-none relative z-20">
                    <div class="w-full h-full flex">
                        <!-- <div class="dailyPlan h-full w-1/12 pl-3 pt-2 text-xl">2</div> -->
                        <div v-if="(polarizedSelected.checkedOut) && (currentIndex != 'polarized')" class="w-full h-full flex justify-center items-center absolute left-0 bottom-0">
                            <div class="w-8 text-primary2">
                                <CheckMarkSvg />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="sunPlan h-full w-1/12 pl-3 pt-2 text-xl">2</div> -->
                    <div v-if="(currentIndex != 'polarized') && (!polarizedSelected.checkedOut)" class="sunPlan w-8 h-full flex justify-center items-center absolute -ml-4 left-0 bottom-0">
                        <i class="sunPlan h-6 w-6 border-primary2Text border-2 border-t-0 border-l-0 inline-block transform -rotate-45"></i>
                    </div>
                    <div v-if="(currentIndex == 'polarized')" class="sunPlan w-10 h-full bg-transparent flex justify-center items-center absolute -mr-5 right-0 bottom-0">
                        <svg class="sunPlan Arrow_3 stroke-current fill-current text-primary2 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.33 93.16"><g data-name="Layer 2"><path d="M18.78 0l37.55 46.58-37.55 46.58V0z" class="sunPlan" /><path class="sunPlan fill-current text-primary2Text" d="M0 0l37.55 46.58L0 93.16V0z"/></g></svg>
                    </div>
                </div>
            </transition>
            <transition
                enter-active-class="transition-all transform duration-700 reverse"
                leave-active-class="transition-all transform duration-700 ease-in-out"
                enter-class="-translate-x-full"
                enter-to-class="translate-x-0"
                leave-class="translate-x-0"
                leave-to-class="-translate-x-full"
            >
                <SunPlan v-if="sunActive" :openSunPlan="openSunPlan" />
            </transition>
        </div>

        <div :class="{'hidden': !computerPlan}"  v-if="computerPlan" class="h-3/12 mt-3">
            <transition
                enter-active-class="transition-all transform duration-700 ease-in-out"
                leave-active-class="transition-all transform duration-700 ease-in-out"
                enter-class="opcity-100 hidden"
                enter-to-class="opacity-0 hidden"
                leave-class="opacity-0 hidden"
                leave-to-class="opacity-100 hidden"
            >
                <!-- <div v-if="!workActive" @click="openWorkPlan" class="workPlan h-full w-10 bg-primary3 text-primary3Text rounded-md rounded-l-none relative z-20">
                    <div class="h-full w-1/12 pl-3 pt-2 text-xl">3</div>
                    <div class="w-8 h-full flex justify-center items-center absolute -ml-4 left-0 bottom-0">
                        <i class="h-6 w-6 border-primary3Text border-2 border-t-0 border-l-0 inline-block transform -rotate-45"></i>
                    </div>
                </div> -->

                <div v-if="!workActive" @click.stop="openWorkPlan" class="workPlan h-full w-10 bg-primary3 text-primary3Text rounded-md rounded-l-none relative z-20">
                    <div class="w-full h-full flex">
                        <!-- <div class="dailyPlan h-full w-1/12 pl-3 pt-2 text-xl">3</div> -->
                        <div v-if="(wholeCart.cartItems.computer.checkedOut) && (currentIndex != 'work')" class="w-full h-full flex justify-center items-center absolute left-0 bottom-0">
                            <div class="w-8 text-primary3">
                                <CheckMarkSvg />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="workPlan h-full w-1/12 pl-3 pt-2 text-xl">3</div> -->
                    <div v-if="(currentIndex != 'work') && (!wholeCart.cartItems.computer.checkedOut)" class="workPlan w-8 h-full flex justify-center items-center absolute -ml-4 left-0 bottom-0">
                        <i class="workPlan h-6 w-6 border-primary3Text border-2 border-t-0 border-l-0 inline-block transform -rotate-45"></i>
                    </div>
                    <div v-if="(currentIndex == 'work')" class="workPlan w-10 h-full bg-transparent flex justify-center items-center absolute -mr-5 right-0 bottom-0">
                        <svg class="workPlan Arrow_3 stroke-current fill-current text-primary3 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.33 93.16"><g data-name="Layer 2"><path d="M18.78 0l37.55 46.58-37.55 46.58V0z" class="workPlan" /><path class="workPlan fill-current text-primary3Text" d="M0 0l37.55 46.58L0 93.16V0z"/></g></svg>
                    </div>
                </div>
            </transition>
            <transition
                enter-active-class="transition-all transform duration-700 reverse"
                leave-active-class="transition-all transform duration-700 ease-in-out"
                enter-class="-translate-x-full"
                enter-to-class="translate-x-0"
                leave-class="translate-x-0"
                leave-to-class="-translate-x-full"
            >
                <WorkPlan v-if="workActive" :openWorkPlan="openWorkPlan" />
            </transition>
        </div>
    </div>
  </div>
</template>

<script>
import DailyPlan from '../../../components/subcomponents/vision-plans/DailyPlan.vue'
import SunPlan from '../../../components/subcomponents/vision-plans/SunPlan.vue'
import WorkPlan from '../../../components/subcomponents/vision-plans/WorkPlan.vue'
import CheckMarkSvg from "../../../assets/svg/icons/checkOutline.svg";
import { generateToast } from "../../../common/functions";


export default {

  name: 'PlansButton',
  data() {
    return {
        activeButton: false,
        dailyActive: false,
        sunActive: false,
        workActive: false,
        generateToast: generateToast,

    };
  },
  methods: {
    // closeDailyPlan(){
    //     this.dailyActive = false;
    // },
    // closeSunPlan(){
    //     this.sunActive = false;
    // },
    // closeWorkPlan(){
    //     this.workActive = false;
    // },
    openDailyPlan () {
        this.dailyActive = !this.dailyActive;
        if(this.dailyActive) {
            this.sunActive = false;
            this.workActive = false;
            this.activeButton = true
        }else {
            this.activeButton = false
        }
        if(this.dailyActive) {
            this.$store.dispatch('sendEvent', {
                hitType: 'event',
                eventCategory: 'button',
                eventAction: 'click',
                eventLabel: 'Daily Plan Button',
                dimension1: this.currentUser.id,
                dimension2: this.currentUser.group.id,
                dimension3: this.view
            })
        }
    },
    openSunPlan (){
        // this.generateToast('Coming Soon')
        // return
         this.sunActive = !this.sunActive
        if(this.sunActive){
            this.workActive = false;
            this.dailyActive = false;
            this.activeButton = true
        }else{
            this.activeButton = false
        }
        if(this.sunActive) {
            this.$store.dispatch('sendEvent', {
                hitType: 'event',
                eventCategory: 'button',
                eventAction: 'click',
                eventLabel: 'Sun Plan Button',
                dimension1: this.currentUser.id,
                dimension2: this.currentUser.group.id,
                dimension3: this.view
            })
        }
    },
    openWorkPlan (){
        // this.generateToast('Coming Soon')
        // return
         this.workActive = !this.workActive
        if(this.workActive){
            this.sunActive = false;
            this.dailyActive = false;
            this.activeButton = true
        }else {
            this.activeButton = false
        }
        if(this.sunActive) {
            this.$store.dispatch('sendEvent', {
                hitType: 'event',
                eventCategory: 'button',
                eventAction: 'click',
                eventLabel: 'Work Plan Button',
                dimension1: this.currentUser.id,
                dimension2: this.currentUser.group.id,
                dimension3: this.view
            })
        }
    },

  },

  components: {
    DailyPlan,
    SunPlan,
    WorkPlan,
    CheckMarkSvg
  },
    created() {
        let self = this
        window.addEventListener('click', function(e){
            if (!e.target.classList.contains('dailyPlan') && self.dailyActive == true) {
                // console.log(e.target)
                self.openDailyPlan()

            }
        })
        window.addEventListener('click', function(e){
            if (!e.target.classList.contains('sunPlan') && self.sunActive == true) {
                // console.log(e.target)
                self.openSunPlan()

            }
        })
        window.addEventListener('click', function(e){
            if (!e.target.classList.contains('workPlan') && self.workActive == true) {
                // console.log(e.target)
                self.openWorkPlan()

            }
        })
    },
    computed: {
        currentUser() {
            return this.$store.getters.getUser
        },
        currentIndex() {
            return this.$store.getters.getCurrentIndex
        },
        everydaySelected() {
            return this.$store.getters.getEveryday
        },
        polarizedSelected() {
            return this.$store.getters.getPolarized
        },
        wholeCart() {
            return this.$store.getters.getWholeCart
        },
        computerPlan() {
            const lifestyleQuestions = this.$store.getters.getLifestyle
            if (lifestyleQuestions.digital == true || lifestyleQuestions.gaming == true){
            return true
            }
            return false
        }
    },
};
</script>

<style scoped>
    .addWidth {
        @apply w-full bg-backOpacity;
    }

</style>
