var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "navigation h-2/12 flex flex-col justify-around" },
    [
      _c(
        "div",
        { staticClass: "h-1/2 flex items-center pl-3 text-xl pt-2" },
        [
          _c("BackButton"),
          _c(
            "span",
            {
              staticClass: "underline",
              on: {
                click: function($event) {
                  return _vm.$router.push("/")
                }
              }
            },
            [_vm._v("home")]
          ),
          ["lenspackage", "frames", "visionplan", "checkout"].indexOf(
            _vm.currentRouteName
          ) > -1
            ? _c("div", [
                _c("span", { staticClass: "mx-1" }, [_vm._v(">")]),
                _c(
                  "span",
                  {
                    class: { underline: _vm.currentRouteName != "visionplan" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/visionplan")
                      }
                    }
                  },
                  [_vm._v("plan")]
                )
              ])
            : _vm._e(),
          ["lenspackage", "frames", "checkout"].indexOf(_vm.currentRouteName) >
          -1
            ? _c("div", [
                _c("span", { staticClass: "mx-1" }, [_vm._v(">")]),
                _c(
                  "span",
                  {
                    class: { underline: _vm.currentRouteName != "frames" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/frames")
                      }
                    }
                  },
                  [_vm._v("frames")]
                )
              ])
            : _vm._e(),
          ["lenspackage", "checkout"].indexOf(_vm.currentRouteName) > -1
            ? _c("div", [
                _c("span", { staticClass: "mx-1" }, [_vm._v(">")]),
                _c(
                  "span",
                  {
                    class: { underline: _vm.currentRouteName != "lenspackage" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/lenspackage")
                      }
                    }
                  },
                  [_vm._v("lenses")]
                )
              ])
            : _vm._e(),
          _vm.currentRouteName == "checkout"
            ? _c("div", [
                _c("span", { staticClass: "mx-1" }, [_vm._v(">")]),
                _c(
                  "span",
                  {
                    class: { underline: _vm.currentRouteName != "checkout" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/checkout")
                      }
                    }
                  },
                  [_vm._v("checkout")]
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "w-8/12 h-12 flex justify-between portrait:items-center portrait:w-7/12"
        },
        [
          _vm.currentView == "Frames"
            ? _c("p", { staticClass: "flex pl-20 items-center" }, [
                _vm._v("Let's start with frames.")
              ])
            : _vm._e(),
          _vm.currentView == "LensPackage"
            ? _c("p", { staticClass: "flex pl-20 items-center" }, [
                _vm._v("Choose your lens package.")
              ])
            : _vm._e(),
          _vm.currentView == "Checkout"
            ? _c("p", { staticClass: "flex pl-20 items-center" }, [
                _vm._v("Great choices!")
              ])
            : _vm._e(),
          _vm.currentView == "VisionPlan" && _vm.currentPatient.user
            ? _c("p", { staticClass: "flex pl-20 items-center" }, [
                _vm._v(
                  _vm._s(_vm.currentPatient.user.name) +
                    "'s Optical Treatment Plan:"
                )
              ])
            : _vm.currentView == "VisionPlan"
            ? _c("p", { staticClass: "flex pl-20 items-center" }, [
                _vm._v("Your Optical Treatment Plan:")
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }