import { render, staticRenderFns } from "./LifeStyle.vue?vue&type=template&id=aa9f9e6a&scoped=true&"
import script from "./LifeStyle.vue?vue&type=script&lang=js&"
export * from "./LifeStyle.vue?vue&type=script&lang=js&"
import style0 from "./LifeStyle.vue?vue&type=style&index=0&id=aa9f9e6a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa9f9e6a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aa9f9e6a')) {
      api.createRecord('aa9f9e6a', component.options)
    } else {
      api.reload('aa9f9e6a', component.options)
    }
    module.hot.accept("./LifeStyle.vue?vue&type=template&id=aa9f9e6a&scoped=true&", function () {
      api.rerender('aa9f9e6a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/LifeStyle.vue"
export default component.exports