
function initialState(){
  return {
    showLensMaterials: false,
    showDemo: false,
    showSideNav: false,
    showDemoButtons: false,
    showFrameDetails: false,
    currentView: '',
    showAboutApp: false,
    showMeasureEyez: false,
    preload: false,
    currentDemoName: '',
    reloadKey: 0,
    customize: false,
    showAppointment: false,
    showTransitionsPopup: false,
  }
}

const state = initialState

const mutations = {
  toggle_lensMaterials(state, toggle) {
    state.showLensMaterials = toggle;
  },
  toggle_demo(state, demoName) {
    state.showDemo = !state.showDemo;
    state.currentDemoName = demoName
  },
  toggle_demoButtons(state, toggle) {
    state.showDemoButtons = toggle;
  },
  toggle_sideNav(state, toggle) {
    state.showSideNav = toggle;
  },
  toggle_frameDetails(state, toggle) {
    state.showFrameDetails = toggle;
  },
  toggle_aboutApp(state, toggle) {
    state.showAboutApp = toggle;
  },
  toggle_measureEyez(state, toggle) {
    state.showMeasureEyez = toggle;
  },
  toggle_appointment(state, toggle) {
    state.showAppointment = toggle;
  },
  toggle_transitionsPopup(state, toggle) {
    state.showTransitionsPopup = toggle;
  },
  set_view(state, currentView){
    state.currentView = currentView
  },
  close_sideNav(state){
    state.showSideNav = false;
  },
  close_demoButtons(state){
    state.showDemoButtons = false;
  },
  show_customize(state, custom){
    state.customize = custom
  },
  set_preload(state, status){
    state.preload = status
  },
  set_reload_key(state, key){
    state.reloadKey = key
  },
  clear_toggles(state){
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = {
  toggleLensMaterials({ commit, state }) {
    commit('toggle_lensMaterials', !state.showLensMaterials);
  },
  toggleDemo({ commit, state }, demoName) {
    commit('toggle_demo', demoName);
  },
  toggleDemoButtons({ commit, state }) {
    commit('toggle_demoButtons', !state.showDemoButtons);
  },
  toggleSideNav({ commit, state }) {
    commit('toggle_sideNav', !state.showSideNav);
  },
  toggleFrameDetails({ commit, state }) {
    commit('toggle_frameDetails', !state.showFrameDetails);
  },
  toggleAboutApp({ commit, state }) {
    commit('toggle_aboutApp', !state.showAboutApp);
  },
  toggleMeasureEyez({ commit, state }) {
    commit('toggle_measureEyez', !state.showMeasureEyez);
  },
  toggleAppointment({ commit, state }) {
    commit('toggle_appointment', !state.showAppointment);
  },
  toggleTransitionsPopup({ commit, state }) {
    commit('toggle_transitionsPopup', !state.showTransitionsPopup);
  },
  showCustomize({commit}, custom){
    commit('show_customize', custom)
  },
  closeSideNav({commit}){
    commit('close_sideNav');
  },
  closeDemoButtons({commit}){
    commit('close_demoButtons')
  },
  setView({commit}, view){
    commit('set_view', view)
  },
  setPreload({commit}, status){
    commit('set_preload', status)
  },
  setReloadKey({commit}, key){
    commit('set_reload_key', key)
  },
}

const getters = {
  // getStyles: () => themeStyles.brandColors,
  getLensMaterialsToggle: (state) => state.showLensMaterials,
  getDemoToggle: (state) => state.showDemo,
  getCurrentDemoName: (state) => state.currentDemoName,
  getDemoButtonsToggle: (state) => state.showDemoButtons,
  getSideNavToggle: (state) => state.showSideNav,
  getFrameDetailsToggle: (state) => state.showFrameDetails,
  getAboutAppToggle: (state) => state.showAboutApp,
  getMeasureEyezToggle: (state) => state.showMeasureEyez,
  getAppointmentToggle: (state) => state.showAppointment,
  getView: (state) => state.currentView,
  getCustomize: (state) => state.customize,
  getPreloadStatus: (state) => state.preload,
  getReloadKey: (state) => state.reloadKey,
  getMeasureEyez: (state) => state.showMeasureEyez,
  getTransitionsPopupToggle: (state) => state.showTransitionsPopup,
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}