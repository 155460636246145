var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "bottomNav text-sm pl-4 landscape:pl-6" },
    [
      _c("div", { staticClass: "flex flex-col" }, [
        _c("p", [
          _vm._v("*Prices shown are before any insurance or discount.")
        ]),
        _c(
          "a",
          {
            staticClass: "underline leading-tight",
            on: {
              click: function($event) {
                return _vm.$store.dispatch("toggleAboutApp")
              }
            }
          },
          [_vm._v("All Rights Reserved 2020")]
        )
      ]),
      _c("ContentlinqSvg")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }