var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "frames bg-background text-backgroundText relative h-10/12",
      attrs: { id: "frames" }
    },
    [
      _c("Navigation"),
      _c(
        "main",
        { staticClass: "h-10/12 w-full flex-col flex relative" },
        [
          _c("PlansButton"),
          _c(
            "section",
            {
              staticClass:
                "w-full h-2/12 border-t-4 border-primary1 portrait:h-1/12"
            },
            [
              _c(
                "div",
                {
                  staticClass: "h-full w-full flex items-center justify-between"
                },
                [
                  _vm.currentPatient.user
                    ? _c("div", [
                        _vm.currentMod == ""
                          ? _c(
                              "h1",
                              {
                                staticClass:
                                  "h-12 flex ml-16 items-center font-bold text-lg float-left"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.currentPatient.user.name) +
                                    "'s Daily Pair"
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.currentMod == "polarized"
                          ? _c(
                              "h1",
                              {
                                staticClass:
                                  "h-12 flex ml-16 items-center font-bold text-lg float-left"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.currentPatient.user.name) +
                                    "'s Sun Pair"
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.currentMod == "computer"
                          ? _c(
                              "h1",
                              {
                                staticClass:
                                  "h-12 flex ml-16 items-center font-bold text-lg float-left"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.currentPatient.user.name) +
                                    "'s Computer Pair"
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _c("div", [
                        _vm.currentMod == ""
                          ? _c(
                              "h1",
                              {
                                staticClass:
                                  "h-12 flex ml-16 items-center font-bold text-lg float-left"
                              },
                              [_vm._v("Your Daily Pair")]
                            )
                          : _vm._e(),
                        _vm.currentMod == "polarized"
                          ? _c(
                              "h1",
                              {
                                staticClass:
                                  "h-12 flex ml-16 items-center font-bold text-lg float-left"
                              },
                              [_vm._v("Your Sun Pair")]
                            )
                          : _vm._e(),
                        _vm.currentMod == "computer"
                          ? _c(
                              "h1",
                              {
                                staticClass:
                                  "h-12 flex ml-16 items-center font-bold text-lg float-left"
                              },
                              [_vm._v("Your Computer Pair")]
                            )
                          : _vm._e()
                      ]),
                  _c(
                    "section",
                    {
                      staticClass:
                        "w-full absolute top-0 right-0 mr-10 -mt-12 flex justify-end items-center portrait:w-7/12"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-12 w-7/12 flex relative rounded-md rounded-tl-none portrait:w-full"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchQuery,
                                expression: "searchQuery"
                              }
                            ],
                            staticClass:
                              "frameSearch inputStyle focus:outline-none focus:shadow-outline",
                            attrs: {
                              type: "text",
                              placeholder: "search frames"
                            },
                            domProps: { value: _vm.searchQuery },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.searchQuery = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.showSearchResult = !_vm.showSearchResult
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-8 absolute top-0 right-0 mt-2 mr-2"
                                },
                                [_c("IconSearchSvg")],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c("div", [
                    !_vm.searchQuery
                      ? _c(
                          "a",
                          {
                            staticClass: "underline h-10 pr-10",
                            on: { click: _vm.skipFrames }
                          },
                          [_vm._v("skip frames")]
                        )
                      : _vm._e()
                  ])
                ]
              )
            ]
          ),
          !_vm.searchQuery
            ? _c(
                "section",
                {
                  staticClass:
                    "w-full h-10/12 flex flex-col pl-20 portrait:h-11/12"
                },
                [
                  _c(
                    "div",
                    { staticClass: "w-full h-10/12 flex portrait:flex-col" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-full w-full flex flex-col items-center portrait:w-full portrait:mt-6"
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-4 ml-10 flex self-start portrait:mb-8 portrait:ml-6"
                            },
                            [_vm._v("Shapes that appeal to you the most:")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-9/12 w-11/12 flex flex-wrap justify-between"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "opticalButtons focus:outline-none",
                                  class: {
                                    activeButtons: _vm.frameShapes.rectangle
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedOption({
                                        key: "rectangle",
                                        status: !_vm.frameShapes.rectangle
                                      })
                                    }
                                  }
                                },
                                [
                                  !_vm.frameShapes.rectangle
                                    ? _c(
                                        "div",
                                        { staticClass: "w-8 ml-4" },
                                        [_c("CheckboxBlankSvg")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.frameShapes.rectangle
                                    ? _c("div", { staticClass: "w-8 ml-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-primary2Text" },
                                          [_c("CheckboxSelectedSvg")],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-backgroundText w-32" },
                                    [
                                      _c("FrameRectangleSvg", {
                                        class: {
                                          "text-primary2Text":
                                            _vm.frameShapes.rectangle
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "opticalButtons focus:outline-none",
                                  class: {
                                    activeButtons: _vm.frameShapes.square
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedOption({
                                        key: "square",
                                        status: !_vm.frameShapes.square
                                      })
                                    }
                                  }
                                },
                                [
                                  !_vm.frameShapes.square
                                    ? _c(
                                        "div",
                                        { staticClass: "w-8 ml-4" },
                                        [_c("CheckboxBlankSvg")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.frameShapes.square
                                    ? _c("div", { staticClass: "w-8 ml-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-primary2Text" },
                                          [_c("CheckboxSelectedSvg")],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-backgroundText w-32" },
                                    [
                                      _c("FrameSquareSvg", {
                                        class: {
                                          "text-primary2Text":
                                            _vm.frameShapes.square
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "opticalButtons focus:outline-none ",
                                  class: {
                                    activeButtons: _vm.frameShapes.round
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedOption({
                                        key: "round",
                                        status: !_vm.frameShapes.round
                                      })
                                    }
                                  }
                                },
                                [
                                  !_vm.frameShapes.round
                                    ? _c(
                                        "div",
                                        { staticClass: "w-8 ml-4" },
                                        [_c("CheckboxBlankSvg")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.frameShapes.round
                                    ? _c("div", { staticClass: "w-8 ml-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-primary2Text" },
                                          [_c("CheckboxSelectedSvg")],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-backgroundText w-32" },
                                    [
                                      _c("FrameRoundSvg", {
                                        class: {
                                          "text-primary2Text":
                                            _vm.frameShapes.round
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "opticalButtons focus:outline-none",
                                  class: {
                                    activeButtons: _vm.frameShapes.aviator
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedOption({
                                        key: "aviator",
                                        status: !_vm.frameShapes.aviator
                                      })
                                    }
                                  }
                                },
                                [
                                  !_vm.frameShapes.aviator
                                    ? _c(
                                        "div",
                                        { staticClass: "w-8 ml-4" },
                                        [_c("CheckboxBlankSvg")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.frameShapes.aviator
                                    ? _c("div", { staticClass: "w-8 ml-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-primary2Text" },
                                          [_c("CheckboxSelectedSvg")],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-backgroundText w-32" },
                                    [
                                      _c("FrameAviatorSvg", {
                                        class: {
                                          "text-primary2Text":
                                            _vm.frameShapes.aviator
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "opticalButtons focus:outline-none",
                                  class: {
                                    activeButtons: _vm.frameShapes.oval
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedOption({
                                        key: "oval",
                                        status: !_vm.frameShapes.oval
                                      })
                                    }
                                  }
                                },
                                [
                                  !_vm.frameShapes.oval
                                    ? _c(
                                        "div",
                                        { staticClass: "w-8 ml-4" },
                                        [_c("CheckboxBlankSvg")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.frameShapes.oval
                                    ? _c("div", { staticClass: "w-8 ml-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-primary2Text" },
                                          [_c("CheckboxSelectedSvg")],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-backgroundText w-32" },
                                    [
                                      _c("FrameOvalSvg", {
                                        class: {
                                          "text-primary2Text":
                                            _vm.frameShapes.oval
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "opticalButtons focus:outline-none",
                                  class: {
                                    activeButtons: _vm.frameShapes.cateye
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedOption({
                                        key: "cateye",
                                        status: !_vm.frameShapes.cateye
                                      })
                                    }
                                  }
                                },
                                [
                                  !_vm.frameShapes.cateye
                                    ? _c(
                                        "div",
                                        { staticClass: "w-8 ml-4" },
                                        [_c("CheckboxBlankSvg")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.frameShapes.cateye
                                    ? _c("div", { staticClass: "w-8 ml-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-primary2Text" },
                                          [_c("CheckboxSelectedSvg")],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-backgroundText w-32" },
                                    [
                                      _c("FrameCateyeSvg", {
                                        class: {
                                          "text-primary2Text":
                                            _vm.frameShapes.cateye
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "opticalButtons focus:outline-none",
                                  class: {
                                    activeButtons: _vm.frameShapes.browline
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedOption({
                                        key: "browline",
                                        status: !_vm.frameShapes.browline
                                      })
                                    }
                                  }
                                },
                                [
                                  !_vm.frameShapes.browline
                                    ? _c(
                                        "div",
                                        { staticClass: "w-8 ml-4" },
                                        [_c("CheckboxBlankSvg")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.frameShapes.browline
                                    ? _c("div", { staticClass: "w-8 ml-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-primary2Text" },
                                          [_c("CheckboxSelectedSvg")],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-backgroundText w-32" },
                                    [
                                      _c("FrameBrowlineSvg", {
                                        class: {
                                          "text-primary2Text":
                                            _vm.frameShapes.browline
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "opticalButtons focus:outline-none",
                                  class: {
                                    activeButtons: _vm.frameShapes.geometric
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedOption({
                                        key: "geometric",
                                        status: !_vm.frameShapes.geometric
                                      })
                                    }
                                  }
                                },
                                [
                                  !_vm.frameShapes.geometric
                                    ? _c(
                                        "div",
                                        { staticClass: "w-8 ml-4" },
                                        [_c("CheckboxBlankSvg")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.frameShapes.geometric
                                    ? _c("div", { staticClass: "w-8 ml-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-primary2Text" },
                                          [_c("CheckboxSelectedSvg")],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-backgroundText w-32" },
                                    [
                                      _c("FrameGeometricSvg", {
                                        class: {
                                          "text-primary2Text":
                                            _vm.frameShapes.geometric
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "opticalButtons focus:outline-none",
                                  class: {
                                    activeButtons: _vm.frameShapes.wayfarer
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectedOption({
                                        key: "wayfarer",
                                        status: !_vm.frameShapes.wayfarer
                                      })
                                    }
                                  }
                                },
                                [
                                  !_vm.frameShapes.wayfarer
                                    ? _c(
                                        "div",
                                        { staticClass: "w-8 ml-4" },
                                        [_c("CheckboxBlankSvg")],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.frameShapes.wayfarer
                                    ? _c("div", { staticClass: "w-8 ml-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "text-primary2Text" },
                                          [_c("CheckboxSelectedSvg")],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "text-backgroundText w-32" },
                                    [
                                      _c("FrameWayfarerSvg", {
                                        class: {
                                          "text-primary2Text":
                                            _vm.frameShapes.wayfarer
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._m(0)
                ]
              )
            : _vm._e(),
          _vm.searchQuery
            ? _c("FramesResults", {
                attrs: { frames: _vm.frameResults },
                on: { "frames-details": _vm.displayFramesDetails }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "opacity-0 w-full h-1/12 flex justify-end items-end mr-20 portrait:justify-center"
      },
      [
        _c(
          "button",
          {
            staticClass:
              "underline h-12 w-48 text-xl mr-8 portrait:mb-0 portrait:mr-0 bg-primary3 rounded-lg rounded-tl-none text-primary3Text block focus:outline-none"
          },
          [_vm._v(" Find Style ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }