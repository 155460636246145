var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "containerRef", staticClass: "vci-container" }, [
    _c("img", {
      ref: "leftImageRef",
      staticClass: "left-image",
      style: _vm.leftImageStyle,
      attrs: { src: _vm.leftImage }
    }),
    _c("img", {
      ref: "rightImageRef",
      staticClass: "right-image",
      attrs: { src: _vm.rightImage }
    }),
    _c("div", { staticClass: "vci-slider", style: _vm.sliderStyle }, [
      _c("div", { staticClass: "line", style: _vm.sliderLineStyle }),
      _c("div", { staticClass: "handle", style: _vm.sliderHandleStyle }, [
        _c("div", {
          staticClass: "left-arrow",
          style: _vm.sliderLeftArrowStyle
        }),
        _c("div", {
          staticClass: "right-arrow",
          style: _vm.sliderRightArrowStyle
        })
      ]),
      _c("div", { staticClass: "line", style: _vm.sliderLineStyle })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }