<template>
  <section
    class="customize bg-background text-backgroundText absolute bottom-0 right-0 w-11/12 h-full flex flex-col pr-4 portrait:pl-10 portrait:h-full"
  >
    <div v-if="currentPatient.user">
      <h1
        class="h-12 flex items-center font-bold text-xl"
        v-if="currentMod == ''"
      >
         {{currentPatient.user.name}}'s Daily Pair
      </h1>
      <h1
        class="h-12 flex items-center font-bold text-xl"
        v-if="currentMod == 'polarized'"
      >
        {{currentPatient.user.name}}'s Sun Pair
      </h1>
      <h1
        class="h-12 flex items-center font-bold text-xl"
        v-if="currentMod == 'computer'"
      >
        {{currentPatient.user.name}}'s Computer Pair
      </h1>
    </div>
    <div v-else>
      <h1
        class="h-12 flex items-center font-bold text-xl"
        v-if="currentMod == ''"
      >
        Your Daily Pair
      </h1>
      <h1
        class="h-12 flex items-center font-bold text-xl"
        v-if="currentMod == 'polarized'"
      >
        Your Sun Pair
      </h1>
      <h1
        class="h-12 flex items-center font-bold text-xl"
        v-if="currentMod == 'computer'"
      >
        Your Computer Pair
      </h1>
    </div>
    <div class="h-10/12 w-full flex">
      <div
        class="h-10/12 w-full flex portrait:flex-col portrait:h-full overflow-y-auto"
      >
        <div class="w-7/12 h-11/12 portrait:w-full portrait:h-1/2 portrait:overflow-y-auto flex flex-wrap"
        >
          <div
            v-for="option in currentOptions"
            :key="option.id"
            class="w-1/2 h-14 flex items-end"
          >
            <div
              @click="toggleOption(option)"
              :class="{
                'bg-primary3':
                  currentPackage.enchancements.findIndex(
                    (elem) => elem.code == option.code
                  ) >= 0,
              }"
              class="h-10 w-10 border-2 border-toggleGray rounded-lg rounded-tl-none"
            >
              <div
                v-if="
                  currentPackage.enchancements.findIndex(
                    (elem) => elem.code == option.code
                  ) < 0
                "
                class="flex justify-center items-center"
              >
                <div class="w-8">
                  <CheckboxBlankSvg />
                </div>
              </div>
              <div
                v-if="
                  currentPackage.enchancements.findIndex(
                    (elem) => elem.code == option.code
                  ) >= 0
                "
                class="flex justify-center items-center"
              >
                <div class="text-primary3Text w-8">
                  <CheckboxSelectedSvg />
                </div>
              </div>
            </div>
            <label class="underline ml-4"
              >{{ option.lensType }} &nbsp<span
                >+${{ option.price }}</span
              ></label
            >
          </div>
          <!-- <div class="w-full h-3/12 flex items-end">
                        <div @click="groovedSelected = !groovedSelected"  :class="{'bg-primary3': groovedSelected}" class="h-10 w-10 border-2 border-toggleGray rounded-lg rounded-tl-none ">
                            <div v-if="!groovedSelected"  class="flex justify-center items-center">
                                <svg class="checkbox_blank w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.8 62.63"><path d="M3.32 36.82c6.71-4.25 11.51-9 13.27.73s3.86 9.41 8.34 4.17S56.35 1.8 64.41 1.06c8.33-.76 1.16 5.13-20 30.24s-18.8 30.33-25.8 30.33S8.1 53.16 5.8 47.85s-7.96-7.57-2.48-11.03z" fill="#fff" stroke="#c8c8c8" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd" data-name="Layer 2"/></svg>
                            </div>
                            <div v-if="groovedSelected"  class="flex justify-center items-center">
                                <svg class="checkbox_selected fill-current text-primary3Text w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.03 42.69"><path d="M1.63 25.22c4.73-3 8.11-6.31 9.35.51s2.71 6.63 5.87 2.94S39 .56 44.65 0c5.86-.53.81 3.61-14.08 21.29s-13.24 21.4-18.17 21.4-7.4-6-9-9.71-5.63-5.32-1.77-7.76z" fill-rule="evenodd" data-name="Layer 2"/></svg>
                            </div>
                        </div>
                        <label class="underline ml-4">grooved rimless &nbsp<span>+$30</span></label>
                    </div>
                    <div  class="w-full h-3/12 flex items-end">
                        <div @click="drillSelected = !drillSelected" :class="{'bg-primary3': drillSelected}" class="h-10 w-10 border-2 border-toggleGray rounded-lg rounded-tl-none ">
                            <div v-if="!drillSelected"  class="flex justify-center items-center">
                                <svg class="checkbox_blank w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.8 62.63"><path d="M3.32 36.82c6.71-4.25 11.51-9 13.27.73s3.86 9.41 8.34 4.17S56.35 1.8 64.41 1.06c8.33-.76 1.16 5.13-20 30.24s-18.8 30.33-25.8 30.33S8.1 53.16 5.8 47.85s-7.96-7.57-2.48-11.03z" fill="#fff" stroke="#c8c8c8" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd" data-name="Layer 2"/></svg>
                            </div>
                            <div v-if="drillSelected"  class="flex justify-center items-center">
                                <svg class="checkbox_selected fill-current text-primary3Text w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.03 42.69"><path d="M1.63 25.22c4.73-3 8.11-6.31 9.35.51s2.71 6.63 5.87 2.94S39 .56 44.65 0c5.86-.53.81 3.61-14.08 21.29s-13.24 21.4-18.17 21.4-7.4-6-9-9.71-5.63-5.32-1.77-7.76z" fill-rule="evenodd" data-name="Layer 2"/></svg>
                            </div>
                        </div>
                        <label class="underline ml-4">drill mount &nbsp<span>+$70</span></label>
                    </div>
                    <div class="w-full h-3/12 flex items-end">
                        <div @click="factorySelected = !factorySelected" :class="{'bg-primary3': factorySelected}" class="h-10 w-10 border-2 border-toggleGray rounded-lg rounded-tl-none ">
                            <div v-if="!factorySelected"  class="flex justify-center items-center">
                                <svg class="checkbox_blank w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.8 62.63"><path d="M3.32 36.82c6.71-4.25 11.51-9 13.27.73s3.86 9.41 8.34 4.17S56.35 1.8 64.41 1.06c8.33-.76 1.16 5.13-20 30.24s-18.8 30.33-25.8 30.33S8.1 53.16 5.8 47.85s-7.96-7.57-2.48-11.03z" fill="#fff" stroke="#c8c8c8" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd" data-name="Layer 2"/></svg>
                            </div>
                            <div v-if="factorySelected"  class="flex justify-center items-center">
                                <svg class="checkbox_selected fill-current text-primary3Text w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.03 42.69"><path d="M1.63 25.22c4.73-3 8.11-6.31 9.35.51s2.71 6.63 5.87 2.94S39 .56 44.65 0c5.86-.53.81 3.61-14.08 21.29s-13.24 21.4-18.17 21.4-7.4-6-9-9.71-5.63-5.32-1.77-7.76z" fill-rule="evenodd" data-name="Layer 2"/></svg>
                            </div>
                        </div>
                        <label class="underline ml-4">factory scratch coat &nbsp<span>+$10</span></label>
                    </div> -->
        </div>
        <div class="w-5/12 h-11/12 portrait:w-full portrait:h-1/2">
          <div
            v-for="option in currentColorOptions"
            :key="option.id"
            class="w-full flex h-14 items-center"
          >
            <div class="w-1/2 h-full flex items-end">
              <div
                @click="toggleOption(option)"
                :class="{
                  'bg-primary3':
                    currentPackage.enchancements.findIndex(
                      (elem) => elem.code == option.code
                    ) >= 0,
                }"
                class="h-10 w-10 border-2 border-toggleGray rounded-lg rounded-tl-none"
              >
                <div
                  v-if="
                    currentPackage.enchancements.findIndex(
                      (elem) => elem.code == option.code
                    ) < 0
                  "
                  class="flex justify-center items-center"
                >
                  <div class="w-8">
                    <CheckboxBlankSvg />
                  </div>
                </div>
                <div
                  v-if="
                    currentPackage.enchancements.findIndex(
                      (elem) => elem.code == option.code
                    ) >= 0
                  "
                  class="flex justify-center items-center"
                >
                  <div class="text-primary3Text w-8">
                    <CheckboxSelectedSvg />
                  </div>
                </div>
              </div>
              <label class="underline ml-4"
                >{{ option.lensType }} <span>+${{ option.price }}</span></label
              >
            </div>
            <div v-if="option.selectedColor" class="w-1/2 h-full mt-6">
              <div class="w-40 flex items-center relative">
                <!-- v-click-outside="closeMaterialDrop" -->
                <div
                  class="relative z-50 w-40 bg-primary1 rounded-lg rounded-tl-none"
                >
                  <button
                    v-if="
                      currentPackage.enchancements.findIndex(
                        (elem) => elem.code == option.code
                      ) >= 0
                    "
                    @click="toggleDropdown(option)"
                    :class="[option.dropdown ? 'animationprimary3' : '']"
                    class="h-12 w-40 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none"
                  >
                    <div
                      class="w-40 h-12 flex items-center justify-between pl-4 pr-2"
                    >
                      <span>{{ option.selectedColor.brandDescText }}</span>
                      <div
                        class="text-lightGrayText w-6"
                        :class="{ arrowRotation: option.dropdown }"
                      >
                        <ArrowDropdownSvg />
                      </div>
                    </div>
                  </button>
                  <div
                    v-if="option.dropdown"
                    class="w-40 absolute top-0 mt-12 bg-primary1 border-4 border-t-0 border-t-none border-primary1 rounded-lg rounded-tl-none rounded-tr-none"
                  >
                    <div
                      v-for="color in option.colors"
                      @click="toggleDropdown(option)"
                      :key="color.id"
                    >
                      <div
                        v-if="color != option.selectedColor"
                        @click="selectColor(option, color)"
                        class="h-12 w-full rounded-md border-t-2 border-primary1 rounded-tl-none bg-white flex items-center px-4 button text-textDark"
                      >
                        <div v-if="color.image">
                          <img
                            class="w-12 mr-3"
                            :alt="color.brandDescText"
                            :src="color.image"
                          />
                        </div>
                        <span>{{ color.brandDescText }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="w-full h-3/12 flex items-end">
                        <div @click="solidSelected = !solidSelected" :class="{'bg-primary3': solidSelected}" class="h-10 w-10 border-2 border-toggleGray rounded-lg rounded-tl-none ">
                            <div v-if="!solidSelected"  class="flex justify-center items-center">
                                <svg class="checkbox_blank w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.8 62.63"><path d="M3.32 36.82c6.71-4.25 11.51-9 13.27.73s3.86 9.41 8.34 4.17S56.35 1.8 64.41 1.06c8.33-.76 1.16 5.13-20 30.24s-18.8 30.33-25.8 30.33S8.1 53.16 5.8 47.85s-7.96-7.57-2.48-11.03z" fill="#fff" stroke="#c8c8c8" stroke-miterlimit="10" stroke-width="2" fill-rule="evenodd" data-name="Layer 2"/></svg>
                            </div>
                            <div v-if="solidSelected"  class="flex justify-center items-center">
                                <svg class="checkbox_selected fill-current text-primary3Text w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.03 42.69"><path d="M1.63 25.22c4.73-3 8.11-6.31 9.35.51s2.71 6.63 5.87 2.94S39 .56 44.65 0c5.86-.53.81 3.61-14.08 21.29s-13.24 21.4-18.17 21.4-7.4-6-9-9.71-5.63-5.32-1.77-7.76z" fill-rule="evenodd" data-name="Layer 2"/></svg>
                            </div>
                        </div>
                        <label class="underline ml-4">tint solid &nbsp<span>+$30</span></label>
                    </div> -->
        </div>
      </div>
    </div>
    <div
      class="h-1/12 w-11/12 flex items-center justify-end portrait:justify-center portrait:items-end portrait:pb-4"
    >
      <button
        @click="goToCheckout"
        class="underline text-xl h-12 w-48 mb-20 portrait:mb-0 portrait:mr-0 bg-primary3 text-primary3Text rounded-lg rounded-tl-none block focus:outline-none"
      >
        Continue
      </button>
    </div>
  </section>
</template>

<script>
const cloneDeep = require("clone-deep");
import CheckboxBlankSvg from "../../assets/svg/icons/checkOutlineBlank.svg";
import CheckboxSelectedSvg from "../../assets/svg/icons/checkOutlineSelected.svg";
import ArrowDropdownSvg from "../../assets/svg/icons/Arrow_5.svg";

export default {
  name: "customize",
  data() {
    return {
      insuranceSelected: false,
      mirrorDropdown: false,
      tintDropdown: false,
      polishSelected: false,
      groovedSelected: false,
      drillSelected: false,
      factorySelected: false,
      mirrorSelected: false,
      solidSelected: false,
      gradientSelected: false,
      currentColorOptions: [],
      dropdownToggles: {},

      tintColors: {
        blue: {
          name: "Blue",
          code: "MIRR",
          img: "./img/mirrorColors/Xperio_mirrorBlue.png",
        },
        gold: {
          name: "Gold",
          code: "MIRR",
          img: "./img/mirrorColors/Xperio_mirrorGold.png",
        },
        silver: {
          name: "Silver",
          code: "MIRR",
          img: "./img/mirrorColors/Xperio_mirrorSilver.png",
        },
        noMirror: {
          name: "No Mirror",
          code: "NOMIRR",
          img: "./img/mirrorColors/Xperio_graymist.png",
        },
      },
    };
  },
  methods: {
    toggleOption(option) {
      let tempPackage = cloneDeep(this.currentPackage);
      const indexOf = tempPackage.enchancements.findIndex(
        (elem) => elem.code == option.code
      );
      if (indexOf >= 0) {
        tempPackage.enchancements.splice(indexOf, 1);
      } else {
        tempPackage.enchancements.push(option);
        this.$store.dispatch("sendEvent", {
          hitType: "event",
          eventCategory: "button",
          eventAction: "click",
          eventLabel: "Enchancements " + option.lensType,
          dimension1: this.currentUser.id,
          dimension2: this.currentUser.group.id,
          dimension3: this.view,
        });
      }

      this.$store.dispatch("createTempPackage", {
        pack: tempPackage,
        packageIndex: this.currentPackageIndex,
        edited: true,
      });
      this.$store.dispatch("setLens", tempPackage);
    },
    toggleDropdown(option) {
      this.$set(option, "dropdown", !option.dropdown);
      this.$forceUpdate();
    },
    selectColor(option, color) {
      let tempPackage = cloneDeep(this.currentPackage);
      const indexOf = tempPackage.enchancements.findIndex(
        (elem) => elem.code == option.code
      );
      if (indexOf >= 0) {
        tempPackage.enchancements.splice(indexOf, 1);
      }
      option.selectedColor = color;
      tempPackage.enchancements.push(option);
      this.$store.dispatch("createTempPackage", {
        pack: tempPackage,
        packageIndex: this.currentPackageIndex,
        edited: true,
      });
      this.$store.dispatch("setLens", tempPackage);

      this.$store.dispatch("sendEvent", {
        hitType: "event",
        eventCategory: "button",
        eventAction: "click",
        eventLabel: "Enchancements " + option.selectedColor.brandDescText,
        dimension1: this.currentUser.id,
        dimension2: this.currentUser.group.id,
        dimension3: this.view,
      });

    },
  },
  props: {
    goToCheckout: Function,
  },
  components: {
    CheckboxBlankSvg,
    CheckboxSelectedSvg,
    ArrowDropdownSvg,
  },
  created() {
    this.currentColorOptions = this.lenses.filter((option) => {
      return option.itemType == "extra" && option.price > 0 && option.colors;
    });
    this.currentColorOptions.forEach((option, optionIndex) => {
      //for each color in the option find the color item in the lenses sheet and replace the string with the color object
      option.dropdown = false;
      if (!Array.isArray(option.colors)) {
        option.colors = option.colors
          .split(",")
          .map((v) => v.toLowerCase().trim());

        option.colors.forEach((color, colorIndex) => {
          const _color = this.lenses.find((lens) => {
            return (
              lens.mod == option.code &&
              lens.itemType == "color" &&
              lens.brandDescText.toLowerCase() == color
            );
          });
          this.currentColorOptions[optionIndex].colors[colorIndex] = _color;
        });
        this.selectColor(option, option.colors[0]);
        this.toggleOption(option);
      }
    });
  },
  // props: {
  //   currentPatient: Function
  // },
  computed: {
    currentPatient(){
      return this.$store.getters.getCurrentPatient
    },
    currentMod() {
      const mod = this.$store.getters.getCurrentIndex;
      if (mod == "polarized") {
        return mod;
      } else if (mod == 'computer'){
        return mod;
      }
      return "";
    },
    currentPackage() {
      return this.$store.getters.getCurrentLens;
    },
    lenses() {
      return this.$store.getters.getLenses;
    },
    currentOptions() {
      const optionsArray = this.lenses.filter((option) => {
        return option.itemType == "extra" && option.price > 0 && !option.colors;
      });
      return optionsArray;
    },
    currentPackageIndex() {
      return this.$store.getters.getCurrentPackageIndex;
    },
    currentView() {
      return this.$store.getters.getView;
    },
    currentUser() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style scoped>
.frameRow:nth-child(even) {
  @apply bg-toggleGray;
}
.button {
  @apply scale-0 duration-300;
}
.animationprimary3 {
  @apply h-auto scale-100 border-4 border-primary1 z-50;
}
.arrowRotation {
  @apply transform rotate-90;
}
</style>
