export function capitalize(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function correctPrice(frame){ //TODO: remove when frames price is corrected on server
  // if (frame.Attributes == 'Ophthalmic Frame'){
  //     return frame.Cost*2.75
  // }
  // return frame.Cost*2
  return frame.price.toFixed(2)
}

export function isEmpty(obj){
  return Object.keys(obj).length === 0;
}

export function clone(obj) { //clone js object
  if (obj == null || typeof (obj) != 'object')
    return obj;

  var temp = new obj.constructor();
  for (var key in obj)
    temp[key] = clone(obj[key]);

  return temp;
}

export function generateToast(text){
  this.$toasted.show(text, {
    position: "top-center",
    className: "coming-soon-toast",
    duration: 3000,
    action : {
      text : 'Close',
      onClick : (e, toastObject) => {
          toastObject.goAway(0);
      }
    },
  })
}

export function initApp(){
  this.$store.dispatch('getBranding')
  this.$store.dispatch('getPreAppointment')
  // this.$store.dispatch('getNewLenses')
  this.$store.dispatch('getLenses')
  .then(res => {
    this.$store.dispatch('getPackages')
    .then(res => {
      this.$store.dispatch('generatePackages')
      .catch(err => {
        this.$store.dispatch('logout')
        .then(res => {
          this.$store.dispatch('setPreload', true)
        })
      })
    })
  })
  this.$store.dispatch('getFrames')
  .catch(err => {
    this.$store.dispatch('logout')
  .then(res => {
    this.$store.dispatch('setPreload', true)
  })
  })
}
