<template>
  <button @click="$emit('click')" class="w-28 h-40 mx-auto bg-primary3 text-primary3Text focus:outline-none flex items-center justify-center">
    <div class="w-full h-full flex flex-col items-center justify-center">
      <svg class="icon_PhotoBooth w-16 fill-current text-primary3Text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.6 108.87"><g data-name="Layer 2"><path d="M70.71 108.86H13.44c-9 0-13.43-4.44-13.43-13.58Q0 63 0 30.65c0-8.82 4.25-13.11 12.93-13.12 6.32 0 12.65.14 19-.1a8.47 8.47 0 005-2.12c3.81-3.48 7.38-7.21 10.91-11A12.87 12.87 0 0157.7 0Q71 .08 84.4 0a12.38 12.38 0 019.35 3.87c3.87 4 7.7 8 11.75 11.78a7.17 7.17 0 004.33 1.79c6.57.19 13.16 0 19.73.09 7.43 0 12 4.58 12 12.1q.08 33.48 0 67c0 7.53-4.8 12.24-12.42 12.26-19.47 0-38.95-.04-58.43-.03zm31.46-48.65a31.38 31.38 0 10-31.32 31.58 31.45 31.45 0 0031.32-31.58zm25.72-24.71c2.7 0 5.16-2.23 4.9-4.84s-1.79-4.6-4.72-4.66-4.55 1.67-4.9 4.44a4.74 4.74 0 004.72 5.06z"/><path d="M94.79 60.22a24 24 0 11-24.15-24.16 24 24 0 0124.15 24.16z"/></g></svg>
      <p class="w-1/2 mt-2">capture photo</p>
    </div>
  </button>
  <!-- <div class="w-40 h-64 mx-auto rounded-lg rounded-tl-none flex flex-col justify-center bg-primary1 text-primary1Text">
    <div class="w-full h-4/12 flex rounded-lg rounded-tl-none rounded-bl-none">
      <button class="w-1/2 border-r-2 border-primary1Text text-primary1Text flex justify-center items-center disable-dbl-tap-zoom">
        <svg class="zoomIn fill-current text-primary1Text w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.07 102.19">
          <path
            d="M0 39a29.91 29.91 0 01.56-6.6 38.78 38.78 0 0113.09-23 35.66 35.66 0 018.43-5.5 53.23 53.23 0 015.4-2.15A47.54 47.54 0 0132.37.61 32.13 32.13 0 0140.5.05a44.76 44.76 0 014.9.54 39.79 39.79 0 015.06 1.14A39.75 39.75 0 0156.07 4a38.49 38.49 0 0110.44 7.4 37.92 37.92 0 016.2 8 33.49 33.49 0 013.07 6.76 51.32 51.32 0 011.49 5.43 34.93 34.93 0 01.59 10.55 42.56 42.56 0 01-.72 4.92 47.81 47.81 0 01-1.68 5.66 34.74 34.74 0 01-2.16 4.82 50.21 50.21 0 01-2.87 4.49c-1 1.47-1 1 .15 2.2.67.7 1.39 1.36 2 2.07a.73.73 0 001 .18 6.86 6.86 0 014.26-.48 7.22 7.22 0 013.85 2.07l6 6L99.54 86a8.41 8.41 0 012 2.75 7.3 7.3 0 01.26 4.88 7.58 7.58 0 01-2 3.28 53.6 53.6 0 01-4.1 3.88 6.66 6.66 0 01-6.32 1.1 9.31 9.31 0 01-3.94-2.71L75.06 88.82c-2.22-2.22-4.45-4.44-6.66-6.67a8.74 8.74 0 01-1.92-2.63 7.13 7.13 0 01-.29-5.12 5.8 5.8 0 01.19-.59c.29-.68.3-.67-.21-1.17-.88-.88-1.76-1.75-2.63-2.64a.5.5 0 00-.77-.06c-1.55 1.06-3.07 2.19-4.71 3.11a40.15 40.15 0 01-5 2.36c-1.63.63-3.31 1.11-5 1.58a31.27 31.27 0 01-3.34.65 34.4 34.4 0 01-6.57.43 40.91 40.91 0 01-8.52-1.15 38.56 38.56 0 01-5.48-1.79A36.21 36.21 0 0118 72 39.66 39.66 0 017.09 61.52a38.26 38.26 0 01-3.64-6.37 41.49 41.49 0 01-2.09-5.81A43.6 43.6 0 010 41.66V39zm7.08 0A31.86 31.86 0 1039 7.19 31.86 31.86 0 007.11 39zm52.28-4.74H42.72V17.6a4 4 0 10-8 0v16.68H18a4 4 0 000 8h16.72V59a4 4 0 008 0V42.28h16.67a4 4 0 000-8z"
            data-name="Layer 2"/>
        </svg>
      </button>
      <button class="w-1/2 text-primary1Text flex justify-center items-center focus:outline-none disable-dbl-tap-zoom">
        <svg class="zoomOut fill-current text-primary1Text w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.07 102.19">
          <path
            d="M0 39a29.91 29.91 0 01.56-6.6 38.78 38.78 0 0113.09-23 35.66 35.66 0 018.43-5.5 53.23 53.23 0 015.4-2.15A47.54 47.54 0 0132.37.61 32.13 32.13 0 0140.5.05a44.76 44.76 0 014.9.54 39.79 39.79 0 015.06 1.14A39.75 39.75 0 0156.07 4a38.49 38.49 0 0110.44 7.4 37.92 37.92 0 016.2 8 33.49 33.49 0 013.07 6.76 51.32 51.32 0 011.49 5.43 34.93 34.93 0 01.59 10.55 42.56 42.56 0 01-.72 4.92 47.81 47.81 0 01-1.68 5.66 34.74 34.74 0 01-2.16 4.82 50.21 50.21 0 01-2.87 4.49c-1 1.47-1 1 .15 2.2.67.7 1.39 1.36 2 2.07a.73.73 0 001 .18 6.86 6.86 0 014.26-.48 7.22 7.22 0 013.85 2.07l6 6L99.54 86a8.41 8.41 0 012 2.75 7.3 7.3 0 01.26 4.88 7.58 7.58 0 01-2 3.28 53.6 53.6 0 01-4.1 3.88 6.66 6.66 0 01-6.32 1.1 9.31 9.31 0 01-3.94-2.71L75.06 88.82c-2.22-2.22-4.45-4.44-6.66-6.67a8.74 8.74 0 01-1.92-2.63 7.13 7.13 0 01-.29-5.12 5.8 5.8 0 01.19-.59c.29-.68.3-.67-.21-1.17-.88-.88-1.76-1.75-2.63-2.64a.5.5 0 00-.77-.06c-1.55 1.06-3.07 2.19-4.71 3.11a40.15 40.15 0 01-5 2.36c-1.63.63-3.31 1.11-5 1.58a31.27 31.27 0 01-3.34.65 34.4 34.4 0 01-6.57.43 40.91 40.91 0 01-8.52-1.15 38.56 38.56 0 01-5.48-1.79A36.21 36.21 0 0118 72 39.66 39.66 0 017.09 61.52a38.26 38.26 0 01-3.64-6.37 41.49 41.49 0 01-2.09-5.81A43.6 43.6 0 010 41.66V39zm7.08 0A31.86 31.86 0 1039 7.19 31.86 31.86 0 007.11 39zm52.28 3.26H18a4 4 0 010-8h41.39a4 4 0 010 8z"
            data-name="Layer 2"
          />
        </svg>
      </button>
    </div>
    <button @click="$emit('click')" class="w-full h-8/12 mx-auto rounded-lg rounded-tl-none bg-primary3 text-primary3Text focus:outline-none flex items-center justify-center">
      <div class="w-full h-full flex flex-col items-center justify-center">
        <svg class="icon_PhotoBooth w-16 fill-current text-primary3Text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.6 108.87"><g data-name="Layer 2"><path d="M70.71 108.86H13.44c-9 0-13.43-4.44-13.43-13.58Q0 63 0 30.65c0-8.82 4.25-13.11 12.93-13.12 6.32 0 12.65.14 19-.1a8.47 8.47 0 005-2.12c3.81-3.48 7.38-7.21 10.91-11A12.87 12.87 0 0157.7 0Q71 .08 84.4 0a12.38 12.38 0 019.35 3.87c3.87 4 7.7 8 11.75 11.78a7.17 7.17 0 004.33 1.79c6.57.19 13.16 0 19.73.09 7.43 0 12 4.58 12 12.1q.08 33.48 0 67c0 7.53-4.8 12.24-12.42 12.26-19.47 0-38.95-.04-58.43-.03zm31.46-48.65a31.38 31.38 0 10-31.32 31.58 31.45 31.45 0 0031.32-31.58zm25.72-24.71c2.7 0 5.16-2.23 4.9-4.84s-1.79-4.6-4.72-4.66-4.55 1.67-4.9 4.44a4.74 4.74 0 004.72 5.06z"/><path d="M94.79 60.22a24 24 0 11-24.15-24.16 24 24 0 0124.15 24.16z"/></g></svg>
      </div>
    </button>
  </div> -->
</template>
<script>
export default {
  name: 'CaptureButton',
  methods: {
  }
}
</script>