<template>
  <div
    class="w-full h-16 flex items-center relative"
  >
    <button
      @click="dropdownState = !dropdownState"
      :class="[dropdownState ? 'animationprimary3' : '']"
      class="absolute bottom-0 h-16 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray rounded-lg rounded-tl-none flex flex-col text-white focus:outline-none"
    >
      <div v-if="dropdownState" class="w-56 text-textDark z-50 bg-primary3">
        <div
          v-for="(image, name) in dropdownImages"
          :key="name"
          @click="$emit('select-item', name, 'human')"
        >
          <div

            class="h-16 w-full rounded-md border-b-2 border-primary3 rounded-tl-none bg-lightGray flex items-center button"
          >
            <div class="w-40" v-html="image.img"></div>
          </div>
        </div>
      </div>

      <div class="w-full h-16 flex items-center pr-2">
        <div class="w-48" v-html="selectedItem.img"></div>
        <div :class="{arrowRotation: dropdownState}">
          <svg
            class="arrow5 fill-current text-primary3 w-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40.19 40.19"
          >
            <g data-name="Layer 2">
              <circle cx="20.1" cy="20.1" r="20.1" fill="none" />
              <path d="M5.11 13.87l14.98 19.66 14.99-19.66H5.11z" />
            </g>
          </svg>
        </div>
      </div>
    </button>
  </div>

</template>

<script>
export default {
  name: 'Dropdown',
  data() {
    return {
      dropdownState: false
    }
  },
  props: {
    // dropdownState: Boolean,
    dropdownImages: Object,
    selectedItem: Object,
    selectItem: Function
  }

}
</script>

<style>
  .animationprimary3 {
    @apply h-auto scale-100 border-4 border-primary3 z-50;
  }
  .animationPrimary2 {
    @apply h-auto scale-100 border-4 border-primary2 z-50;
  }
  .arrowRotation {
    @apply transform rotate-90;
  }
</style>