var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "office-fees h-full w-full flex justify-center items-center bg-backOpacity fixed top-0 z-1000"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "h-7/12 w-5/12 flex flex-col items-center border-4 border-backgroundText rounded-lg rounded-tl-none bg-background text-backgroundText portrait:w-10/12 portrait:h-8/12"
        },
        [
          _c("div", { staticClass: "w-full h-12 flex justify-end" }, [
            _c(
              "button",
              {
                staticClass: "w-10 block focus:outline-none",
                on: { click: _vm.toggleOfficeFees }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass:
                      "icon_x fill-current stroke-current text-textDark w-6",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 73.86 73.8"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "4.85",
                          "fill-rule": "evenodd",
                          "data-name": "Layer 2"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            )
          ]),
          _c(
            "main",
            {
              staticClass:
                "h-11/12 w-full flex flex-col items-center justify-around"
            },
            [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass:
                    "w-full h-11/12 flex flex-col justify-around mt-4"
                },
                [
                  _c("div", { staticClass: "w-full h-4/12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "h-1/2 w-full flex items-center justify-center -ml-12"
                      },
                      [
                        _c("span", { staticClass: "w-10" }, [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "icon_x fill-current stroke-current text-backgroundText w-6",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 60.53 62.52"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Layer_2",
                                    "data-name": "Layer 2"
                                  }
                                },
                                [
                                  _c("g", { attrs: { id: "keyboard" } }, [
                                    _c("g", [
                                      _c("g", [
                                        _c("circle", {
                                          attrs: {
                                            cx: "18.35",
                                            cy: "56.74",
                                            r: "5.78"
                                          }
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M49.21,51A5.78,5.78,0,1,0,55,56.74,5.77,5.77,0,0,0,49.21,51Z"
                                          }
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M53.67,6.07H12.56C9.57.72,8.06.67,7,.67H0V6.2H6.11A29.64,29.64,0,0,1,8.19,9.64c.31.86.76,1.91,1.32,3.16,2,4.5,7,13.64,8.48,17.16-4.47,6.65-6.85,10.72-5.16,13.89.58,1.11,1.88,2.42,4.72,2.42h37V40.74L18,40.71a43.81,43.81,0,0,1,4.06-6.93,10.57,10.57,0,0,0,1.16.08H40.36c5,0,6.44.29,7.75-1.47S52.65,25.08,57.62,16,61.13,6.07,53.67,6.07Z"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "text",
                                        {
                                          attrs: {
                                            transform: "translate(24.13 28.95)",
                                            "font-size": "33.74",
                                            fill: "#fff",
                                            "font-family":
                                              "Arial-BoldMT, Arial",
                                            "font-weight": "700"
                                          }
                                        },
                                        [_vm._v("x")]
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._m(1)
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "h-1/2 w-full flex items-center justify-center -ml-8"
                      },
                      [
                        _c("span", { staticClass: "w-10" }, [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "icon_x fill-current stroke-current text-backgroundText w-6",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 60.53 62.52"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Layer_2",
                                    "data-name": "Layer 2"
                                  }
                                },
                                [
                                  _c("g", { attrs: { id: "keyboard" } }, [
                                    _c("g", [
                                      _c("g", [
                                        _c("circle", {
                                          attrs: {
                                            cx: "18.35",
                                            cy: "56.74",
                                            r: "5.78"
                                          }
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M49.21,51A5.78,5.78,0,1,0,55,56.74,5.77,5.77,0,0,0,49.21,51Z"
                                          }
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M53.67,6.07H12.56C9.57.72,8.06.67,7,.67H0V6.2H6.11A29.64,29.64,0,0,1,8.19,9.64c.31.86.76,1.91,1.32,3.16,2,4.5,7,13.64,8.48,17.16-4.47,6.65-6.85,10.72-5.16,13.89.58,1.11,1.88,2.42,4.72,2.42h37V40.74L18,40.71a43.81,43.81,0,0,1,4.06-6.93,10.57,10.57,0,0,0,1.16.08H40.36c5,0,6.44.29,7.75-1.47S52.65,25.08,57.62,16,61.13,6.07,53.67,6.07Z"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "text",
                                        {
                                          attrs: {
                                            transform: "translate(24.13 28.95)",
                                            "font-size": "33.74",
                                            fill: "#fff",
                                            "font-family":
                                              "Arial-BoldMT, Arial",
                                            "font-weight": "700"
                                          }
                                        },
                                        [_vm._v("x")]
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._m(2)
                      ]
                    )
                  ]),
                  _vm._m(3),
                  _vm._m(4)
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-full h-1/12 flex items-center justify-center" },
      [
        _c("p", { staticClass: "text-xl font-bold" }, [
          _vm._v("Office Fees Include")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Exam "),
      _c("span", { staticClass: "font-bold mr-2" }, [_vm._v("$20")]),
      _c("span", { staticClass: "line-through" }, [_vm._v("$168")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Refraction "),
      _c("span", { staticClass: "font-bold mr-2" }, [_vm._v("$20")]),
      _c("span", { staticClass: "line-through" }, [_vm._v("$168")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "h-4/12 w-full flex flex-col justify-center items-center"
      },
      [
        _c("p", [_vm._v("This information is")]),
        _c("p", [
          _vm._v("included in your "),
          _c("span", { staticClass: "underline" }, [_vm._v("Summary")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "h-4/12 flex justify-center items-center" },
      [
        _c(
          "button",
          {
            staticClass:
              "underline h-10 w-24 text-xl pb-1 flex justify-center items-center bg-primary3 text-primary3Text rounded-lg rounded-tl-none focus:outline-none portrait:mb-0 portrait:mr-0"
          },
          [_vm._v("Ok")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }