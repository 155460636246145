var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "lined-bifocal h-full relative flex" }, [
    _c(
      "div",
      {},
      [
        _c("CompareSlider", {
          attrs: {
            leftImage: "./img/demos/Bifocal_Progressive.jpg",
            rightImage: "./img/demos/Bifocal.jpg"
          }
        })
      ],
      1
    ),
    _c(
      "label",
      {
        staticClass:
          "absolute left-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl"
      },
      [_vm._v("Progressive")]
    ),
    _c(
      "label",
      {
        staticClass:
          "absolute right-0 bottom-0 mx-8 my-24 text-white font-semibold textShadow text-2xl"
      },
      [_vm._v("Lined Bifocal")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }