<template>
    <!-- eslint-disable -->
    <!-- <section class="frames-results h-10/12 -mt-6 portrait:mt-0"> -->
       <section class="frames-results h-10/12">
        <div class="h-full">
            <div class="w-full h-10 flex pl-24 mt-3 border-toggleGray border-b-2 landscape:pr-4 portrait:pl-16">
                <div class="w-40 portrait:w-3/12">CODE</div>
                <div class="w-40 portrait:w-2/12">NAME</div>
                <div class="w-40">SIZE</div>
                <div class="w-40">COLOR</div>
                <div class="w-40">RETAIL</div>
                <div class="relative w-2/12 h-full pl-full"></div>
            </div>
            <div class="h-5/6 overflow-auto">
            <!-- @click="$emit('frames-details', frame)" -->
                <div v-for="frame in frames" :key="frame.id" @click="$emit('frames-details', frame)" class="w-full landscape:pr-4 flex items-center pl-24 h-20 border-toggleGray border-b-2 portrait:pl-16 portrait:text-center">
                    <div class="w-40 portrait:w-3/12">{{frame.code}}</div>
                    <div class="w-40 portrait:w-2/12">{{frame.name}}</div>
                    <div class="w-40">{{frame.size}}</div>
                    <div class="w-40">{{frame.color}}</div>
                    <div class="w-40">${{correctPrice(frame)}}</div>
                    <div class="relative w-2/12 h-full pl-full">
                        <img v-if="frame.images && frame.images[0].image" class="absolute left-0 top-0 h-full w-full object-contain portrait:object-contain" :src="frame.images[0].image" alt="">
                        <img v-else class="absolute left-0 top-0 h-full w-full object-contain portrait:object-contain" src="../../assets/img/frames/frame_ImagePlaceholder.png" alt="">
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import { capitalize, correctPrice } from "../../common/functions";
export default {
  name: 'FramesResults',
  data() {
    return {
        correctPrice: correctPrice
    };
  },
  methods: {
      goToLenspackages() {
        this.$router.push('/lenspackage');
        this.$store.dispatch('sendEvent', {
            hitType: 'event',
            eventCategory: 'button',
            eventAction: 'click',
            eventLabel: 'Continue to Lens Packages',
            dimension1: this.currentUser.id,
            dimension2: this.currentUser.group.id,
            dimension3: this.view
        })
    }
  },
  components: {
  },
  props: {
    showFramesDetails: Boolean,
    frames: Array
  },
   computed: {
    currentUser() {
      return this.$store.getters.getUser
    }
  }
};
</script>

<style scoped>
/* .frameRow:nth-child(even) {
    @apply bg-toggleGray;
} */

</style>
