import Config from '../../json/config.json';
import { isEmpty } from "../../common/functions";
import themeStyles from '../../json/brand.config.json';
import axios from 'axios'
import localforage from 'localforage'

function parseJSON(localObject){
  try {
    const obj = JSON.parse(localObject)
    return obj
  } catch (error) {
    return {}
  }
}


function initialState(){
  // localStorage.clear()
  return {
    showLensMaterials: false,
    baseUrl: process.env.VUE_APP_URL,
    config: Config[Config.target],
    lenses: parseJSON(localStorage.getItem('lenses')) || {},
    frames: {},
    packages: parseJSON(localStorage.getItem('packages')) || {},
    lensesDateModified: localStorage.getItem('lenses_date') || '',
    framesDateModified: localStorage.getItem('frames_date') || '',
    packagesDateModified: localStorage.getItem('packages_date') || '',
    downloadingData: {
      lenses: false,
      frames: false,
      packages: false
    },
  }
}

const state = initialState

const mutations = {
  set_lenses(state, lenses) {
    state.lenses = lenses;
    localStorage.setItem('lenses', JSON.stringify(lenses))
  },
  set_lenses_date(state, date) {
    state.lensesDateModified = date
    localStorage.setItem('lenses_date', date)
  },
  // set_new_lenses(state, lenses) {
  //   state.newLenses = lenses;
  //   localStorage.setItem('new_lenses', JSON.stringify(lenses))
  // },
  // set_new_lenses_date(state, date) {
  //   state.newLensesDateModified = date
  //   localStorage.setItem('new_lenses_date', date)
  // },
  set_packages(state, packages) {
    state.packages = packages;
    localStorage.setItem('packages', JSON.stringify(packages))
  },
  set_packages_date(state, date) {
    state.packagesDateModified = date
    localStorage.setItem('packages_date', date)
  },
  set_frames(state, frames) {
    state.frames = frames;
  },
  set_frames_date(state, date) {
    state.framesDateModified = date
    localStorage.setItem('frames_date', date)
  },
  set_downloading_data(state, isDownloading){
    const dKey = Object.keys(isDownloading)[0]
    state.downloadingData[dKey] = isDownloading[dKey]
  },
  clear_databases(state){
    localStorage.removeItem('lenses')
    localforage.removeItem('frames')
    localStorage.removeItem('packages')
    localStorage.removeItem('lenses_date')
    localStorage.removeItem('frames_date')
    localStorage.removeItem('packages_date')
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
}

const actions = {
  getLenses({ commit, state }) {
    return new Promise ((resolve, reject) => {
      commit('set_downloading_data', {lenses: true})
      let modifiedDate = ''
      if (state.lenses && !isEmpty(state.lenses) && state.lensesDateModified){
        modifiedDate = state.lensesDateModified
      }
      axios.get(state.baseUrl + 'api/lenses/master?lastModifiedDate=' + modifiedDate)
      .then(response => {
        if (response.data.error == 'Unauthorized'){
          reject({packages: false})
        }
        if (response.data){
          commit('set_lenses', response.data.data)
          commit('set_lenses_date', response.data.lastModifiedDate)
        }
        commit('set_downloading_data', {lenses: false})
        resolve({lenses: true})
      })
      .catch(err => {
        console.log(err)
        reject({lenses: false})
      })
    })
    
  },
  getPackages({ commit, state }) {
    return new Promise ((resolve, reject) => {
      commit('set_downloading_data', {packages: true})
      let modifiedDate = ''
      if (state.packages && !isEmpty(state.packages) && state.packagesDateModified){
        modifiedDate = state.packagesDateModified
      }
      axios.get(state.baseUrl + 'api/lenses/package?lastModifiedDate=' + modifiedDate)
      .then(response => {
        if (response.data.error == 'Unauthorized'){
          reject({packages: false})
        }
        if (response.data){
          commit('set_packages', response.data.data)
          commit('set_packages_date', response.data.lastModifiedDate)
        }
        commit('set_downloading_data', {packages: false})
        resolve({packages: true})
      })
      .catch(err => {
        console.log(err)
        reject({packages: false})
      })
    })
    
  },
  getFrames({ commit, state }) {
    return new Promise ((resolve, reject) => {
      commit('set_downloading_data', {frames: true})
      let modifiedDate = ''
      if (state.frames && state.framesDateModified){
        modifiedDate = state.framesDateModified
      }
      if (localStorage.getItem('frames')){ //temporary check for deprecated localstorage frames
        localStorage.removeItem('frames')
        modifiedDate = ''
      }
      axios.get(state.baseUrl + 'api/frames?lastModifiedDate=' + modifiedDate)
      .then(response => {
        if (response.data.error == 'Unauthorized'){
          reject({frames: false})
        }
        if (response.data){
          commit('set_frames', response.data.data)
          localforage.setItem('frames', response.data.data)
          commit('set_frames_date', response.data.lastModifiedDate)
          commit('set_downloading_data', {frames: false})
        }
        else{
          localforage.getItem('frames').then(function(frames){
            if (frames){
              commit('set_frames', frames)
              commit('set_downloading_data', {frames: false})
              resolve({frames: true})
              
            }
            reject({frames: false})
          })
        }
        
        // resolve({frames: true})
      })
      .catch(err => {
        console.log(err)
        reject({frames: false})
      })
    })
    
  },
  
}

const getters = {
  getFrames: state => state.frames,
  getLenses: state => state.lenses,
  getLensesDate: state => state.lensesDateModified,
  getPackagesDate: state => state.packagesDateModified,
  getFramesDate: state => state.framesDateModified,
  getIsDownloading: state => state.downloadingData
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}