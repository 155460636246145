var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentView == "Checkout"
        ? _c(
            "main",
            {
              staticClass:
                "h-3/12 w-70 checkout-box bg-background text-backgroundText absolute top-0 right-0 mt-4 border-4 border-primary1 border-r-0 flex flex-col justify-around rounded-bl-lg z-50 portrait:h-1/5 portrait:mt-16 portrait:w-64s"
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-col justify-around h-full" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-10/12 h-2/5 flex items-center self-start pl-8"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-8 text-primary1" },
                        [_c("ShoppingCartSvg")],
                        1
                      ),
                      _c("div", { staticClass: "w-9/12 flex" }, [
                        _c(
                          "span",
                          {
                            staticClass: "font-semibold text-lg mx-2",
                            class: {
                              "line-through":
                                _vm.selfpayDiscount != 0 ||
                                _vm.currentInsurancePlan != "selfpay"
                            }
                          },
                          [_vm._v("$" + _vm._s(_vm.totalPrice) + "*")]
                        ),
                        _vm.discountTotal > 0
                          ? _c("span", { staticClass: "text-lg" }, [
                              _vm._v("$" + _vm._s(_vm.discountTotal))
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full h-3/5 border-t-4 border-primary1 pl-8"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full h-full flex justify-between items-center"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "underline h-12 w-40 text-xl flex justify-between items-center pl-4 pr-4 bg-primary3 text-primary3Text rounded-lg rounded-tl-none focus:outline-none portrait:mb-0 portrait:mr-0",
                              on: { click: _vm.toggleEmailPopup }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "w-8 text-primary3Text" },
                                [_c("IconEmailSvg")],
                                1
                              ),
                              _c("span", {}, [_vm._v("Summary")])
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "w-10  focus:outline-none",
                              on: { click: _vm.toggleInsurancePopup }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-backgroundText w-6" },
                                [_c("CheckoutDetailSvg")],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm.currentView != "Checkout"
        ? _c(
            "main",
            {
              staticClass:
                "checkout-box h-2/12 w-48 absolute top-0 right-0 mt-2 border-4 border-primary1 border-r-0 flex flex-col justify-around rounded-bl-lg bg-white z-40"
            },
            [
              _c(
                "div",
                {
                  staticClass: "w-full flex justify-center items-center",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("checkout")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "w-10 text-primary1" },
                    [_c("ShoppingCartSvg")],
                    1
                  ),
                  _c("span", { staticClass: "underline text-xl mb-1 ml-3" }, [
                    _vm._v("Checkout")
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm.showOfficeFees
        ? _c("OfficeFeesPopup", {
            attrs: { toggleOfficeFees: _vm.toggleOfficeFees }
          })
        : _vm._e(),
      _vm.showEmailPopup
        ? _c("EmailPopup", {
            attrs: { toggleEmailPopup: _vm.toggleEmailPopup }
          })
        : _vm._e(),
      _vm.showInsurancePopup
        ? _c("InsurancePopup", {
            attrs: {
              currentInsurancePlan: _vm.currentInsurancePlan,
              selfpayDiscount: _vm.selfpayDiscount,
              toggleInsurancePopup: _vm.toggleInsurancePopup
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }