var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "insurance-popup h-full w-full bg-white flex justify-center items-center bg-backOpacity fixed top-0 z-1000 "
    },
    [
      _c(
        "div",
        {
          staticClass:
            "h-10/12 w-7/12 relative flex flex-col items-center border-4 border-backgroundText rounded-lg rounded-tl-none bg-background text-backgroundText portrait:w-10/12 portrait:h-8/12"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full h-2/12 text-center flex flex-col items-center justify-center"
            },
            [
              _c("div", { staticClass: "absolute top-0 right-0 m-3" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-6 stroke-current focus:outline-none text-backgroundText",
                    on: { click: _vm.toggleInsurancePopup }
                  },
                  [_c("IconXSvg")],
                  1
                )
              ]),
              _vm.currentPatient.user
                ? _c("div", [
                    _c("h1", { staticClass: "text-xl font-bold" }, [
                      _vm._v(_vm._s(_vm.currentPatient.user.name) + "'s")
                    ]),
                    _c("h1", { staticClass: "text-xl font-bold" }, [
                      _vm._v(" Optician's Lab Summary")
                    ])
                  ])
                : _c("h1", { staticClass: "text-xl font-bold" }, [
                    _vm._v(" Optician's Lab Summary")
                  ])
            ]
          ),
          _c(
            "main",
            {
              staticClass:
                "h-10/12 w-full flex flex-col items-center justify-between overflow-y-auto"
            },
            [
              _vm.wholeCart.cartItems.everyday.checkedOut
                ? _c(
                    "section",
                    {
                      staticClass:
                        "h-auto w-7/12 mt-6 border-t-2 border-backgroundText"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center justify-center" },
                        [
                          _c(
                            "h1",
                            {
                              staticClass:
                                "h-12 flex items-center font-semibold text-lg mr-4"
                            },
                            [_vm._v("Daily Pair")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "font-semibold mr-2",
                              class: {
                                "line-through":
                                  _vm.selfpayDiscount != 0 ||
                                  _vm.currentInsurancePlan != "selfpay"
                              }
                            },
                            [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.calculatePairPrice(
                                      _vm.wholeCart.cartItems.everyday
                                    )
                                  )
                              )
                            ]
                          ),
                          _vm.discountPairPrice > 0
                            ? _c("span", [
                                _vm._v("$" + _vm._s(_vm.discountPairPrice))
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm.wholeCart.cartItems.everyday.frame.name !=
                      "Customer Supplied"
                        ? _c(
                            "div",
                            { staticClass: "flex items-center justify-center" },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass: "flex items-center font-semibold"
                                },
                                [_vm._v("Frames:")]
                              ),
                              _c("div", { staticClass: "flex ml-4" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "font-semibold mr-2",
                                    class: {
                                      "line-through":
                                        _vm.selfpayDiscount != 0 ||
                                        _vm.currentInsurancePlan != "selfpay"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.everyday.frame.price.toFixed(
                                            2
                                          )
                                        ) +
                                        "*"
                                    )
                                  ]
                                ),
                                _vm.discountPairPrice > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "$" + _vm._s(_vm.discountPairPrice)
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.wholeCart.cartItems.everyday.frame.name ==
                      "Customer Supplied"
                        ? _c(
                            "div",
                            { staticClass: "flex items-center justify-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center font-semibold mr-4"
                                },
                                [_vm._v("Frames: ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.everyday.frame.name
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.wholeCart.cartItems.everyday.frame.name !=
                      "Customer Supplied"
                        ? _c(
                            "div",
                            { staticClass: "relative h-18 w-full my-2" },
                            [
                              _vm.wholeCart.cartItems.everyday.frame.images &&
                              _vm.wholeCart.cartItems.everyday.frame.images[0]
                                .image
                                ? _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src:
                                        _vm.wholeCart.cartItems.everyday.frame
                                          .images[0].image,
                                      alt: ""
                                    }
                                  })
                                : _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src: require("../../assets/img/frames/frame_ImagePlaceholder.png"),
                                      alt: ""
                                    }
                                  })
                            ]
                          )
                        : _vm._e(),
                      _vm.wholeCart.cartItems.everyday.frame.name !=
                      "Customer Supplied"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col items-center justify-center"
                            },
                            [
                              _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.everyday.frame.name
                                  )
                                )
                              ]),
                              _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.everyday.frame.color
                                  )
                                )
                              ]),
                              _c("div", {}, [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v("UPC:")
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.everyday.frame.code
                                  )
                                )
                              ]),
                              _c("div", {}, [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v("Size:")
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.everyday.frame.size
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._m(0),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col items-center justify-center mt-2"
                        },
                        [
                          _c("h1", { staticClass: "font-semibold" }, [
                            _vm._v("Lenses:")
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex w-80 items-center justify-center"
                            },
                            [
                              _vm.wholeCart.cartItems.everyday.lens.base
                                .price != 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "mr-2",
                                      class: {
                                        "line-through":
                                          _vm.selfpayDiscount != 0 ||
                                          _vm.currentInsurancePlan != "selfpay"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            _vm.wholeCart.cartItems.everyday
                                              .lens.base.price
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.everyday.lens.base
                                      .lensType
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm.wholeCart.cartItems.everyday.lens.base.brandName
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.wholeCart.cartItems.everyday.lens
                                          .base.brandDescText
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.everyday.lens.material
                            .brandName ||
                          _vm.wholeCart.cartItems.everyday.lens.material
                            .lensType
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.everyday.lens.material
                                    .price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems.everyday
                                                  .lens.material.price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.wholeCart.cartItems.everyday.lens
                                    .material.brandName
                                    ? _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.wholeCart.cartItems.everyday
                                              .lens.material.lensType
                                          )
                                        )
                                      ])
                                    : _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.wholeCart.cartItems.everyday
                                              .lens.material.brandName +
                                              " " +
                                              _vm.wholeCart.cartItems.everyday
                                                .lens.material.lensType
                                          )
                                        )
                                      ])
                                ]
                              )
                            : _vm._e(),
                          _vm._l(
                            _vm.wholeCart.cartItems.everyday.lens.addons,
                            function(addon) {
                              return _c(
                                "div",
                                {
                                  key: addon.id,
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  addon.price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [_vm._v("$" + _vm._s(addon.price))]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(_vm._s(addon.brandDescText))
                                  ])
                                ]
                              )
                            }
                          ),
                          _vm.wholeCart.cartItems.everyday.lens.transition
                            .brandDescText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.everyday.lens
                                    .transition.price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems.everyday
                                                  .lens.transition.price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.wholeCart.cartItems.everyday.lens
                                          .transition.brandDescText
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.everyday.lens.transition
                            .brandDescText &&
                          _vm.wholeCart.cartItems.everyday.lens.color
                            .brandDescText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.wholeCart.cartItems.everyday.lens
                                          .color.brandDescText
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.everyday.lens.polarized
                            .brandDescText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      "Polarized " +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.everyday.lens
                                            .polarized.brandDescText
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.everyday.lens.mirror.price
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.everyday.lens.mirror
                                    .price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems.everyday
                                                  .lens.mirror.price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(
                                      "Mirror Color " +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.everyday.lens
                                            .mirror.name
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._m(1),
                      _vm.wholeCart.cartItems.everyday.lens.enchancements
                        .length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col items-center justify-center mt-2"
                            },
                            [
                              _c("h1", { staticClass: "font-semibold" }, [
                                _vm._v("Treatments:")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex flex-col items-center justify-center"
                                },
                                _vm._l(
                                  _vm.wholeCart.cartItems.everyday.lens
                                    .enchancements,
                                  function(enchancement) {
                                    return _c("div", { key: enchancement.id }, [
                                      _c("div", { staticClass: "flex" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "mr-2",
                                            class: {
                                              "line-through":
                                                _vm.selfpayDiscount != 0 ||
                                                _vm.currentInsurancePlan !=
                                                  "selfpay"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "$" + _vm._s(enchancement.price)
                                            )
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(enchancement.lensType) + " "
                                          ),
                                          enchancement.selectedColor
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    enchancement.selectedColor
                                                      .brandDescText
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ])
                                    ])
                                  }
                                ),
                                0
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm.wholeCart.cartItems.polarized.checkedOut
                ? _c(
                    "section",
                    {
                      staticClass:
                        "h-auto w-7/12 mt-6 border-t-2 border-backgroundText"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center justify-center" },
                        [
                          _c(
                            "h1",
                            {
                              staticClass:
                                "h-12 flex items-center font-semibold text-lg mr-4"
                            },
                            [_vm._v("Sun Pair")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "font-semibold mr-2",
                              class: {
                                "line-through":
                                  _vm.selfpayDiscount != 0 ||
                                  _vm.currentInsurancePlan != "selfpay"
                              }
                            },
                            [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.calculatePairPrice(
                                      _vm.wholeCart.cartItems.polarized
                                    )
                                  )
                              )
                            ]
                          ),
                          _vm.discountPairPrice > 0
                            ? _c("span", [
                                _vm._v("$" + _vm._s(_vm.discountPairPrice))
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm.wholeCart.cartItems.polarized.frame.name !=
                      "Customer Supplied"
                        ? _c(
                            "div",
                            { staticClass: "flex items-center justify-center" },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass: "flex items-center font-semibold"
                                },
                                [_vm._v("Frames:")]
                              ),
                              _c("div", { staticClass: "flex ml-4" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "font-semibold mr-2",
                                    class: {
                                      "line-through":
                                        _vm.selfpayDiscount != 0 ||
                                        _vm.currentInsurancePlan != "selfpay"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.polarized.frame.price.toFixed(
                                            2
                                          )
                                        ) +
                                        "*"
                                    )
                                  ]
                                ),
                                _vm.discountPairPrice > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "$" + _vm._s(_vm.discountPairPrice)
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.wholeCart.cartItems.polarized.frame.name ==
                      "Customer Supplied"
                        ? _c(
                            "div",
                            { staticClass: "flex items-center justify-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center font-semibold mr-4"
                                },
                                [_vm._v("Frames: ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.polarized.frame.name
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.wholeCart.cartItems.polarized.frame.name !=
                      "Customer Supplied"
                        ? _c(
                            "div",
                            { staticClass: "relative h-18 w-full my-2" },
                            [
                              _vm.wholeCart.cartItems.polarized.frame.images &&
                              _vm.wholeCart.cartItems.polarized.frame.images[0]
                                .image
                                ? _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src:
                                        _vm.wholeCart.cartItems.polarized.frame
                                          .images[0].image,
                                      alt: ""
                                    }
                                  })
                                : _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src: require("../../assets/img/frames/frame_ImagePlaceholder.png"),
                                      alt: ""
                                    }
                                  })
                            ]
                          )
                        : _vm._e(),
                      _vm.wholeCart.cartItems.polarized.frame.name !=
                      "Customer Supplied"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col items-center justify-center"
                            },
                            [
                              _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.polarized.frame.name
                                  )
                                )
                              ]),
                              _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.polarized.frame
                                      .color
                                  )
                                )
                              ]),
                              _c("div", {}, [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v("UPC:")
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.polarized.frame.code
                                  )
                                )
                              ]),
                              _c("div", {}, [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v("Size:")
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.polarized.frame.size
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._m(2),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col items-center justify-center mt-2"
                        },
                        [
                          _c("h1", { staticClass: "font-semibold" }, [
                            _vm._v("Lenses:")
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "flex w-80 items-center justify-center"
                            },
                            [
                              _vm.wholeCart.cartItems.polarized.lens.base
                                .price != 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "mr-2",
                                      class: {
                                        "line-through":
                                          _vm.selfpayDiscount != 0 ||
                                          _vm.currentInsurancePlan != "selfpay"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            _vm.wholeCart.cartItems.polarized
                                              .lens.base.price
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.polarized.lens.base
                                      .lensType
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm.wholeCart.cartItems.polarized.lens.base.brandName
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.wholeCart.cartItems.polarized.lens
                                          .base.brandDescText
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.polarized.lens.material
                            .brandName ||
                          _vm.wholeCart.cartItems.polarized.lens.material
                            .lensType
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.polarized.lens
                                    .material.price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems
                                                  .polarized.lens.material.price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.wholeCart.cartItems.polarized.lens
                                    .material.brandName
                                    ? _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.wholeCart.cartItems.polarized
                                              .lens.material.lensType
                                          )
                                        )
                                      ])
                                    : _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.wholeCart.cartItems.polarized
                                              .lens.material.brandName +
                                              " " +
                                              _vm.wholeCart.cartItems.polarized
                                                .lens.material.lensType
                                          )
                                        )
                                      ])
                                ]
                              )
                            : _vm._e(),
                          _vm._l(
                            _vm.wholeCart.cartItems.polarized.lens.addons,
                            function(addon) {
                              return _c(
                                "div",
                                {
                                  key: addon.id,
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  addon.price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [_vm._v("$" + _vm._s(addon.price))]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(_vm._s(addon.brandDescText))
                                  ])
                                ]
                              )
                            }
                          ),
                          _vm.wholeCart.cartItems.polarized.lens.transition
                            .brandDescText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.polarized.lens
                                    .transition.price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems
                                                  .polarized.lens.transition
                                                  .price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.wholeCart.cartItems.polarized.lens
                                          .transition.brandDescText
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.polarized.lens.color
                            .brandDescText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      "Polarized " +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.polarized.lens
                                            .color.brandDescText
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.polarized.lens.polarized
                            .brandDescText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.polarized.lens
                                    .polarized.price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems
                                                  .polarized.lens.polarized
                                                  .price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(
                                      "Polarized " +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.polarized.lens
                                            .brandDescText
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.polarized.lens.mirror.price
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.polarized.lens.mirror
                                    .price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems
                                                  .polarized.lens.mirror.price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(
                                      "Mirror Color " +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.polarized.lens
                                            .mirror.name
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._m(3),
                      _vm.wholeCart.cartItems.polarized.lens.enchancements
                        .length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col items-center justify-center mt-2"
                            },
                            [
                              _c("h1", { staticClass: "font-semibold" }, [
                                _vm._v("Treatments:")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex flex-col items-center justify-center"
                                },
                                _vm._l(
                                  _vm.wholeCart.cartItems.polarized.lens
                                    .enchancements,
                                  function(enchancement) {
                                    return _c("div", { key: enchancement.id }, [
                                      _c("div", { staticClass: "flex" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "mr-2",
                                            class: {
                                              "line-through":
                                                _vm.selfpayDiscount != 0 ||
                                                _vm.currentInsurancePlan !=
                                                  "selfpay"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "$" + _vm._s(enchancement.price)
                                            )
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(enchancement.lensType) + " "
                                          ),
                                          enchancement.selectedColor
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    enchancement.selectedColor
                                                      .brandDescText
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ])
                                    ])
                                  }
                                ),
                                0
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm.wholeCart.cartItems.computer.checkedOut
                ? _c(
                    "section",
                    {
                      staticClass:
                        "h-auto w-7/12 mt-6 border-t-2 border-backgroundText"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center justify-center" },
                        [
                          _c(
                            "h1",
                            {
                              staticClass:
                                "h-12 flex items-center font-semibold text-lg mr-4"
                            },
                            [_vm._v("Computer Pair")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "font-semibold mr-2",
                              class: {
                                "line-through":
                                  _vm.selfpayDiscount != 0 ||
                                  _vm.currentInsurancePlan != "selfpay"
                              }
                            },
                            [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.calculatePairPrice(
                                      _vm.wholeCart.cartItems.computer
                                    )
                                  )
                              )
                            ]
                          ),
                          _vm.discountPairPrice > 0
                            ? _c("span", [
                                _vm._v("$" + _vm._s(_vm.discountPairPrice))
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm.wholeCart.cartItems.computer.frame.name !=
                      "Customer Supplied"
                        ? _c(
                            "div",
                            { staticClass: "flex items-center justify-center" },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass: "flex items-center font-semibold"
                                },
                                [_vm._v("Frames:")]
                              ),
                              _c("div", { staticClass: "flex ml-4" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "font-semibold mr-2",
                                    class: {
                                      "line-through":
                                        _vm.selfpayDiscount != 0 ||
                                        _vm.currentInsurancePlan != "selfpay"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.computer.frame.price.toFixed(
                                            2
                                          )
                                        ) +
                                        "*"
                                    )
                                  ]
                                ),
                                _vm.discountPairPrice > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "$" + _vm._s(_vm.discountPairPrice)
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.wholeCart.cartItems.computer.frame.name ==
                      "Customer Supplied"
                        ? _c(
                            "div",
                            { staticClass: "flex items-center justify-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center font-semibold mr-4"
                                },
                                [_vm._v("Frames: ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.computer.frame.name
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.wholeCart.cartItems.computer.frame.name !=
                      "Customer Supplied"
                        ? _c(
                            "div",
                            { staticClass: "relative h-18 w-full my-2" },
                            [
                              _vm.wholeCart.cartItems.computer.frame.images &&
                              _vm.wholeCart.cartItems.computer.frame.images[0]
                                .image
                                ? _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src:
                                        _vm.wholeCart.cartItems.computer.frame
                                          .images[0].image,
                                      alt: ""
                                    }
                                  })
                                : _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src: require("../../assets/img/frames/frame_ImagePlaceholder.png"),
                                      alt: ""
                                    }
                                  })
                            ]
                          )
                        : _vm._e(),
                      _vm.wholeCart.cartItems.computer.frame.name !=
                      "Customer Supplied"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col items-center justify-center"
                            },
                            [
                              _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.computer.frame.name
                                  )
                                )
                              ]),
                              _c("div", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.computer.frame.color
                                  )
                                )
                              ]),
                              _c("div", {}, [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v("UPC:")
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.computer.frame.code
                                  )
                                )
                              ]),
                              _c("div", {}, [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v("Size:")
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.computer.frame.size
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._m(4),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col items-center justify-center mt-2"
                        },
                        [
                          _c("h1", { staticClass: "font-semibold" }, [
                            _vm._v("Lenses:")
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex w-80 items-center justify-center"
                            },
                            [
                              _vm.wholeCart.cartItems.computer.lens.base
                                .price != 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "mr-2",
                                      class: {
                                        "line-through":
                                          _vm.selfpayDiscount != 0 ||
                                          _vm.currentInsurancePlan != "selfpay"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            _vm.wholeCart.cartItems.computer
                                              .lens.base.price
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.wholeCart.cartItems.computer.lens.base
                                      .lensType
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm.wholeCart.cartItems.computer.lens.base.brandName
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.wholeCart.cartItems.computer.lens
                                          .base.brandDescText
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.computer.lens.material
                            .brandName ||
                          _vm.wholeCart.cartItems.computer.lens.material
                            .lensType
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.computer.lens.material
                                    .price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems.computer
                                                  .lens.material.price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.wholeCart.cartItems.computer.lens
                                    .material.brandName
                                    ? _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.wholeCart.cartItems.computer
                                              .lens.material.lensType
                                          )
                                        )
                                      ])
                                    : _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.wholeCart.cartItems.computer
                                              .lens.material.brandName +
                                              " " +
                                              _vm.wholeCart.cartItems.computer
                                                .lens.material.lensType
                                          )
                                        )
                                      ])
                                ]
                              )
                            : _vm._e(),
                          _vm._l(
                            _vm.wholeCart.cartItems.computer.lens.addons,
                            function(addon) {
                              return _c(
                                "div",
                                {
                                  key: addon.id,
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  addon.price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [_vm._v("$" + _vm._s(addon.price))]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(_vm._s(addon.brandDescText))
                                  ])
                                ]
                              )
                            }
                          ),
                          _vm.wholeCart.cartItems.computer.lens.transition
                            .brandDescText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.computer.lens
                                    .transition.price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems.computer
                                                  .lens.transition.price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.wholeCart.cartItems.computer.lens
                                          .transition.brandDescText
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.computer.lens.color
                            .brandDescText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      "Polarized " +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.computer.lens
                                            .color.brandDescText
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.computer.lens.polarized
                            .brandDescText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _c("span", {}, [
                                    _vm._v(
                                      "Polarized " +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.computer.lens
                                            .polarized.brandDescText
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.wholeCart.cartItems.computer.lens.mirror.price
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex items-center justify-center"
                                },
                                [
                                  _vm.wholeCart.cartItems.computer.lens.mirror
                                    .price != 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mr-2",
                                          class: {
                                            "line-through":
                                              _vm.selfpayDiscount != 0 ||
                                              _vm.currentInsurancePlan !=
                                                "selfpay"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.wholeCart.cartItems.computer
                                                  .lens.mirror.price
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", {}, [
                                    _vm._v(
                                      "Mirror Color " +
                                        _vm._s(
                                          _vm.wholeCart.cartItems.computer.lens
                                            .mirror.name
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._m(5),
                      _vm.wholeCart.cartItems.computer.lens.enchancements
                        .length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col items-center justify-center mt-2"
                            },
                            [
                              _c("h1", { staticClass: "font-semibold" }, [
                                _vm._v("Treatments:")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full flex flex-col items-center justify-center"
                                },
                                _vm._l(
                                  _vm.wholeCart.cartItems.computer.lens
                                    .enchancements,
                                  function(enchancement) {
                                    return _c("div", { key: enchancement.id }, [
                                      _c("div", { staticClass: "flex" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(
                                            "$" + _vm._s(enchancement.price)
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(enchancement.lensType) + " "
                                          ),
                                          enchancement.selectedColor
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    enchancement.selectedColor
                                                      .brandDescText
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ])
                                    ])
                                  }
                                ),
                                0
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _c(
                "section",
                {
                  staticClass:
                    "h-auto w-6/12 mt-6 border-t-2 border-backgroundText border-2"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col items-center justify-center mt-2"
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "flex items-center font-semibold text-lg"
                        },
                        [_vm._v("Total in Checkout:")]
                      ),
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "span",
                          {
                            staticClass: "font-semibold text-lg mx-2",
                            class: {
                              "line-through":
                                _vm.selfpayDiscount != 0 ||
                                _vm.currentInsurancePlan != "selfpay"
                            }
                          },
                          [_vm._v("$" + _vm._s(_vm.totalPrice) + "*")]
                        ),
                        _vm.discountTotal > 0
                          ? _c("span", { staticClass: "text-lg" }, [
                              _vm._v("$" + _vm._s(_vm.discountTotal))
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "section",
                {
                  staticClass:
                    "h-auto w-5/12 border-2 border-backgroundText rounded-xlg rounded-tl-none p-4 mt-2",
                  class: {
                    hidden:
                      _vm.wholeCart.cartItems.everyday.checkedOut &&
                      _vm.wholeCart.cartItems.polarized.checkedOut &&
                      _vm.wholeCart.cartItems.computer.checkedOut
                  }
                },
                [
                  _c(
                    "h2",
                    { staticClass: "flex items-center font-semibold text-lg" },
                    [_vm._v("Optical Treatment Plan Reminder:")]
                  ),
                  !_vm.wholeCart.cartItems.everyday.checkedOut
                    ? _c(
                        "div",
                        { staticClass: "flex flex-col justify-center pt-4" },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "flex items-center font-semibold text-lg pb-2"
                            },
                            [_vm._v("Daily Pair:")]
                          ),
                          _c("p", { staticClass: "leading-5" }, [
                            _vm._v(
                              "Improve your comfort and overall vision experience with a premium vision lens, complete with an anti-reflective coating."
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  !_vm.wholeCart.cartItems.polarized.checkedOut
                    ? _c(
                        "div",
                        { staticClass: "flex flex-col justify-center pt-4" },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "flex items-center font-semibold text-lg pb-2"
                            },
                            [_vm._v("Sun Pair:")]
                          ),
                          _c("p", { staticClass: "leading-5" }, [
                            _vm._v(
                              "Polarization offers superior sun protection for your eyes and reduces glare outdoors."
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  !_vm.wholeCart.cartItems.computer.checkedOut
                    ? _c(
                        "div",
                        { staticClass: "flex flex-col justify-center pt-4" },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "flex items-center font-semibold text-lg pb-2"
                            },
                            [_vm._v("Computer Pair:")]
                          ),
                          _c("p", { staticClass: "leading-5" }, [
                            _vm._v(
                              "Rest your eyes from the strain of screens with blue light blocking glasses specific for your daily digital usage"
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._m(6)
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-full h-40 flex flex-col border-2 border-backgroundText rounded-lg rounded-tl-none p-3 mt-2 hidden"
      },
      [
        _c("h1", { staticClass: "font-semibold" }, [_vm._v("Measureyes:")]),
        _c("div", { staticClass: "flex w-full" }, [
          _c("div", { staticClass: "w-1/2" }, [
            _c("div", { staticClass: "font-semibold" }, [_vm._v("PD")]),
            _c("div", [
              _vm._v("OD:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("29")])
            ]),
            _c("div", [
              _vm._v("OS:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("29")])
            ])
          ]),
          _c("div", { staticClass: "w-1/2" }, [
            _c("div", { staticClass: "font-semibold" }, [_vm._v("OC Height")]),
            _c("div", [
              _vm._v("OD:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("4.5")])
            ]),
            _c("div", [
              _vm._v("OS:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("5")])
            ])
          ])
        ]),
        _c("p", [_vm._v("Notes:")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex flex-col items-center justify-center mt-2 hidden" },
      [
        _c("h1", { staticClass: "font-semibold" }, [
          _vm._v("Light responsive:")
        ]),
        _c("div", { staticClass: "w-full flex items-center justify-center" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("$123")]),
          _c("span", {}, [_vm._v("Transitions")])
        ]),
        _c("div", { staticClass: "w-full flex items-center justify-center" }, [
          _c("span", {}, [_vm._v("XTRActive Gray")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-full h-40 flex flex-col border-2 border-backgroundText rounded-lg rounded-tl-none p-3 mt-2 hidden"
      },
      [
        _c("h1", { staticClass: "font-semibold" }, [_vm._v("Measureyes:")]),
        _c("div", { staticClass: "flex w-full" }, [
          _c("div", { staticClass: "w-1/2" }, [
            _c("div", { staticClass: "font-semibold" }, [_vm._v("PD")]),
            _c("div", [
              _vm._v("OD:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("29")])
            ]),
            _c("div", [
              _vm._v("OS:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("29")])
            ])
          ]),
          _c("div", { staticClass: "w-1/2" }, [
            _c("div", { staticClass: "font-semibold" }, [_vm._v("OC Height")]),
            _c("div", [
              _vm._v("OD:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("4.5")])
            ]),
            _c("div", [
              _vm._v("OS:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("5")])
            ])
          ])
        ]),
        _c("p", [_vm._v("Notes:")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex flex-col items-center justify-center mt-2 hidden" },
      [
        _c("h1", { staticClass: "font-semibold" }, [
          _vm._v("Light responsive:")
        ]),
        _c("div", { staticClass: "w-full flex items-center justify-center" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("$123")]),
          _c("span", {}, [_vm._v("Transitions")])
        ]),
        _c("div", { staticClass: "w-full flex items-center justify-center" }, [
          _c("span", {}, [_vm._v("XTRActive Gray")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-full h-40 flex flex-col border-2 border-backgroundText rounded-lg rounded-tl-none p-3 mt-2 hidden"
      },
      [
        _c("h1", { staticClass: "font-semibold" }, [_vm._v("Measureyes:")]),
        _c("div", { staticClass: "flex w-full" }, [
          _c("div", { staticClass: "w-1/2" }, [
            _c("div", { staticClass: "font-semibold" }, [_vm._v("PD")]),
            _c("div", [
              _vm._v("OD:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("29")])
            ]),
            _c("div", [
              _vm._v("OS:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("29")])
            ])
          ]),
          _c("div", { staticClass: "w-1/2" }, [
            _c("div", { staticClass: "font-semibold" }, [_vm._v("OC Height")]),
            _c("div", [
              _vm._v("OD:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("4.5")])
            ]),
            _c("div", [
              _vm._v("OS:"),
              _c("span", { staticClass: "ml-1" }, [_vm._v("5")])
            ])
          ])
        ]),
        _c("p", [_vm._v("Notes:")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex flex-col items-center justify-center mt-2 hidden" },
      [
        _c("h1", { staticClass: "font-semibold" }, [
          _vm._v("Light responsive:")
        ]),
        _c("div", { staticClass: "w-full flex items-center justify-center" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("$123")]),
          _c("span", {}, [_vm._v("Transitions")])
        ]),
        _c("div", { staticClass: "w-full flex items-center justify-center" }, [
          _c("span", {}, [_vm._v("XTRActive Gray")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "flex justify-center items-center my-4" },
      [_c("p", [_vm._v("* inherent property listed for insurance")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }