var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "about-app h-full w-full flex justify-center items-center bg-backOpacity absolute bottom-0 right-0 z-1000"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "h-8/12 w-5/12 flex flex-col  items-center border-4 border-backgroundText rounded-lg rounded-tl-none bg-background text-backgroundText portrait:w-10/12 portrait:h-6/12"
        },
        [
          _c("div", { staticClass: "w-full h-12 flex justify-end" }, [
            _c(
              "button",
              {
                staticClass: "w-10 block focus:outline-none",
                on: {
                  click: function($event) {
                    return _vm.$store.dispatch("toggleAboutApp")
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass:
                      "icon_x fill-current stroke-current text-textDark w-6",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 73.86 73.8"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "4.85",
                          "fill-rule": "evenodd",
                          "data-name": "Layer 2"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M65 2.49c9.47-.86 1.31 5.83-22.74 34.39S20.87 71.37 12.9 71.37C-1 71.37 55.82 3.33 65 2.49z"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M71.37 58.48c.78-7.93-5.94-5.31-34.49-29.35S1.63-3.08 2.49 6.39c.84 9.17 40.28 39.8 46.24 44.9 4.47 3.83 20.17 32.49 22.64 7.19z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            )
          ]),
          _c(
            "main",
            {
              staticClass:
                "h-10/12 w-full flex flex-col items-center justify-around"
            },
            [
              _c("p", { staticClass: "text-xl" }, [_vm._v("About App")]),
              _c(
                "div",
                {
                  staticClass:
                    "h-4/12 flex flex-col items-center justify-around"
                },
                [
                  _c("div", [
                    _vm._v("Frames DB: "),
                    _c("span", [_vm._v(_vm._s(_vm.framesDate))])
                  ]),
                  _c("div", [
                    _vm._v("Lenses DB: "),
                    _c("span", [_vm._v(_vm._s(_vm.lensesDate))])
                  ]),
                  _c("div", [
                    _vm._v("Packages DB: "),
                    _c("span", [_vm._v(_vm._s(_vm.packagesDate))])
                  ]),
                  _c("div", [
                    _vm._v("App Version: "),
                    _c("span", [_vm._v(_vm._s(_vm.version))])
                  ])
                ]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "w-5/12 h-10  bg-primary1 text-primary1Text flex justify-center items-center bg-p rounded-lg rounded-tl-none focus:outline-none focus:shadow-outline"
                },
                [
                  _c("div", { staticClass: "w-10" }, [
                    _c(
                      "svg",
                      {
                        staticClass:
                          "icon_Refresh fill-current text-primary1Text w-6",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 44.7 44.7"
                        }
                      },
                      [
                        _c(
                          "g",
                          { attrs: { id: "Layer_2", "data-name": "Layer 2" } },
                          [
                            _c("g", { attrs: { id: "keyboard" } }, [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M42.48,20.13a2.21,2.21,0,0,0-2.22,2.22A17.89,17.89,0,0,1,10.64,35.88l2.93.23a2.22,2.22,0,0,0,.34-4.43L5.66,31a2.23,2.23,0,0,0-2.39,2l-.64,8.26a2.22,2.22,0,0,0,2,2.38h.18a2.22,2.22,0,0,0,2.2-2l.23-2.87A22.31,22.31,0,0,0,44.7,22.35,2.22,2.22,0,0,0,42.48,20.13Z"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M4.44,22.35A17.89,17.89,0,0,1,34.77,9.47l-3.14-.24a2.22,2.22,0,1,0-.35,4.42l8.26.64h.17a2.23,2.23,0,0,0,2.21-2.05L42.56,4a2.22,2.22,0,1,0-4.42-.34l-.21,2.71A22.32,22.32,0,0,0,0,22.35a2.22,2.22,0,0,0,4.44,0Z"
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "span",
                    { staticClass: "underline", on: { click: _vm.reloadApp } },
                    [_vm._v("Refresh App")]
                  )
                ]
              ),
              _vm._m(0)
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "w-full flex flex-col justify-center items-center" },
      [
        _c("p", [_vm._v("contact us at:")]),
        _c("p", [_vm._v("support@contentlinq.com")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }