var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "light-reactive h-full relative" }, [
    _vm.currentSetting == "outdoors"
      ? _c(
          "div",
          { staticClass: "h-full w-full relative" },
          [
            _c("div", { staticClass: "relative h-full w-full pl-full" }, [
              _c("img", {
                staticClass: "absolute left-0 top-0 h-full w-full object-cover",
                attrs: {
                  src: "./img/demos/LightReactive_Outdoor_None.jpg",
                  alt: ""
                }
              })
            ]),
            _c(
              "transition",
              {
                attrs: {
                  "enter-active-class":
                    "transition-all duration-3000 ease-in-out",
                  "enter-class": "opacity-0",
                  "enter-to-class": "opacity-100",
                  "leave-class": "opacity-0",
                  "leave-to-class": "opacity-100"
                }
              },
              [
                _c("img", {
                  key: _vm.outdoorsSrc,
                  staticClass:
                    "absolute left-0 top-0 h-full w-full object-cover",
                  attrs: { src: _vm.outdoorsSrc, alt: "" }
                })
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.currentSetting == "indoor"
      ? _c(
          "div",
          { staticClass: "h-full w-full relative" },
          [
            _c("div", { staticClass: "relative h-full w-full pl-full" }, [
              _c("img", {
                staticClass: "absolute left-0 top-0 h-full w-full object-cover",
                attrs: { src: _vm.indoorSrc, alt: "" }
              })
            ]),
            _c(
              "transition",
              {
                attrs: {
                  "enter-active-class":
                    "transition-all duration-3000 ease-in-out",
                  "enter-class": "opacity-0",
                  "enter-to-class": "opacity-100",
                  "leave-class": "opacity-0",
                  "leave-to-class": "opacity-100"
                }
              },
              [
                _c("img", {
                  key: _vm.indoorSrc,
                  staticClass:
                    "absolute left-0 top-0 h-full w-full object-cover",
                  attrs: {
                    src: "./img/demos/LightReactive_Indoor_None.jpg",
                    alt: ""
                  }
                })
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _c("div", [
      _c("div", { staticClass: "absolute top-0 right-0 mt-6 mr-4 z-10" }, [
        _c(
          "button",
          {
            staticClass:
              "button focus:outline-none focus:border-none overflow-hidden",
            class: [_vm.colorDropDown ? "animation" : ""],
            attrs: { id: "btn-color" },
            on: { click: _vm.toggleColor }
          },
          [
            _c(
              "label",
              {
                class: { display: _vm.colorDropDown },
                attrs: { id: "btn-color" }
              },
              [_vm._v("colors")]
            ),
            _vm.colorDropDown
              ? _c("demo-button", {
                  attrs: {
                    changeDemo: _vm.changeColors,
                    currentOption: _vm.currentColor,
                    dropDown: _vm.colorDropDown,
                    title: "colors",
                    changeArray: _vm.colors
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "absolute top-0 right-0 mt-32 mr-4" }, [
        _c(
          "button",
          {
            staticClass:
              "button focus:outline-none focus:border-none overflow-hidden",
            class: [
              _vm.settingDropDown ? "animation" : "",
              _vm.colorDropDown ? "mt-24" : ""
            ],
            attrs: { id: "btn-setting" },
            on: {
              click: function($event) {
                return _vm.changeSetting(_vm.currentSetting)
              }
            }
          },
          [
            _c(
              "label",
              {
                class: { display: _vm.settingDropDown },
                attrs: { id: "btn-setting" }
              },
              [_vm._v("setting")]
            ),
            _vm.settingDropDown
              ? _c("demo-button", {
                  attrs: {
                    dropDown: _vm.settingDropDown,
                    currentOption: _vm.currentSetting,
                    changeDemo: _vm.changeSetting,
                    title: "setting",
                    changeArray: _vm.setting
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "absolute bottom-0 right-0 mb-20 mr-4",
          on: {
            click: function($event) {
              return _vm.updateTransition()
            }
          }
        },
        [
          _c("img", {
            staticClass: "w-16",
            attrs: {
              src: require("../../../assets/img/demo_icon_replay.png"),
              alt: ""
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }