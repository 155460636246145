var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "sideNav absolute left-0 portrait:w-8/12 landscape:w-4/12 h-full z-1000"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "h-11/12 w-full flex flex-col bg-lightGray text-toggleGrayText"
        },
        [
          _c(
            "button",
            {
              staticClass: "sideNav buttons block focus:outline-none",
              on: {
                click: function($event) {
                  return _vm.changeNavBtn("toggleDemo")
                }
              }
            },
            [
              _c("div", { staticClass: "w-20" }, [
                _c(
                  "div",
                  { staticClass: "w-10 text-toggleGrayText" },
                  [_c("LensIconSvg")],
                  1
                )
              ]),
              _c("span", { staticClass: "underline text-toggleGrayText" }, [
                _vm._v("Lens Features")
              ])
            ]
          ),
          _c(
            "button",
            {
              staticClass: "sideNav buttons block focus:outline-none",
              on: {
                click: function($event) {
                  return _vm.changeNavBtn("lifestyle")
                }
              }
            },
            [
              _c("div", { staticClass: "w-20" }, [
                _c(
                  "div",
                  { staticClass: "text-toggleGrayText w-10" },
                  [_c("LifestyleSvg")],
                  1
                )
              ]),
              _c("span", { staticClass: "underline text-toggleGrayText" }, [
                _vm._v("Lifestyle")
              ])
            ]
          ),
          _c(
            "button",
            {
              staticClass: "sideNav buttons block focus:outline-none",
              on: {
                click: function($event) {
                  return _vm.changeNavBtn("photoBooth")
                }
              }
            },
            [
              _c("div", { staticClass: "w-20" }, [
                _c(
                  "div",
                  { staticClass: "text-toggleGrayText w-8 ml-2" },
                  [_c("PhotoBoothSvg")],
                  1
                )
              ]),
              _c("span", { staticClass: "underline text-toggleGrayText" }, [
                _vm._v("Photobooth")
              ])
            ]
          ),
          _c(
            "button",
            {
              staticClass: "sideNav buttons block focus:outline-none",
              on: {
                click: function($event) {
                  return _vm.changeNavBtn("measureEyez")
                }
              }
            },
            [
              _c("div", { staticClass: "w-20" }, [
                _c(
                  "div",
                  { staticClass: "w-12 text-toggleGrayText" },
                  [_c("MeasureyesSvg")],
                  1
                )
              ]),
              _vm._m(0)
            ]
          ),
          _c(
            "button",
            {
              staticClass:
                "sideNav buttons block focus:outline-none bg-toggleGray",
              on: {
                click: function($event) {
                  return _vm.changeNavBtn("clear")
                }
              }
            },
            [
              _c("div", { staticClass: "w-20" }, [
                _c(
                  "div",
                  { staticClass: "text-toggleGrayText w-6 ml-2" },
                  [_c("RefreshSvg")],
                  1
                )
              ]),
              _c("span", { staticClass: "underline text-toggleGrayText" }, [
                _vm._v("Reset All")
              ])
            ]
          ),
          _c(
            "button",
            {
              staticClass:
                "sideNav buttons block focus:outline-none bg-toggleGray",
              on: {
                click: function($event) {
                  return _vm.changeNavBtn("aboutApp")
                }
              }
            },
            [
              _c("div", { staticClass: "w-20" }, [
                _c(
                  "div",
                  { staticClass: "text-toggleGrayText w-6 ml-2" },
                  [_c("AboutAppSvg")],
                  1
                )
              ]),
              _c("span", { staticClass: "underline text-toggleGrayText" }, [
                _vm._v("About App")
              ])
            ]
          ),
          _c(
            "button",
            {
              staticClass:
                "h-4/12 w-full flex items-center justify-start pl-4 bg-toggleGray focus:outline-none"
            },
            [
              _c("div", { staticClass: "w-20" }, [
                _c(
                  "div",
                  { staticClass: "text-toggleGrayText w-6 ml-2" },
                  [_c("LogooutSvg")],
                  1
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "underline text-toggleGrayText bg-toggleGray",
                  on: { click: _vm.logout }
                },
                [_vm._v("Logout")]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "underline text-toggleGrayText" }, [
      _vm._v("Measureyes "),
      _c("span", { staticClass: "font-medium" }, [_vm._v("BETA")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }