<template>
  <div class="progress-bar h-full w-full flex justify-center items-center fixed  top-0 left-0 bg-background text-backgroundText z-110">
    <div class="w-7/12 h-7/12  flex flex-col justify-center items-center">
      <p v-if="pageName == 'lensType' && currentPatient.user" class="mb-10 font-thin text-3xl">Creating {{currentPatient.user.name}}'s Optical Treatment Plan</p>
      <p v-else-if="pageName == 'lensType'" class="mb-10 font-thin text-3xl">Creating Your Optical Treatment Plan</p>
      <div v-if="pageName == 'measureyes'" class="mb-10 font-thin text-3xl text-center">
        <p>Calculating measurements</p>
        <p>for a perfect fit</p>
      </div>
      <img src="../../assets/img/square_loading.gif" alt="">
    </div>
  </div>
</template>

<script>


export default {
  name: 'ProgressPopup',
    data() {
        return {

        }
    },
    props: {
      pageName: String
    },
    computed: {
      currentPatient(){
        return this.$store.getters.getCurrentPatient
      }
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
