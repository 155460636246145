var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "lens-package bg-background text-backgroundText relative h-10/12"
    },
    [
      _c("Navigation"),
      _c("CheckoutBox"),
      _c(
        "main",
        {
          staticClass:
            "h-10/12 flex-col flex relative border-t-4 border-primary1"
        },
        [
          _c("PlansButton"),
          !_vm.showCustomize
            ? _c(
                "div",
                { staticClass: "h-full w-full flex portrait:flex-col" },
                [
                  _c(
                    "section",
                    {
                      staticClass:
                        "w-3/12 h-full ml-16 flex flex-col portrait:flex-row portrait:justify-between portrait:items-center portrait:w-full portrait:h-3/12"
                    },
                    [
                      _vm.currentPatient.user
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-11/12 h-16 flex items-end portrait:absolute top-0"
                            },
                            [
                              _vm.currentMod == ""
                                ? _c(
                                    "h1",
                                    { staticClass: "h-12 font-bold text-lg" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentPatient.user.name) +
                                          "'s Daily Pair "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentMod == "polarized"
                                ? _c(
                                    "h1",
                                    { staticClass: "h-12 font-bold text-lg" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentPatient.user.name) +
                                          "'s Sun Pair "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentMod == "computer"
                                ? _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "h-12 font-bold text-lg leading-6"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentPatient.user.name) +
                                          "'s Computer Pair "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "w-11/12 h-16 flex items-end portrait:absolute top-0"
                            },
                            [
                              _vm.currentMod == ""
                                ? _c(
                                    "h1",
                                    { staticClass: "h-12 font-bold text-lg" },
                                    [_vm._v(" Your Daily Pair ")]
                                  )
                                : _vm._e(),
                              _vm.currentMod == "polarized"
                                ? _c(
                                    "h1",
                                    { staticClass: "h-12 font-bold text-lg" },
                                    [_vm._v(" Your Sun Pair ")]
                                  )
                                : _vm._e(),
                              _vm.currentMod == "computer"
                                ? _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "h-12 font-bold text-lg leading-6"
                                    },
                                    [_vm._v(" Your Computer Pair ")]
                                  )
                                : _vm._e()
                            ]
                          ),
                      _vm.currentFrame.name != "Customer Supplied"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "relative h-4/12 w-10/12 pl-full portrait:w-4/12 portrait:h-6/12 portrait:mt-4"
                            },
                            [
                              _vm.currentFrame.images &&
                              _vm.currentFrame.images[0].image
                                ? _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src: _vm.currentFrame.images[0].image,
                                      alt: ""
                                    }
                                  })
                                : _c("img", {
                                    staticClass:
                                      "absolute left-0 top-0 h-full w-full object-contain",
                                    attrs: {
                                      src: require("../assets/img/frames/frame_ImagePlaceholder.png"),
                                      alt: ""
                                    }
                                  })
                            ]
                          )
                        : _vm._e(),
                      _vm.currentFrame.name == "Customer Supplied"
                        ? _c("div", [
                            _c("div", { staticClass: "font-bold" }, [
                              _vm._v("Frames: No Charge")
                            ]),
                            _c("div", [_vm._v(_vm._s(_vm.currentFrame.name))])
                          ])
                        : _vm._e(),
                      _vm.currentFrame.name != "Customer Supplied"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "h-5/12 w-10/12 flex flex-col justify-between portrait:w-6/12"
                            },
                            [
                              _c("div", [
                                _c("div", {}, [
                                  _vm._v(_vm._s(_vm.currentFrame.name))
                                ]),
                                _c("div", {}, [
                                  _vm._v(_vm._s(_vm.currentFrame.color))
                                ]),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.currentFrame.code))
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-semibold",
                                    class: {
                                      "line-through":
                                        _vm.selfpayDiscount != 0 ||
                                        _vm.currentInsurancePlan != "selfpay"
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            _vm.currentFrame.price.toFixed(2)
                                          ) +
                                          "*"
                                      )
                                    ])
                                  ]
                                )
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "underline text-sm",
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push("/frames")
                                    }
                                  }
                                },
                                [_vm._v(" (edit) ")]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "section",
                    {
                      staticClass:
                        "w-8/12 h-full pr-4 portrait:flex-col portrait:w-full portrait:ml-24 portrait:h-9/12"
                    },
                    [
                      _vm.currentPackage.level == "premium"
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "h-12 flex items-center portrait:relative portrait:mt-0 portrait:mb-1"
                            },
                            [
                              _vm.currentPatient.user
                                ? _c("span", [
                                    _c("span", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.packagePhrases[
                                            _vm.currentPackage.level
                                          ]
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.currentPatient.user.name) +
                                          ": "
                                      )
                                    ])
                                  ])
                                : _c("span", { staticClass: "mr-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.packagePhrases[
                                          _vm.currentPackage.level
                                        ]
                                      ) + " you: "
                                    )
                                  ]),
                              _c(
                                "span",
                                {
                                  staticClass: "font-semibold ml-2",
                                  class: {
                                    "line-through":
                                      _vm.selfpayDiscount != 0 ||
                                      _vm.currentInsurancePlan != "selfpay"
                                  }
                                },
                                [_vm._v("$" + _vm._s(_vm.packagePrice) + "*")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.currentPackage.level != "premium"
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "h-12 flex items-center portrait:relative portrait:mt-0 portrait:mb-1"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.packagePhrases[_vm.currentPackage.level]
                                  ) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "font-semibold ml-2",
                                  class: {
                                    "line-through":
                                      _vm.selfpayDiscount != 0 ||
                                      _vm.currentInsurancePlan != "selfpay"
                                  }
                                },
                                [_vm._v("$" + _vm._s(_vm.packagePrice) + "*")]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full h-10/12 border-4 border-primary1 bg-white text-textDark flex rounded-lg relative portrait:w-10/12"
                        },
                        [
                          _vm.currentPackageIndex > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-8 h-full absolute left-0 -ml-5 flex justify-center items-center"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-16 h-16 flex justify-center items-center",
                                      on: {
                                        click: function($event) {
                                          return _vm.changePackage("left")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "transform rotate-180 w-8 text-primary1"
                                        },
                                        [_c("BundleIconSvg")],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-full w-1/2 pl-8 flex flex-col portrait:pl-8"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "h-1/2 flex flex-col pt-4" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full h-20 flex items-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "click-outside",
                                              rawName: "v-click-outside",
                                              value: _vm.closeMaterialDrop,
                                              expression: "closeMaterialDrop"
                                            }
                                          ],
                                          staticClass:
                                            "relative z-50 w-56 bg-primary1 rounded-lg rounded-tl-none"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "h-12 w-56 portrait:mb-0 portrait:mr-0 bg-primary1 text-primary1Text rounded-lg rounded-tl-none focus:outline-none",
                                              class: [
                                                _vm.lensMaterialDropdown
                                                  ? "materialDropdown"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  _vm.lensMaterialDropdown = !_vm.lensMaterialDropdown
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-56 h-12 flex items-center justify-between pl-4 pr-2"
                                                },
                                                [
                                                  !_vm.currentPackage.material
                                                    .brandName
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentPackage
                                                              .material.lensType
                                                          )
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentPackage
                                                              .material
                                                              .brandName
                                                          )
                                                        )
                                                      ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fill-current w-6 text-primary1Text",
                                                      class: {
                                                        arrowRotation:
                                                          _vm.lensMaterialDropdown
                                                      }
                                                    },
                                                    [_c("ArrowDropdownSvg")],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm.lensMaterialDropdown
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-56 absolute top-0 mt-12 bg-primary1 border-4 border-t-0 border-t-none border-primary1 rounded-lg rounded-tl-none rounded-tr-none",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.lensMaterialDropdown = !_vm.lensMaterialDropdown
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.currentMaterials,
                                                  function(material) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: material.id,
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.selectMaterial(
                                                              material
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm.currentPackage
                                                          .material.code !=
                                                        material.code
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "h-12 w-full rounded-md border-t-2 border-primary1 rounded-tl-none bg-white flex items-center px-4 button text-textDark"
                                                              },
                                                              [
                                                                !material.brandName
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          material.lensType
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          material.brandName
                                                                        )
                                                                      )
                                                                    ])
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full h-20 flex items-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "click-outside",
                                              rawName: "v-click-outside",
                                              value: _vm.closeTypeDrop,
                                              expression: "closeTypeDrop"
                                            }
                                          ],
                                          staticClass:
                                            "relative z-20 w-56 bg-primary1 rounded-lg rounded-tl-none"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "h-12 w-56 portrait:mb-0 portrait:mr-0 bg-primary1 text-primary1Text rounded-lg rounded-tl-none focus:outline-none",
                                              class: [
                                                _vm.lensTypeDropdown
                                                  ? "materialDropdown"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  _vm.lensTypeDropdown = !_vm.lensTypeDropdown
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-56 h-12 flex items-center justify-between pl-4 pr-2"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentPackage.base
                                                          .lensType
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fill-current w-6 text-primary1Text",
                                                      class: {
                                                        arrowRotation:
                                                          _vm.lensTypeDropdown
                                                      }
                                                    },
                                                    [_c("ArrowDropdownSvg")],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm.lensTypeDropdown
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-56 absolute top-0 mt-12 bg-primary1 border-4 border-t-0 border-t-none border-primary1 rounded-lg rounded-tl-none rounded-tr-none",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.lensTypeDropdown = !_vm.lensTypeDropdown
                                                    }
                                                  }
                                                },
                                                _vm._l(_vm.lensTypes, function(
                                                  type,
                                                  index
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeLensType(
                                                            _vm.lensTypes[index]
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm.currentPackage.base
                                                        .lensType !=
                                                      _vm.lensTypes[index].name
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "h-12 w-full rounded-md border-t-2 border-primary1 rounded-tl-none bg-white flex items-center px-4 button text-textDark"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .lensTypes[
                                                                      index
                                                                    ].name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }),
                                                0
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-1/2 flex flex-col pt-4 lg:pt-6"
                                },
                                [
                                  _vm.currentMod != "polarized" &&
                                  _vm.LRCompatiblity
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "click-outside",
                                              rawName: "v-click-outside",
                                              value: _vm.closeTransDrop,
                                              expression: "closeTransDrop"
                                            }
                                          ],
                                          staticClass:
                                            "w-full h-16 flex items-center relative"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "absolute bottom-0 h-16 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none",
                                              class: [
                                                _vm.transitionDropdown
                                                  ? "animationprimary3"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  _vm.transitionDropdown = !_vm.transitionDropdown
                                                }
                                              }
                                            },
                                            [
                                              _vm.transitionDropdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-56 ext-lightGrayText z-50"
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.currentLightResonsives,
                                                        function(
                                                          transition,
                                                          name
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: name,
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.selectTransition(
                                                                    transition,
                                                                    "human"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "h-16 w-full rounded-md border-b-2 border-primary1 rounded-tl-none pl-2 bg-white text-lightGrayText flex items-center button"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "w-40"
                                                                    },
                                                                    [
                                                                      transition.image
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              attrs: {
                                                                                src:
                                                                                  transition.image,
                                                                                alt:
                                                                                  ""
                                                                              }
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  transition.brandDescText
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _vm.currentPackage
                                                        .transition
                                                        .brandDescText
                                                        ? _c(
                                                            "div",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.selectTransition(
                                                                    false,
                                                                    "human"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._m(0)]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-full h-16 flex items-center justify-between px-2"
                                                },
                                                [
                                                  !_vm.currentPackage.transition
                                                    .brandDescText
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "w-40" },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "No Light Responsive"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.currentPackage.transition
                                                    .brandDescText
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "w-40" },
                                                        [
                                                          _vm.currentPackage
                                                            .transition.image
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    _vm
                                                                      .currentPackage
                                                                      .transition
                                                                      .image,
                                                                  alt: ""
                                                                }
                                                              })
                                                            : _c("div", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .currentPackage
                                                                        .transition
                                                                        .brandDescText
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fill-current text-lightGrayText",
                                                      class: {
                                                        arrowRotation:
                                                          _vm.transitionDropdown
                                                      }
                                                    },
                                                    [_c("ArrowDropdownSvg")],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.currentPackage.transition.brandDescText
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "click-outside",
                                              rawName: "v-click-outside",
                                              value: _vm.closeColorDrop,
                                              expression: "closeColorDrop"
                                            }
                                          ],
                                          staticClass:
                                            "w-56 h-16 flex items-center relative"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "absolute bottom-0 h-12 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none",
                                              class: [
                                                _vm.colorDropdown
                                                  ? "animationprimary3"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  _vm.colorDropdown = !_vm.colorDropdown
                                                }
                                              }
                                            },
                                            [
                                              _vm.colorDropdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-56 text-lightGrayText z-50 bg-primary1"
                                                    },
                                                    [
                                                      _vm.currentColors.length >
                                                      1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ml-1 w-full h-14 text-white p-2 flex justify-between text-left z-50 bg-secondary"
                                                            },
                                                            [
                                                              _c("p", {}, [
                                                                _vm._v("colors")
                                                              ]),
                                                              _c(
                                                                "TransitionLogoSvg"
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._l(
                                                        _vm.currentLRColors,
                                                        function(color) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: color.id,
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeColor(
                                                                    color,
                                                                    "human"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm.currentPackage
                                                                .color
                                                                .brandDescText !=
                                                              color.brandDescText
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-lightGrayText flex items-center px-2 button"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "w-12 mr-3"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              attrs: {
                                                                                src:
                                                                                  color.image,
                                                                                alt:
                                                                                  color.brandDescText
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              color.brandDescText
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-full h-12 flex items-center"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "flex w-48"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-12 mx-3"
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src:
                                                                _vm
                                                                  .currentPackage
                                                                  .color.image,
                                                              alt:
                                                                _vm
                                                                  .currentPackage
                                                                  .color
                                                                  .brandDescText
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentPackage
                                                              .color
                                                              .brandDescText
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm.currentLRColors.length > 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "fill-current text-lightGrayText",
                                                          class: {
                                                            arrowRotation:
                                                              _vm.colorDropdown
                                                          }
                                                        },
                                                        [
                                                          _c("ArrowDropdownSvg")
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.currentMod == "polarized"
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "click-outside",
                                              rawName: "v-click-outside",
                                              value: _vm.closePolDrop,
                                              expression: "closePolDrop"
                                            }
                                          ],
                                          staticClass:
                                            "w-56 h-16 flex items-center relative"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "absolute bottom-0 h-12 w-56 mr-8 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none",
                                              class: [
                                                _vm.polDropdown
                                                  ? "animationprimary3"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  _vm.polDropdown = !_vm.polDropdown
                                                }
                                              }
                                            },
                                            [
                                              _vm.polDropdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-56 z-50 bg-primary1"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-full h-14 text-white p-2 flex justify-between text-left z-50 bg-secondary"
                                                        },
                                                        [
                                                          _c("p", {}, [
                                                            _vm._v("colors")
                                                          ]),
                                                          _vm.selectedAddon
                                                            .code == "XPUV"
                                                            ? _c("XperioUvSvg")
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      _vm._l(
                                                        _vm.addonColors,
                                                        function(color, name) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: name,
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.selectPolarizedColor(
                                                                    color,
                                                                    "human"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm.currentPackage
                                                                .color
                                                                .brandDescText !=
                                                              color.brandDescText
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-textDark flex items-center px-3 button"
                                                                    },
                                                                    [
                                                                      color.image
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "w-12",
                                                                              attrs: {
                                                                                src:
                                                                                  color.image,
                                                                                alt:
                                                                                  color.brandDescText
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              color.brandDescText
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-full h-12 flex items-center"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "h-full flex items-center w-48 pl-3"
                                                    },
                                                    [
                                                      _vm.currentPackage.color
                                                        .image
                                                        ? _c("img", {
                                                            staticClass: "w-12",
                                                            attrs: {
                                                              src:
                                                                _vm
                                                                  .currentPackage
                                                                  .color.image,
                                                              alt:
                                                                _vm
                                                                  .currentPackage
                                                                  .color
                                                                  .brandDescText
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-3" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.currentPackage
                                                                .color
                                                                .brandDescText
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fill-current text-lightGrayText",
                                                      class: {
                                                        arrowRotation:
                                                          _vm.polDropdown
                                                      }
                                                    },
                                                    [_c("ArrowDropdownSvg")],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.currentMod == "polarized"
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "click-outside",
                                              rawName: "v-click-outside",
                                              value: _vm.closeMirrorDrop,
                                              expression: "closeMirrorDrop"
                                            }
                                          ],
                                          staticClass:
                                            "w-56 h-16 flex items-center relative"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "absolute bottom-0 h-12 w-56 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none",
                                              class: [
                                                _vm.mirrorDropdown
                                                  ? "animationprimary3"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  _vm.mirrorDropdown = !_vm.mirrorDropdown
                                                }
                                              }
                                            },
                                            [
                                              _vm.mirrorDropdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-56 text-primary1Text z-50 bg-primary1"
                                                    },
                                                    _vm._l(
                                                      _vm.mirrorColors,
                                                      function(color, name) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: name,
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectMirror(
                                                                  color,
                                                                  "human"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm.currentPackage
                                                              .mirror.name !=
                                                            color.name
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-textDark flex items-center px-3 button"
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          key:
                                                                            color.img,
                                                                          staticClass:
                                                                            "w-12 mr-3",
                                                                          attrs: {
                                                                            src:
                                                                              color.img
                                                                          }
                                                                        }
                                                                      )
                                                                    ]),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          color.name
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-full h-12 flex items-center"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "h-full w-48 flex items-center"
                                                    },
                                                    [
                                                      _c("img", {
                                                        key:
                                                          _vm.currentPackage
                                                            .mirror.img,
                                                        staticClass:
                                                          "w-12 mx-3",
                                                        attrs: {
                                                          src:
                                                            _vm.currentPackage
                                                              .mirror.img
                                                        }
                                                      }),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentPackage
                                                              .mirror.name
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fill-current text-lightGrayText",
                                                      class: {
                                                        arrowRotation:
                                                          _vm.mirrorDropdown
                                                      }
                                                    },
                                                    [_c("ArrowDropdownSvg")],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-full w-1/2 relative flex flex-col items-center justify-around"
                            },
                            [
                              _vm.currentTreatments
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "absolute top-0 right-0 m-1",
                                      on: { click: _vm.openCustomize }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "Edit_icon fill-current text-textDark w-6"
                                        },
                                        [_c("EditIconSvg")],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-3/6 flex flex-col items-center justify-center"
                                },
                                [
                                  !_vm.currentPackage.base.image
                                    ? _c(
                                        "div",
                                        { staticClass: "w-48 portrait:h-2/6" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "package-heading" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.currentPackage.base
                                                    .brandDescText
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "w-48 portrait:h-2/6" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                _vm.currentPackage.base.image,
                                              alt:
                                                _vm.currentPackage.base
                                                  .brandDescText
                                            }
                                          })
                                        ]
                                      ),
                                  _vm.currentPackage.base.code.includes("EYZ")
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "click-outside",
                                              rawName: "v-click-outside",
                                              value: _vm.closeEyezenDrop,
                                              expression: "closeEyezenDrop"
                                            }
                                          ],
                                          staticClass:
                                            "w-56  h-16 flex items-center relative"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "absolute bottom-0 h-12 w-56 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none",
                                              class: [
                                                _vm.eyezenDropdown
                                                  ? "animationprimary3"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  _vm.eyezenDropdown = !_vm.eyezenDropdown
                                                }
                                              }
                                            },
                                            [
                                              _vm.eyezenDropdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-56 text-primary1Text z-50 bg-primary1"
                                                    },
                                                    _vm._l(
                                                      _vm.eyezenArray,
                                                      function(option, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.changeEyezenOptions(
                                                                  $event,
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm.selectedEyezen
                                                              .brandDescText !=
                                                            option.brandDescText
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-textDark flex items-center px-3 button"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {},
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            option.brandDescText
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-full h-12 flex items-center"
                                                },
                                                [
                                                  _vm.selectedEyezen
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-48 flex justify-start pl-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .selectedEyezen
                                                                  .brandDescText
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fill-current text-lightGrayText w-6",
                                                      class: {
                                                        arrowRotation:
                                                          _vm.eyezenDropdown
                                                      }
                                                    },
                                                    [_c("ArrowDropdownSvg")],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm.eyezenDropdown
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-full mt-14 absolute top-0 z-40 text-primary text-xs p-2 text-left bg-primary1 text-primary1Text",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.eyezenDropdown = !_vm.eyezenDropdown
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " *It is recommended that you consider progressive lenses for patients aged 45+ "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full h-1/3 flex flex-col justify-center items-center"
                                },
                                [
                                  _c("div", { staticClass: "h-1/3" }, [
                                    _vm._v("with anti-reflective")
                                  ]),
                                  _vm.optionsArray.length == 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-40 h-3/6 portrait:h-2/6 mt-3 flex justify-center items-center"
                                        },
                                        [
                                          _vm.currentAddonImage.img
                                            ? _c("img", {
                                                key: _vm.currentAddonImage.img,
                                                attrs: {
                                                  src: _vm.currentAddonImage.img
                                                }
                                              })
                                            : _c("div", {
                                                staticClass:
                                                  "package-heading leading-6",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.currentAddonImage.div
                                                  )
                                                }
                                              })
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-3/6 w-full flex justify-center items-center portrait:h-2/6"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "click-outside",
                                              rawName: "v-click-outside",
                                              value: _vm.closeOptionDrop,
                                              expression: "closeOptionDrop"
                                            }
                                          ],
                                          staticClass:
                                            "flex justify-center items-center"
                                        },
                                        [
                                          _vm.optionsArray.length > 0
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "absolute h-12 w-56 overflow-hidden portrait:mb-0 portrait:mr-0 bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex flex-col focus:outline-none",
                                                  class: [
                                                    _vm.optionDropdown
                                                      ? "animationprimary3"
                                                      : ""
                                                  ],
                                                  on: {
                                                    click: function($event) {
                                                      _vm.optionDropdown = !_vm.optionDropdown
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.optionDropdown
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-56 text-primary1Text z-50 bg-primary1"
                                                        },
                                                        _vm._l(
                                                          _vm.optionsArray,
                                                          function(
                                                            option,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeAddon(
                                                                      option
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm
                                                                  .selectedAddon
                                                                  .code !=
                                                                option.code
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "h-12 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-textDark flex items-center button"
                                                                      },
                                                                      [
                                                                        option.image
                                                                          ? _c(
                                                                              "img",
                                                                              {
                                                                                staticClass:
                                                                                  "w-48 mx-6",
                                                                                attrs: {
                                                                                  src:
                                                                                    option.image,
                                                                                  alt:
                                                                                    option.brandDescText
                                                                                }
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "w-48"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "w-48"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        option.brandDescText
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-full h-12 flex items-center"
                                                    },
                                                    [
                                                      _vm.currentAddonImage.img
                                                        ? _c("img", {
                                                            key:
                                                              _vm
                                                                .currentAddonImage
                                                                .img,
                                                            staticClass:
                                                              "w-48 flex justify-start px-3",
                                                            attrs: {
                                                              src:
                                                                _vm
                                                                  .currentAddonImage
                                                                  .img
                                                            }
                                                          })
                                                        : _c("div", {
                                                            staticClass:
                                                              "w-48 text-lightGrayText",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm
                                                                  .currentAddonImage
                                                                  .div
                                                              )
                                                            }
                                                          }),
                                                      _c(
                                                        "div",
                                                        {
                                                          class: {
                                                            arrowRotation:
                                                              _vm.optionDropdown
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-lightGrayText"
                                                            },
                                                            [
                                                              _c(
                                                                "ArrowDropdownSvg"
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full h-16 flex justify-end items-end portrait:justify-center portrait:items-center mt-4 mb-2 portrait:mb-0"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "underline h-12 w-48 text-xl mr-8 portrait:mb-0 portrait:mr-0 bg-primary3 rounded-lg rounded-tl-none text-primary3Text block focus:outline-none",
                                      on: { click: _vm.goToCheckout }
                                    },
                                    [_vm._v(" Continue ")]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm.currentPackageIndex < _vm.packageArray.length - 1
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "w-8 h-full absolute right-0 -mr-4 flex justify-center items-center"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-16 h-16 flex justify-center items-center",
                                      on: {
                                        click: function($event) {
                                          return _vm.changePackage("right")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "w-8 text-primary1" },
                                        [_c("BundleIconSvg")],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm.showCustomize
            ? _c("Customize", { attrs: { goToCheckout: _vm.goToCheckout } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "h-16 w-full rounded-md border-b-2 border-primary1 rounded-tl-none bg-white text-lightGrayText flex items-center button"
      },
      [_c("div", { staticClass: "w-48" }, [_vm._v("No Light Responsive")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }