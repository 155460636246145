var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "life-style bg-background text-backgroundText h-10/12" },
    [
      _c(
        "section",
        {
          staticClass:
            "h-2/12 w-full flex items-center justify-between portrait:h-1/12"
        },
        [
          _c("div", { staticClass: "flex items-center ml-4" }, [
            _c("div", { staticClass: "w-10 mr-6" }, [_c("BackButton")], 1),
            _vm.currentPatient.user
              ? _c("p", { staticClass: "text-xl" }, [
                  _vm._v(
                    _vm._s(_vm.currentPatient.user.name) +
                      "'s Optical Treatment Plan:"
                  )
                ])
              : _c("p", { staticClass: "text-xl" }, [
                  _vm._v("Optical Treatment Plan:")
                ])
          ])
        ]
      ),
      _c(
        "section",
        {
          staticClass:
            "h-10/12 w-full flex flex-col portrait:flex-col portrait:justify-between portrait:h-11/12"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex h-full portrait:flex-col portrait:justify-between"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-2/3 h-full pl-20 portrait:h-7/12 portrait:w-full"
                },
                [
                  _vm.currentPatient.user
                    ? _c(
                        "p",
                        {
                          staticClass: "opacity-0 font-semibold portrait:mt-2"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPatient.user.name) +
                              "'s optical needs:"
                          )
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass: "opacity-0 font-semibold portrait:mt-2"
                        },
                        [_vm._v("Patient's optical needs:")]
                      ),
                  _c("div", { staticClass: "h-11/12 w-full flex" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-1/2 h-full flex flex-col justify-around portrait:w-1/2"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "opticalButtons block focus:outline-none",
                            class: { activeButtons: _vm.lifestyle.digital },
                            on: {
                              click: function($event) {
                                return _vm.selectedOption({
                                  key: "digital",
                                  status: !_vm.lifestyle.digital
                                })
                              }
                            }
                          },
                          [
                            !_vm.lifestyle.digital
                              ? _c(
                                  "div",
                                  { staticClass: "w-8 ml-4 mr-5" },
                                  [_c("CheckboxBlankSvg")],
                                  1
                                )
                              : _vm._e(),
                            _vm.lifestyle.digital
                              ? _c("div", { staticClass: "w-8 ml-4 mr-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary3Text" },
                                    [_c("CheckboxSelectedSvg")],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "text-xl" }, [
                              _vm._v("Digital")
                            ])
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "opticalButtons block focus:outline-none",
                            class: { activeButtons: _vm.lifestyle.reading },
                            on: {
                              click: function($event) {
                                return _vm.selectedOption({
                                  key: "reading",
                                  status: !_vm.lifestyle.reading
                                })
                              }
                            }
                          },
                          [
                            !_vm.lifestyle.reading
                              ? _c(
                                  "div",
                                  { staticClass: "w-8 ml-4 mr-5" },
                                  [_c("CheckboxBlankSvg")],
                                  1
                                )
                              : _vm._e(),
                            _vm.lifestyle.reading
                              ? _c("div", { staticClass: "w-8 ml-4 mr-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary3Text" },
                                    [_c("CheckboxSelectedSvg")],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "text-xl" }, [
                              _vm._v("Reading")
                            ])
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "opticalButtons block focus:outline-none",
                            class: { activeButtons: _vm.lifestyle.sports },
                            on: {
                              click: function($event) {
                                return _vm.selectedOption({
                                  key: "sports",
                                  status: !_vm.lifestyle.sports
                                })
                              }
                            }
                          },
                          [
                            !_vm.lifestyle.sports
                              ? _c(
                                  "div",
                                  { staticClass: "w-8 ml-4 mr-5" },
                                  [_c("CheckboxBlankSvg")],
                                  1
                                )
                              : _vm._e(),
                            _vm.lifestyle.sports
                              ? _c("div", { staticClass: "w-8 ml-4 mr-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary3Text" },
                                    [_c("CheckboxSelectedSvg")],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "text-xl" }, [
                              _vm._v("Sports")
                            ])
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "opticalButtons block focus:outline-none",
                            class: { activeButtons: _vm.lifestyle.hiking },
                            on: {
                              click: function($event) {
                                return _vm.selectedOption({
                                  key: "hiking",
                                  status: !_vm.lifestyle.hiking
                                })
                              }
                            }
                          },
                          [
                            !_vm.lifestyle.hiking
                              ? _c(
                                  "div",
                                  { staticClass: "w-8 ml-4 mr-5" },
                                  [_c("CheckboxBlankSvg")],
                                  1
                                )
                              : _vm._e(),
                            _vm.lifestyle.hiking
                              ? _c("div", { staticClass: "w-8 ml-4 mr-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary3Text" },
                                    [_c("CheckboxSelectedSvg")],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "text-xl" }, [
                              _vm._v("Hiking")
                            ])
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-1/2 h-full flex flex-col justify-around portrait:w-1/2"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "opticalButtons block focus:outline-none",
                            class: { activeButtons: _vm.lifestyle.driving },
                            on: {
                              click: function($event) {
                                return _vm.selectedOption({
                                  key: "driving",
                                  status: !_vm.lifestyle.driving
                                })
                              }
                            }
                          },
                          [
                            !_vm.lifestyle.driving
                              ? _c(
                                  "div",
                                  { staticClass: "w-8 ml-4 mr-5" },
                                  [_c("CheckboxBlankSvg")],
                                  1
                                )
                              : _vm._e(),
                            _vm.lifestyle.driving
                              ? _c("div", { staticClass: "w-8 ml-4 mr-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary3Text" },
                                    [_c("CheckboxSelectedSvg")],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "text-xl" }, [
                              _vm._v("Driving")
                            ])
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "opticalButtons block focus:outline-none",
                            class: { activeButtons: _vm.lifestyle.gaming },
                            on: {
                              click: function($event) {
                                return _vm.selectedOption({
                                  key: "gaming",
                                  status: !_vm.lifestyle.gaming
                                })
                              }
                            }
                          },
                          [
                            !_vm.lifestyle.gaming
                              ? _c(
                                  "div",
                                  { staticClass: "w-8 ml-4 mr-5" },
                                  [_c("CheckboxBlankSvg")],
                                  1
                                )
                              : _vm._e(),
                            _vm.lifestyle.gaming
                              ? _c("div", { staticClass: "w-8 ml-4 mr-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary3Text" },
                                    [_c("CheckboxSelectedSvg")],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "text-xl" }, [
                              _vm._v("Gaming")
                            ])
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "opticalButtons block focus:outline-none",
                            class: { activeButtons: _vm.lifestyle.safety },
                            on: {
                              click: function($event) {
                                return _vm.selectedOption({
                                  key: "safety",
                                  status: !_vm.lifestyle.safety
                                })
                              }
                            }
                          },
                          [
                            !_vm.lifestyle.safety
                              ? _c(
                                  "div",
                                  { staticClass: "w-8 ml-4 mr-5" },
                                  [_c("CheckboxBlankSvg")],
                                  1
                                )
                              : _vm._e(),
                            _vm.lifestyle.safety
                              ? _c("div", { staticClass: "w-8 ml-4 mr-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary3Text" },
                                    [_c("CheckboxSelectedSvg")],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "text-xl" }, [
                              _vm._v("Safety")
                            ])
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "opticalButtons block focus:outline-none",
                            class: { activeButtons: _vm.lifestyle.golfing },
                            on: {
                              click: function($event) {
                                return _vm.selectedOption({
                                  key: "golfing",
                                  status: !_vm.lifestyle.golfing
                                })
                              }
                            }
                          },
                          [
                            !_vm.lifestyle.golfing
                              ? _c(
                                  "div",
                                  { staticClass: "w-8 ml-4 mr-5" },
                                  [_c("CheckboxBlankSvg")],
                                  1
                                )
                              : _vm._e(),
                            _vm.lifestyle.golfing
                              ? _c("div", { staticClass: "w-8 ml-4 mr-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary3Text" },
                                    [_c("CheckboxSelectedSvg")],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "text-xl" }, [
                              _vm._v("Golfing")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "w-1/3 h-full flex flex-col portrait:w-full portrait:h-4/12 portrait:items-center"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-10/12 flex flex-col justify-between portrait:flex-row portrait:w-full portrait:h-8/12 portrait:items-center portrait:justify-around portrait:ml-40"
                    },
                    [
                      _vm.insurancePlans.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "h-5/12 portrait:w-1/2 portrait:h-full"
                            },
                            [
                              _c("p", { staticClass: "font-semibold" }, [
                                _vm._v("Vision Insurance:")
                              ]),
                              _c("div", { staticClass: "w-full flex" }, [
                                _c("div", { staticClass: "flex flex-col" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-16 w-28 flex mt-4 ml-4 border-toggleGray border-2 rounded-lg rounded-tl-none rounded-bl-none rounded-br-none",
                                      class: {
                                        activeInsurance: _vm.insurance === true
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.activateInsurance(true)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.insurance === true,
                                              expression: "insurance === true"
                                            }
                                          ],
                                          staticClass:
                                            "w-16 flex items-center justify-center"
                                        },
                                        [
                                          _c("div", { staticClass: "w-8" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-primary3Text"
                                              },
                                              [_c("CheckboxSelectedSvg")],
                                              1
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm.insurance != true
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-16 flex items-center justify-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w-8" },
                                                [_c("CheckboxBlankSvg")],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "h-12 flex justify-center items-end"
                                        },
                                        [_vm._v("Yes")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-16 w-28 flex ml-4 border-toggleGray border-2 border-t-0 rounded-lg rounded-tl-none rounded-tr-none",
                                      class: {
                                        activeInsurance: _vm.insurance === false
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.activateInsurance(false)
                                        }
                                      }
                                    },
                                    [
                                      _vm.insurance === false
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-16 flex items-center justify-center "
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w-8" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-primary3Text"
                                                    },
                                                    [_c("CheckboxSelectedSvg")],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.insurance != false
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-16 flex items-center justify-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w-8" },
                                                [_c("CheckboxBlankSvg")],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "h-12 flex justify-center items-end"
                                        },
                                        [_vm._v("No")]
                                      )
                                    ]
                                  )
                                ]),
                                _vm.insurance === true
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "click-outside",
                                            rawName: "v-click-outside",
                                            value: _vm.hide,
                                            expression: "hide"
                                          }
                                        ],
                                        staticClass:
                                          "w-40 ml-2 flex relative -mt-8",
                                        class: {
                                          "bg-primary2 rounded-tr-lg":
                                            _vm.vspSeclected
                                        }
                                      },
                                      [
                                        _vm.vspSeclected
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "h-12 w-full bg-primary2 text-primary2Text rounded-tr-lg flex items-center pl-4"
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("insurance")
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.insurance === true
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "absolute top-0 mt-12 h-16 w-full overflow-hidden bg-lightGray text-lightGrayText rounded-lg rounded-tl-none flex justify-around items-center focus:outline-none",
                                                class: [
                                                  _vm.vspSeclected
                                                    ? "border-primary2 border-4"
                                                    : ""
                                                ],
                                                on: {
                                                  click: function($event) {
                                                    _vm.vspSeclected = !_vm.vspSeclected
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "flex" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentInsurance
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-backgroundText",
                                                    class: {
                                                      arrowRotation:
                                                        _vm.vspSeclected
                                                    }
                                                  },
                                                  [_c("ArrowDropdownSvg")],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.vspSeclected
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-full absolute top-0 overflow-y-scroll mt-28  bg-primary2 flex flex-col justify-around  border-toggleGray border-solid border-2 rounded-lg rounded-tl-none rounded-tr-none border-t-0",
                                                class: [
                                                  _vm.vspSeclected
                                                    ? "animationPrimary2"
                                                    : ""
                                                ],
                                                on: {
                                                  click: function($event) {
                                                    _vm.vspSeclected = !_vm.vspSeclected
                                                  }
                                                }
                                              },
                                              _vm._l(
                                                _vm.insurancePlans,
                                                function(plan) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: plan.id,
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectPlan(
                                                            plan
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm.currentInsurance !=
                                                      plan
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "h-16 w-full overflow-hidden bg-white text-textDark border-2 my-1 rounded-lg rounded-tl-none pl-2 flex items-center"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(plan) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "h-6/12  portrait:h-full portrait:w-1/2 hidden"
                        },
                        [
                          _c("p", { staticClass: "font-semibold" }, [
                            _vm._v("I wear:")
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "h-10 flex mt-4 ml-4 ",
                              on: {
                                click: function($event) {
                                  _vm.eyewearSeclected = !_vm.eyewearSeclected
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-full w-10 border-2 border-toggleGray rounded-lg rounded-tl-none ",
                                  class: { "bg-primary3": _vm.eyewearSeclected }
                                },
                                [
                                  !_vm.eyewearSeclected
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-center items-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-8" },
                                            [_c("CheckboxBlankSvg")],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.eyewearSeclected
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-center items-center"
                                        },
                                        [
                                          _c("div", { staticClass: "w-8" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-primary3Text"
                                              },
                                              [_c("CheckboxSelectedSvg")],
                                              1
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "h-10 ml-4 flex justify-center items-end"
                                },
                                [_vm._v("Eyewear")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "h-10 flex mt-4 ml-4 ",
                              on: {
                                click: function($event) {
                                  _vm.contactsSeclected = !_vm.contactsSeclected
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-full w-10 border-2 border-toggleGray rounded-lg rounded-tl-none ",
                                  class: {
                                    "bg-primary3": _vm.contactsSeclected
                                  }
                                },
                                [
                                  !_vm.contactsSeclected
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-center items-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w-8" },
                                            [_c("CheckboxBlankSvg")],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.contactsSeclected
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-center items-center"
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "checkbox_selected fill-current text-primary3Text w-8",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                viewBox: "0 0 47.03 42.69"
                                              }
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d:
                                                    "M1.63 25.22c4.73-3 8.11-6.31 9.35.51s2.71 6.63 5.87 2.94S39 .56 44.65 0c5.86-.53.81 3.61-14.08 21.29s-13.24 21.4-18.17 21.4-7.4-6-9-9.71-5.63-5.32-1.77-7.76z",
                                                  "fill-rule": "evenodd",
                                                  "data-name": "Layer 2"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "h-10 ml-4 flex justify-center items-end"
                                },
                                [_vm._v("Contacts")]
                              ),
                              _c(
                                "div",
                                { staticClass: "w-12 ml-8 mt-4" },
                                [_c("LensContactsSvg")],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-2/12 w-full flex items-center landscape:pr-20 justify-end portrait:justify-center portrait:items-end portrait:h-3/12"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "underline h-12 w-48 pb-1 flex justify-center items-center text-xl portrait:mb-0 portrait:mr-0 bg-primary3 text-primary3Text rounded-lg rounded-tl-none text-white focus:outline-none",
                          on: { click: _vm.goToLensType }
                        },
                        [_vm._v(" Continue ")]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }